import { useCallback, useEffect, useState } from "react";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";
import { IsfpProcess, IsfpProcessStatus } from "../types/cockpit/types";

export const API_ENDPOINT = `${apiEndpoint()}/isfps`;

export type SetOfferAcceptedRequest = {
  isfpProcessId: string;
};

export default function useSetIsfpOfferAcceptance() {
  const { authAxios, error, running, data } = useAuthAxios<IsfpProcess>();
  const [isfpProcess, setIsfpProcess] = useState<IsfpProcess>();

  useEffect(() => {
    setIsfpProcess(data);
  }, [data]);

  const postSetOfferAcceptance = useCallback(
    async (isfpId: string, status: IsfpProcessStatus.OFFER_ACCEPTED | IsfpProcessStatus.OFFER_REJECTED) =>
      await authAxios({
        method: "POST",
        baseURL: `${API_ENDPOINT}/${isfpId}/offer`,
        data: JSON.stringify({ status }),
      }),
    [authAxios],
  );

  return { postSetOfferAcceptance, error, running, isfpProcess, setIsfpProcess };
}
