import { useCallback } from "react";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";
import { GetMetricsResponse } from "../pages/cockpit/metrics/types";

export const API_ENDPOINT = `${apiEndpoint()}/metrics`;

export default function useFetchMetrics() {
  const { authAxios, error, running, data } = useAuthAxios<GetMetricsResponse>();

  const fetchMetrics = useCallback(
    async (from?: number, to?: number, allData?: boolean) => {
      const params = allData ? "" : `?from=${from}&to=${to}`;
      return await authAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}${params}`,
      });
    },
    [authAxios],
  );

  return { fetchMetrics, error, isLoading: running, data };
}
