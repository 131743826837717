import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

// this state can be passed in as 'state' url search arg to 'orders/{order.id}/payment/stripe?search={base64encodedState}';
// Stripe payment flow is configured to redirect to this page, under
// 'orders/{order.id}/payment/stripe/result?stripe_session_id={CHECKOUT_SESSION_ID}&search={base64encodedState}'
// and so we can read this state again and make appropriate decisions from this page here.
// This object can be further extended :)
export type StripePaymentAppState = {
  // show a success page in case payment is successful
  // default: true
  showSuccessBeforeRedirecting?: boolean;
  // use window.location.href instead of react navigate() method
  // default: true
  hardRefresh?: boolean;
  // url where to redirect after successful payment
  redirectTo?: string;
};

/**
 * This hook makes navigating to paying a purchase order via stripe easy, since it
 * encapsulates the app state that needs to be passed
 * @returns
 */
export const useNavigateToStripePayment = () => {
  const navigate = useNavigate();

  const navigateToStripePayment = useCallback(
    (orderId: string, state: StripePaymentAppState) => {
      const encodedState = encodeURIComponent(btoa(JSON.stringify(state)));

      navigate(`/orders/${orderId}/payment/stripe?state=${encodedState}`);
    },
    [navigate],
  );

  return navigateToStripePayment;
};
