import { Stack, Typography } from "@mui/material";
import { PasswordValidationResult } from "./passwordValidator";

export type PasswordValidityTooltipsProps = {
  validation: PasswordValidationResult;
};

export const PasswordValidityTooltips: React.FC<PasswordValidityTooltipsProps> = ({ validation }: PasswordValidityTooltipsProps) => {
  return (
    <Stack direction='row' justifyContent='left' columnGap={2}>
      <Typography
        variant='caption'
        sx={{
          color: !validation.passwordHasValue ? "#ccc" : validation.hasLowerCase && validation.hasUpperCase ? "success.light" : "error.light",
          fontSize: 12,
        }}
      >
        ● Groß- und Kleinbuchstaben
      </Typography>
      <Typography
        variant='caption'
        sx={{ color: !validation.passwordHasValue ? "#ccc" : validation.hasNumber ? "success.light" : "error.light", fontSize: 12 }}
      >
        ● Zahlen
      </Typography>
      <Typography
        variant='caption'
        sx={{ color: !validation.passwordHasValue ? "#ccc" : validation.hasMinLength ? "success.light" : "error.light", fontSize: 12 }}
      >
        ● Mindestens 8 Zeichen
      </Typography>
      <Typography
        variant='caption'
        sx={{ color: !validation.passwordHasValue ? "#ccc" : validation.hasSpecialChar ? "success.light" : "error.light", fontSize: 12 }}
      >
        ● Sonderzeichen
      </Typography>
    </Stack>
  );
};
