import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { HouseDetails } from "../../components/questionnaire/HouseDetails";
import { Insulation } from "../../components/questionnaire/Insulation";
import { Openings } from "../../components/questionnaire/Openings";
import { Report } from "../../components/questionnaire/Report";
import { Contact } from "../../components/questionnaire/Contact";
import { Bye } from "../../components/questionnaire/Bye";
import { StepsSplash } from "../../components/questionnaire/StepsSplash";
import novo from "../../themes/novo";
import usePutMetric from "../../hooks/usePutMetric";

const theme = createTheme(novo, {
  palette: {
    primary: {
      main: "#ed702d",
      contrastText: "#ffffff",
    },
    background: {
      // default: "#0f2c63"
      default: "#e7eff6",
    },
    text: {
      primary: "#0f2c63",
    },
  },
  typography: {
    // eslint-disable-next-line quotes
    fontFamily: `"Segoe UI", "Kohinoor Bangla", Arial`,
    fontSize: 20,
  },
});

export default function FoerderWeltEmbedded() {
  const [putMetric] = usePutMetric();
  const [question, setQuestion] = useState(0);

  function renderQuestion() {
    switch (question) {
      case 0:
        return <StepsSplash setNextQuestion={setQuestion} question={question} />;
      case 1:
        return <HouseDetails setNextQuestion={setQuestion} question={question} />;
      case 2:
        return <Insulation setNextQuestion={setQuestion} question={question} />;
      case 3:
        return <Openings setNextQuestion={setQuestion} question={question} />;
      case 4:
        return <Report setNextQuestion={setQuestion} question={question} />;
      case 5:
        return <Contact setNextQuestion={setQuestion} question={question} />;
      case 6:
        return <Bye />;
      default:
        break;
    }
    return;
  }

  useEffect(() => {
    putMetric("FoerderWeltEmbedded", "RenderPage");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component='main' alignItems='center' sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }} height={"100%"}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {renderQuestion()}
      </ThemeProvider>
    </Box>
  );
}
