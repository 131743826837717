import Grid from "@mui/material/Grid2";
import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";
import OutlineFrame from "../OutlineFrame";
import { Stack, Typography } from "@mui/material";

export default function FloorsHeightComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  return (
    <Grid container spacing={4}>
      <Grid size={3}>
        <OutlineFrame label={"Erdgeschoss"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography>{renovations?.answers?.houseConfiguration?.groundFloorHeight}</Typography>
            <Typography>cm</Typography>
          </Stack>
        </OutlineFrame>
      </Grid>
      <Grid size={3}>
        <OutlineFrame label={"Obergeschoss"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography>{renovations?.answers?.houseConfiguration?.upperFloorsHeight || "--"}</Typography>
            <Typography>cm</Typography>
          </Stack>
        </OutlineFrame>
      </Grid>
      <Grid size={3}>
        <OutlineFrame label={"Dachboden"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography>{renovations?.answers?.houseConfiguration?.atticHeight || "--"}</Typography>
            <Typography>cm</Typography>
          </Stack>
        </OutlineFrame>
      </Grid>
      <Grid size={3}>
        <OutlineFrame label={"Keller"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography>{renovations?.answers?.houseConfiguration?.basementHeight || "--"}</Typography>
            <Typography>cm</Typography>
          </Stack>
        </OutlineFrame>
      </Grid>
    </Grid>
  );
}
