import { apiEndpoint } from "../utils/params";
import { useUnauthAxios } from "./useUnauthAxios";
import { useCallback } from "react";
import { ReferrerResponse } from "../types/cockpit/types";

export default function useGetReferrerInfo() {
  const { unauthAxios, error, data } = useUnauthAxios<ReferrerResponse>();

  const getReferrerInfo = useCallback(
    async (referrerCode: string) => {
      await unauthAxios({
        method: "GET",
        url: `${apiEndpoint()}/consultants/${referrerCode}`,
      });
    },
    [unauthAxios],
  );

  return { getReferrerInfo, referrerInfoError: error, referrerInfo: data };
}
