import Grid from "@mui/material/Grid";
import { Property } from "../../../components/property/Property";

export default function AddProperty() {
  return (
    <>
      <Grid container justifyContent='center'>
        <Property />
      </Grid>
    </>
  );
}
