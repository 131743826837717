import { Typography } from "@mui/material";
import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";
import OutlineFrame from "../OutlineFrame";
import { Ventilation } from "../../../../../../funnels/isfp/renovations/utils";

export function VentilationComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  const ventilation = renovations?.answers?.hotWaterAndVentilationInformation?.ventilation;
  let ventilationLabel;
  if (ventilation === "other") {
    ventilationLabel = renovations?.answers?.hotWaterAndVentilationInformation?.otherVentilation;
  } else {
    ventilationLabel = Ventilation[ventilation as keyof typeof Ventilation];
  }
  return (
    <OutlineFrame label={"Lüftung"}>
      <Typography>{ventilationLabel || "--"}</Typography>
    </OutlineFrame>
  );
}
