import { useCallback } from "react";
import { SchnellcheckQuestionnaire } from "../types/Schnellcheck";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";

const API_ENDPOINT = `${apiEndpoint()}/questionnaires`;

export function useFetchSchnellcheck() {
  const { authAxios, error, running, data } = useAuthAxios<SchnellcheckQuestionnaire>();

  const getSchnellcheck = useCallback(
    async (questionnaireId: string) =>
      await authAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}/${questionnaireId}`,
      }),
    [authAxios],
  );

  return { getSchnellcheck, error, isLoading: running, schnellcheck: data };
}
