import { FormControl, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ChangeEvent, useEffect, useState } from "react";
import { StatusQuoScreenProps } from "./StatusQuoFunnel";
import useImage from "../../../../hooks/useImage";
import NovoSelect from "../../../../components/common/NovoSelect";
import { WindowType } from "../../../../types/Schnellcheck";
import { allWindowTypes, fromWindowLabel, getWindowLabel } from "./utils";
import usePutMetric from "../../../../hooks/usePutMetric";
import FormScreen from "../FormScreen";
import NovoTextField from "../../../../components/common/NovoTextField";

export default function Screen4({ schnellcheck, setSchnellcheck, isUpdateRunning, prev, next }: StatusQuoScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [putMetric] = usePutMetric();
  const window = useImage("isfp-icons/window.png");
  const door = useImage("isfp-icons/door.png");
  const [doorError, setDoorError] = useState<string>();
  const [windowError, setWindowError] = useState<string>();
  const currentYear = new Date().getFullYear();

  const updateDoorYear = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const doorsYear = /^[0-9]+$/.test(value) ? Number.parseInt(value) : undefined;
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseOpenings: {
          ...schnellcheck.answers?.houseOpenings,
          doorsYear,
        },
      },
    });
    if (doorsYear) {
      setDoorError(undefined);
    }
  };

  const updateWindowType = (value: string) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        houseOpenings: {
          ...schnellcheck.answers?.houseOpenings,
          windows: fromWindowLabel(value),
        },
      },
    });
    setWindowError(undefined);
  };

  const getWindowTypeOptions = (): string[] => allWindowTypes().map((windowType) => getWindowLabel(windowType));

  const validate = () => {
    const { windows, doorsYear } = schnellcheck.answers?.houseOpenings ?? {};
    if (!windows) {
      setWindowError("Pflichtfeld");
      return false;
    }
    setWindowError(undefined);

    if (!doorsYear) {
      setDoorError("Pflichtfeld");
      return false;
    }
    if (doorsYear < (schnellcheck.answers?.houseDetails?.yearOfConstruction ?? 1800) || doorsYear > currentYear) {
      setDoorError("Ungültige Jahr");
      return false;
    }
    setDoorError(undefined);

    return true;
  };

  useEffect(() => {
    putMetric("Schnellcheck", "RenderOpeningsPage");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateAndSave = () => {
    if (validate()) {
      next();
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: { xs: 2, lg: 2 } }}>
          <Typography variant='body1' fontSize={isMobile ? 14 : 16}>
            <strong>Schritt 4: </strong>Nun benötigen wir bitte noch ein paar Angaben zu Fenstern und Türen
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Stack component={Paper} direction={"row"} alignItems='center' sx={{ p: 2 }}>
            <Stack alignItems={"start"} spacing={isMobile ? 0 : 1}>
              <img src={window.image} height={isMobile ? 48 : 60} width={isMobile ? 48 : 60} style={{ marginLeft: "auto", marginRight: "auto" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Fenster
              </Typography>
            </Stack>
            <NovoSelect
              label={"Art des Fensters"}
              options={getWindowTypeOptions()}
              dataCy={{ field: "window-type", values: Object.keys(WindowType) }}
              value={getWindowLabel(schnellcheck.answers?.houseOpenings?.windows)}
              onUpdate={updateWindowType}
              required
              error={!!windowError}
              helperText={windowError}
              sx={{
                flexGrow: 1,
                mx: 2,
                "& > label.Mui-focused": {
                  color: "text.primary",
                },
                "& > div": {
                  color: "text.primary",
                  backgroundColor: "rgba(255, 255, 255, 0.10)",
                },
                "& > .MuiOutlinedInput-root.Mui-focused > fieldset": {
                  color: "text.primary",
                  borderColor: "rgba(0 ,0, 0, 0.87)",
                },
              }}
            />
          </Stack>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Stack component={Paper} direction={"row"} alignItems='center' sx={{ p: 2 }}>
            <Stack alignItems={"start"} spacing={isMobile ? 0 : 1}>
              <img src={door.image} height={isMobile ? 48 : 60} width={isMobile ? 48 : 60} style={{ marginLeft: "auto", marginRight: "auto" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Tür
              </Typography>
            </Stack>
            <FormControl sx={{ "& > div > div.MuiInputBase-root": { backgroundColor: "#FFFFFF" }, width: "100%" }}>
              <NovoTextField
                variant='outlined'
                id='door-year-input'
                data-cy='door-year-input'
                value={schnellcheck.answers?.houseOpenings?.doorsYear}
                label='Jahr der Tür'
                onChange={updateDoorYear}
                required={true}
                type='number'
                error={!!doorError}
                helperText={doorError}
                sx={{
                  flexGrow: 1,
                  mx: 2,
                  "& > label.Mui-focused": {
                    color: "text.primary",
                  },
                  "& > div": {
                    color: "text.primary",
                    backgroundColor: "rgba(255, 255, 255, 0.10)",
                  },
                  "& > .MuiOutlinedInput-root.Mui-focused > fieldset": {
                    color: "text.primary",
                    borderColor: "rgba(0 ,0, 0, 0.87)",
                  },
                }}
                slotProps={{
                  htmlInput: { min: 1800, max: currentYear },
                  inputLabel: { shrink: !!schnellcheck.answers?.houseOpenings?.doorsYear },
                }}
              />
            </FormControl>
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return (
    <FormScreen
      prev={prev}
      next={validateAndSave}
      isUpdateRunning={isUpdateRunning}
      progressLabel='60% geschafft'
      progressValue={60}
      content={content}
      helpContent={helpContent}
    />
  );
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Auch über Fenster und Türen kann viel
      <br />
      Wärme entweichen. Je besser die
      <br />
      Verglasung und je moderner die Haustür,
      <br />
      desto besser ist in der Regel
      <br />
      die Energieeffizienz des Hauses.
    </Typography>
  );
}
