import { ChangeEvent, Fragment } from "react";
import Grid from "@mui/material/Grid2";
import FormControl from "@mui/material/FormControl";
import { Checkbox, FormControlLabel, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import NovoSelect from "../../../components/common/NovoSelect";
import { SectionProps } from "./ConsumptionCertificateRequestPage";
import NovoImageUpload from "../../../components/common/NovoImageUpload";
import { BuildingType, Reforms } from "./ConsumptionCertificateRequestEnums";
import HelpIcon from "@mui/icons-material/Help";
import { NovoTooltip } from "../../../components/common/NovoTooltip";
import { ContactRequestRequired } from "./ContactRequestRequired";
import NovoAddressAutocomplete, { AddressDetails, getAddressDetails } from "../../../components/common/NovoAddressAutocomplete";
import { FileRef } from "../../../components/common/NovoFileUpload";

export default function BuildingSection({ data, updateHandler, unableToProceed, setUnableToProceed }: SectionProps) {
  const photoHandler = (value: FileRef[]) => {
    data.buildingData.photo = value[0].file;
    updateHandler(data);
  };
  const purposeHandler = (value: string) => {
    data.buildingData.purpose = value;
    updateHandler(data);
  };

  const isTypeInvalid = (type: BuildingType) => {
    return [BuildingType.Sonstiges, BuildingType["Wohnteil gemischt genutztes Gebäude"]].includes(type);
  };

  const typeHandler = (type: BuildingType) => {
    data.buildingData.type = type;
    updateHandler(data);
    setUnableToProceed(isTypeInvalid(type));
  };

  const isReformOptionInvalid = (type: Reforms) => {
    return [Reforms["Ich weiß es nicht"], Reforms.Nein].includes(type);
  };

  const reformHandler = (value: Reforms) => {
    data.buildingData.reform = value;
    setUnableToProceed(isReformOptionInvalid(value));
    updateHandler(data);
  };

  const houseCountHandler = (event: ChangeEvent<HTMLInputElement>) => {
    data.buildingData.houseCount = event.target.value;
    setUnableToProceed(+data.buildingData.houseCount > 4);
    updateHandler(data);
  };

  const constructionYearHandler = (event: ChangeEvent<HTMLInputElement>) => {
    data.buildingData.constructionYear = event.target.value;
    setUnableToProceed(+data.buildingData.constructionYear > data.buildingData.maxConstructionYear);
    updateHandler(data);
  };

  const surfaceHandler = (event: ChangeEvent<HTMLInputElement>) => {
    data.buildingData.surface = event.target.value;
    updateHandler(data);
  };

  const addressHandler = (autocompleteAddress: google.maps.places.AutocompletePrediction | null) => {
    data.buildingData.autocompleteAddress = autocompleteAddress;
    updateHandler(data);
    if (autocompleteAddress) {
      getAddressDetails(autocompleteAddress, processAddressDetails);
    }
  };

  const processAddressDetails = (details: AddressDetails) => {
    data.buildingData.address = details.address ?? "";
    data.buildingData.postalCode = details.postalCode ?? data.buildingData.postalCode;
    data.buildingData.town = details.town ?? data.buildingData.town;
    data.buildingData.state = details.state ?? data.buildingData.state;
    updateHandler(data, !!details.postalCode);
  };

  const postalCodeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const postalCode = event.target.value;
    data.buildingData.postalCode = postalCode;
    const mustVerifyPLZ = /^[0-9]{5}$/.test(postalCode);
    updateHandler(data, mustVerifyPLZ);
  };

  const townHandler = (event: ChangeEvent<HTMLInputElement>) => {
    data.buildingData.town = event.target.value;
    updateHandler(data);
  };

  const stateHandler = (value: string) => {
    data.buildingData.state = value;
    updateHandler(data);
  };

  const generatePLZHelperText = () => {
    let plzHelperText = "";
    if (data.buildingData.postalCodeError) {
      plzHelperText = "5-stellig, z.B. 10115";
    } else if (data.buildingData.postalCodeUnknown) {
      plzHelperText = "Unbekannte Postleitzahl";
    }
    return plzHelperText;
  };

  const handleEmptyChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    event.target.ariaInvalid = checked ? "true" : "false";
    data.buildingData.isEmptyProperty = checked;
    setUnableToProceed(checked);
    updateHandler(data);
  };

  const hasHeatedBasementChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    data.buildingData.hasHeatedBasement = checked;
    updateHandler(data);
  };

  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <FormControlLabel
          control={<Checkbox required={false} value={data.buildingData.isEmptyProperty} data-cy='long-period-empty-checkbox' onChange={handleEmptyChange} />}
          label='Während der drei aufeinanderfolgenden Verbrauchsjahre für das Zertifikat war die Immobilie zu über 30% leerstehend'
          disabled={unableToProceed && !data.buildingData.isEmptyProperty}
        />
      </Grid>
      {data.buildingData.isEmptyProperty && (
        <Grid size={12}>
          <ContactRequestRequired
            title='Neuen Bedarfsausweis beantragen'
            message={
              "Hallo NOVO-Team,\r\n\r\nda meine Immobilie seit einiger Zeit überwiegend leer steht, möchte ich einen Bedarfsausweis beantragen und ein unverbindliches Angebot einholen."
            }
          />
        </Grid>
      )}
      <Grid size={12}>
        <NovoImageUpload data-cy='building-photo' onChange={photoHandler} error={data.buildingData.photoError} disabled={unableToProceed} required />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <FormControl fullWidth>
          <TextField
            variant='outlined'
            data-cy='build-year-input'
            id='build-year-input'
            value={data.buildingData.constructionYear}
            type='number'
            label='Baujahr'
            onChange={constructionYearHandler}
            required={true}
            error={data.buildingData.constructionYearError}
            disabled={unableToProceed && +data.buildingData.constructionYear <= data.buildingData.maxConstructionYear}
            slotProps={{
              htmlInput: { min: 1700, max: data.buildingData.maxConstructionYear },
            }}
          />
        </FormControl>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Stack direction='row' alignItems='center' spacing={2}>
          <FormControl fullWidth>
            <TextField
              data-cy='sq-meters-input'
              variant='outlined'
              id='wohnflache-input'
              value={data.buildingData.surface}
              type='number'
              label='Wohnfläche'
              onChange={surfaceHandler}
              required={true}
              error={data.buildingData.surfaceError}
              disabled={unableToProceed}
              slotProps={{
                input: {
                  endAdornment: <InputAdornment position='end'>m²</InputAdornment>,
                },

                htmlInput: { min: 10 },
              }}
            />
          </FormControl>
          <NovoTooltip
            title={
              <Fragment>
                <Typography variant='body2'>
                  Die Wohnfläche einer Wohnung umfasst die Grundflächen der Räume, die ausschließlich zu dieser Wohnung gehören, wie zum Beispiel:
                  <ul>
                    <li>Schlafzimmer</li>
                    <li>Wohnzimmer</li>
                    <li>Küche</li>
                    <li>Badezimmer</li>
                    <li>Esszimmer</li>
                  </ul>
                  Kellerräume, Garagen, und Abstellräume gehören nicht zur Wohnfläche.
                </Typography>
              </Fragment>
            }
          >
            <HelpIcon />
          </NovoTooltip>
        </Stack>
      </Grid>
      {+data.buildingData.constructionYear > data.buildingData.maxConstructionYear && (
        <Grid size={12}>
          <ContactRequestRequired
            description={`Verbrauchsausweise können nur für Gebäude erstellt werden, die älter als ${data.buildingData.maxConstructionYear} sind. Kontaktieren Sie uns per E-Mail und wir unterstützen Sie bei den nächsten Schritten.`}
            title='Neuen Bedarfsausweis beantragen'
            message={
              "Hallo NOVO-Team,\r\n\r\nda mir nicht genügend Verbrauchsdaten vorliegen, möchte ich einen Bedarfsausweis anfordern und ein unverbindliches Angebot einholen."
            }
          />
        </Grid>
      )}
      {data.buildingData.constructionYear.length > 3 && +data.buildingData.constructionYear < 1977 && (
        <Grid size={12}>
          <Stack direction='row' alignItems='center' spacing={2}>
            <NovoSelect
              label='Wärmeschutzverordnung'
              value={data.buildingData.reform}
              options={data.buildingData.reformOptions}
              onUpdate={reformHandler}
              required={true}
              error={data.buildingData.reformError}
              disabled={unableToProceed && !isReformOptionInvalid(data.buildingData.reform as Reforms)}
            />
            <NovoTooltip
              title={
                <Fragment>
                  <Typography variant='subtitle1' style={{ fontWeight: 400 }}>
                    Die Anforderung gilt als erfüllt (alle Punkte müssen erfüllt werden), wenn:
                  </Typography>
                  <Typography variant='body2'>
                    <ol>
                      <li>
                        für den Großteil der Außenwände eine der folgenden Konstruktionen vorhanden ist:
                        <ul>
                          <li>24cm Bimsstein-Mauerwerk, Porenbeton oder Blocksteine ODER</li>
                          <li>38cm Kalksanstein-Mauerwerk mit mind. 6cm äußerer Dämmung ODER</li>
                          <li>Zweischaliges Mauerwerk mit 8cm Dämmung</li>
                        </ul>
                      </li>
                      <li>Ihre Fenster Holz- oder Kunststofffenster mit Doppel- oder Isolierverglasung sind</li>
                      <li>das Dach gedämmt ist (mind. 18cm Aufsparren-, 15cm Zwischensparrendämmung oder 8cm Dachbodendämmung)</li>
                      <li>
                        bei einem unbeheizten Keller 5,5cm Kellerdeckendämmung vorhanden sind (bei beheizten Kellern oder keinem Keller ist keine Dämmung
                        notwendig).
                      </li>
                    </ol>
                    Sind alle Kategorien erfüllt, können Sie „Wärmeschutzverordnung von 1977 wird vom Objekt eingehalten“ auswählen. Wir fragen nach den
                    Informationen, um Sie in die richtige Kategorie des Verbrauchs- oder Bedarfsausweises einordnen zu können.
                  </Typography>
                </Fragment>
              }
            >
              <HelpIcon />
            </NovoTooltip>
          </Stack>
        </Grid>
      )}
      {data.buildingData.constructionYear.length > 3 &&
        +data.buildingData.constructionYear < 1977 &&
        data.buildingData.reform &&
        data.buildingData.reform !== Reforms["Wärmeschutzverordnung von 1977 wird vom Objekt eingehalten"] && (
          <Grid size={12}>
            <ContactRequestRequired
              title='Neuen Bedarfsausweis beantragen'
              message={
                "Hallo Team NOVO,\r\n\r\nda meine Immobilie die Anforderungen der Wärmeschutzverordnung von 1977 wahrscheinlich nicht erfüllt, möchte ich einen Bedarfsausweis anfragen und bitte um ein unverbindliches Angebot."
              }
            />
          </Grid>
        )}
      <Grid size={12}>
        <NovoSelect
          data-cy='reason-select'
          label='Anlass'
          value={data.buildingData.purpose}
          options={data.buildingData.purposeOptions}
          onUpdate={purposeHandler}
          required={true}
          error={data.buildingData.purposeError}
          disabled={unableToProceed}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <NovoSelect
          data-cy='construction-type-select'
          label='Gebäudetyp'
          value={data.buildingData.type}
          options={data.buildingData.typeOptions}
          onUpdate={typeHandler}
          required={true}
          error={data.buildingData.typeError}
          disabled={unableToProceed && !isTypeInvalid(data.buildingData.type as BuildingType)}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <FormControl fullWidth>
          <TextField
            variant='outlined'
            id='anzahl-input'
            data-cy='division-count-input'
            value={data.buildingData.houseCount}
            type='number'
            label='Anzahl Wohnungen'
            onChange={houseCountHandler}
            required={true}
            error={data.buildingData.houseCountError}
            disabled={unableToProceed && +data.buildingData.houseCount < 5}
            slotProps={{
              htmlInput: { min: 1 },
            }}
          />
        </FormControl>
      </Grid>
      {data.buildingData.type === BuildingType["Wohnteil gemischt genutztes Gebäude"] && (
        <Grid size={12}>
          <ContactRequestRequired
            description='Benötigen Sie einen Verbrauchsausweis für den Wohnteil eines gemischt genutzten Gebäudes? Dann schreiben Sie uns gerne eine E-Mail, und wir melden uns bei Ihnen zurück!'
            title='Neuen Verbrauchsausweis beantragen'
            message={
              "Hallo Team NOVO,\r\n\r\nIch benötige einen Verbrauchsausweis für den Wohnteil eines gemischt genutzten Gebäudes. Bitte senden Sie mir ein kostenloses Angebot."
            }
          />
        </Grid>
      )}
      {data.buildingData.type === BuildingType.Sonstiges && (
        <Grid size={12}>
          <ContactRequestRequired
            description='Benötigen Sie einen Verbrauchsausweis für ein Gebäude, das nicht in die oben genannten Kategorien passt? Dann schreiben Sie uns gerne eine E-Mail, und wir melden uns bei Ihnen zurück!'
            title='Neuen Verbrauchsausweis beantragen'
            message={
              "Hallo Team NOVO,\r\n\r\nIch benötige einen Verbrauchsausweis für ein Gebäude, welches nicht in die Gebäudetypen-Kategorien passt. Ich möchte ein unverbindliches Angebot anfordern."
            }
          />
        </Grid>
      )}
      {+data.buildingData.houseCount > 4 && (
        <Grid size={12}>
          <ContactRequestRequired
            description='Sie benötigen einen Verbauchsausweis für ein Gebäude mit mehr als 4 Wohneinheiten? Dann schreiben Sie uns gerne eine Email und wir melden uns bei Ihnen zurück! '
            title='Neuen Bedarfsausweis beantragen'
            message={
              "Hallo Team NOVO,\r\n\r\nda meine Immobilie mehr als 4 Wohnungen hat, möchte ich einen Bedarfsausweis anfragen und bitte um ein unverbindliches Angebot."
            }
          />
        </Grid>
      )}
      {[1, 2].includes(+data.buildingData.houseCount) && (
        <Grid size={12}>
          <FormControlLabel
            control={<Checkbox required={false} value={data.buildingData.hasHeatedBasement} onChange={hasHeatedBasementChange} />}
            label='Das Gebäude verfügt über einen beheizten Keller'
            disabled={unableToProceed}
          />
        </Grid>
      )}
      <Grid size={12}>
        <Stack direction='row' alignItems='center' spacing={2}>
          <NovoAddressAutocomplete
            id='street-address-input'
            data_cy='street-address-input'
            address={data.buildingData.autocompleteAddress}
            updateAddress={addressHandler}
            addressError={data.buildingData.addressError}
            disabled={unableToProceed}
          />
          <NovoTooltip title='Wenn Ihre Adresse in den Vorschlägen nicht enthalten ist, geben Sie weitere Informationen ein, beispielsweise Stadt oder Stadtteil.'>
            <HelpIcon />
          </NovoTooltip>
        </Stack>
      </Grid>
      <Grid size={{ xs: 12, md: 2 }}>
        <FormControl fullWidth>
          <TextField
            data-cy='plz-input'
            variant='outlined'
            id='plz-input'
            value={data.buildingData.postalCode}
            type='number'
            label='PLZ'
            onChange={postalCodeHandler}
            required={true}
            error={data.buildingData.postalCodeError || data.buildingData.postalCodeUnknown}
            helperText={generatePLZHelperText()}
            disabled={unableToProceed}
            slotProps={{
              htmlInput: { pattern: /^[0-9]{5}$/ },
            }}
          />
        </FormControl>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <FormControl fullWidth>
          <TextField
            data-cy='city-input'
            variant='outlined'
            id='ort-input'
            value={data.buildingData.town}
            label='Ort'
            onChange={townHandler}
            required={true}
            error={data.buildingData.townError}
            disabled={unableToProceed}
          />
        </FormControl>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <NovoSelect
          data-cy='country-state-select'
          label='Bundesland'
          value={data.buildingData.state}
          options={data.buildingData.stateOptions}
          onUpdate={stateHandler}
          required={true}
          error={data.buildingData.stateError}
          disabled={unableToProceed}
        />
      </Grid>
    </Grid>
  );
}
