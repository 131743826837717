import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { FunctionComponent } from "react";
import { Property } from "../../types/types";
import { formatEuros } from "../../utils/utils";

type RenovationTableProps = {
  property?: Property;
};

export const SanierungspflichtRenovationTable: FunctionComponent<RenovationTableProps> = ({ property }) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 350 }} size='small' aria-label='Energy Efficiency'>
          <TableBody>
            <TableRow>
              <TableCell colSpan={3} style={{ backgroundColor: "black", color: "white" }}>
                Cost estimate
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Building envelope</TableCell>
              <TableCell align='right'>{formatEuros(property!.analysis.sanierungspflicht.costs.buildingEnvelopeCosts)} €</TableCell>
            </TableRow>
            {!!property!.analysis.sanierungspflicht.costs.airWaterHeatPumpCosts && (
              <TableRow>
                <TableCell>Air-water heat pump</TableCell>
                <TableCell align='right'>{formatEuros(property!.analysis.sanierungspflicht.costs.airWaterHeatPumpCosts)} €</TableCell>
              </TableRow>
            )}
            {!!property!.analysis.sanierungspflicht.costs.heatingOptimizationCosts && (
              <TableRow>
                <TableCell>Heating optimization</TableCell>
                <TableCell align='right'>{formatEuros(property!.analysis.sanierungspflicht.costs.heatingOptimizationCosts)} €</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Planning costs (20%)</TableCell>
              <TableCell align='right'>{formatEuros(property!.analysis.sanierungspflicht.costs.planningCosts)} €</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
