import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StripePaymentAppState, useNavigateToStripePayment, useStateFromStripeRedirect } from "../../../hooks";
import { apiEndpoint } from "../../../utils/params";

export default function StripeOrderPaymentResultPage() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [paymentStatus, setPaymentStatus] = useState<string>("");
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const state = useStateFromStripeRedirect();
  const navigateToStripePayment = useNavigateToStripePayment();

  useEffect(() => {
    if (isLoading) {
      fetch(`${apiEndpoint()}/payments/stripe-session/${searchParams.get("stripe_session_id")}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setPaymentStatus(data.payment_status);
          setIsLoading(false);
        });
    }
  }, [isLoading, searchParams]);

  useEffect(() => {
    if (!isLoading && paymentStatus && !state.showSuccessBeforeRedirecting && state.redirectTo) {
      if (state.hardRefresh) {
        window.location.href = state.redirectTo;
      } else {
        navigate(state.redirectTo);
      }
    }
  }, [isLoading, navigate, paymentStatus, state.hardRefresh, state.redirectTo, state.showSuccessBeforeRedirecting]);

  const redirectUsingState = (state: StripePaymentAppState) => {
    if (state.redirectTo) {
      if (state.hardRefresh) {
        window.location.href = state.redirectTo;
      } else {
        navigate(state.redirectTo);
      }
    }
  };

  return (
    <>
      {!isLoading && paymentStatus !== "paid" && params.orderId && (
        <Stack spacing={"1vh"} alignItems='center' p={3}>
          <Typography style={{ fontSize: "calc(8px + 2vw)", fontWeight: 400 }}>Die Zahlung war nicht erfolgreich. Bitte versuche es erneut.</Typography>
          <Button
            variant='contained'
            onClick={() =>
              // params.orderId is guaranteed to be set here, but the linter does not understand the react code above
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              navigateToStripePayment(params.orderId!, state)
            }
            sx={{ height: 40, width: 300 }}
          >
            Zahlung erneut versuchen
          </Button>
        </Stack>
      )}

      {!isLoading && paymentStatus === "paid" && (!state.redirectTo || (state.redirectTo && state.showSuccessBeforeRedirecting)) && (
        <Stack spacing={"1vh"} alignItems='center' p={3}>
          <Typography style={{ fontSize: "calc(8px + 2vw)", fontWeight: 400 }}>Zahlung erfolgreich, vielen Dank!</Typography>
          {state.redirectTo && (
            <Button variant='contained' onClick={() => redirectUsingState(state)} sx={{ height: 40, width: 300 }}>
              Weiter
            </Button>
          )}
        </Stack>
      )}

      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
}
