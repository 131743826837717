import { useCallback } from "react";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";
import { FeedbackReport } from "../types/cockpit/types";

export const API_ENDPOINT = `${apiEndpoint()}/tenants/feedback`;

export default function useSendFeedback() {
  const { authAxios, error, running, responseStatus } = useAuthAxios();

  const postFeedback = useCallback(
    async (feedback: FeedbackReport) =>
      await authAxios({
        method: "POST",
        baseURL: `${API_ENDPOINT}`,
        data: JSON.stringify(feedback),
      }),
    [authAxios],
  );

  return { postFeedback, error, running, responseStatus };
}
