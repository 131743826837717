import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid2";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { SchnellcheckQuestionnaire } from "../../../../types/Schnellcheck";
import HouseStatus from "../../../funnels/isfp/statusquo/HouseStatus";
import { getDoorLabel, getHeatingLabel, getWindowLabel } from "../../../funnels/isfp/statusquo/utils";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button/Button";
import DialogActions from "@mui/material/DialogActions";

interface DisplayEmbeddedDialogProps {
  schnellcheck: SchnellcheckQuestionnaire;
  open: boolean;
  handleClose: () => void;
}

export function DisplayEmbeddedDialog({ schnellcheck, open, handleClose }: DisplayEmbeddedDialogProps) {
  if (!schnellcheck) return null;
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        iSFP Schnellcheck status & antworten
        <Button
          sx={{ float: "inline-end", color: "rgba(0, 0, 0, 0.54)", ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
          onClick={handleClose}
          endIcon={<CloseIcon />}
        >
          Schließen
        </Button>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid size={12}>
            <HouseStatus status={schnellcheck.report?.status} />
          </Grid>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Informationen zum Haus
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              Baujahr: {schnellcheck.answers.houseDetails?.yearOfConstruction}
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              Wohnfläche: {schnellcheck.answers.houseDetails?.area} m²
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              Heizsystem: {getHeatingLabel(schnellcheck.answers.houseDetails!.heatingSystemType!)}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Verfügt Ihr Haus über Dämmung?
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              Dach: {schnellcheck.answers.houseInsulation?.yearOfRoofInsulation ?? "--"}
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              Wände: {schnellcheck.answers.houseInsulation?.yearOfWallsInsulation ?? "--"}
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              Kellerdecke: {schnellcheck.answers.houseInsulation?.yearOfBasementInsulation ?? "--"}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Nun benötigen wir bitte noch ein paar Angaben zu Fenstern und Türen
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              Fenster: {getWindowLabel(schnellcheck.answers.houseOpenings?.windows) ?? "--"}
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              Tür: {getDoorLabel(schnellcheck.answers.houseOpenings?.doors) ?? "--"}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='secondary' onClick={handleClose}>
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
