import { FunctionComponent } from "react";
import { Box, Stack, Typography } from "@mui/material";

type ReportDataFieldProps = {
  label: string;
  data: string;
};

export const ReportDataField: FunctionComponent<ReportDataFieldProps> = ({ label, data, ...props }) => {
  return (
    <>
      <Stack spacing={1} direction='row' alignItems={"flex-start"} justifyContent={"space-between"}>
        <Typography align='left' style={{ fontSize: 16, fontWeight: 400 }}>
          {label}
        </Typography>
        <Box
          sx={{ backgroundColor: "#ffffff", borderRadius: "10px", width: "60%", display: "baseline", textAlign: "center", fontSize: 16, fontWeight: 700, p: 2 }}
          {...props}
        >
          {data}
        </Box>
      </Stack>
    </>
  );
};
