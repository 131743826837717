import {
  BenefitsResponse,
  CeilingRenovation,
  DoorRenovation,
  FloorInsulation,
  HeatingSystemAge,
  OuterWallInsulation,
  StatusCollor,
  StatusQuoResponse,
  StatusResponse,
  WindowRenovation,
} from "../../../../types/cockpit/types";

export const ceilingStatus = (renovation?: string, buildYear?: number): StatusResponse => {
  if (renovation) {
    switch (renovation) {
      case CeilingRenovation.before1968:
        return {
          color: StatusCollor.red,
          minSavings: 680,
          maxSavings: 1000,
          subsidy: 0.2,
        };
      case CeilingRenovation.between1968And2001:
        return {
          color: StatusCollor.yellow,
          minSavings: 680,
          maxSavings: 1000,
          subsidy: 0.2,
        };
      case CeilingRenovation.from2002:
        return {
          color: StatusCollor.green,
          minSavings: 0,
          maxSavings: 0,
          subsidy: 0,
        };
      case CeilingRenovation.none:
      // use buildYear below to determine cost factor
    }
  }
  if (buildYear) {
    switch (true) {
      case buildYear < 1968:
        return {
          color: StatusCollor.red,
          minSavings: 680,
          maxSavings: 1000,
          subsidy: 0.2,
        };
      case buildYear < 2002:
        return {
          color: StatusCollor.yellow,
          minSavings: 680,
          maxSavings: 1000,
          subsidy: 0.2,
        };
      case buildYear > 2001:
        return {
          color: StatusCollor.green,
          minSavings: 0,
          maxSavings: 0,
          subsidy: 0,
        };
    }
  }
  return {
    color: StatusCollor.none,
    minSavings: 0,
    maxSavings: 0,
    subsidy: 0,
  };
};

export const doorStatus = (renovation?: string, buildYear?: number): StatusResponse => {
  if (renovation) {
    switch (renovation) {
      case DoorRenovation.before1968:
        return {
          color: StatusCollor.red,
          minSavings: 100,
          maxSavings: 300,
          subsidy: 0.2,
        };
      case DoorRenovation.between1968And2001:
        return {
          color: StatusCollor.yellow,
          minSavings: 100,
          maxSavings: 300,
          subsidy: 0.2,
        };
      case DoorRenovation.from2002:
        return {
          color: StatusCollor.green,
          minSavings: 0,
          maxSavings: 0,
          subsidy: 0,
        };
      case DoorRenovation.none:
    }
  }
  // if renovation details were not provided or no renovation took place, use build year to estimate cost factor
  if (buildYear) {
    switch (true) {
      case buildYear < 1968:
        return {
          color: StatusCollor.red,
          minSavings: 100,
          maxSavings: 300,
          subsidy: 0.2,
        };
      case buildYear < 2002:
        return {
          color: StatusCollor.yellow,
          minSavings: 100,
          maxSavings: 300,
          subsidy: 0.2,
        };
      case buildYear > 2001:
        return {
          color: StatusCollor.green,
          minSavings: 0,
          maxSavings: 0,
          subsidy: 0,
        };
    }
  }
  return {
    color: StatusCollor.none,
    minSavings: 0,
    maxSavings: 0,
    subsidy: 0,
  };
};

export const floorStatus = (renovation?: string, buildYear?: number): StatusResponse => {
  if (renovation) {
    switch (renovation) {
      case FloorInsulation.before1968:
        return {
          color: StatusCollor.red,
          minSavings: 0,
          maxSavings: 0,
          subsidy: 0,
        };
      case FloorInsulation.between1968And2001:
        return {
          color: StatusCollor.yellow,
          minSavings: 0,
          maxSavings: 0,
          subsidy: 0,
        };
      case FloorInsulation.from2002:
        return {
          color: StatusCollor.green,
          minSavings: 0,
          maxSavings: 0,
          subsidy: 0,
        };
      case FloorInsulation.none:
    }
  }
  if (buildYear) {
    switch (true) {
      case buildYear < 1968:
        return {
          color: StatusCollor.red,
          minSavings: 0,
          maxSavings: 0,
          subsidy: 0,
        };
      case buildYear < 2002:
        return {
          color: StatusCollor.yellow,
          minSavings: 0,
          maxSavings: 0,
          subsidy: 0,
        };
      case buildYear > 2001:
        return {
          color: StatusCollor.green,
          minSavings: 0,
          maxSavings: 0,
          subsidy: 0,
        };
    }
  }
  return {
    color: StatusCollor.none,
    minSavings: 0,
    maxSavings: 0,
    subsidy: 0,
  };
};

export const outerWallStatus = (renovation?: string, buildYear?: number): StatusResponse => {
  if (renovation) {
    switch (renovation) {
      case OuterWallInsulation.before1968:
        return {
          color: StatusCollor.red,
          minSavings: 500,
          maxSavings: 800,
          subsidy: 0.2,
        };
      case OuterWallInsulation.between1968And2001:
        return {
          color: StatusCollor.yellow,
          minSavings: 500,
          maxSavings: 800,
          subsidy: 0.2,
        };
      case OuterWallInsulation.from2002:
        return {
          color: StatusCollor.green,
          minSavings: 0,
          maxSavings: 0,
          subsidy: 0,
        };
      case OuterWallInsulation.none:
    }
  }
  if (buildYear) {
    switch (true) {
      case buildYear < 1968:
        return {
          color: StatusCollor.red,
          minSavings: 500,
          maxSavings: 800,
          subsidy: 0.2,
        };
      case buildYear < 2002:
        return {
          color: StatusCollor.yellow,
          minSavings: 500,
          maxSavings: 800,
          subsidy: 0.2,
        };
      case buildYear > 2001:
        return {
          color: StatusCollor.green,
          minSavings: 0,
          maxSavings: 0,
          subsidy: 0,
        };
    }
  }
  return {
    color: StatusCollor.none,
    minSavings: 0,
    maxSavings: 0,
    subsidy: 0,
  };
};

export const heatingSystemStatus = (renovation?: string, buildYear?: number): StatusResponse => {
  if (renovation) {
    switch (renovation) {
      case HeatingSystemAge.moreThan30Years:
        return {
          color: StatusCollor.red,
          minSavings: 400,
          maxSavings: 1500,
          subsidy: 0.4,
        };
      case HeatingSystemAge.between20To30Years:
        return {
          color: StatusCollor.red,
          minSavings: 400,
          maxSavings: 1500,
          subsidy: 0.4,
        };
      case HeatingSystemAge.between10To20Years:
        return {
          color: StatusCollor.yellow,
          minSavings: 400,
          maxSavings: 1500,
          subsidy: 0.4,
        };
      case HeatingSystemAge.lessThan10Years:
        return {
          color: StatusCollor.yellow,
          minSavings: 400,
          maxSavings: 1500,
          subsidy: 0.4,
        };
      case HeatingSystemAge.lessThan5Years:
        return {
          color: StatusCollor.green,
          minSavings: 0,
          maxSavings: 0,
          subsidy: 0,
        };
    }
  }
  if (buildYear) {
    switch (true) {
      case buildYear < new Date().getFullYear() - 30:
        return {
          color: StatusCollor.red,
          minSavings: 400,
          maxSavings: 1500,
          subsidy: 0.4,
        };
      case buildYear < new Date().getFullYear() - 20:
        return {
          color: StatusCollor.red,
          minSavings: 400,
          maxSavings: 1500,
          subsidy: 0.4,
        };
      case buildYear < new Date().getFullYear() - 10:
        return {
          color: StatusCollor.yellow,
          minSavings: 400,
          maxSavings: 1500,
          subsidy: 0.4,
        };
      case buildYear < new Date().getFullYear() - 5:
        return {
          color: StatusCollor.yellow,
          minSavings: 0,
          maxSavings: 0,
          subsidy: 0,
        };
      default:
        return {
          color: StatusCollor.green,
          minSavings: 0,
          maxSavings: 0,
          subsidy: 0,
        };
    }
  }
  return {
    color: StatusCollor.none,
    minSavings: 0,
    maxSavings: 0,
    subsidy: 0,
  };
};

export const windowStatus = (renovation?: string, buildYear?: number): StatusResponse => {
  if (renovation) {
    switch (renovation) {
      case WindowRenovation.before1979:
        return {
          color: StatusCollor.red,
          minSavings: 200,
          maxSavings: 400,
          subsidy: 0.2,
        };
      case WindowRenovation.between1979And1995:
        return {
          color: StatusCollor.yellow,
          minSavings: 200,
          maxSavings: 400,
          subsidy: 0.2,
        };
      case WindowRenovation.between1995And2019:
        return {
          color: StatusCollor.yellow,
          minSavings: 200,
          maxSavings: 400,
          subsidy: 0.2,
        };
      case WindowRenovation.from2020:
        return {
          color: StatusCollor.green,
          minSavings: 0,
          maxSavings: 0,
          subsidy: 0,
        };
      case WindowRenovation.none:
    }
  }
  if (buildYear) {
    switch (true) {
      case buildYear < 1979:
        return {
          color: StatusCollor.red,
          minSavings: 200,
          maxSavings: 400,
          subsidy: 0.2,
        };
      case buildYear <= 1995:
        return {
          color: StatusCollor.yellow,
          minSavings: 200,
          maxSavings: 400,
          subsidy: 0.2,
        };
      case buildYear <= 2019:
        return {
          color: StatusCollor.yellow,
          minSavings: 200,
          maxSavings: 400,
          subsidy: 0.2,
        };
      case buildYear >= 2020:
        return {
          color: StatusCollor.green,
          minSavings: 0,
          maxSavings: 0,
          subsidy: 0,
        };
    }
  }
  return {
    color: StatusCollor.none,
    minSavings: 0,
    maxSavings: 0,
    subsidy: 0,
  };
};

export const estimateIsfpBenefits = (statusQuoResponse?: StatusQuoResponse): BenefitsResponse => {
  const { buildYear, cellingRenovation, doorRenovation, floorInsulation, outerWallInsulation, heatingSystemAge, windowRenovation } = statusQuoResponse || {};

  const statuses = [
    ceilingStatus(cellingRenovation, buildYear),
    doorStatus(doorRenovation, buildYear),
    floorStatus(floorInsulation, buildYear),
    outerWallStatus(outerWallInsulation, buildYear),
    heatingSystemStatus(heatingSystemAge, buildYear),
    windowStatus(windowRenovation, buildYear),
  ];
  const minSavings = statuses.reduce((acc, v) => acc + v.minSavings, 0);
  const maxSavings = statuses.reduce((acc, v) => acc + v.maxSavings, 0);
  const subsidy = statuses.reduce((a, b) => (a > b.subsidy ? a : b.subsidy), 0) * 100;

  return {
    minSavings,
    maxSavings,
    subsidy,
  };
};
