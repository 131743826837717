import { TableRow, TableCell, Typography, Accordion, AccordionSummary, AccordionDetails, TableContainer, Table, TableBody } from "@mui/material";
import { FunctionComponent } from "react";
import { PageViewMetric } from "./types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type PageViewsProps = {
  pageViews: PageViewMetric[];
  displayZeros?: boolean;
};

const PageViews: FunctionComponent<PageViewsProps> = ({ pageViews, displayZeros }) => {
  const rows = [];
  rows.push(
    <TableRow>
      <TableCell style={{ borderBottom: "none" }} align='right'>
        <Typography variant='subtitle1' style={{ fontWeight: 600 }}>
          Page
        </Typography>
      </TableCell>
      <TableCell style={{ borderBottom: "none" }} align='right'>
        <Typography variant='subtitle1' style={{ fontWeight: 600 }}>
          Action
        </Typography>
      </TableCell>
      <TableCell style={{ borderBottom: "none" }} align='right'>
        <Typography variant='subtitle1' style={{ fontWeight: 600 }}>
          Total
        </Typography>
      </TableCell>
    </TableRow>,
  );
  for (const metric of pageViews) {
    if (metric.total || displayZeros) {
      rows.push(
        <TableRow>
          <TableCell style={{ borderBottom: "none" }} align='right'>
            <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
              {metric.page}
            </Typography>
          </TableCell>
          <TableCell style={{ borderBottom: "none" }} align='right'>
            <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
              {metric.action}
            </Typography>
          </TableCell>
          <TableCell style={{ borderBottom: "none" }} align='right'>
            <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
              {metric.total.toLocaleString("de-DE")}
            </Typography>
          </TableCell>
        </TableRow>,
      );
    }
  }

  return (
    <Accordion slotProps={{ transition: { unmountOnExit: true } }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='subtitle1' sx={{ fontWeight: 500, flexGrow: 1 }}>
          Page Views
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default PageViews;
