import { useCallback } from "react";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";
import { InviteEmailTemplate, Product } from "../types/cockpit/types";

const API_ENDPOINT = `${apiEndpoint()}/invite-email-template`;

export default function useFetchInviteEmailTemplate() {
  const { authAxios, error, running, data } = useAuthAxios<InviteEmailTemplate>();

  const getInviteEmailTemplate = useCallback(
    async (product?: Product, isFree?: boolean) => {
      return await authAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}`,
        params: {
          ...(product ? { product: encodeURIComponent(product) } : {}),
          ...(isFree ? { isFree: encodeURIComponent(isFree) } : {}),
        },
      });
    },
    [authAxios],
  );

  return { getInviteEmailTemplate, errorLoadingTemplate: error, isLoadingTemplate: running, template: data };
}
