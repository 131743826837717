export type UpsertQuestionnaireRequest = {
  id?: string;
  leadId?: string;
  source?: SchnellcheckSource;
  answers?: Answers;
  referrerCode?: string;
  referrer?: string;
};

export enum SchnellcheckSource {
  embedded = "embedded",
  standalone = "standalone",
}

export type Answers = {
  houseDetails?: HouseDetails;
  houseHeating?: HouseHeating;
  houseInsulation?: HouseInsulation;
  houseOpenings?: HouseOpenings;
  contact?: Contact;
};

export type HouseDetails = {
  area?: number;
  yearOfConstruction?: number;
  type?: HouseType;
  heatingSystemType?: HeatingSystemType;
  isMonument?: boolean;
};

export type HouseHeating = {
  type?: HeatingSystemType;
  yearOfInstalation?: number;
};

export type HouseInsulation = {
  yearOfBasementInsulation?: number;
  yearOfRoofInsulation?: number;
  yearOfWallsInsulation?: number;
};

export type HouseOpenings = {
  doors?: DoorType;
  windows?: WindowType;
  doorsYear?: number;
};

export type Contact = {
  email?: string;
  telephone?: string;
  callMode?: CallModeType;
  firstName?: string;
  lastName?: string;
  address?: string;
};

export enum HouseType {
  singleFamily = "singleFamily",
  doubleFamily = "doubleFamily",
  multiFamily = "multiFamily",
}

export enum DoorType {
  steel = "steel",
  wood = "wood",
  plastic = "plastic",
}

export enum WindowType {
  simple = "simple",
  double = "double",
  triple = "triple",
}

export enum HeatingSystemType {
  gas = "gas",
  oil = "oil",
  woodPellets = "woodPellets",
  electricity = "electricity",
  districtHeating = "districtHeating",
  heatPump = "heatPump",
  liquefiedPetroleumGas = "liquefiedPetroleumGas",
  other = "other",
}

export enum InsulationType {
  walls = "walls",
  basement = "basement",
  roof = "roof",
}

export enum CallModeType {
  "Call me" = "Call me",
  "Book a call" = "Book a call",
}

export type AnalysisState = {
  id: string;
  report: AnalysisReport;
};

export type SchnellcheckQuestionnaire = {
  id: string;
  source?: SchnellcheckSource;
  leadId?: string;
  answers: Answers;
  report: AnalysisReport;
  tenant?: TenantBasicInfo;
  referrerCode?: string;
  referrer?: string;
  lastUpdatedAt: number;
};

export type TenantBasicInfo = {
  id: string;
  name: string;
  displayName: string;
};

export type AnalysisReport = {
  energyCostSavings: number;
  subsidy: number;
  trees: number;
  currentEnergyClass: string;
  afterRenovationEnergyClass: string;
  status: Status;
};

export enum StatusColor {
  red = 0,
  yellow,
  green,
}

export type Status = {
  heating: StatusColor;
  windows: StatusColor;
  roof: StatusColor;
  envelope: StatusColor;
  basement: StatusColor;
  doors: StatusColor;
};

export enum ReportStatusEntity {
  Heating = "heating",
  Windows = "windows",
  Roof = "roof",
  Envelope = "envelope",
  Basement = "basement",
  Doors = "doors",
}
