import { apiEndpoint } from "../utils/params";

import { useUnauthAxios } from "./useUnauthAxios";
import { useCallback } from "react";
import { ConsumptionCertContactRequest } from "../pages/funnels/consumption-certificate/types";

const API_ENDPOINT = `${apiEndpoint()}/consumption-certificate/contact`;
const headers = { "Content-Type": "application/json" };

export function useSubmitConsCertContactReq() {
  const { unauthAxios, error, running, data } = useUnauthAxios<ConsumptionCertContactRequest>();

  const submitConsCertContactReq = useCallback(
    async (request: ConsumptionCertContactRequest) => {
      await unauthAxios({
        method: "POST",
        url: API_ENDPOINT,
        data: request,
        headers,
      });
    },
    [unauthAxios],
  );

  return { submitConsCertContactReq, error, running, data };
}
