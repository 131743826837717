import axios from "axios";
import { apiEndpoint } from "../utils/params";
import { retrieveRum } from "../AwsRum";

const API_ENDPOINT = `${apiEndpoint()}/metrics`;

export default function usePutMetric() {
  function putMetric(page: string, action: string, leadId?: string) {
    const sendData = async () => {
      await axios.put(API_ENDPOINT, JSON.stringify({ page, action, leadId }), {
        headers: {
          "Content-Type": "application/json",
        },
      });
    };
    sendData().catch((e: unknown) => {
      console.error(e);
      retrieveRum()?.recordError(e);
    });
  }
  return [putMetric];
}
