import { useCallback } from "react";
import { ModernisationRecommendationState } from "../types/ConsumptionCertificateState";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";

export const API_ENDPOINT = `${apiEndpoint()}/consumption-certificate`;

export const useUpdateConsumptionCertModernisations = (consumptionCertId: string) => {
  const { authAxios, error, running } = useAuthAxios();

  const putModernisations = useCallback(
    async (modernisations: ModernisationRecommendationState[]) =>
      authAxios({
        method: "PUT",
        baseURL: `${API_ENDPOINT}/${consumptionCertId}/modernisations`,
        data: JSON.stringify(modernisations),
      }),
    [authAxios, consumptionCertId],
  );

  return { putModernisations, error, running };
};
