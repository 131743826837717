import { AuthenticationDetails, ChallengeName, CognitoUser, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UserResponse } from "../types/cockpit/types";
import { apiEndpoint } from "../utils/params";
import Smartlook from "smartlook-client";
import { cognitoClientId, cognitoPoolId } from "../utils/params";
import { retrieveRum } from "../AwsRum";
import { differenceInMinutes } from "date-fns";
import { ActionNeeded, ActionType, AuthError, AuthErrorType, AuthSession } from "../types/Auth";
import { useNavigate } from "react-router-dom";

const fetchUser = async (session: CognitoUserSession) => {
  const res: { data: UserResponse; status: number } = await axios.request({
    method: "GET",
    baseURL: `${apiEndpoint()}/user`,
    headers: {
      Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
    },
  });
  return res;
};

export const isSessionValid = (session?: CognitoUserSession) => {
  const expiration = (session?.getIdToken().getExpiration() ?? 0) * 1_000;
  return differenceInMinutes(new Date(expiration), new Date()) > 30;
};

export default function useAuth(): AuthSession {
  const userPool = useMemo(() => new CognitoUserPool({ ClientId: cognitoClientId(), UserPoolId: cognitoPoolId() }), []);
  const [session, setSession] = useState<CognitoUserSession>();
  const [isLoading, setIsLoading] = useState(true);
  const [actionNeeded, setActionNeeded] = useState<ActionNeeded | undefined>(undefined);
  const [user, setUser] = useState<UserResponse>();
  const [error, setError] = useState<AuthError | undefined>();
  const navigate = useNavigate();

  // When login fails due to require password change, this method changes the password using old and
  // new values, and already logs into the session
  const updatePasswordOnRequireChange = async (newPassword: string, requiredAttributes: { [key: string]: string }) => {
    if (actionNeeded?.actionType != ActionType.PASSWORD_CHANGE_REQUIRED) {
      setError({ type: AuthErrorType.MISSING_MANDATORY_PASSWORD_CHANGE_DATA });
      return;
    }
    setIsLoading(true);
    setError(undefined);
    const user = new CognitoUser({ Pool: userPool, Username: actionNeeded.username });
    await new Promise((resolve, reject) => {
      user.authenticateUser(new AuthenticationDetails({ Username: actionNeeded.username, Password: actionNeeded.oldPassword }), {
        onFailure: (err) => {
          setIsLoading(false);
          setError({ type: AuthErrorType.FAILED_AUTHENTICATING });
          reject(err);
        },
        onSuccess: async () => {
          setIsLoading(false);
          setError({ type: AuthErrorType.CURRENT_PASSWORD_IS_CORRECT });
          reject("already changed password");
        },
        newPasswordRequired: (_userAttr, _requiredAttr) => {
          user.completeNewPasswordChallenge(newPassword, requiredAttributes, {
            onFailure: (changePswErr) => {
              setIsLoading(false);
              setError({ type: AuthErrorType.FAILED_CHANGING_PASSWORD });
              reject(changePswErr);
            },
            onSuccess: (changePswdSucc) => {
              setSession(changePswdSucc);
              populateUserInfo(changePswdSucc);
              setActionNeeded(undefined);
              resolve(changePswdSucc);
            },
          });
        },
      });
    });
  };

  const login = async (username: string, password: string) => {
    clearActionNeeded();
    setIsLoading(true);
    setError(undefined);
    const user = new CognitoUser({ Pool: userPool, Username: username });

    await new Promise((resolve: (val?: CognitoUserSession) => void, reject) => {
      user.authenticateUser(new AuthenticationDetails({ Username: username, Password: password }), {
        onFailure: (err) => {
          setIsLoading(false);
          switch (err.name) {
            case "UserNotConfirmedException":
              setActionNeeded({ actionType: ActionType.CONFIRM_ACCOUNT_REQUIRED, username });
              break;
            case "NotAuthorizedException":
            case "UserNotFoundException":
              setError({ type: AuthErrorType.WRONG_CREDENTIALS });
              break;
            default:
              setError({ type: AuthErrorType.FAILED_AUTHENTICATING });
              reject(err);
              break;
          }
          resolve();
        },
        onSuccess: async (rslt) => {
          setSession(rslt);
          populateUserInfo(rslt);
          resolve(rslt);
        },
        newPasswordRequired: (userAttributes: { [key: string]: string }, requiredAttributes: string[]) => {
          setIsLoading(false);
          setActionNeeded({ actionType: ActionType.PASSWORD_CHANGE_REQUIRED, userAttributes, requiredAttributes, oldPassword: password, username });
          resolve();
        },
        mfaRequired: (challengeName: ChallengeName, challengeParameters: unknown) => {
          setIsLoading(false);
          setActionNeeded({ actionType: ActionType.MFA_REQUIRED, challengeName, challengeParameters });
          resolve();
        },
      });
    });
  };

  const clearActionNeeded = () => {
    setIsLoading(false);
    setActionNeeded(undefined);
  };

  const logout = useCallback(() => {
    userPool.getCurrentUser()?.signOut();
    clearActionNeeded();
    setError(undefined);
    setUser(undefined);
    setSession(undefined);
    setIsLoading(false);
    navigate("/signin");
  }, [navigate, userPool]);

  const populateUserInfo = useCallback(async (session: CognitoUserSession) => {
    setIsLoading(true);
    try {
      const { data } = await fetchUser(session);
      setUser(data);
      Smartlook.identify(data.id, { name: data.name, email: data.email, tenant: data.tenant?.name ?? "", roles: JSON.stringify(data.groups ?? []) });
      retrieveRum()?.addSessionAttributes({
        userId: data.id,
        name: data.name,
        email: data.email,
        tenant: data.tenant?.name ?? "",
        roles: JSON.stringify(data.groups ?? []),
      });
    } catch (e) {
      retrieveRum()?.recordError(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const refreshSession = useCallback(async () => {
    setIsLoading(true);
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      setIsLoading(false);
      return;
    }
    if (!session || !isSessionValid(session)) {
      cognitoUser.getSession((error: Error, cognitoSession: CognitoUserSession | null) => {
        if (error || !cognitoSession) {
          setIsLoading(false);
          return;
        }
        if (!isSessionValid(cognitoSession)) {
          cognitoUser.refreshSession(cognitoSession.getRefreshToken(), (error: Error, refreshedSession: CognitoUserSession | null) => {
            if (error || !refreshedSession) {
              setIsLoading(false);
              return;
            }
            setSession(refreshedSession);
            if (!user) {
              populateUserInfo(refreshedSession);
            }
          });
        } else {
          setSession(cognitoSession);
          if (!user) {
            populateUserInfo(cognitoSession);
          }
        }
      });
    } else if (!user) {
      populateUserInfo(session);
    }
  }, [populateUserInfo, session, user, userPool]);

  useEffect(() => {
    if (!session) {
      refreshSession();
    } else if (user) {
      setIsLoading(false);
    }
  }, [refreshSession, session, user]);

  return { session, refreshSession, user, isLoading, error, actionNeeded, login, logout, clearActionNeeded, updatePasswordOnRequireChange };
}
