import { FunctionComponent } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { formatNum } from "../../utils/utils";
import { PropertyInput } from "../../types/types";
import { FormattedMessage } from "react-intl";

export const PropertyInfoTable: FunctionComponent<PropertyInput> = (props) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table size='small' aria-label='Energy Efficiency'>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} style={{ backgroundColor: "black", color: "white" }}>
                <FormattedMessage id='app.property-info.info' />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <FormattedMessage id='app.property-info.type' />
              </TableCell>
              <TableCell align='right'>
                <FormattedMessage id={`app.add-property.${props.type}`} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id='app.property-info.area' />
              </TableCell>
              <TableCell align='right'>{formatNum(props.area)} m²</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id='app.property-info.year' />
              </TableCell>
              <TableCell align='right'>{props.yearOfConstruction}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id='app.property-info.heating' />
              </TableCell>
              <TableCell align='right'>
                <FormattedMessage id={`app.property-info.${props.heatingSystemType}`} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
