import { Box, Button, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ProgressBar from "../ProgressBar";
import { StatusQuoScreenProps } from "./StatusQuoFunnel";
import CheckIcon from "@mui/icons-material/Check";
import Screen5Dialog from "./Screen5Dialog";
import { useEffect, useRef, useState } from "react";
import { AnalysisReport, TenantBasicInfo } from "../../../../types/Schnellcheck";
import EnergyClasses from "./EnergyClasses";
import HouseStatus from "./HouseStatus";
import SavingsEstimates from "./SavingsEstimates";
import usePutMetric from "../../../../hooks/usePutMetric";

interface Screen5Props extends StatusQuoScreenProps {
  report?: AnalysisReport;
  tenant?: TenantBasicInfo;
}
export default function Screen5({ report, schnellcheck, setSchnellcheck, isUpdateRunning, prev, next, tenant }: Screen5Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [open, setOpen] = useState(false);
  const ctaRef = useRef(null);
  const toggleOpen = () => setOpen(!open);
  const [putMetric] = usePutMetric();

  useEffect(() => {
    putMetric("Schnellcheck", "RenderReportPage");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    if (ctaRef.current) {
      (ctaRef.current as HTMLButtonElement).scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box
      sx={{
        p: { xs: 0, lg: 4 },
        mx: { xs: 0, lg: 10 },
        backgroundColor: "#f6f6f6",
        mb: 4,
        maxWidth: "100%",
      }}
    >
      <Grid
        container
        rowSpacing={{
          xs: 0,
          lg: 8,
        }}
        columnSpacing={{
          xs: 0,
          lg: 8,
        }}
      >
        <Grid size={12}>
          <ProgressBar label={"80% geschafft"} value={80} mt={{ xs: 2, lg: 0 }} />
        </Grid>
        <Grid size={{ xs: 12, lg: 8 }}>
          <Report report={report} />
        </Grid>
        {isMobile && (
          <Grid size={12}>
            <Button variant='contained' sx={{ width: "calc(100% - 2rem)", m: 2 }} onClick={handleClick}>
              Wie geht es weiter?
            </Button>
          </Grid>
        )}
        <Grid size={{ xs: 12, lg: 4 }}>
          <Stack component={Paper} spacing={2} sx={{ p: 2, m: { xs: 2, lg: 0 } }}>
            <Typography fontSize={14} fontWeight={"fontWeightBold"}>
              Jetzt erfahren, welche Sanierungs-maßnahmen sich für Sie lohnen:
            </Typography>
            <Typography fontSize={14}>Mit einem individuellen Sanierungsfahrplan (iSFP)</Typography>
            <Stack direction={"row"} sx={{ pt: 2 }}>
              <CheckIcon color='success' sx={{ mr: 1 }} />
              <Typography fontSize={14}>Überblick über Maßnahmen, Kosten und Förderungen</Typography>
            </Stack>
            <Stack direction={"row"}>
              <CheckIcon color='success' sx={{ mr: 1 }} />
              <Typography fontSize={14}>dena-zertifiziert</Typography>
            </Stack>
            <Stack direction={"row"}>
              <CheckIcon color='success' sx={{ mr: 1 }} />
              <Typography fontSize={14}>bis zu 850€ Zuschuss</Typography>
            </Stack>
            <Stack direction={"row"}>
              <CheckIcon color='success' sx={{ mr: 1 }} />
              <Typography fontSize={14}>doppelte förderfähige Summe</Typography>
            </Stack>
            <Stack direction={"row"} sx={{ pb: 2 }}>
              <CheckIcon color='success' sx={{ mr: 1 }} />
              <Typography fontSize={14}>5% Extra-Förderung</Typography>
            </Stack>
            {!isMobile && (
              <Button variant='contained' id='offer-btn' data-cy='offer-btn' onClick={toggleOpen}>
                Kostenloses Erstgespräch & Angebot anfordern
              </Button>
            )}
            {!isMobile && (
              <Button variant='text' sx={{ color: "text.primary" }} onClick={prev}>
                Züruck
              </Button>
            )}
          </Stack>
        </Grid>
        {isMobile && (
          <Grid size={12}>
            <Button ref={ctaRef} id='offer-btn' data-cy='offer-btn' variant='contained' sx={{ width: "calc(100% - 2rem)", m: 2 }} onClick={toggleOpen}>
              Kostenloses Erstgespräch & Angebot anfordern
            </Button>
          </Grid>
        )}
        {isMobile && (
          <Grid size={12}>
            <Stack alignItems={"center"}>
              <Button variant='text' sx={{ color: "text.primary", mx: "auto" }} onClick={prev}>
                Züruck
              </Button>
            </Stack>
          </Grid>
        )}
        <Screen5Dialog
          schnellcheck={schnellcheck}
          setSchnellcheck={setSchnellcheck}
          isUpdateRunning={isUpdateRunning}
          prev={prev}
          next={next}
          open={open}
          handleClose={toggleOpen}
          fullscreen={isMobile}
          tenant={tenant}
        />
      </Grid>
    </Box>
  );
}

function Report({ report }: { report?: AnalysisReport }) {
  return (
    <Grid
      container
      spacing={0}
      sx={{
        ml: { lg: 4 },
        mx: { xs: 2, lg: "inherit" },
      }}
    >
      <Grid size={12}>
        <Typography variant='body1' fontWeight={"fontWeightBold"} mt={{ xs: 2, lg: 0 }} mb={2} fontSize={{ xs: 14, lg: 16 }}>
          Ihr Haus im Überblick:
        </Typography>
      </Grid>
      <Grid size={12}>
        <EnergyClasses future={report?.afterRenovationEnergyClass} current={report?.currentEnergyClass} />
      </Grid>
      <Grid size={12}>
        <HouseStatus status={report?.status} />
      </Grid>
      <Grid size={12}>
        <SavingsEstimates energyCostSavings={report?.energyCostSavings} subsidy={report?.subsidy} trees={report?.trees} />
      </Grid>
    </Grid>
  );
}
