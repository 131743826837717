import { Typography } from "@mui/material";
import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";
import OutlineFrame from "../OutlineFrame";
import { FrameMaterial } from "../../../../../../funnels/isfp/renovations/utils";

export function DoorMaterialComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  return (
    <OutlineFrame label={"Material der Haustür"}>
      <Typography>{FrameMaterial[renovations?.answers?.windowsAndDoorsInformation?.doorsMaterial as keyof typeof FrameMaterial] || "--"}</Typography>
    </OutlineFrame>
  );
}
