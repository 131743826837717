import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import useFetchVoucherCount from "../../../hooks/useFetchVoucherCount";
import { Product } from "../../../types/cockpit/types";
import { getProductCost } from "../../../utils/utils";

interface VoucherProps {
  product: Product;
  voucherSelected: (free: boolean) => void;
}

export default function Voucher({ product, voucherSelected }: VoucherProps) {
  const [free, setFree] = useState(false);
  const { getVoucherCount, count, hasVouchers } = useFetchVoucherCount();

  useEffect(() => {
    if (product) {
      getVoucherCount(product);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const handleFreeChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFree(checked);
    voucherSelected(checked);
  };

  const getProductCostStatement = (product: Product) => {
    switch (product) {
      case Product.consumptionCertificate:
        return (
          <>
            <Typography variant='body1'>
              Die Kosten für den Kunden starten ab {getProductCost(product)} für einen Verbrauchsausweis und ab {getProductCost(Product.requirementCertificate)}{" "}
              für einen Bedarfsausweis. Die Art des Ausweises richtet sich nach den gesetzlichen Anforderungen für das jeweilige Gebäude.
            </Typography>
            <Typography variant='body1'>
              Ein Bedarfsausweis ist verpflichtend für Gebäude mit max. 4 Wohnungen, deren Bauantrag vor 1977 gestellt worden ist und die danach nicht saniert
              wurden. Außerdem ist ein Bedarfsausweis nötig, falls zwar ein Verbrauchsausweis erlaubt wäre, aber keine zusammenhängenden Verbrauchswerte der
              letzten 3 Jahre vorliegen.
            </Typography>
          </>
        );
      case Product.onePager:
        return <Typography variant='body1'>Die Kosten für den Kunden starten ab {getProductCost(product)}.</Typography>;
      case Product.isfp:
      default:
        return <></>;
    }
  };

  const getVoucherOption = (product: Product) => {
    switch (product) {
      case Product.consumptionCertificate:
        return `Es sind noch ${count} Gutscheine für Energieverbrauchsausweise verfügbar. Möchtest du dem Kunden das Produkt kostenfrei anbieten? Falls rechtlich ein Energiebedarfsausweis erforderlich ist, machen wir der Kundin / dem Kunden ein separates Angebot.`;
      case Product.onePager:
        return `Es sind noch ${count} Gutscheine verfügbar. Möchtest du dem Kunden das Produkt kostenfrei anbieten?`;
      case Product.isfp:
      default:
        return "";
    }
  };

  const getVoucherExtraCost = (product: Product) => {
    switch (product) {
      case Product.consumptionCertificate:
        return (
          <>
            <Typography variant='body1'>
              Du weißt bereits, dass deine Kundin / dein Kunde einen Bedarfsausweis braucht? Dann lasse bitte das Häkchen weg und wir senden ihr / ihm eine
              Einladung als Selbstzahler.
            </Typography>
            <Typography variant='body1'>
              Ein Bedarfsausweis (ab {getProductCost(Product.requirementCertificate)}) ist verpflichtend für Gebäude mit max. 4 Wohnungen, deren Bauantrag vor
              1977 gestellt worden ist und die danach nicht saniert wurden. Außerdem ist ein Bedarfsausweis nötig, falls zwar ein Verbrauchsausweis erlaubt
              wäre, aber keine zusammenhängenden Verbrauchswerte der letzten 3 Jahre vorliegen.
            </Typography>
          </>
        );
      case Product.onePager:
        return <Typography variant='body1'>Für Selbstzahler-Kunden starten die Kosten bei {getProductCost(product)}.</Typography>;
      case Product.isfp:
      default:
        return "";
    }
  };

  return (
    <>
      {hasVouchers && product !== Product.isfp && (
        <>
          <FormControlLabel control={<Checkbox value={free} onChange={handleFreeChange} />} label={getVoucherOption(product)} />
          {getVoucherExtraCost(product)}
        </>
      )}
      {!hasVouchers && product && product !== Product.isfp && getProductCostStatement(product)}
    </>
  );
}
