import { useEffect, useCallback } from "react";
import { apiEndpoint } from "../utils/params";
import { Property } from "../types/types";
import { useAuthAxios } from "./useAuthAxios";

const API_ENDPOINT = `${apiEndpoint()}/properties`;

export default function useFetchProperties() {
  const { authAxios, data: properties, running: isLoading } = useAuthAxios<Property[]>();

  const fetchProperties = useCallback(async () => {
    await authAxios({
      method: "GET",
      baseURL: API_ENDPOINT,
    });
  }, [authAxios]);

  useEffect(() => {
    fetchProperties();
  }, [fetchProperties]);

  return { properties, isLoading };
}
