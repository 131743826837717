import { useEffect, useState } from "react";

export const useReachedScrollBottom = (offset = 0) => {
  const [reachedBottom, setReachedBottom] = useState(false);

  // attach scroll observer
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight - offset) {
        setReachedBottom(true);
      } else {
        setReachedBottom(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [offset]);

  return reachedBottom;
};
