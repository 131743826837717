import { TableRow, TableCell, Typography, TableContainer, Table, TableBody, Accordion, AccordionSummary, AccordionDetails, Chip, Stack } from "@mui/material";
import { FunctionComponent } from "react";
import { EventType, TenantMetrics } from "../../../types/ConsultantMetrics";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AuthMetric } from "./types";
import { NoMetricsFound, metricLabel } from "./utils";

type TenantMetricsComponentProps = {
  tenantMetrics: TenantMetrics;
  displayZeros?: boolean;
  authentication?: AuthMetric[];
  accountCreation?: AuthMetric[];
};

const TenantMetricsComponent: FunctionComponent<TenantMetricsComponentProps> = ({ tenantMetrics, authentication, accountCreation }) => {
  const rows = [];
  const rangeName = Object.keys(tenantMetrics.metrics).shift();
  if (!rangeName) {
    return <NoMetricsFound />;
  }
  const rangeMetrics = tenantMetrics.metrics[rangeName];
  const metricKeys = Object.keys(rangeMetrics);

  for (const metricKey of metricKeys) {
    const label = metricLabel(metricKey as EventType);
    const value = rangeMetrics[metricKey as EventType];

    if (value !== 0) {
      rows.push(
        <TableRow>
          <TableCell style={{ borderBottom: "none" }} align='right'>
            <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
              {label}
            </Typography>
          </TableCell>
          <TableCell style={{ borderBottom: "none" }} align='right'>
            <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
              {value.toLocaleString("de-DE")}
            </Typography>
          </TableCell>
        </TableRow>,
      );
    }
  }
  for (const metric of authentication ?? []) {
    if (metric.total !== 0) {
      rows.push(
        <TableRow>
          <TableCell style={{ borderBottom: "none" }} align='right'>
            <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
              {`Authentications with role ${metric.group ?? "none"} `}
            </Typography>
          </TableCell>
          <TableCell style={{ borderBottom: "none" }} align='right'>
            <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
              {metric.total.toLocaleString("de-DE")}
            </Typography>
          </TableCell>
        </TableRow>,
      );
    }
  }
  for (const metric of accountCreation ?? []) {
    if (metric.total !== 0) {
      rows.push(
        <TableRow>
          <TableCell style={{ borderBottom: "none" }} align='right'>
            <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
              {`Account creations for role ${metric.group ?? "none"} `}
            </Typography>
          </TableCell>
          <TableCell style={{ borderBottom: "none" }} align='right'>
            <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
              {metric.total.toLocaleString("de-DE")}
            </Typography>
          </TableCell>
        </TableRow>,
      );
    }
  }

  return (
    <TableContainer>
      <Table>
        <TableBody>{rows.length > 0 ? rows : <NoMetricsFound />}</TableBody>
      </Table>
    </TableContainer>
  );
};

const TenantState: FunctionComponent<{ state: boolean }> = ({ state }) => {
  return <Chip label={state ? "Active" : "Inctive"} style={{ backgroundColor: `${state ? "green" : "red"}` }} />;
};

const isTenantActive = (tenantMetrics: TenantMetrics): boolean => {
  const rangeName = Object.keys(tenantMetrics.metrics).shift();
  if (!rangeName) {
    return false;
  }
  const rangeMetrics = tenantMetrics.metrics[rangeName];
  const metricKeys = Object.keys(rangeMetrics);

  for (const metricKey of metricKeys) {
    if (rangeMetrics[metricKey as EventType]) {
      return true;
    }
  }
  return false;
};

type TenantsMetricsProps = {
  tenantsMetrics: TenantMetrics[];
  authentication: AuthMetric[];
  accountCreation: AuthMetric[];
};

const indexAuthMetrics = (metrics: AuthMetric[]): Map<string, AuthMetric[]> => {
  const map = new Map<string, AuthMetric[]>();
  for (const metric of metrics) {
    const tenantId = metric.tenantId ?? "none";
    const list = map.get(tenantId) ?? [];

    list.push(metric);
    map.set(tenantId, list);
  }
  return map;
};

const TenantsMetrics: FunctionComponent<TenantsMetricsProps> = ({ tenantsMetrics, authentication, accountCreation }) => {
  const accordions = [];
  const authenticationMap = indexAuthMetrics(authentication);
  const accountCreationMap = indexAuthMetrics(accountCreation);

  for (const tenantMetrics of tenantsMetrics) {
    accordions.push(
      <Accordion slotProps={{ transition: { unmountOnExit: true } }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction='row' alignItems='center' spacing={2} width={"100%"} pr={2}>
            <Typography variant='subtitle1' sx={{ fontWeight: 500, flexGrow: 1 }}>
              Tenant: {tenantMetrics.tenantDisplayName}
            </Typography>
            <TenantState state={isTenantActive(tenantMetrics)} />
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <TenantMetricsComponent
            tenantMetrics={tenantMetrics}
            authentication={authenticationMap.get(tenantMetrics.tenantId)}
            accountCreation={accountCreationMap.get(tenantMetrics.tenantId)}
          />
        </AccordionDetails>
      </Accordion>,
    );
  }
  return <>{accordions}</>;
};

export default TenantsMetrics;
