import { apiEndpoint } from "../utils/params";
import { SchnellcheckQuestionnaire } from "../types/Schnellcheck";
import axios from "axios";
import { useState } from "react";
import { retrieveRum } from "../AwsRum";

export const API_ENDPOINT = `${apiEndpoint()}/questionnaires`;
const LOCAL_STORAGE_KEY = "unsubmittedSchnellcheckId";
const headers = { "Content-Type": "application/json" };

export default function useResumeSchnellcheck() {
  const [resuming, setResuming] = useState(false);

  async function resumeSchnellcheck(leadId?: string): Promise<SchnellcheckQuestionnaire | null> {
    setResuming(true);
    const id = fromLocal();
    if (!id && leadId) {
      return getByLeadId(leadId);
    }
    if (id) {
      return getById(id);
    }
    setResuming(false);
    return null;
  }

  async function getByLeadId(leadId: string): Promise<SchnellcheckQuestionnaire | null> {
    return axios
      .get<SchnellcheckQuestionnaire>(`${API_ENDPOINT}?leadId=${leadId}`, { headers })
      .then((response) => response.data)
      .catch((e) => {
        console.error(e);
        retrieveRum()?.recordError(e);
        return null;
      })
      .finally(() => {
        setResuming(false);
      });
  }

  async function getById(id: string): Promise<SchnellcheckQuestionnaire | null> {
    return await axios
      .get<SchnellcheckQuestionnaire>(`${API_ENDPOINT}/${id}`, { headers })
      .then((response) => response.data)
      .catch((e) => {
        console.error(e);
        retrieveRum()?.recordError(e);
        return null;
      })
      .finally(() => {
        setResuming(false);
      });
  }

  function fromLocal() {
    return localStorage.getItem(LOCAL_STORAGE_KEY);
  }

  function toLocal(id: string) {
    localStorage.setItem(LOCAL_STORAGE_KEY, id);
  }

  function clearLocal() {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }

  return { resumeSchnellcheck, toLocal, clearLocal, resuming };
}
