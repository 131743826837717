import { createTheme } from "@mui/material/styles";
import novo from "./novo";
import { Themes } from "../types/types";

const novoDark = createTheme(novo, {
  name: Themes.NOVO_DARK,
  logo: "NOVO-WHI-TBG-XL.png",
  palette: {
    mode: "dark",
    primary: {
      main: "#FFDD59",
    },
    secondary: {
      main: "#597BFF",
    },
    background: {
      default: "#121212",
      paper: "#121212",
    },
    text: {
      primary: "rgba(255,255,255,0.87)",
      secondary: "rgba(255,255,255,0.6)",
      disabled: "rgba(255,255,255,0.38)",
    },
    action: {
      active: "#FFFFFF",
      hover: "rgba(255,255,255,0.08)",
      hoverOpacity: 0.08,
      selected: "rgba(255,255,255,0.16)",
      selectedOpacity: 0.16,
      disabled: "rgba(255,255,255,0.3)",
      disabledBackground: "rgba(255,255,255,0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(255,255,255,0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
  },
});

export default novoDark;
