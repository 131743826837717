import { Typography } from "@mui/material";
import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";
import OutlineFrame from "../OutlineFrame";
import { FrameMaterial } from "../../../../../../funnels/isfp/renovations/utils";

export function WindowsMaterialComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  return (
    <OutlineFrame label={"Material der Fensterrahmen"}>
      <Typography>{FrameMaterial[renovations?.answers?.windowsAndDoorsInformation?.windowsFrame as keyof typeof FrameMaterial] || "--"}</Typography>
    </OutlineFrame>
  );
}
