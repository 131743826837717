import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { ConsumptionCertificate } from "../../../../types/ConsumptionCertificateState";
import ConsumptionCertificateCard from "../../consumption-certificate/ConsumptionCertificateCard";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

interface DisplayConsumptionCertificateDialogProps {
  consumptionCertificate?: ConsumptionCertificate;
  open: boolean;
  handleClose: () => void;
}

export function DisplayConsumptionCertificateDialog({ consumptionCertificate, open, handleClose }: DisplayConsumptionCertificateDialogProps) {
  if (!consumptionCertificate) return null;
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth>
      <DialogTitle>
        Verbrauchsausweise antworten
        <Button
          sx={{ float: "inline-end", color: "rgba(0, 0, 0, 0.54)", ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
          onClick={handleClose}
          endIcon={<CloseIcon />}
        >
          Schließen
        </Button>
      </DialogTitle>
      <DialogContent dividers>
        <ConsumptionCertificateCard cert={consumptionCertificate} />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='secondary' onClick={handleClose}>
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
