import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ChangeEvent, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { SectionProps } from "./ConsumptionCertificateRequestPage";
import { HotWaterSystem } from "./ConsumptionCertificateRequest";
import FormHelperText from "@mui/material/FormHelperText";
import { FormControl } from "@mui/material";

export default function HotWaterSection({ data, unableToProceed, updateHandler }: SectionProps) {
  const [systemType, setSystemType] = useState<string>("");
  const selectType = (event: ChangeEvent<HTMLInputElement>) => setSystemType(event.target.value);
  const addSystem = () => {
    const systems = data.hotWaterData.systems;
    const matchesHeating = data.heatingData.heatingSystem === systemType || data.heatingData.secondaryHeatingSystemType === systemType;
    data.hotWaterData.systems = [...systems, { type: systemType, isPartOfHeating: matchesHeating ? undefined : false }];
    updateHandler(data);
    setSystemType("");
  };
  const removeSystemFactory = (index: number) => {
    return () => {
      const systems = data.hotWaterData.systems;
      systems.splice(index, 1);
      updateHandler(data);
    };
  };
  const updateSystemFactory = (index: number) => {
    return (system: HotWaterSystem) => {
      data.hotWaterData.systems[index] = system;
      updateHandler(data);
    };
  };
  return (
    <Stack spacing={2}>
      <Typography variant='body1'>
        Bitte teile uns hier mit, über welche Energiequelle dein Warmwasser erhitzt wird. <sup>*</sup>
      </Typography>
      {data.hotWaterData.systems.map((system, index) => (
        <HotWaterSystemCard key={index} system={system} remove={removeSystemFactory(index)} update={updateSystemFactory(index)} />
      ))}
      <FormControl error={data.hotWaterData.error} fullWidth>
        <Select data-cy='hotwater-select' onChange={selectType} value={systemType} disabled={unableToProceed}>
          {data.hotWaterData.systemOptions.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
        {data.hotWaterData.error && <FormHelperText>Erfolgt</FormHelperText>}
      </FormControl>
      <Stack direction='row'>
        <Button data-cy='hotwater-add-btn' color='secondary' onClick={addSystem} disabled={unableToProceed || !systemType}>
          + Add system
        </Button>
      </Stack>
    </Stack>
  );
}

function HotWaterSystemCard({ system, remove, update }: { system: HotWaterSystem; remove: () => void; update: (system: HotWaterSystem) => void }) {
  return (
    <Stack component={Paper} spacing={2} p={2}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h6'>{system.type}</Typography>
        <Stack>
          <IconButton aria-label='delete' onClick={remove}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Stack direction='row' alignItems='baseline' spacing={1}>
        <Typography>Identisch mit dem Heizsystem?</Typography>
        <Stack>
          <ToggleButtonGroup
            color={system.error ? "error" : "secondary"}
            value={system.isPartOfHeating === undefined ? "" : system.isPartOfHeating ? "yes" : "no"}
            size='small'
            exclusive
            onChange={(_, value) => {
              if (!value) return;
              update({
                ...system,
                isPartOfHeating: value === "yes",
                isCentralized: undefined,
              });
            }}
          >
            <ToggleButton data-cy='hotwater-system-partOfHeating-yes' value='yes'>
              Ja
            </ToggleButton>
            <ToggleButton data-cy='hotwater-system-partOfHeating-no' value='no'>
              Nein
            </ToggleButton>
          </ToggleButtonGroup>
          <FormHelperText error={system.partOfHeatingError}>{system.partOfHeatingError ? "Erfolgt" : " "}</FormHelperText>
        </Stack>
      </Stack>
      {system.isPartOfHeating === false && (
        <Stack direction='row' alignItems='baseline' spacing={1} useFlexGap sx={{ flexWrap: "wrap" }}>
          <Typography>{"Ist die Warmwassererwärmung zentral oder dezentral (zB. Durchlauferhitzer)?"}</Typography>
          <Stack>
            <ToggleButtonGroup
              color={system.error ? "error" : "secondary"}
              value={system.isCentralized === undefined ? "" : system.isCentralized ? "centralized" : "decentralized"}
              size='small'
              exclusive
              onChange={(_, value) => {
                if (!value) return;
                update({
                  ...system,
                  isCentralized: value === "centralized",
                });
              }}
            >
              <ToggleButton data-cy='hotwater-system-centralized' value='centralized'>
                Zentral
              </ToggleButton>
              <ToggleButton data-cy='hotwater-system-decentralized' value='decentralized'>
                Dezentral
              </ToggleButton>
            </ToggleButtonGroup>
            <FormHelperText error={system.centralizedError}>{system.centralizedError ? "Erfolgt" : " "}</FormHelperText>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
