import { useCallback } from "react";
import { apiEndpoint } from "../utils/params";
import { useUnauthAxios } from "./useUnauthAxios";

export const API_ENDPOINT = `${apiEndpoint()}/klimafaktor`;

export default function useVerifyPLZ() {
  const { unauthAxios, error, responseStatus } = useUnauthAxios<void>();

  const verifyPLZ = useCallback(
    async (plz: string, time: number) =>
      await unauthAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}?plz=${plz}&time=${time}`,
      }),
    [unauthAxios],
  );

  return { verifyPLZ, plzError: error, plzStatus: responseStatus };
}
