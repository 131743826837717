import Grid from "@mui/material/Grid2";
import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";
import { QuestionComponent } from "../QuestionComponent";
import OutlineFrame from "../OutlineFrame";
import { Typography } from "@mui/material";

export default function PhotovoltaicsComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  const photovoltaicsOptions = [
    {
      label: "Ja",
      selected: !!renovations?.answers?.renewablesInformation?.photovoltaicsInstalled,
    },
    {
      label: "Nein",
      selected: !renovations?.answers?.renewablesInformation?.photovoltaicsInstalled,
    },
  ];
  const fullfeedOptions = [
    {
      label: "Ja",
      selected: !!renovations?.answers?.renewablesInformation?.isFullfeed,
    },
    {
      label: "Nein",
      selected: !renovations?.answers?.renewablesInformation?.isFullfeed,
    },
  ];
  const batteryOptions = [
    {
      label: "Ja",
      selected: !!renovations?.answers?.renewablesInformation?.isBatteryInstalled,
    },
    {
      label: "Nein",
      selected: !renovations?.answers?.renewablesInformation?.isBatteryInstalled,
    },
  ];
  return (
    <Grid container spacing={4}>
      <Grid size={6}>
        <QuestionComponent question='Ist aktuell Photovoltaik vorhanden?' options={photovoltaicsOptions} />
      </Grid>
      <Grid size={6} />
      {!!renovations?.answers?.renewablesInformation?.photovoltaicsInstalled && (
        <>
          <Grid size={6}>
            <OutlineFrame label={"Photovoltaik Kapazität"}>
              <Typography>{renovations?.answers?.renewablesInformation?.photovoltaicsCapacity}</Typography>
            </OutlineFrame>
          </Grid>
          <Grid size={6}>
            <OutlineFrame label={"Photovoltaik Installationsjahr"}>
              <Typography>{renovations?.answers?.renewablesInformation?.photovoltaicsInstallationYear}</Typography>
            </OutlineFrame>
          </Grid>
        </>
      )}
      <Grid size={6}>
        <QuestionComponent question='Betreiben Sie PV-Volleinspeisung in das Stromnetz?' options={fullfeedOptions} />
      </Grid>
      <Grid size={6} />
      {!renovations?.answers?.renewablesInformation?.isFullfeed && (
        <>
          <Grid size={6}>
            <QuestionComponent question='Ist ein Batteriespeicher in Verbindung mit der PV-Anlage vorhanden??' options={batteryOptions} />
          </Grid>
          <Grid size={6} />
        </>
      )}
      {!renovations?.answers?.renewablesInformation?.isFullfeed && !!renovations?.answers?.renewablesInformation?.isBatteryInstalled && (
        <>
          <Grid size={6}>
            <OutlineFrame label={"Batterie Kapazität"}>
              <Typography>{renovations?.answers?.renewablesInformation?.batteryCapacity}</Typography>
            </OutlineFrame>
          </Grid>
          <Grid size={6}>
            <OutlineFrame label={"Installationsjahr Batteriespeicher"}>
              <Typography>{renovations?.answers?.renewablesInformation?.batteryInstallationYear}</Typography>
            </OutlineFrame>
          </Grid>
        </>
      )}
    </Grid>
  );
}
