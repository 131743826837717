import { useCallback } from "react";
import { useUnauthAxios } from "./useUnauthAxios";
import { apiEndpoint } from "../utils/params";

export type MailingSettings = {
  email: string;
  // (consumption certs, schnellchecks, etc...)
  acceptOffers?: boolean;
};

export type GetMailSettingsParams = { email?: string; leadId?: string };

export const useGetMailingSettings = () => {
  const { unauthAxios, error, running, data } = useUnauthAxios<MailingSettings>();

  const getMailingSettings = useCallback(
    async (params: GetMailSettingsParams) => {
      const paramKeys = Object.keys(params) as (keyof GetMailSettingsParams)[];
      if (!paramKeys.length) {
        throw new Error("missing parameter");
      }
      await unauthAxios({
        method: "GET",
        url: `${apiEndpoint()}/mailing-preferences?${paramKeys.map((key) => `${key}=${params[key]}`).join("&")}`,
      });
    },
    [unauthAxios],
  );

  return { getMailingSettings, error, running, data };
};
