import { Box, FormControl, FormControlProps, FormHelperText, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";

interface NovoCustomSelectOption {
  value: string;
  subTitle: string;
  icon: JSX.Element;
}

interface NovoCustomSelectProps extends FormControlProps {
  label: string;
  name?: string;
  required?: boolean;
  options: NovoCustomSelectOption[];
  value: string;
  error?: boolean;
  helperText?: string;
  onUpdate?: (value: string) => void;
}

export default function NovoCustomSelect({
  label,
  options,
  value,
  name = label.toLowerCase().replace(" ", "-"),
  required = false,
  error = false,
  helperText,
  onUpdate,
  ...props
}: NovoCustomSelectProps) {
  const [_value, setValue] = useState("");

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handler = (newValue: string) => {
    setValue(newValue);
    if (onUpdate) {
      onUpdate(newValue);
    }
  };

  const _options: Array<ReactElement> = [];
  for (let i = 0; i < options.length; i++) {
    _options.push(
      <MenuItem
        key={i}
        value={options[i].value}
        onClick={() => {
          handler(`${options[i].value}`);
        }}
      >
        <Grid container sx={{ alignItems: "center" }}>
          <Grid size={12} sx={{ display: "flex", width: 44 }}>
            {options[i].icon}
          </Grid>
          <Grid size={12} sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
            <Box component='span'>{options[i].value}</Box>
            <Typography variant='body2' sx={{ color: "text.secondary" }}>
              {options[i].subTitle}
            </Typography>
          </Grid>
        </Grid>
      </MenuItem>,
    );
  }

  return (
    <FormControl error={error} fullWidth {...props}>
      <InputLabel id={`${name}-selector-label`} required={required}>
        {label}
      </InputLabel>
      <Select
        labelId={`${name}-selector-label`}
        id={`${name}-selector`}
        value={_value}
        label={label}
        onChange={() => {}}
        required={required}
        renderValue={(_) => _value}
      >
        {_options}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
