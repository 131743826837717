import { Typography } from "@mui/material";
import { BubbleFlow } from "../../../../../../../types/cockpit/types";
import { SchnellcheckQuestionnaire } from "../../../../../../../types/Schnellcheck";
import OutlineFrame from "../OutlineFrame";

export default function ConstructionYearComponent({ schnellcheck, bubbleFlow }: { schnellcheck?: SchnellcheckQuestionnaire; bubbleFlow?: BubbleFlow }) {
  if (!schnellcheck && !bubbleFlow) {
    return null;
  }
  const constructionYear = schnellcheck?.answers?.houseDetails?.yearOfConstruction || bubbleFlow?.statusQuoResponse?.buildYear;

  return (
    <OutlineFrame label={"Baujahr"}>
      <Typography>{constructionYear}</Typography>
    </OutlineFrame>
  );
}
