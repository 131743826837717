import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Smartlook from "smartlook-client";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import { useCreateConsumptionCertificate } from "../../../hooks/useCreateConsumptionCertificate";
import { useCreatePurchaseOrder } from "../../../hooks/useCreatePurchaseOrder";
import { useNavigateToStripePayment } from "../../../hooks/useNavigateToStripePayment";
import { OrderPaymentStatus } from "../../../types/PurchaseOrder";
import { Product } from "../../../types/types";
import { bubbleCertificateEndpointRedirect } from "../../../utils/params";
import BuildingSection from "./BuildingSection";
import ConsumptionCertificateRequest from "./ConsumptionCertificateRequest";
import GeneralSection from "./GeneralSection";
import HeatingSection from "./HeatingSection";
import HotWaterSection from "./HotWaterSection";
import Stepper from "./Stepper";
import useVerifyPLZ from "../../../hooks/useVerifyPLZ";
import CoolingSection from "./CoolingSection";
import { retrieveRum } from "../../../AwsRum";
import { ConsentLabel } from "../../../components/ConsentLabel";

const today = new Date();
const time = new Date(today.getFullYear() - 1, 0, 0);

export type SectionProps = {
  data: ConsumptionCertificateRequest;
  unableToProceed: boolean;
  setUnableToProceed: Dispatch<SetStateAction<boolean>>;
  updateHandler: (cert: ConsumptionCertificateRequest, mustVerifyPLZ?: boolean) => void;
};

export default function ConsumptionCertificateRequestPage() {
  const { createConsumptionCertificate, isLoading, createdCertificate } = useCreateConsumptionCertificate();
  const { createPurchaseOrder, createOrderRunning, createdPurchaseOrder } = useCreatePurchaseOrder();
  const { verifyPLZ, plzStatus } = useVerifyPLZ();
  const navigateToStripePayment = useNavigateToStripePayment();
  const [searchParams] = useSearchParams();
  const [consumptionCertificate, setConsumptionCertificate] = useState(new ConsumptionCertificateRequest());
  const formRef = useRef<HTMLFormElement>(null);
  const buildingSectionRef = useRef(null);
  const generalSectionRef = useRef(null);
  const coolingSectionRef = useRef(null);
  const heatingSectionRef = useRef(null);
  const hotWaterSectionRef = useRef(null);
  const [lead] = useState(searchParams.get("lead") ?? undefined); // Lead ID coming from Bubble
  const [leadId] = useState(searchParams.get("leadId") ?? undefined);
  const [voucherId] = useState(searchParams.get("voucherId") ?? undefined);
  const [unableToProceed, setUnableToProceed] = useState(false);

  const update = (cert: ConsumptionCertificateRequest, mustVerifyPLZ: boolean) => {
    if (!cert.isValid()) {
      cert.validate();
    }
    if (mustVerifyPLZ) {
      verifyPLZ(cert.buildingData.postalCode, time.getTime());
    }
    const certificate = ConsumptionCertificateRequest.deepClone(cert);
    setConsumptionCertificate(certificate);
    certificate.save();
  };

  const submitRequest = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const form = event.currentTarget?.form;
    const isValid = consumptionCertificate.validate();
    setConsumptionCertificate(ConsumptionCertificateRequest.deepClone(consumptionCertificate));
    if (isValid && form && form.checkValidity()) {
      const certReqObject = consumptionCertificate.toConsumptionCertificateState();
      createConsumptionCertificate(certReqObject, { lead, leadId, voucherId });
    } else if (form && form.reportValidity()) {
      const errorElement = findElementOnError(form);
      errorElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  };

  const findElementOnError = (form: HTMLFormElement): Element | undefined => {
    for (let i = 0; i < form.elements.length; i++) {
      if (form.elements[i].ariaInvalid === "true") {
        return form.elements[i];
      }
    }
    return;
  };

  useEffect(() => {
    consumptionCertificate.buildingData.postalCodeUnknown = !!plzStatus && plzStatus !== 200;
    setConsumptionCertificate(ConsumptionCertificateRequest.deepClone(consumptionCertificate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plzStatus]);

  // after certificate was created, automatically create a purchase order for it
  useEffect(() => {
    if (createdCertificate && !createdPurchaseOrder) {
      consumptionCertificate.cleanUp();
      createPurchaseOrder({
        bubbleLeadId: lead,
        leadId: leadId,
        voucherId: voucherId,
        items: [{ id: createdCertificate.id, product: Product.consumptionCertificate }],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdCertificate]);

  useEffect(() => {
    if (createdPurchaseOrder) {
      // if resulting order is not free, navigate to stripe to pay it
      if (createdPurchaseOrder.paymentData?.paymentStatus !== OrderPaymentStatus.NoPaymentRequired) {
        navigateToStripePayment(createdPurchaseOrder.id, { redirectTo: bubbleCertificateEndpointRedirect() });
      } else {
        // otherwise just proceed to bubble since certificate is free
        window.location.href = bubbleCertificateEndpointRedirect();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdPurchaseOrder]);

  useEffect(() => {
    Smartlook.init("ccdf96019b9dcddb440eb89056ab6f3572810b4e", { region: "eu" });
    Smartlook.record({ forms: true, numbers: true, emails: true, ips: true, api: true });
    const userId = lead ?? leadId;
    if (userId) {
      Smartlook.identify(userId, { system: lead ? "Bubble" : "NewCockpit", voucherId: voucherId ?? "" });
      retrieveRum()?.addSessionAttributes({ userId, voucherId: voucherId ?? "" });
    }
    const certificate = ConsumptionCertificateRequest.load();
    if (certificate) {
      setConsumptionCertificate(certificate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleTermsChange(_: ChangeEvent<HTMLInputElement>, checked: boolean): void {
    consumptionCertificate.checksData.acceptedTerms = checked;
    update(consumptionCertificate, false);
  }

  function handleDataVerifiedChange(_: ChangeEvent<HTMLInputElement>, checked: boolean): void {
    consumptionCertificate.checksData.isDataVerifiedToBestKnowledge = checked;
    update(consumptionCertificate, false);
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Grid
        container
        spacing={2}
        ref={formRef}
        component={"form"}
        sx={{
          mt: 5,
          maxWidth: "lg",
          mx: "auto",
        }}
      >
        <Grid size={12} mb={10}>
          <Typography variant='h2' textAlign='center' fontWeight={600}>
            NOVO
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Typography variant='h3' gutterBottom fontSize={{ xs: "2rem", sm: "3rem" }}>
            Ihr Verbrauchsausweis
          </Typography>
        </Grid>
        <Grid size={12}>
          <Divider sx={{ mb: 2 }} />
        </Grid>
        <Grid size={12} sx={{ display: { xs: "block", md: "none" } }}>
          <Typography variant='h4'>{consumptionCertificate.sections[0]}</Typography>
        </Grid>
        <Grid sx={{ display: { xs: "none", md: "block" } }} size={{ md: 5 }}>
          <Stepper
            steps={consumptionCertificate.sections}
            refs={{ buildingSectionRef, generalSectionRef, coolingSectionRef, heatingSectionRef, hotWaterSectionRef }}
          />
        </Grid>
        <Grid ref={buildingSectionRef} size={{ xs: 12, md: 7 }}>
          <BuildingSection data={consumptionCertificate} updateHandler={update} unableToProceed={unableToProceed} setUnableToProceed={setUnableToProceed} />
        </Grid>
        <Grid size={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid size={12} sx={{ display: { xs: "block", md: "none" } }}>
          <Typography variant='h4'>{consumptionCertificate.sections[1]}</Typography>
        </Grid>
        <Grid sx={{ display: { xs: "none", md: "block" } }} size={{ md: 5 }} />
        <Grid ref={generalSectionRef} size={{ xs: 12, md: 7 }}>
          <GeneralSection data={consumptionCertificate} updateHandler={update} unableToProceed={unableToProceed} setUnableToProceed={setUnableToProceed} />
        </Grid>
        <Grid size={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid size={12} sx={{ display: { xs: "block", md: "none" } }}>
          <Typography variant='h4'>{consumptionCertificate.sections[2]}</Typography>
        </Grid>
        <Grid sx={{ display: { xs: "none", md: "block" } }} size={{ md: 5 }} />
        <Grid ref={coolingSectionRef} size={{ xs: 12, md: 7 }}>
          <CoolingSection data={consumptionCertificate} updateHandler={update} unableToProceed={unableToProceed} setUnableToProceed={setUnableToProceed} />
        </Grid>
        <Grid size={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid size={12} sx={{ display: { xs: "block", md: "none" } }}>
          <Typography variant='h4'>{consumptionCertificate.sections[3]}</Typography>
        </Grid>
        <Grid sx={{ display: { xs: "none", md: "block" } }} size={{ md: 5 }} />
        <Grid ref={heatingSectionRef} size={{ xs: 12, md: 7 }}>
          <HeatingSection data={consumptionCertificate} updateHandler={update} unableToProceed={unableToProceed} setUnableToProceed={setUnableToProceed} />
        </Grid>
        <Grid size={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid size={12} sx={{ display: { xs: "block", md: "none" } }}>
          <Typography variant='h4'>{consumptionCertificate.sections[4]}</Typography>
        </Grid>
        <Grid sx={{ display: { xs: "none", md: "block" } }} size={{ md: 5 }} />
        <Grid ref={hotWaterSectionRef} size={{ xs: 12, md: 7 }}>
          <HotWaterSection data={consumptionCertificate} updateHandler={update} unableToProceed={unableToProceed} setUnableToProceed={setUnableToProceed} />
        </Grid>
        <Grid size={{ xs: 12, md: 7 }} offset={{ md: 5 }} mt={10}>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ color: consumptionCertificate.checksData.isDataVerifiedToBestKnowledgeError ? "error.main" : "default" }}
                data-cy='data-correctness-confirmation-checkbox'
                disabled={unableToProceed}
                required
                checked={consumptionCertificate.checksData.isDataVerifiedToBestKnowledge}
                onChange={handleDataVerifiedChange}
              />
            }
            label={
              <Typography color={consumptionCertificate.checksData.isDataVerifiedToBestKnowledgeError ? "error.main" : "default"} display={"inline"}>
                Ich habe meine Eingaben und die Energiewerte überprüft und keine Fehler gefunden.
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={{ color: consumptionCertificate.checksData.acceptedTermsError ? "error.main" : "default" }}
                data-cy='terms-option-consent'
                name='checkbox-consent'
                required
                disabled={unableToProceed}
                checked={consumptionCertificate.checksData.acceptedTerms}
                onChange={handleTermsChange}
              />
            }
            label={<ConsentLabel termsConsentError={consumptionCertificate.checksData.acceptedTermsError} />}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 7 }} offset={{ md: 5 }} mt={4} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <ButtonWithSpinner
            data-cy='submit-consumption-cert-button'
            loading={isLoading || createOrderRunning}
            variant={"contained"}
            label='Verbrauchsausweis bestellen'
            disabled={unableToProceed}
            onClick={(e) => submitRequest(e)}
          />
        </Grid>
        <Grid size={12} mb={10} />
      </Grid>
    </Box>
  );
}
