import { Property } from "../components/property/Property";
import { apiEndpoint } from "../utils/params";
import { useCallback } from "react";
import { useAuthAxios } from "./useAuthAxios";

export default function useCreateProperty() {
  const { authAxios } = useAuthAxios();

  const createProperty = useCallback(
    async (property: Property) =>
      await authAxios({
        method: "POST",
        baseURL: `${apiEndpoint()}/properties`,
        data: { property },
      }),
    [authAxios],
  );

  return [createProperty];
}
