import Grid from "@mui/material/Grid2";
import { SectionProps } from "./ConsumptionCertificateRequestPage";
import NovoMultiSelect from "../../../components/common/NovoMultiSelect";
import { FormControl, FormHelperText, TextField } from "@mui/material";

export default function GeneralSection({ data, unableToProceed, updateHandler }: SectionProps) {
  const ventilationHandler = (value: Array<string>) => {
    data.generalData.ventilationTypes = [...value];
    updateHandler(data);
  };

  const renewablesHandler = (value: Array<string>) => {
    data.generalData.renewableEnergySources = [...value];
    updateHandler(data);
  };

  const renewablesUsageHandler = (value: Array<string>) => {
    data.generalData.renewableEnergyUsage = [...value];
    updateHandler(data);
  };

  const renovationMeasuresHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    data.generalData.renovationMeasures = event.target.value;
    updateHandler(data);
  };

  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <NovoMultiSelect
          data-cy='ventilation-type-select'
          label='Art der Lüftung'
          value={data.generalData.ventilationTypes}
          options={data.generalData.ventilationOptions}
          required={true}
          error={data.generalData.ventilationError}
          helperText={data.generalData.ventilationError ? "Pflichtfeld!" : undefined}
          disabled={unableToProceed}
          onUpdate={ventilationHandler}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <NovoMultiSelect
          data-cy='renewable-energy-select'
          label='Erneuerbare Energien'
          value={data.generalData.renewableEnergySources}
          options={data.generalData.renewableEnergyOptions}
          disabled={unableToProceed}
          onUpdate={renewablesHandler}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <NovoMultiSelect
          data-cy='renewable-energy-usage-select'
          label='Verwendung erneuerbarer Energien'
          value={data.generalData.renewableEnergyUsage}
          options={data.generalData.renewableEnergyUsageOptions}
          disabled={unableToProceed || !data.generalData.renewableEnergySources.length}
          error={data.generalData.renewableEnergyUsageError}
          helperText={
            data.generalData.renewableEnergyUsageError ? "Bei der Wahl einer erneuerbaren Energiequelle muss der Verbrauch angegeben werden" : undefined
          }
          onUpdate={renewablesUsageHandler}
        />
      </Grid>
      <Grid size={12}>
        <FormControl fullWidth>
          <TextField
            variant='outlined'
            id='renovation-measures'
            value={data.generalData.renovationMeasures}
            type='text'
            multiline
            rows={2}
            label='bereits erfolgte Sanierungsmaßnahmen'
            disabled={unableToProceed}
            onChange={renovationMeasuresHandler}
          />
        </FormControl>
        <FormHelperText id='renovation-measures-helper-text'>Fenster gewechselt, Dämmung erhöht, ...</FormHelperText>
      </Grid>
    </Grid>
  );
}
