import { ContactPhone, Mail, VideoCall } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import DoneIcon from "@mui/icons-material/Done";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import ModeCommentRoundedIcon from "@mui/icons-material/ModeCommentRounded";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grow,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Rating,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import Spinner from "../../components/Spinner";
import useSendFeedback from "../../hooks/useSendFeedback";
import { FeedbackReport } from "../../types/cockpit/types";
import { Link } from "react-router-dom";
import { faqNotionEndpoint } from "../../utils/params";
import { AuthContext } from "../../Contexts";

/**
 * Returns a Stack with two support components:
 *  1. Help/contact options
 *  2. Feedback form
 */
export default function SupportActions() {
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const [openFeedback, setOpenFeedback] = useState<boolean>(false);
  const [expandHelp, setExpandHelp] = useState<boolean>(true);
  const [expandFeedback, setExpandFeedback] = useState<boolean>(false);

  const toggleHelp = () => {
    setOpenHelp(!openHelp);
  };

  const enterFeedback = () => {
    setExpandHelp(false);
    setExpandFeedback(true);
  };

  const leaveFeedback = () => {
    setExpandHelp(true);
    setExpandFeedback(false);
  };

  const toggleFeedback = () => {
    setOpenFeedback(!openFeedback);
  };

  return (
    <Stack spacing={2} direction={"row"}>
      <Stack
        spacing={0}
        direction={"row"}
        alignItems={"center"}
        onClick={toggleHelp}
        sx={{
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            width: expandHelp ? "9rem" : 0,
            transition: "width 0.3s ease-in-out",
            overflow: "hidden",
            textWrap: "nowrap",
          }}
        >
          <Typography variant='body2' color='textPrimary' noWrap>
            Brauchst du Hilfe?
          </Typography>
          <Typography color='textPrimary' variant='caption'>
            Kontaktiere uns
          </Typography>
        </Box>
        <Box>
          <Avatar sx={{ bgcolor: "#f2f3f4" }}>
            <ContactSupportIcon style={{ color: "black" }} />
          </Avatar>
        </Box>
      </Stack>
      <Stack
        spacing={0}
        direction={"row"}
        alignItems={"center"}
        onClick={toggleFeedback}
        sx={{
          cursor: "pointer",
        }}
        onMouseLeave={leaveFeedback}
      >
        <Box
          sx={{
            width: expandFeedback ? "9rem" : 0,
            transition: "width 0.3s ease-in-out",
            overflow: "hidden",
            textWrap: "nowrap",
          }}
        >
          <Typography variant='body2' color='textPrimary' noWrap>
            Hast du Feedback?
          </Typography>
          <Typography color='textPrimary' variant='caption'>
            Erzähl uns
          </Typography>
        </Box>
        <Box>
          <Avatar sx={{ bgcolor: "#f2f3f4" }} onMouseEnter={enterFeedback}>
            <ModeCommentRoundedIcon style={{ color: "black" }} />
          </Avatar>
        </Box>
      </Stack>
      <HelpDialog open={openHelp} handleClose={toggleHelp} />
      <FeedbackDialog open={openFeedback} handleClose={toggleFeedback} />
    </Stack>
  );
}

function HelpDialog({ open, handleClose }: { open: boolean; handleClose: () => void }) {
  const { postFeedback, running, responseStatus } = useSendFeedback();
  const { user } = useContext(AuthContext);
  const faqEndpoint = faqNotionEndpoint(user?.tenant?.name);

  const send = () => {
    postFeedback({ callback: true });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Brauchst du Hilfe?
        <Button
          sx={{ float: "inline-end", color: "rgba(0, 0, 0, 0.54)", ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
          onClick={handleClose}
          endIcon={<CloseIcon />}
        >
          Schließen
        </Button>
      </DialogTitle>
      <DialogContent dividers>
        <List>
          {faqEndpoint && (
            <ListItem key='Häufig gestellte Fragen' disablePadding>
              <ListItemButton component={Link} to={faqEndpoint} target='new' onClick={handleClose}>
                <ListItemIcon>
                  <QuestionAnswerIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      Häufig gestellte Fragen{" "}
                      <LaunchIcon
                        sx={{
                          fontSize: "1.2rem",
                          verticalAlign: "middle",
                        }}
                      />
                    </>
                  }
                />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem key='NOVO-Akademie' disablePadding>
            <ListItemButton component={Link} to='/cockpit/academy' onClick={handleClose}>
              <ListItemIcon>
                <SubscriptionsIcon />
              </ListItemIcon>
              <ListItemText primary='NOVO-Akademie' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Call' disablePadding>
            <ListItemButton onClick={send} disabled={running || !!responseStatus}>
              {!running && !responseStatus && (
                <>
                  <ListItemIcon>
                    <ContactPhone />
                  </ListItemIcon>
                  <ListItemText primary='Rückrufbitte' />
                </>
              )}
              {running && (
                <ListItemIcon
                  sx={{
                    justifyContent: "center",
                    flexGrow: 1,
                  }}
                >
                  <Spinner enabled={running} size={20} />
                </ListItemIcon>
              )}
              {responseStatus && Math.floor(responseStatus / 100) === 2 && (
                <>
                  <ListItemIcon>
                    <DoneIcon />
                  </ListItemIcon>
                  <ListItemText primary='Danke, wir rufen dich schnellstmöglich an.' />
                </>
              )}
            </ListItemButton>
          </ListItem>
          <ListItem key='Onboarding' disablePadding>
            <ListItemButton component='a' target='_blank' rel='noopener noreferrer' href='https://calendar.app.google/Nfx7DnxqQck45RS77'>
              <ListItemIcon>
                <VideoCall />
              </ListItemIcon>
              <ListItemText primary='Termin buchen' />
            </ListItemButton>
          </ListItem>
          <ListItem key='Mail' disablePadding>
            <ListItemButton component='a' href='mailto:hi@buildingnovo.com'>
              <ListItemIcon>
                <Mail />
              </ListItemIcon>
              <ListItemText primary='Schicke uns eine Email' />
            </ListItemButton>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='secondary' onClick={handleClose}>
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function FeedbackDialog({ open, handleClose }: { open: boolean; handleClose: () => void }) {
  const [feedback, setFeedback] = useState<FeedbackReport>({ score: 0, followUp: false });
  const { postFeedback, running, responseStatus } = useSendFeedback();

  const handleRatingChange = (event: React.ChangeEvent<HTMLInputElement>, value: number) => {
    setFeedback({ ...feedback, score: value });
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback({ ...feedback, message: event.target.value });
  };

  const handleFollowUpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback({ ...feedback, followUp: event.target.checked });
  };

  const close = () => {
    setFeedback({ score: 0, followUp: false, message: undefined });
    handleClose();
  };

  const send = () => {
    postFeedback(feedback);
  };

  if (responseStatus && Math.floor(responseStatus / 100) === 2) {
    return (
      <Dialog open={open} onClose={close}>
        <DialogTitle sx={{ px: 4, py: 2 }}>
          Feedback
          <Button
            sx={{ float: "inline-end", color: "rgba(0, 0, 0, 0.54)", ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
            onClick={handleClose}
            endIcon={<CloseIcon />}
          >
            Schließen
          </Button>
        </DialogTitle>
        <DialogContent sx={{ px: 4, py: 2 }} dividers>
          <Stack spacing={0}>
            <Grow in={true} timeout={1000}>
              <DialogContentText variant='body2' sx={{ fontSize: "6rem", alignSelf: "center" }}>
                🫶
              </DialogContentText>
            </Grow>
            <DialogContentText variant='body1' sx={{ pb: 2 }}>
              Vielen Dank für deinen Feedback!
              <br />
              Deine Stimme ist uns wichtig.
            </DialogContentText>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle sx={{ px: 4, py: 2 }}>
        Feedback
        <Button
          sx={{ float: "inline-end", color: "rgba(0, 0, 0, 0.54)", ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
          onClick={handleClose}
          endIcon={<CloseIcon />}
        >
          Schließen
        </Button>
      </DialogTitle>
      <DialogContent sx={{ px: 4, py: 2 }} dividers>
        <Stack spacing={4}>
          <Stack spacing={0}>
            <DialogContentText variant='body2' gutterBottom>
              Wie zufrieden bist du mit dem NOVO Cockpit?
            </DialogContentText>
            <Rating size='large' precision={0.5} sx={{ alignSelf: "center" }} onChange={handleRatingChange} />
          </Stack>
          <Stack spacing={0}>
            <DialogContentText variant='body2' gutterBottom>
              Teile deine Gedanken mit uns:
            </DialogContentText>
            <TextField multiline minRows={4} onChange={handleMessageChange} />
            <FormControlLabel control={<Checkbox onChange={handleFollowUpChange} />} label='Ich möchte von NOVO kontaktiert werden' />
          </Stack>
        </Stack>
        <DialogActions sx={{ mt: 2 }}>
          <Button variant='outlined' color='secondary' onClick={handleClose}>
            Abbrechen
          </Button>
          <ButtonWithSpinner variant='contained' color='primary' label='Senden' loading={running} onClick={send} />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
