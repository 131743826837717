import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import { useContext } from "react";
import { AuthContext } from "../../../Contexts";
import { DeploymentEnv } from "../../../types/types";
import { deploymentEnv } from "../../../utils/params";
import { KPIs } from "./Kpis";
import RevenueEstimate from "./RevenueEstimate";
import Shortcuts from "./Shortcuts";
import LeadList from "./leadlist/LeadList";

export default function Leads() {
  const { user } = useContext(AuthContext);

  const consultantReferrerLink = () => {
    if (deploymentEnv() === DeploymentEnv.Prod && !["novo", "coba"].includes(user?.tenant?.name ?? "")) {
      return user?.shortLink;
    }
    return `${window.location.protocol}//${window.location.host}/s/${user?.referrerCode}`;
  };

  return (
    <>
      <Box
        data-joyride='leadlist-page'
        sx={{
          flexGrow: 1,
          ml: "56px",
          width: "calc(100% - 56px)",
        }}
      >
        <Grid
          container
          spacing={2}
          columnSpacing={{
            xs: 0,
            lg: 4,
          }}
          sx={{
            mt: 2,
            maxWidth: "xl",
            mx: { xs: 0, xl: "auto" },
          }}
        >
          <Grid data-joyride='lead-metrics-section' container size={{ xs: 12, lg: 8 }}>
            <Grid size={12}>
              <KPIs />
            </Grid>
            <Grid size={12}>
              <RevenueEstimate />
            </Grid>
          </Grid>
          <Grid container size={{ xs: 12, lg: 4 }}>
            <Grid size={12}>
              <Shortcuts consultantCode={consultantReferrerLink()} />
            </Grid>
          </Grid>
          <Grid data-joyride='lead-list-container' size={12}>
            <LeadList />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
