import { useCallback } from "react";
import { CreateLeadRequest, Lead, ProductRequest } from "../types/cockpit/types";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";

export const API_ENDPOINT = `${apiEndpoint()}/leads`;

export enum LeadErrors {
  // when lead does not to be contacted again for new offers
  NO_FUTHER_OFFERS_DESIRED = "no_further_offers_desired",
  // if another consultant already invited the same lead they should be solely responsible for them
  LEAD_ALREADY_EXISTS_FOR_TENANT = "lead_exists_for_tenant",
  ALREADY_INVITED = "lead_already_invited_for_product",
}

export const leadErrorCodeToMessage = (err?: LeadErrors) => {
  switch (err) {
    case LeadErrors.ALREADY_INVITED:
      return "Kunde wurde bereits für dieses Produkt eingeladen.";
    case LeadErrors.LEAD_ALREADY_EXISTS_FOR_TENANT:
      return "Kunde wurde bereits von einem deiner Kollegen eingeladen.";
    case LeadErrors.NO_FUTHER_OFFERS_DESIRED:
      return "Kunde wünscht, nicht mehr über neue Angebote kontaktiert zu werden.";
    default:
      return "Es ist leider ein Fehler aufgetreten. Bitten kontaktiere uns.";
  }
};

export function useCreateLead() {
  const { authAxios, error, running, data } = useAuthAxios<Lead>();

  const createLead = useCallback(
    async (lead: CreateLeadRequest) =>
      await authAxios({
        method: "POST",
        baseURL: `${API_ENDPOINT}`,
        data: JSON.stringify(lead),
      }),
    [authAxios],
  );

  return { createLead, error, isLoading: running, lead: data };
}

export function useInviteLeadProduct() {
  const { authAxios, error, running, data } = useAuthAxios<{ inviteLink: string }>();

  const inviteLeadToProduct = useCallback(
    async (leadId: string, productRequest: ProductRequest) =>
      await authAxios({
        method: "POST",
        baseURL: `${API_ENDPOINT}/${leadId}/invitation`,
        data: productRequest,
      }),
    [authAxios],
  );

  return { inviteLeadToProduct, error, isLoading: running, inviteResponse: data };
}
