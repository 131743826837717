import { Alert, Box, Link, Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, MouseEventHandler, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ActionContainer from "../../components/ActionContainer";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import useConfirmAccount from "../../hooks/useConfirmAccount";
import { useResendConfirmationCode } from "../../hooks/useResendConfirmationCode";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { AuthError } from "../../types/Auth";
import { authErrorTypeToErrorText } from "../../utils/auth";
import { AuthContext } from "../../Contexts";

export default function ConfirmAccount() {
  const { clearActionNeeded } = useContext(AuthContext);
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const username = params.get("username") || "";
  if (!username) {
    navigate("/signup");
  }

  const { error: resendCodeError, isLoading: isResendCodeLoading, resendConfirmationCode, resendSuccess } = useResendConfirmationCode();
  const { confirmSuccess, error: confirmAccountError, isLoading, confirmAccount } = useConfirmAccount();
  const [error, setError] = useState<AuthError | undefined>(undefined);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(false);

  useEffect(() => {
    setError(resendCodeError);
  }, [resendCodeError]);

  useEffect(() => {
    setError(confirmAccountError);
  }, [confirmAccountError]);

  const codeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
    setCodeError(false);
  };

  const navigateToLogin = () => {
    if (confirmSuccess) {
      // if this code confirmation dialog was started from the signin page, action needed needs to be cleared
      // otherwise it will return to this page
      clearActionNeeded();
      navigate(`/signin?username=${encodeURIComponent(confirmSuccess.username)}`);
    }
  };

  const confirmCustomerAccount = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (!code) {
      setCodeError(true);
      return;
    }
    confirmAccount(username, code);
  };

  if (confirmSuccess) {
    return (
      <ActionContainer minWidth={600} minHeight={300} sticker={{ image: "thumbs-up.svg", t: -20, r: -14, s: 70 }}>
        <Typography variant='h5' fontWeight={600} data-cy='confirm-code-message'>
          Dein Account ist nun Aktiv
        </Typography>
        <Box paddingTop={16} display={"flex"} flexDirection={"row-reverse"}>
          <ButtonWithSpinner
            sx={{ minWidth: 200 }}
            data-cy='sign-in-btn'
            loading={isLoading}
            onClick={navigateToLogin}
            label='Weiter zum Login'
            size='large'
            variant={"contained"}
            disabled={isLoading}
          />
        </Box>
      </ActionContainer>
    );
  }

  function resendCode(): MouseEventHandler<HTMLAnchorElement> | undefined {
    if (isResendCodeLoading) {
      return;
    }
    resendConfirmationCode(username);
    return;
  }

  return (
    <ActionContainer sticker={{ image: "auth_window_icon.svg", t: -40, r: -14, s: 70 }}>
      <Typography variant='h4' fontWeight={600}>
        Konto-Bestätigung
      </Typography>
      <Typography>Wir haben dir einen Bestätigungscode an deine E-Mail geschickt.</Typography>
      <Typography textAlign={"end"} variant='caption'>
        <WarningAmberIcon sx={{ verticalAlign: "text-bottom", fontSize: "20px" }} /> Schau’ bitte auch im Spam / Junk Mail-Ordner nach.
      </Typography>
      <Stack rowGap={4} paddingTop={4}>
        <TextField
          label='Bestätigungscode'
          variant='outlined'
          data-cy='code-input'
          id='code-input'
          value={code}
          type='string'
          helperText={codeError ? "Bitte gib deinen Bestätigungscode ein" : undefined}
          error={codeError}
          onChange={codeHandler}
          required={true}
        />
        {!resendSuccess && !isResendCodeLoading && (
          <Typography marginLeft={"auto"}>
            Du hast keinen Code bekommen?{" "}
            <Link onClick={resendCode} style={{ fontWeight: 600, color: "#000", textDecorationColor: "none", cursor: "pointer" }} data-cy='novo-sign-up-lnk'>
              Bestätigungscode neu anfordern
            </Link>
          </Typography>
        )}
        {isResendCodeLoading && <Typography marginLeft={"auto"}>Bestätigungscode wird geschickt...</Typography>}
        {resendSuccess && <Typography marginLeft={"auto"}>Ein neuer Bestätigungscode wurde an deine E-Mail geschickt.</Typography>}
        <Box paddingTop={2} display={"flex"} flexDirection={"row-reverse"}>
          <ButtonWithSpinner
            sx={{ minWidth: 200 }}
            data-cy='confirm-btn'
            loading={isLoading}
            onClick={confirmCustomerAccount}
            label='Bestätigen'
            size='large'
            variant={"contained"}
            disabled={isLoading}
          />
        </Box>
        {error && (
          <Alert data-cy='confirm-code-error-alert' sx={{ marginTop: 2 }} severity='error'>
            {authErrorTypeToErrorText(error.type)}
          </Alert>
        )}
      </Stack>
    </ActionContainer>
  );
}
