import { Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import ActionContainer from "../../../components/ActionContainer";

export const ThankYouAfterContact = () => {
  const [searchParams] = useSearchParams();
  const leadName = searchParams.get("name");

  return (
    <ActionContainer maxWidth={800} sticker={{ image: "hot-air-balloon.svg", t: -40, r: 0, s: 80 }}>
      <Typography paddingBottom={8} variant='h4' data-cy='contact-thank-you-title'>
        Vielen Dank{leadName ? `, ${leadName}` : ""}
      </Typography>
      <Typography paddingBottom={4}>Wir werden uns schnellstmöglich mit Ihnen in Verbindung setzen, um eine Lösung für Ihr Anliegen zu finden.</Typography>
      <Typography>Sie können jetzt diese Seite verlassen.</Typography>
    </ActionContainer>
  );
};
