import { FunctionComponent, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { ReportStatusItem } from "./ReportStatusItem";
import { ANALYSIS } from "./storage";
import { StatusColor, AnalysisState, ReportStatusEntity } from "../../types/Schnellcheck";

export const IconsTable: FunctionComponent = () => {
  const [status, setStatus] = useState({
    heating: StatusColor.red,
    windows: StatusColor.red,
    roof: StatusColor.red,
    envelope: StatusColor.red,
    basement: StatusColor.red,
    doors: StatusColor.red,
  });

  useEffect(() => {
    const answer = localStorage.getItem(ANALYSIS);
    if (answer) {
      const parsedAnswer: AnalysisState = JSON.parse(answer);
      setStatus(parsedAnswer.report.status);
    }
  }, []);

  return (
    <>
      <Grid container spacing={0.5}>
        <Grid item xs={6} sm={4}>
          <ReportStatusItem item={ReportStatusEntity.Heating} color={status.heating} data-cy='heating-logo' />
        </Grid>
        <Grid item xs={6} sm={4}>
          <ReportStatusItem item={ReportStatusEntity.Windows} color={status.windows} data-cy='windows-logo' />
        </Grid>
        <Grid item xs={6} sm={4}>
          <ReportStatusItem item={ReportStatusEntity.Roof} color={status.roof} data-cy='roof-logo' />
        </Grid>
        <Grid item xs={6} sm={4}>
          <ReportStatusItem item={ReportStatusEntity.Envelope} color={status.envelope} data-cy='envelope-logo' />
        </Grid>
        <Grid item xs={6} sm={4}>
          <ReportStatusItem item={ReportStatusEntity.Basement} color={status.basement} data-cy='basement-logo' />
        </Grid>
        <Grid item xs={6} sm={4}>
          <ReportStatusItem item={ReportStatusEntity.Doors} color={status.doors} data-cy='doors-logo' />
        </Grid>
      </Grid>
    </>
  );
};
