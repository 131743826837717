import { FunctionComponent } from "react";
import { Avatar, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

type StatusItemProps = {
  label: string;
  logo: string;
  color?: string;
};

export const StatusItem: FunctionComponent<StatusItemProps> = ({ label, logo, color = "transparent" }) => {
  return (
    <>
      <Stack spacing={0} alignItems='center'>
        <Avatar sx={{ bgcolor: color, borderRadius: 4, height: 79, width: 79 }} variant='rounded'>
          <img src={logo} className='Status-icons' alt={label} width='79' height='79' />
        </Avatar>
        <Typography variant='subtitle1' align='center' style={{ fontSize: 16 }}>
          {label}
        </Typography>
      </Stack>
    </>
  );
};
