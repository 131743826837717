import Box from "@mui/material/Box";
import Topbar from "../../components/foerder-welt/Topbar";
import Content from "../../components/foerder-welt/Content";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import Footer from "../../components/foerder-welt/Footer";
import IframeResizer from "iframe-resizer-react";
import novo from "../../themes/novo";
import { embeddablePage } from "../../utils/params";

const theme = createTheme(novo, {
  palette: {
    primary: {
      main: "#ed702d",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#e18931",
      secondary: "#707172",
    },
  },
  typography: {
    // eslint-disable-next-line quotes
    fontFamily: `"Segoe UI", "Kohinoor Bangla", Arial`,
  },
});

export default function EmbeddedDemo() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Topbar />
      <Content />
      <Box paddingTop={"5vh"} justifyContent='center' alignItems='start' sx={{ display: "flex", flexDirection: "row" }}>
        <Box width='70%' sx={{ display: "flex", flexDirection: "row" }}>
          <IframeResizer
            heightCalculationMethod='max'
            id='NovoIframe'
            src={embeddablePage()}
            style={{ border: "none", padding: 0, width: "1px", minWidth: "100%" }}
            data-cy='novo-iframe'
          />
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}
