import { Typography, Stack, Box, TextField, Alert } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import { authErrorTypeToErrorText } from "../../utils/auth";
import ActionContainer from "../../components/ActionContainer";
import { isValidEmail } from "./emailValidator";
import { useForgotPasswordCode } from "../../hooks/useForgotPasswordCode";

type ForgotPasswordState = {
  username: string;
  usernameError: boolean;
};

export default function ForgotPassword() {
  const { isLoading, error, sendCodeSuccess, sendForgotPasswordCode } = useForgotPasswordCode();
  const [params] = useSearchParams();
  const [formState, setFormState] = useState<ForgotPasswordState>({
    username: params.get("username")?.toLowerCase() ?? "",
    usernameError: false,
  });
  const navigate = useNavigate();

  function usernameChangeHandler(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    setFormState({ ...formState, username: event.target.value.toLowerCase(), usernameError: false });
  }

  function onSubmitClick(): void {
    if (!isValidEmail(formState.username)) {
      setFormState({ ...formState, usernameError: true });
      return;
    }
    sendForgotPasswordCode(formState.username);
  }

  useEffect(() => {
    if (sendCodeSuccess?.username) {
      navigate(`/forgot-password-change?username=${encodeURIComponent(sendCodeSuccess.username)}`);
    }
  }, [navigate, sendCodeSuccess?.username]);

  return (
    <ActionContainer sticker={{ image: "hot-air-balloon.svg", t: -40, r: 0, s: 80 }}>
      <Typography variant='h4' fontWeight={600}>
        Passwort vergessen?
      </Typography>
      <Typography paddingTop={4}>Gib einfach deine E-Mail-Adresse ein.</Typography>
      <Typography>Wir werden in Kürze einen Zurücksetzungscode an deine E-Mail schicken.</Typography>
      <Stack paddingTop={4}>
        <Box display={"flex"}>
          <TextField
            fullWidth
            label='E-mail'
            variant='outlined'
            data-cy='email-input'
            id='email-input'
            value={formState.username}
            type='text'
            helperText={formState.usernameError ? "Bitte gib eine gültige E-Mail-Adresse ein" : undefined}
            error={!!formState.usernameError}
            onChange={usernameChangeHandler}
            required={true}
          />
        </Box>

        <Typography pt={4}>
          Du hast noch gar kein NOVO-Beraterkonto?{" "}
          <Link to='/signup' style={{ fontWeight: 600, color: "#000", textDecorationColor: "none" }}>
            Dann klicke hier
          </Link>
        </Typography>
        <Box paddingTop={4} display={"flex"} flexDirection={"row-reverse"}>
          <ButtonWithSpinner
            sx={{ minWidth: 200 }}
            data-cy='send-code-btn'
            loading={isLoading}
            onClick={onSubmitClick}
            label='Code schicken'
            size='large'
            variant={"contained"}
            disabled={isLoading}
          />
        </Box>
        {error && (
          <Alert data-cy='change-error-alert' sx={{ marginTop: 4 }} severity='error'>
            {authErrorTypeToErrorText(error.type)}
          </Alert>
        )}
      </Stack>
    </ActionContainer>
  );
}
