import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export interface SpinnerProps {
  enabled: boolean;
  size: number;
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | "inherit";
  sx?: SxProps;
}

export default function Spinner({ enabled, size, sx, color }: SpinnerProps) {
  if (enabled) {
    return (
      <Box sx={{ display: "flex", ...sx }}>
        <CircularProgress color={color} size={size} />
      </Box>
    );
  }
  return <></>;
}
