import { createTheme } from "@mui/material/styles";
import { Themes } from "../types/types";
import novo from "./novo";

const psd = createTheme(novo, {
  name: Themes.PSD,
  logo: "PSD_by_NOVO.png",
  logoClass: "App-logo-psd",
  palette: {
    mode: "light",
    primary: {
      main: "#029967",
      light: "#03c987",
      dark: "#026443",
      contrastText: "#fff",
    },
    secondary: {
      main: "#990234",
    },
  },
});

export default psd;
