import { Stack, Typography } from "@mui/material";

export type PasswordsMatchTooltipProps = {
  passwordHasValue: boolean;
  passwordsMatch: boolean;
};

export const PasswordsMatchTooltip: React.FC<PasswordsMatchTooltipProps> = ({ passwordHasValue, passwordsMatch }: PasswordsMatchTooltipProps) => {
  return (
    <Stack direction='row' justifyContent='left' columnGap={2}>
      <Typography variant='caption' sx={{ color: !passwordHasValue ? "#ccc" : passwordsMatch ? "success.light" : "error.light", fontSize: 12 }}>
        ● Passwörter stimmen überein
      </Typography>
    </Stack>
  );
};

export default PasswordsMatchTooltip;
