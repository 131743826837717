import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Box, SxProps, Theme, Typography } from "@mui/material";

export interface ShowPasswordToggleProps {
  isShowing: boolean;
  onClick: () => void;
  sx?: SxProps<Theme>;
}

export const ShowPasswordToggle: React.FC<ShowPasswordToggleProps> = ({ isShowing: isVisible, onClick, sx }: ShowPasswordToggleProps) => {
  return (
    <Box display={"flex"} alignItems={"center"} flexDirection={"row-reverse"} sx={{ ...sx, ...{ cursor: "pointer" } }} onClick={onClick}>
      <Typography paddingLeft={1} variant='caption'>
        Passwort {isVisible ? "ausblenden" : "anzeigen"}
      </Typography>
      {isVisible ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
    </Box>
  );
};
