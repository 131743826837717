import axios from "axios";
import { UpsertQuestionnaireRequest } from "../types/RenovationQuestionnaire";
import { apiEndpoint } from "../utils/params";
import { useUnauthAxios } from "./useUnauthAxios";
import { useCallback } from "react";
import { v4 } from "uuid";
import { FileRef } from "../components/common/NovoFileUpload";

const API_ENDPOINT = `${apiEndpoint()}/renovation-questionnaires`;
const headers = { "Content-Type": "application/json" };

export default function useUpsertRenovations() {
  const { unauthAxios, error, running, data } = useUnauthAxios<UpsertQuestionnaireRequest>();

  const upsertRenovations = useCallback(
    async (request: UpsertQuestionnaireRequest) => {
      await unauthAxios({
        method: "POST",
        url: API_ENDPOINT,
        data: request,
        headers,
      });
    },
    [unauthAxios],
  );

  const uploadFile = async ({ id, name, file }: FileRef) => {
    if (!file) return;
    let fileId = id;
    if (!fileId) {
      if (!name) return;
      const uuid = v4();
      const [fileName, extension] = name.split(".");
      fileId = `${fileName}-${uuid.slice(0, 7)}.${extension}`;
    }
    const requestUri = `${API_ENDPOINT}/file-upload-link?fileId=${fileId}`;
    const response = await axios.get(requestUri, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const presignedLink = response.data.presignedLink;
    await axios.put(presignedLink, file, {
      headers: {
        "Content-Type": file.type,
        "Access-Control-Allow-Origin": "*",
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        responseType: "json",
      },
    });
    return fileId;
  };

  return { upsertRenovations, uploadFile, upsertRenovationsError: error, upsertRenovationsRunning: running, upsertRenovationsResponse: data };
}
