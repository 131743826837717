import { Grid, Stack, Typography } from "@mui/material";

export default function Content() {
  return (
    <Grid container justifyContent='center' paddingTop={"5vh"} paddingLeft={"10vw"} paddingRight={"10vw"}>
      <Stack spacing={"1vh"}>
        <Typography style={{ fontSize: "calc(8px + 2vw)", fontWeight: 400 }}>Energieberatung online</Typography>
        <Typography style={{ fontSize: "calc(8px + 3vw)", fontWeight: 600 }}>Energetische Sanierung starten ohne langes Warten</Typography>
        <Typography style={{ fontSize: "calc(8px + 1vw)", fontWeight: 400 }} color='#707172'>
          Mit der zertifizierten Energieberatung von NOVO bekommen Sie Zugang zu Fördermitteln. Erster Schritt: ein kostenloser Schnell-Check, der Ihnen einen
          Überblick über den energetischen Stand Ihres Hauses gibt. Auf Wunsch erstellt Ihnen NOVO anschließend einen individuellen Sanierungsfahrplan - und das
          alles ohne lange Wartezeiten!
        </Typography>
      </Stack>
    </Grid>
  );
}
