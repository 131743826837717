import CloseIcon from "@mui/icons-material/Close";
import { Alert, Button, DialogActions, Grid2, Link, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ChangeEvent, useEffect, useState } from "react";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import { useSubmitConsCertContactReq } from "../../../hooks/useSubmitConsCertContactReq";
import { isValidEmail } from "../../auth/emailValidator";
import { phoneNumberIsValid } from "../../auth/phoneNumberValidator";
import { ConsumptionCertContactRequest } from "./types";
import { useNavigate } from "react-router-dom";

interface ContactFormDialogProps {
  open: boolean;
  handleClose: () => void;
  leadId?: string;
  bubbleLeadId?: string;
  title: string;
  // pre-fill dialog with a template message
  initialMessage?: string;
}

type ContactFormState = Partial<ConsumptionCertContactRequest>;

type ContactFormValidators = {
  // if function returns a string, it means there is an error. undefined if valid
  [key in keyof ContactFormState]: (val: string | undefined) => string | undefined;
};

export default function ContactFormDialog({ open, handleClose, initialMessage, leadId, bubbleLeadId, title }: ContactFormDialogProps) {
  const [formState, setFormState] = useState<ContactFormState>({ message: initialMessage });
  const [formErrorState, setFormErrorState] = useState<ContactFormState>({});
  const navigate = useNavigate();

  const { submitConsCertContactReq, running: isSubmitRunning, error: submitError, data: submitDataResponse } = useSubmitConsCertContactReq();

  const contactFormValidators: ContactFormValidators = {
    firstName: (name) => (!name ? "Bitte geben Sie Ihren Namen ein" : undefined),
    lastName: (surname) => (!surname ? "Bitte geben Sie Ihren Nachnamen ein" : undefined),
    email: (mail) => (!isValidEmail(mail || "") ? "Bitte geben Sie Ihre valide E-Mail-Addresse ein" : undefined),
    telephone: (phone) =>
      !phoneNumberIsValid(phone ?? "")
        ? "Bitte geben Sie Ihre Telefonnummer (inklusive Ländervorwahl) ein, damit wir schnellstmöglich Kontakt mit Ihnen aufnehmen können"
        : undefined,
    message: (msg) => (!msg ? "Bitte geben Sie Ihr Anliegen ein" : undefined),
  };

  const updateFieldValue = (field: keyof ContactFormState) => (event: ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [field]: event.target.value,
    });
    setFormErrorState({
      ...formErrorState,
      [field]: undefined,
    });
  };

  const isFormValid = (): boolean => {
    const validatorKeys = Object.keys(contactFormValidators) as (keyof ContactFormState)[];
    let errors: ContactFormState = {};
    let valid = true;
    validatorKeys.forEach((key) => {
      const validationErr = contactFormValidators[key]?.(formState[key]);
      if (validationErr) {
        valid = false;
      }
      errors = { ...errors, [key]: validationErr };
    });
    setFormErrorState(errors);
    return valid;
  };

  useEffect(() => {
    if (!submitDataResponse) {
      return;
    }
    navigate(`/consumption-certificate/contact-thanks?name=${encodeURIComponent(`${formState.firstName} ${formState.lastName}`)}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitDataResponse]);

  const onSubmitContact = () => {
    if (isSubmitRunning) {
      return;
    }
    if (!isFormValid()) {
      return;
    }
    submitConsCertContactReq({ ...formState, leadId, bubbleLeadId } as ConsumptionCertContactRequest);
  };

  const onGoingToClose = () => {
    if (isSubmitRunning) {
      return;
    }
    handleClose();
  };

  const consentLabel = () => {
    return (
      <Typography fontSize={11}>
        Ich akzeptiere die{" "}
        <Link target='_blank' href='https://app.novo.eco/AGB_NOVO_Terms_of_service_DE.pdf' color={"text.primary"}>
          AGB
        </Link>{" "}
        und{" "}
        <Link target='_blank' href='https://novo.eco/data_protection' color={"text.primary"}>
          Datenschutzbestimmungen.
        </Link>
      </Typography>
    );
  };

  return (
    <Dialog open={open} onClose={onGoingToClose} maxWidth={"xl"}>
      <DialogTitle>
        {title}
        <Button
          sx={{ float: "inline-end", color: "rgba(0, 0, 0, 0.54)", ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
          onClick={handleClose}
          endIcon={<CloseIcon />}
        >
          Schließen
        </Button>
      </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          <Grid2 container pt={1} spacing={2}>
            <Grid2 size={{ xs: 12, lg: 6 }}>
              <TextField
                fullWidth
                variant='outlined'
                id='first-name-input'
                data-cy='first-name-input'
                value={formState.firstName}
                type='text'
                label='Vorname'
                onChange={updateFieldValue("firstName")}
                required={true}
                error={!!formErrorState.firstName}
                helperText={formErrorState.firstName}
                slotProps={{
                  inputLabel: { shrink: !!formState.firstName },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, lg: 6 }}>
              <TextField
                fullWidth
                variant='outlined'
                id='last-name-input'
                data-cy='last-name-input'
                value={formState.lastName}
                type='text'
                label='Nachname'
                onChange={updateFieldValue("lastName")}
                required={true}
                error={!!formErrorState.lastName}
                helperText={formErrorState.lastName}
                slotProps={{
                  inputLabel: { shrink: !!formState.lastName },
                }}
              />
            </Grid2>
          </Grid2>

          <FormControl>
            <TextField
              variant='outlined'
              id='telephone-input'
              data-cy='telephone-input'
              value={formState.telephone}
              type='tel'
              inputMode='tel'
              label='Handynummer'
              onChange={updateFieldValue("telephone")}
              required={true}
              error={!!formErrorState.telephone}
              helperText={formErrorState.telephone}
              slotProps={{
                inputLabel: { shrink: !!formState.telephone },
              }}
            />
          </FormControl>
          <FormControl>
            <TextField
              variant='outlined'
              id='email-input'
              data-cy='email-input'
              value={formState.email}
              type='email'
              inputMode='email'
              label='Email Adresse'
              onChange={updateFieldValue("email")}
              required={true}
              error={!!formErrorState.email}
              helperText={formErrorState.email}
              slotProps={{
                inputLabel: { shrink: !!formState.email },
              }}
            />
          </FormControl>
          <FormControl>
            <TextField
              variant='outlined'
              id='message-input'
              data-cy='message-input'
              multiline
              value={formState.message}
              type='text'
              inputMode='text'
              label='Anliegen'
              onChange={updateFieldValue("message")}
              required={true}
              error={!!formErrorState.message}
              helperText={formErrorState.message}
              slotProps={{
                inputLabel: { shrink: !!formState.message },
              }}
            />
          </FormControl>
          <FormControlLabel control={<Checkbox data-cy='terms-option' name='checkbox-consent' color='secondary' checked />} label={consentLabel()} />
          {submitError && (
            <Alert severity='error'>
              Leider ist ein Fehler bei der Kontaktaufnahme aufgetreten. Bitte versuchen Sie es später erneut oder schicken Sie uns eine E-Mail an
              hi@buildingnovo.com.
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='secondary' onClick={handleClose}>
          Abbrechen
        </Button>
        <ButtonWithSpinner variant='contained' data-cy='submit-contact-request' loading={isSubmitRunning} onClick={onSubmitContact} label={"Schicken"} />
      </DialogActions>
    </Dialog>
  );
}
