import { useCallback } from "react";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";

export const API_ENDPOINT = `${apiEndpoint()}/isfps`;

export const useFetchIsfpDocumentUrl = () => {
  const { authAxios, error: fetchDocumentError, running: fetchDocumentRunning, data: isfpDocumentLink } = useAuthAxios<{ url: string }>();

  const fetchIsfpDocumentUrl = useCallback(
    async (isfpProcessId: string) =>
      await authAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}/${isfpProcessId}/document`,
      }),
    [authAxios],
  );

  return { fetchIsfpDocumentUrl, fetchDocumentError, fetchDocumentRunning, isfpDocumentLink };
};
