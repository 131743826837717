import { useCallback } from "react";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";

export const useDownloadIsfpDocumentsZip = () => {
  const {
    authAxios,
    error: downloadIsfpDocumentsZipError,
    running: downloadIsfpDocumentsZipRunning,
    data: downloadIsfpDocumentsZipLink,
  } = useAuthAxios<{ url: string }>();

  const downloadIsfpDocumentsZip = useCallback(
    async (isfpProcessId: string) =>
      await authAxios({
        method: "GET",
        baseURL: `${apiEndpoint()}/isfps/${isfpProcessId}/answers`,
      }),
    [authAxios],
  );

  return { downloadIsfpDocumentsZip, downloadIsfpDocumentsZipError, downloadIsfpDocumentsZipRunning, downloadIsfpDocumentsZipLink };
};
