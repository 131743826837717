import { Typography } from "@mui/material";
import { RenovationsScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { ReverseWallMaterials, WallMaterials } from "./utils";
import NovoSelect from "../../../../components/common/NovoSelect";
import FormScreen from "../FormScreen";
import InsulationComponent from "./InsulationComponent";
import { useState } from "react";
import { ExteriorWalls, Insulation } from "../../../../types/RenovationQuestionnaire";

export default function OuterWallsScreen({ renovations, setRenovations, isUpdateRunning, prev, next }: RenovationsScreenProps) {
  const [wallsMaterialError, setWallsMaterialError] = useState<string>();
  const [insulationSelectedError, setInsulationSelectedError] = useState<string>();
  const [insulationYearError, setInsulationYearError] = useState<string>();
  const [exteriorWalls, setExteriorWalls] = useState<ExteriorWalls>(renovations.answers?.exteriorWalls || {});

  const minYear = renovations.constructionYear ?? 1900;
  const maxYear = new Date().getFullYear();

  const validate = () => {
    if (!exteriorWalls.wallsMaterial) {
      setWallsMaterialError("Bitte auswählen");
      return false;
    } else {
      setWallsMaterialError(undefined);
    }
    if (exteriorWalls.insulation?.installed === undefined) {
      setInsulationSelectedError("Bitte auswählen");
      return false;
    } else {
      setInsulationSelectedError(undefined);
    }
    if (exteriorWalls.insulation?.installed) {
      if (!exteriorWalls.insulation.year) {
        setInsulationYearError("Pflichtfeld");
        return false;
      } else if (exteriorWalls.insulation.year < minYear || exteriorWalls.insulation.year > maxYear) {
        setInsulationYearError(`Muss zwischen ${minYear} und ${maxYear} liegen`);
        return false;
      } else {
        setInsulationYearError(undefined);
      }
    }
    return true;
  };
  const validateAndSave = () => {
    if (validate()) {
      const newState = { ...renovations, answers: { ...renovations.answers, exteriorWalls } };
      setRenovations(newState);
      next(newState);
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            <strong>Schritt 3: </strong>Informationen zu den Außenwänden
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <NovoSelect
            label={"Aufbau Außenwand"}
            options={[...Object.values(WallMaterials)]}
            value={exteriorWalls.wallsMaterial ? WallMaterials[exteriorWalls.wallsMaterial as keyof typeof WallMaterials] : ""}
            onUpdate={(value) => {
              const wallsMaterial = ReverseWallMaterials[value as keyof typeof ReverseWallMaterials];
              setExteriorWalls({ ...exteriorWalls, wallsMaterial });
              setWallsMaterialError(undefined);
            }}
            error={!!wallsMaterialError}
            helperText={wallsMaterialError}
            required
          />
        </Grid>
        <Grid size={12}>
          <InsulationComponent
            question='Sind die rot markierten Wände gedämmt?'
            picture='House_sections_outer_walls.png'
            insulation={exteriorWalls.insulation}
            setInsulation={(insulation: Insulation) => setExteriorWalls({ ...exteriorWalls, insulation })}
            selectedError={insulationSelectedError}
            setSelectedError={setInsulationSelectedError}
            minYear={minYear}
            maxYear={maxYear}
            yearError={insulationYearError}
            setYearError={setInsulationYearError}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <FormScreen
      prev={prev}
      next={validateAndSave}
      isUpdateRunning={isUpdateRunning}
      progressLabel='Außenwände'
      progressValue={25}
      content={content}
      helpContent={helpContent}
    />
  );
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Die Dicke und das Material einer
      <br />
      möglichen Dämmung wirken sich auf
      <br />
      die individuelle Energiebilanz aus.
      <br />
      <br />
      Um den Ist-Zustand Ihrer Immobilie
      <br />
      bestmöglich zu berechnen,
      <br />
      benötigen wir diese Informationen.
    </Typography>
  );
}
