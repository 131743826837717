import { FunctionComponent } from "react";
import { PageViewMetric } from "./types";
import { ConsultantMetricsEventCounts } from "../../../types/ConsultantMetrics";
import { classifyMetrics } from "./utils";
import PageViews from "./PageViews";
import TotalMetrics from "./TotalMetrics";

type MetricsTableProps = {
  pageViews: PageViewMetric[];
  displayZeros?: boolean;
  totals: ConsultantMetricsEventCounts;
};

const GeneralMetrics: FunctionComponent<MetricsTableProps> = ({ pageViews, displayZeros, totals }) => {
  const accordions = [];
  const classifiedMetrics = classifyMetrics(totals);

  accordions.push(<PageViews pageViews={pageViews} displayZeros={displayZeros} />);
  accordions.push(<TotalMetrics title={"Schnellcheck / ISFP"} metrics={classifiedMetrics.isfpMetrics} displayZeros={displayZeros} />);
  accordions.push(<TotalMetrics title={"Consumption Certificate"} metrics={classifiedMetrics.consCertMetrics} displayZeros={displayZeros} />);
  accordions.push(<TotalMetrics title={"One Pager"} metrics={classifiedMetrics.onePagerMetrics} displayZeros={displayZeros} />);
  accordions.push(<TotalMetrics title={"New Leads Created"} metrics={classifiedMetrics.newLeadCreated} displayZeros={displayZeros} />);

  return <>{accordions}</>;
};

export default GeneralMetrics;
