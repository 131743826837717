import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useEffect } from "react";
import Smartlook from "smartlook-client";
import { Paper, Stack } from "@mui/material";
import usePutMetric from "../../../hooks/usePutMetric";

export const CONSENT = "CONSENT";

export default function ConsentSnackbar({ smartlookId, metricName }: { smartlookId: string; metricName: string }) {
  const [open, setOpen] = React.useState(false);
  const [putMetric] = usePutMetric();

  const handleNoConsent = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    localStorage.setItem(CONSENT, "false");
    setOpen(false);
  };

  const handleConsentGiven = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    localStorage.setItem(CONSENT, "true");
    setOpen(false);
    hasGivenConsent();
    putMetric(metricName, "ConsentGiven");
  };

  const hasGivenConsent = () => {
    Smartlook.init(smartlookId, { region: "eu" });
    Smartlook.record({ forms: true, numbers: true, emails: true, ips: true, api: true });
  };

  useEffect(() => {
    // Local Storage
    const consentString = localStorage.getItem(CONSENT);
    if (consentString) {
      setOpen(false);
      if (JSON.parse(consentString)) {
        hasGivenConsent();
      }
    } else {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <Snackbar open={open} anchorOrigin={{ vertical: "top", horizontal: "center" }} style={{ backgroundColor: "#d2d4d6" }}>
          <Paper style={{ padding: 15 }}>
            <Stack spacing={2}>
              <Typography style={{ fontSize: "11" }} color='#7d7c79'>
                Cookie- und Tracking-Zustimmung: Wir respektieren Ihre Privatsphäre. Um Ihre Erfahrung auf unserer Website zu verbessern, möchten wir Cookies
                und Tracking-Technologien verwenden. Diese helfen uns dabei, die Leistung der Website zu optimieren, Ihnen relevante Inhalte anzuzeigen und Ihr
                Erlebnis zu personalisieren. Ihre Privatsphäre ist uns wichtig. Bitte wählen Sie, wie Sie mit Cookies und Tracking umgehen möchten.{" "}
                <Link target='_blank' href='https://novo.eco/data_protection' color={"text.primary"}>
                  Datenschutzerklärung
                </Link>
              </Typography>
              <Stack direction={"row"} spacing={2}>
                <Button variant='contained' size='small' onClick={handleConsentGiven} data-cy='accept-cookies-btn'>
                  Alle akzeptieren
                </Button>
                <Button variant='text' color='secondary' size='small' onClick={handleNoConsent}>
                  Ablehnen
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Snackbar>
      </Backdrop>
    </>
  );
}
