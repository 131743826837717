import { FunctionComponent } from "react";
import heating from "../../imgs/heating.png";
import windows from "../../imgs/windows.png";
import roof from "../../imgs/roof.png";
import envelope from "../../imgs/envelope.png";
import basement from "../../imgs/basement.png";
import doors from "../../imgs/doors.png";
import Grid from "@mui/material/Grid";
import { StatusItem } from "./StatusItem";
import { Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";

type StatusProps = {
  title: string;
  heating: number;
  windows: number;
  roof: number;
  envelope: number;
  basement: number;
  doors: number;
};

const convertLevelToColor = (level: number) => {
  switch (level) {
    case 0:
      return "#22fe16";
    case 1:
      return "#8eff04";
    case 2:
      return "#bbff00";
    case 3:
      return "#e0fe00";
    case 4:
      return "#fefe02";
    case 5:
      return "#fef200";
    case 6:
      return "#fcda00";
    case 7:
      return "#fe7b00";
    case 8:
      return "#fc3700";
    default:
      return "#ffffff";
  }
};

export const Status: FunctionComponent<StatusProps> = (props) => {
  const intl = useIntl();

  return (
    <>
      <Stack spacing={2} style={{ maxWidth: 370 }}>
        <Typography variant='h4' align='center' style={{ fontWeight: 600 }}>
          {props.title}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <StatusItem logo={heating} label={intl.formatMessage({ id: "app.status.heating" })} color={convertLevelToColor(props.heating)} />
          </Grid>
          <Grid item xs={4}>
            <StatusItem logo={windows} label={intl.formatMessage({ id: "app.status.windows" })} color={convertLevelToColor(props.windows)} />
          </Grid>
          <Grid item xs={4}>
            <StatusItem logo={roof} label={intl.formatMessage({ id: "app.status.roof" })} color={convertLevelToColor(props.roof)} />
          </Grid>
          <Grid item xs={4}>
            <StatusItem logo={envelope} label={intl.formatMessage({ id: "app.status.envelope" })} color={convertLevelToColor(props.envelope)} />
          </Grid>
          <Grid item xs={4}>
            <StatusItem logo={basement} label={intl.formatMessage({ id: "app.status.basement" })} color={convertLevelToColor(props.basement)} />
          </Grid>
          <Grid item xs={4}>
            <StatusItem logo={doors} label={intl.formatMessage({ id: "app.status.doors" })} color={convertLevelToColor(props.doors)} />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};
