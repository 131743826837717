import { Typography } from "@mui/material";
import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";
import OutlineFrame from "../OutlineFrame";
import { PreferredHeating } from "../../../../../../funnels/isfp/renovations/utils";

export function PreferredHeatingComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  return (
    <OutlineFrame label={"bevorzugtes Heizsystem"}>
      <Typography>{PreferredHeating[renovations?.answers?.customerPreferences?.preferredHeating as keyof typeof PreferredHeating] || "--"}</Typography>
    </OutlineFrame>
  );
}
