import { Link, Typography } from "@mui/material";
import { TenantBasicInfo } from "../types/Schnellcheck";

export type ConsentLabelProps = {
  tenant?: TenantBasicInfo;
  termsConsentError?: boolean;
  fontSize?: number;
  // this renders an additional "*" asterisk if the checkbox itself is not set to requried
  required?: boolean;
};

export const ConsentLabel = ({ tenant, termsConsentError, fontSize, required }: ConsentLabelProps) => {
  if (!tenant || tenant.name === "novo") {
    return (
      <Typography display={"inline"} fontSize={fontSize} color={termsConsentError ? "error.main" : "default"}>
        Ich akzeptiere die{" "}
        <Link target='_blank' href='https://app.novo.eco/AGB_NOVO_Terms_of_service_DE.pdf' color={termsConsentError ? "error.main" : "text.primary"}>
          AGB
        </Link>{" "}
        und{" "}
        <Link target='_blank' href='https://novo.eco/data_protection' color={termsConsentError ? "error.main" : "text.primary"}>
          Datenschutzbestimmungen.
        </Link>
        {required && <sup> *</sup>}
      </Typography>
    );
  }
  return (
    <Typography display={"inline"} fontSize={fontSize} color={termsConsentError ? "error.main" : "default"}>
      Ich habe die{" "}
      <Link target='_blank' href='https://app.novo.eco/AGB_NOVO_Terms_of_service_DE.pdf' color={termsConsentError ? "error.main" : "text.primary"}>
        AGB
      </Link>{" "}
      und{" "}
      <Link target='_blank' href='https://novo.eco/data_protection' color={termsConsentError ? "error.main" : "text.primary"}>
        Datenschutzbestimmungen
      </Link>{" "}
      gelesen und akzeptiert und bin damit einverstanden, dass mein Berater von der {tenant.displayName} von NOVO über Status und Inhalt meiner Energieberatung
      informiert wird, um mir maßgeschneiderte Finanzierungsberatung anbieten zu können.
      {required && <sup> *</sup>}
    </Typography>
  );
};
