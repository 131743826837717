import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Button, Grid, MenuItem, MobileStepper, TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ChangeEvent, FunctionComponent, useEffect, useState, MouseEvent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import doors from "../../imgs/report_doors.png";
import windows from "../../imgs/report_windows.png";
import { HOUSE_OPENINGS } from "./storage";
import useUpsertQuestionnaire from "../../hooks/useUpsertQuestionnaire";

type OpeningsProps = {
  question: number;
  setNextQuestion: (questionNbr: number) => void;
};

type OpeningsState = {
  doors?: string;
  windows?: string;
};

export const Openings: FunctionComponent<OpeningsProps> = ({ question, setNextQuestion }) => {
  const [upsertQuestionnaire] = useUpsertQuestionnaire();
  const [disabled, setDisabled] = useState<boolean>(true);
  const intl = useIntl();
  const [openings, setOpenings] = useState<OpeningsState>({
    doors: "",
    windows: "",
  });

  function doorsTypeOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newOpeningsState = {
      ...openings,
      doors: e.target.value,
    };
    setDisabled(newOpeningsState.doors === "" || newOpeningsState.windows === "");
    setOpenings(newOpeningsState);
    localStorage.setItem(HOUSE_OPENINGS, JSON.stringify(newOpeningsState));
  }

  function windowsTypeOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newOpeningsState = {
      ...openings,
      windows: e.target.value,
    };
    setDisabled(newOpeningsState.doors === "" || newOpeningsState.windows === "");
    setOpenings(newOpeningsState);
    localStorage.setItem(HOUSE_OPENINGS, JSON.stringify(newOpeningsState));
  }

  function sendQuestionnaireOnClick(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    upsertQuestionnaire();
    setNextQuestion(4);
  }

  useEffect(() => {
    // Local Storage
    const answer = localStorage.getItem(HOUSE_OPENINGS);
    if (answer) {
      const parsedAnswer = JSON.parse(answer);
      setDisabled(parsedAnswer.doors === "" || parsedAnswer.windows === "");
      setOpenings(parsedAnswer);
    }
  }, []);

  return (
    <>
      <Stack spacing={2} alignItems='center' style={{ width: "100%" }} p={3}>
        <Typography component='h2' style={{ fontSize: 20, fontWeight: 600, padding: 10 }}>
          <FormattedMessage id='app.openings.title' />
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2} alignItems='center'>
              <img src={windows} className='Status-icons' alt={"Windows"} width='79' height='79' />
              <TextField
                id='windows-type'
                value={openings?.windows}
                label={intl.formatMessage({ id: "app.openings.windows" })}
                onChange={windowsTypeOnChange}
                size='small'
                select
                fullWidth
                data-cy='windows-input'
              >
                <MenuItem value={"simple"} data-cy='simple-windows-input'>
                  <FormattedMessage id='app.openings.simple' />
                </MenuItem>
                <MenuItem value={"double"} data-cy='double-windows-input'>
                  <FormattedMessage id='app.openings.double' />
                </MenuItem>
                <MenuItem value={"triple"} data-cy='triple-windows-input'>
                  <FormattedMessage id='app.openings.triple' />
                </MenuItem>
              </TextField>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2} alignItems='center'>
              <img src={doors} className='Status-icons' alt={"Doors"} width='79' height='79' />
              <TextField
                id='doors-type'
                value={openings?.doors}
                label={intl.formatMessage({ id: "app.openings.doors" })}
                onChange={doorsTypeOnChange}
                size='small'
                select
                fullWidth
                data-cy='doors-input'
              >
                <MenuItem value={"stell"} data-cy='stell-doors-input'>
                  <FormattedMessage id='app.openings.steel' />
                </MenuItem>
                <MenuItem value={"wood"} data-cy='wood-doors-input'>
                  <FormattedMessage id='app.openings.wood' />
                </MenuItem>
                <MenuItem value={"plastic"} data-cy='plastic-doors-input'>
                  <FormattedMessage id='app.openings.plastic' />
                </MenuItem>
              </TextField>
            </Stack>
          </Grid>
        </Grid>
        <MobileStepper
          variant='dots'
          steps={6}
          activeStep={question}
          position='top'
          sx={{ flexGrow: 1, position: "relative", alignItems: "center", width: "100%" }}
          nextButton={
            <Button variant='contained' size='small' data-cy='openings-next-btn' onClick={(e) => sendQuestionnaireOnClick(e)} disabled={disabled}>
              <FormattedMessage id='app.openings.next' /> <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button variant='contained' size='small' onClick={() => setNextQuestion(2)} disabled={question === 0}>
              <KeyboardArrowLeft /> <FormattedMessage id='app.openings.back' />
            </Button>
          }
        />
      </Stack>
    </>
  );
};
