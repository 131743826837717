import { Grid, Stack, Link, Typography } from "@mui/material";

export default function Footer() {
  return (
    <Grid
      container
      spacing={2}
      justifyContent='center'
      paddingTop={"5vh"}
      paddingLeft={"10vw"}
      paddingRight={"10vw"}
      style={{ background: "transparent", borderTop: "1px solid #cbcbcb" }}
    >
      <Grid item xs={12} sm={4}>
        <Stack spacing={1}>
          <Typography style={{ fontWeight: 400 }} color='#707172'>
            NOVO
          </Typography>
          <Link href='https://novo.eco' underline='none' style={{ fontWeight: 400 }} color='#707172'>
            Home
          </Link>
          <Link href='https://novo.eco/about' underline='none' style={{ fontWeight: 400 }} color='#707172'>
            Über uns
          </Link>
          <Link href='mailto: hi@buildingnovo.com' underline='none' style={{ fontWeight: 400 }} color='#707172'>
            Kontakt
          </Link>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Stack spacing={1}>
          <Typography style={{ fontWeight: 400 }} color='#707172'>
            RECHTLICHES
          </Typography>
          <Link href='https://novo.eco/impressum' underline='none' style={{ fontWeight: 400 }} color='#707172'>
            Impressum
          </Link>
          <Link href='https://novo.eco/data_protection' underline='none' style={{ fontWeight: 400 }} color='#707172'>
            Datenschutz
          </Link>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Stack spacing={1}>
          <Typography style={{ fontWeight: 400 }} color='#707172'>
            NÜTZLICHES
          </Typography>
          <Link href='https://novo.eco/faq' underline='none' style={{ fontWeight: 400 }} color='#707172'>
            Häufige Fragen
          </Link>
        </Stack>
      </Grid>
    </Grid>
  );
}
