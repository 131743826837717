import Grid from "@mui/material/Grid2";
import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";
import OutlineFrame from "../OutlineFrame";
import { Typography } from "@mui/material";
import InsulationAnswerComponent from "../InsulationAnswerComponent";
import { WallMaterials } from "../../../../../../funnels/isfp/renovations/utils";

export default function ExteriorWallsComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  return (
    <Grid container spacing={4}>
      <Grid size={6}>
        <OutlineFrame label={"Aufbau Außenwand"}>
          <Typography>{WallMaterials[renovations?.answers?.exteriorWalls?.wallsMaterial as keyof typeof WallMaterials] || "--"}</Typography>
        </OutlineFrame>
      </Grid>
      <Grid size={6} />
      <Grid size={12}>
        <InsulationAnswerComponent question='Sind die Außenwänden gedämmt?' insulation={renovations?.answers?.exteriorWalls?.insulation} />
      </Grid>
    </Grid>
  );
}
