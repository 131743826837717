import { Stack, Typography } from "@mui/material";
import { BubbleFlow } from "../../../../../../../types/cockpit/types";
import { SchnellcheckQuestionnaire } from "../../../../../../../types/Schnellcheck";
import OutlineFrame from "../OutlineFrame";

export default function HouseSurfaceComponent({ schnellcheck, bubbleFlow }: { schnellcheck?: SchnellcheckQuestionnaire; bubbleFlow?: BubbleFlow }) {
  if (!schnellcheck && !bubbleFlow) {
    return null;
  }
  const surface = schnellcheck?.answers?.houseDetails?.area || "--"; // Could not find this field in the bubble data

  return (
    <OutlineFrame label={"Wohnfläche"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography>{surface}</Typography>
        <Typography>m²</Typography>
      </Stack>
    </OutlineFrame>
  );
}
