import { Box, BoxProps, styled } from "@mui/material";

/**
 * This component is a decorator that wraps any given component as a {@link FormControl} of the 'outline' variant
 */
const OutlineFrame = styled(Box)(({ label }: BoxProps & { label: string }) => ({
  borderRadius: 4,
  border: "1px solid #00000042",
  padding: 14,
  width: "100%",
  position: "relative",
  "&:after": {
    content: `'${label}'`,
    position: "absolute",
    display: "inline=table",
    textWrap: "nowrap",
    maxWidth: "80%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    top: -10,
    left: 10,
    pointerEvents: "none",
    backgroundColor: "#FFFFFF",
    padding: "0 4px",
    fontSize: "12px",
    color: "#00000061",
  },
}));

export default OutlineFrame;
