import Grid from "@mui/material/Grid2";
import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";
import OutlineFrame from "../OutlineFrame";
import { Typography } from "@mui/material";
import InsulationAnswerComponent from "../InsulationAnswerComponent";

export default function BasementComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  const basementTypology = renovations?.answers?.basementOrGroundInformation?.basement;
  let basementTypologyLabel;
  switch (basementTypology) {
    case "heated":
      basementTypologyLabel = "beheizt Keller";
      break;
    case "partiallyHeated":
      basementTypologyLabel = "teilweise beheizt Keller";
      break;

    case "unheated":
      basementTypologyLabel = "unbeheizt Keller";
      break;

    case "none":
      basementTypologyLabel = "kein Keller";
      break;
  }
  return (
    <Grid container spacing={4}>
      <Grid size={6}>
        <OutlineFrame label={"Roof Typology"}>
          <Typography>{basementTypologyLabel}</Typography>
        </OutlineFrame>
      </Grid>
      <Grid size={6} />
      {basementTypology === "heated" && (
        <>
          <Grid size={12}>
            <InsulationAnswerComponent
              question='Sind die Außenwände des Kellers gedämmt?'
              insulation={renovations?.answers?.basementOrGroundInformation?.basementWallsInsulation}
            />
          </Grid>
          <Grid size={12}>
            <InsulationAnswerComponent
              question='Ist die Bodenplatte des Kellers gedämmt?'
              insulation={renovations?.answers?.basementOrGroundInformation?.basementFloorInsulation}
            />
          </Grid>
        </>
      )}
      {basementTypology === "partiallyHeated" && (
        <>
          <Grid size={12}>
            <InsulationAnswerComponent
              question='Sind die Außenwände des Kellers in den beheizten Räumen gedämmt?'
              insulation={renovations?.answers?.basementOrGroundInformation?.hotWallsInsulation}
            />
          </Grid>
          <Grid size={12}>
            <InsulationAnswerComponent
              question='Ist die Bodenplatte des Kellers in den beheizten Räumen gedämmt?'
              insulation={renovations?.answers?.basementOrGroundInformation?.hotFloorInsulation}
            />
          </Grid>
          <Grid size={12}>
            <InsulationAnswerComponent
              question='Ist die Kellerdecke in den unbeheizten Räumen gedämmt?'
              insulation={renovations?.answers?.basementOrGroundInformation?.coldCeilingInsulation}
            />
          </Grid>
        </>
      )}
      {basementTypology === "unheated" && (
        <Grid size={12}>
          <InsulationAnswerComponent
            question='Ist die Kellerdecke gedämmt?'
            insulation={renovations?.answers?.basementOrGroundInformation?.basementCeilingInsulation}
          />
        </Grid>
      )}
      {basementTypology === "none" && (
        <Grid size={12}>
          <InsulationAnswerComponent
            question='Ist die Bodenplatte des Erdgeschosses gedämmt?'
            insulation={renovations?.answers?.basementOrGroundInformation?.groundFloorInsulation}
          />
        </Grid>
      )}
    </Grid>
  );
}
