import { Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import ReactJoyride, { ACTIONS, EVENTS, STATUS, Step } from "react-joyride";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { retrieveRum } from "../../AwsRum";
import { SlowLoadingImage } from "../../components/common/NovoSlowLoadingImage";
import { useFetchUser } from "../../hooks/useFetchUser";
import { useUpsertGuidedTourState } from "../../hooks/useUpsertGuidedTourState";

export const RUN_COCKPIT_TOUR_SEARCH_ARG = "run-cockpit-tour";

type StepAction = {
  clickOn?: string;
  await?: number;
};

type TypedDataStep = Omit<Step, "data"> & {
  data?: {
    // before navigating to next step, take actions and await time before doing so
    next?: StepAction;
    // before navigating to previous step, take actions and await time before doing so
    previous?: StepAction;
  };
};

export const CockpitTour = () => {
  const { getUser, user } = useFetchUser();
  const { upsertGuidedToursState } = useUpsertGuidedTourState();
  const [running, setRunning] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const theme = useTheme();

  const [params] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation(); // To get the current pathname

  useEffect(() => {
    const tourParam = params.get(RUN_COCKPIT_TOUR_SEARCH_ARG);

    if (tourParam) {
      setStepIndex(0);
      setRunning(true);
      params.delete(RUN_COCKPIT_TOUR_SEARCH_ARG);
      navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && !user.guidedTours?.cockpit) {
      setRunning(true);
      return;
    }
  }, [user]);

  const steps: Array<TypedDataStep> = [
    {
      content: (
        <>
          <Typography paddingBottom={2}>Willkommen im NOVO Cockpit! </Typography>
          <Typography>Wir geben dir jetzt einen schnellen Überblick über die wichtigsten Funktionen.</Typography>
        </>
      ),
      placement: "center",
      target: "body",
      disableBeacon: true,
    },
    {
      content: <BookmarkingInstructions />,
      styles: { tooltip: { maxWidth: 800, width: "auto" } },
      placement: "center",
      target: "body",
      disableBeacon: true,
    },
    {
      target: "[data-joyride='expand-side-menu']",
      styles: { tooltip: { maxWidth: 600, width: "auto" } },
      content: "Hier kannst du die Menüleiste einblenden, um die Beschreibung der Menüeinträge anzuschauen",
      data: { next: { clickOn: "[data-joyride='expand-side-menu']", await: 200 } },
      disableBeacon: true,
    },
    {
      content: <Typography>Dies ist der Hauptbereich, in dem du deine Kunden sehen, ihren Status überprüfen und neue Kunden einladen kannst.</Typography>,
      styles: { tooltip: { maxWidth: 600, width: "auto" } },
      target: "[data-joyride='leads-menu-button']",
      data: { next: { clickOn: "[data-joyride='leads-menu-button']" } },
      disableBeacon: true,
    },
    {
      styles: { tooltip: { maxWidth: 600, width: "auto" } },
      content: "Hier kannst du deine wichtigsten Kennzahlen im Blick behalten und das Sanierungspotenzial deiner eingeladenen Kunden ablesen.",
      target: "[data-joyride='lead-metrics-section']",
      disableBeacon: true,
    },
    {
      styles: { tooltip: { maxWidth: 600, width: "auto" } },
      content: "Hier kannst du deine Kunden zu NOVO einladen, damit sie eine bedarfsgerechte Energieberatung  erhalten",
      target: "[data-joyride='lead-invite-button-section']",
      data: { next: { clickOn: "[data-joyride='lead-invite-button']" } },
      disableBeacon: true,
    },
    {
      content:
        'Nachdem du den „Kunden einladen"-Knopf betätigt hast, kannst du über dieses Fenster neue Kunden zu NOVO einladen. Gib einfach einen Namen und die E-Mail-Adresse ein, wähle ein passendes Produkt aus, und schon kannst du die Einladung versenden.',
      target: "[data-joyride='lead-invite-modal']",
      styles: { tooltip: { maxWidth: 800, width: "auto" } },
      data: {
        next: { clickOn: "[data-joyride='lead-invite-modal-close']" },
        previous: { clickOn: "[data-joyride='lead-invite-modal-close']" },
      },
      disableBeacon: true,
    },
    {
      content: 'Im Menü "NOVO-Akademie" findest du Material, um dich weiter zu informieren und Fragen zum Cockpit zu klären.',
      styles: { tooltip: { width: "auto" } },
      target: "[data-joyride='novo-academy-menu-button']",
      data: {
        next: { clickOn: "[data-joyride='novo-academy-menu-button']", await: 200 },
        previous: { clickOn: "[data-joyride='lead-invite-button']", await: 200 },
      },
      disableBeacon: true,
    },
    {
      content:
        "In diesem Abschnitt findest du Videos, die dir helfen werden, das Cockpit besser zu verstehen. So kannst du deine Kunden besser erreichen und ihnen dabei helfen, energetisch nachhaltiger zu werden.",
      styles: { tooltip: { maxWidth: 800, width: "auto" } },

      target: "[data-joyride='videos-and-learning']",
      data: {
        previous: { clickOn: "[data-joyride='leads-menu-button']", await: 200 },
      },
      disableBeacon: true,
    },
    {
      content:
        "Das war der Kurzüberblick. Viel Erfolg bei der Arbeit mit dem NOVO Cockpit! Wenn du Fragen hast, kannst du uns gern jederzeit über “Brauchst du Hilfe?” oben rechts kontaktieren.",
      target: "body",
      styles: { tooltip: { maxWidth: 800, width: "auto" } },
      data: { next: { clickOn: "[data-joyride='leads-menu-button']" } },
      placement: "center",
      disableBeacon: true,
    },
  ];

  const buttonStylingBase: Partial<React.CSSProperties> = {
    paddingBottom: 12,
    paddingTop: 10,
    paddingLeft: 18,
    paddingRight: 18,
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    fontVariantCaps: "all-petite-caps",
    borderRadius: "4px",
    border: "1px solid",
  };

  return (
    <ReactJoyride
      locale={{
        back: "Zurück",
        close: "Schließen",
        last: "Fertig",
        next: "Weiter",
        skip: "Überspringen",
        open: "Öffnen",
      }}
      styles={{
        buttonNext: {
          ...buttonStylingBase,
          backgroundColor: theme.palette.primary.main,
          border: "none",
          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.3)",
        },
        buttonBack: buttonStylingBase,
        buttonSkip: buttonStylingBase,
        options: { zIndex: 10000 },
        tooltip: { zIndex: 10001 },
        overlay: { zIndex: 9999 },
      }}
      steps={steps}
      stepIndex={stepIndex}
      run={running}
      disableCloseOnEsc
      disableScrolling
      hideCloseButton
      disableOverlayClose
      showSkipButton
      continuous
      callback={(callbackProps) => {
        const { action, index, status, type } = callbackProps;
        const step: TypedDataStep = callbackProps.step;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type as any)) {
          let increment = 0;
          let stepActions: StepAction | undefined;

          if (action === ACTIONS.PREV) {
            increment = -1;
            stepActions = step.data?.previous;
          } else if (action === ACTIONS.NEXT) {
            increment = 1;
            stepActions = step.data?.next;
          }

          if (stepActions?.clickOn) {
            const element = document.querySelector(stepActions.clickOn);
            if (element) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (element as any).click();
            } else {
              console.error(`could not find element ${stepActions?.clickOn}`);
              retrieveRum()?.recordError(`could not find element ${stepActions?.clickOn}`);
            }
          }

          const nextStepIndex = stepIndex + increment;
          if (nextStepIndex >= 0 && nextStepIndex < steps.length) {
            let tries = 0;
            const MAX_TRIES = 20;

            const tryAdvance = () => {
              const waitFor = stepActions?.await != undefined ? stepActions?.await : 0;
              const nextStepTarget = steps[nextStepIndex].target;
              // if element was found, trigger the next step after await for "await" time (if it was defined)
              if (typeof nextStepTarget === "string" && document.querySelector(nextStepTarget)) {
                setTimeout(() => {
                  setStepIndex(nextStepIndex);
                }, waitFor);
              } else {
                tries++;
                if (tries > MAX_TRIES) {
                  console.error("Failed to run tour");
                  retrieveRum()?.recordError("Failed to run tour");
                  setRunning(false);
                  return;
                }
                setTimeout(() => tryAdvance(), 200);
              }
            };

            tryAdvance();
          } else {
            setStepIndex(nextStepIndex);
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)) {
          // You need to set our running state to false, so we can restart if we click start again.
          upsertGuidedToursState({ cockpit: { status: STATUS.FINISHED ? "FINISHED" : "SKIPPED", step: index, timestamp: Date.now() } });
          setRunning(false);
        }
      }}
    />
  );
};

const BookmarkingInstructions = () => {
  return (
    <>
      <Typography paddingBottom={2}>
        Um ein neues Lesezeichen für NOVO zu erstellen, drücke Strg+D (Windows) oder Cmd+D (Mac) oder klicke auf den Stern rechts in der Adressleiste, wie im
        Bild gezeigt. So findest du die Seite jederzeit schnell wieder.
      </Typography>
      <SlowLoadingImage assetPath='cockpit-tour/bookmark-simplified.gif' height={131} width={463} />
    </>
  );
};
