import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import { ChangeEvent, useEffect, useState } from "react";
import ButtonWithSpinner from "../../../../components/ButtonWithSpinner";
import NovoAddressAutocomplete, { includesHouseNumber } from "../../../../components/common/NovoAddressAutocomplete";
import NovoTextField from "../../../../components/common/NovoTextField";
import { NovoTooltip } from "../../../../components/common/NovoTooltip";
import { ConsentLabel } from "../../../../components/ConsentLabel";
import usePutMetric from "../../../../hooks/usePutMetric";
import { TenantBasicInfo } from "../../../../types/Schnellcheck";
import { isValidEmail } from "../../../auth/emailValidator";
import { StatusQuoScreenProps } from "./StatusQuoFunnel";

interface Screen5DialogProps extends StatusQuoScreenProps {
  open: boolean;
  handleClose: () => void;
  fullscreen: boolean;
  tenant?: TenantBasicInfo;
}

export default function Screen5Dialog({
  schnellcheck,
  setSchnellcheck,
  isUpdateRunning,
  open,
  handleClose,
  next,
  fullscreen = false,
  tenant,
}: Screen5DialogProps) {
  const [firstNameError, setFirstNameError] = useState<string>();
  const [lastNameError, setLastNameError] = useState<string>();
  const [telephoneError, setTelephoneError] = useState<string>();
  const [emailError, setEmailError] = useState<string>();
  const [autocompleteAddress, setAutocompleteAddress] = useState<google.maps.places.AutocompletePrediction | null>(null);
  const [addressError, setAddressError] = useState<string>();
  const [putMetric] = usePutMetric();
  const [termsConsent, setTermsConsent] = useState(false);
  const [termsConsentError, setTermsConsentError] = useState(false);

  useEffect(() => {
    putMetric("Schnellcheck", "RenderContactDialog");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFirstName = (event: ChangeEvent<HTMLInputElement>) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        contact: {
          ...schnellcheck.answers?.contact,
          firstName: event.target.value,
        },
      },
    });
  };

  const updateLastName = (event: ChangeEvent<HTMLInputElement>) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        contact: {
          ...schnellcheck.answers?.contact,
          lastName: event.target.value,
        },
      },
    });
  };

  const updateTelephone = (event: ChangeEvent<HTMLInputElement>) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        contact: {
          ...schnellcheck.answers?.contact,
          telephone: event.target.value,
        },
      },
    });
  };

  const updateEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        contact: {
          ...schnellcheck.answers?.contact,
          email: event.target.value,
        },
      },
    });
  };

  const updateAddress = (address: google.maps.places.AutocompletePrediction | null) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        contact: {
          ...schnellcheck.answers?.contact,
          address: address?.description,
        },
      },
    });
    setAutocompleteAddress(address);
  };

  const validate = () => {
    const { email, telephone, firstName, lastName, address } = schnellcheck.answers?.contact ?? {};
    if (!firstName) {
      setFirstNameError("Pflichtfeld");
      return false;
    } else {
      setFirstNameError(undefined);
    }

    if (!lastName) {
      setLastNameError("Pflichtfeld");
      return false;
    } else {
      setLastNameError(undefined);
    }

    if (!telephone) {
      setTelephoneError("Pflichtfeld");
      return false;
    } else if (!/(^0|^\+49)\s*[1-9][0-9]{2,3}\s*[0-9]{0,3}\s*[0-9]{0,4}$/g.test(telephone)) {
      setTelephoneError("Ungültige Handynummer");
      return false;
    } else {
      setTelephoneError(undefined);
    }

    if (!email) {
      setEmailError("Pflichtfeld");
      return false;
    } else if (!isValidEmail(email)) {
      setEmailError("Ungültige Email Adresse");
      return false;
    } else {
      setEmailError(undefined);
    }

    if (!termsConsent) {
      setTermsConsentError(true);
      return false;
    } else {
      setTermsConsentError(false);
    }

    if (!address) {
      setAddressError("Pflichtfeld");
      return false;
    } else if (!includesHouseNumber(autocompleteAddress)) {
      setAddressError("Geben Sie die Hausnummer an");
      return false;
    } else {
      setAddressError(undefined);
    }
    putMetric("Schnellcheck", "OfferRequested");
    return true;
  };

  const handleTermsChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setTermsConsent(checked);
    setTermsConsentError(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={fullscreen}>
      <DialogTitle fontSize={{ xs: 18, lg: 20 }}>
        Anfrage Angebot & Erstgespräch
        <Button
          sx={{ float: "inline-end", color: "rgba(0, 0, 0, 0.54)", ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
          onClick={handleClose}
          endIcon={<CloseIcon />}
        >
          Schließen
        </Button>
      </DialogTitle>
      <DialogContent>
        <Stack>
          <DialogContentText mb={4} fontSize={{ xs: 14, lg: 16 }}>
            Im nächsten Schritt können Sie ein unverbindliches persönliches Gespräch mit unserem Team vereinbaren.
          </DialogContentText>
          <FormControl sx={{ mb: 2 }}>
            <NovoTextField
              variant='outlined'
              id='first-name-input'
              data-cy='first-name-input'
              value={schnellcheck.answers?.contact?.firstName}
              type='text'
              label='Vorname'
              onChange={updateFirstName}
              required={true}
              error={!!firstNameError}
              helperText={firstNameError}
              slotProps={{
                inputLabel: { shrink: !!schnellcheck.answers?.contact?.firstName },
              }}
            />
          </FormControl>
          <FormControl sx={{ mb: 2 }}>
            <NovoTextField
              variant='outlined'
              id='last-name-input'
              data-cy='last-name-input'
              value={schnellcheck.answers?.contact?.lastName}
              type='text'
              label='Nachname'
              onChange={updateLastName}
              required={true}
              error={!!lastNameError}
              helperText={lastNameError}
              slotProps={{
                inputLabel: { shrink: !!schnellcheck.answers?.contact?.lastName },
              }}
            />
          </FormControl>
          <FormControl sx={{ mb: 2 }}>
            <NovoTextField
              variant='outlined'
              id='telephone-input'
              data-cy='telephone-input'
              value={schnellcheck.answers?.contact?.telephone}
              type='tel'
              inputMode='tel'
              label='Handynummer'
              onChange={updateTelephone}
              required={true}
              error={!!telephoneError}
              helperText={telephoneError}
              slotProps={{
                inputLabel: { shrink: !!schnellcheck.answers?.contact?.telephone },
              }}
            />
          </FormControl>
          <FormControl sx={{ mb: 2 }}>
            <NovoTextField
              variant='outlined'
              id='email-input'
              data-cy='email-input'
              value={schnellcheck.answers?.contact?.email}
              type='email'
              inputMode='email'
              label='Email Adresse'
              onChange={updateEmail}
              required={true}
              error={!!emailError}
              helperText={emailError}
              slotProps={{
                inputLabel: { shrink: !!schnellcheck.answers?.contact?.email },
              }}
            />
          </FormControl>
          <Stack direction='row' alignItems='center' spacing={2} sx={{ mb: 2 }}>
            <NovoAddressAutocomplete
              id='address-input'
              data_cy='address-input'
              address={autocompleteAddress}
              updateAddress={updateAddress}
              addressError={addressError}
            />
            <NovoTooltip title='Wenn Ihre Adresse in den Vorschlägen nicht enthalten ist, geben Sie weitere Informationen ein, beispielsweise Stadt oder Stadtteil.'>
              <HelpIcon sx={{ fontSize: { xs: 18, lg: 20 } }} />
            </NovoTooltip>
          </Stack>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ color: termsConsentError ? "error.main" : "default" }}
                data-cy='terms-option'
                name='checkbox-consent'
                color='secondary'
                checked={termsConsent}
                onChange={handleTermsChange}
              />
            }
            label={<ConsentLabel fontSize={11} required tenant={tenant} termsConsentError={termsConsentError} />}
          />
        </Stack>
        <Stack sx={{ alignSelf: "center", mt: 1 }}>
          <ButtonWithSpinner
            variant='contained'
            data-cy='submit-offer-request-btn'
            loading={isUpdateRunning}
            onClick={() => {
              if (validate()) {
                next();
              }
            }}
            label={"Kostenloses Erstgespräch & Angebot anfordern"}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
