import { Checkbox, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import OutlineFrame from "../OutlineFrame";
import useImage from "../../../../../../../hooks/useImage";
import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";

export default function HouseTypeComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  const detached = useImage("Detached.png");
  const semi = useImage("Semi.png");
  const terraced = useImage("Terraced.png");

  if (!renovations) {
    return null;
  }

  const selectedType = renovations.answers?.houseInformation?.houseTypology;
  return (
    <OutlineFrame label='House typology'>
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
      >
        <Grid size={{ xs: 6, md: 4 }}>
          <Stack
            direction={"row"}
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, backgroundColor: selectedType === "detached" ? "#00000015" : "inherit", borderRadius: 2 }}
          >
            <Stack alignItems={"start"} spacing={1}>
              <img src={detached.image} height={45} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                freistehend
              </Typography>
            </Stack>
            <Checkbox disabled sx={{ flexGrow: 1, "&.Mui-checked": { color: "text.primary" } }} checked={selectedType === "detached"} />
          </Stack>
        </Grid>
        <Grid size={{ xs: 6, md: 4 }}>
          <Stack
            direction={"row"}
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, backgroundColor: selectedType === "semiDetached" ? "#00000015" : "inherit", borderRadius: 2 }}
          >
            <Stack alignItems={"start"} spacing={1}>
              <img src={semi.image} height={40} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Reihenendhaus / Doppelhaushälfte
              </Typography>
            </Stack>
            <Checkbox disabled sx={{ flexGrow: 1, "&.Mui-checked": { color: "text.primary" } }} checked={selectedType === "semiDetached"} />
          </Stack>
        </Grid>
        <Grid size={{ xs: 6, md: 4 }}>
          <Stack
            direction={"row"}
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, backgroundColor: selectedType === "terraced" ? "#00000015" : "inherit", borderRadius: 2 }}
          >
            <Stack alignItems={"start"} spacing={1}>
              <img src={terraced.image} height={40} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Reihenmittelhaus
              </Typography>
            </Stack>
            <Checkbox disabled sx={{ flexGrow: 1, "&.Mui-checked": { color: "text.primary" } }} checked={selectedType === "terraced"} />
          </Stack>
        </Grid>
      </Grid>
    </OutlineFrame>
  );
}
