import { useSearchParams } from "react-router-dom";
import { StripePaymentAppState } from "./useNavigateToStripePayment";

export const useStateFromStripeRedirect = () => {
  const [searchParams] = useSearchParams();

  // base64 encoded state object
  const encodedState = searchParams.get("state");

  let state: StripePaymentAppState = { hardRefresh: true, showSuccessBeforeRedirecting: false };
  if (encodedState) {
    const parsedState = JSON.parse(atob(decodeURIComponent(encodedState)));

    state = {
      ...state,
      ...parsedState,
    };
  }

  return state;
};
