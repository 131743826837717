import { Typography } from "@mui/material";
import { BubbleFlow } from "../../../../../../../types/cockpit/types";
import { SchnellcheckQuestionnaire } from "../../../../../../../types/Schnellcheck";
import OutlineFrame from "../OutlineFrame";

export default function WallsInsulationYearComponent({ schnellcheck, bubbleFlow }: { schnellcheck?: SchnellcheckQuestionnaire; bubbleFlow?: BubbleFlow }) {
  if (!schnellcheck && !bubbleFlow) {
    return null;
  }
  const insulationYear = schnellcheck?.answers?.houseInsulation?.yearOfWallsInsulation || bubbleFlow?.statusQuoResponse?.outerWallInsulation || "--";

  return (
    <OutlineFrame label={"Wände Dämmung Jahr"}>
      <Typography>{insulationYear}</Typography>
    </OutlineFrame>
  );
}
