import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";
import { QuestionComponent } from "../QuestionComponent";

export function WindowsOtherTypesComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  const options = [
    {
      label: "Ja",
      selected: !!renovations?.answers?.windowsAndDoorsInformation?.otherFrameTypes,
    },
    {
      label: "Nein",
      selected: !renovations?.answers?.windowsAndDoorsInformation?.otherFrameTypes,
    },
  ];
  return <QuestionComponent question='Gibt es unterschiedliche Fenstertypen?' options={options} />;
}
