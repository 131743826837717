import { Dispatch, SetStateAction, useState } from "react";

export type ValidityCheck<T> = (val: T | undefined) => boolean;

export type ErrorControlState<T> = {
  isErrorState: boolean;
  setIsError: Dispatch<SetStateAction<boolean>>;
  // current error message
  errorMsgState?: string;
  setErrorMsg: Dispatch<SetStateAction<string>>;
  // what error message to display
  errorMsg: string;
  // validity check function
  isValid: ValidityCheck<T>;
};

export const useFormErrorControl = <T>(isValid: ValidityCheck<T>, errorMsg: string): ErrorControlState<T> => {
  const [isErrorState, setIsError] = useState(false);
  const [errorMsgState, setErrorMsg] = useState<string>("");
  return {
    isErrorState,
    setIsError,
    errorMsgState,
    setErrorMsg,
    errorMsg,
    isValid,
  };
};
