import { FunctionComponent } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { Property } from "../../types/types";
import { FormattedMessage } from "react-intl";

export const OwnerStatusTable: FunctionComponent<Property> = () => {
  return (
    <>
      <TableContainer component={Paper} style={{ maxWidth: 500 }}>
        <Table size='small' aria-label='Owner Status'>
          <TableBody>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell style={{ backgroundColor: "black", color: "white" }} colSpan={2}>
                <FormattedMessage id='app.offer.state' />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id='app.offer.status' />
              </TableCell>
              <TableCell align='right'>
                <FormattedMessage id='app.offer.isfp' />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id='app.offer.next' />
              </TableCell>
              <TableCell align='right'>
                <FormattedMessage id='app.offer.notification' />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
