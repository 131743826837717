export type PasswordValidationResult = {
  hasMinLength: boolean;
  hasSpecialChar: boolean;
  hasLowerCase: boolean;
  hasUpperCase: boolean;
  hasNumber: boolean;
  passwordsMatch: boolean;
  passwordHasValue: boolean;
};

export const passwordValidator = (password: string, passwordRetyped?: string, minLength = 8): PasswordValidationResult => {
  if (!password) {
    return {
      hasLowerCase: false,
      hasMinLength: false,
      hasNumber: false,
      hasSpecialChar: false,
      hasUpperCase: false,
      passwordsMatch: password == passwordRetyped,
      passwordHasValue: password != "",
    };
  }
  const lowerCaseRegex = /[a-z]+/g;
  const upperCaseRegex = /[A-Z]+/g;
  const numberRegex = /[0-9]+/g;
  const symbolsRegex = /[^A-Za-z0-9]/;

  return {
    hasLowerCase: lowerCaseRegex.test(password),
    hasMinLength: password.length >= minLength,
    hasNumber: numberRegex.test(password),
    hasSpecialChar: symbolsRegex.test(password),
    hasUpperCase: upperCaseRegex.test(password),
    passwordsMatch: password == passwordRetyped,
    passwordHasValue: password != "",
  };
};
