import { Steps } from "../../../../types/cockpit/types";
import { DoorType, HeatingSystemType, HouseType, WindowType } from "../../../../types/Schnellcheck";

export const allHeatingTypes = () => {
  return Object.values(HeatingSystemType).filter((v) => isNaN(Number.parseInt(v)));
};

export const labelFromBoolean = (value?: unknown): string => {
  return value ? "Ja" : "Nein";
};

export const getHouseTypeLabel = (houseType: HouseType): string => {
  switch (houseType) {
    case HouseType.singleFamily:
      return "Einfamilienhaus";
    case HouseType.doubleFamily:
      return "Zweifamilienhaus";
    case HouseType.multiFamily:
      return "Mehrfamilienhaus";
    default:
      return "";
  }
};

export const getHeatingLabel = (heatingSystem: HeatingSystemType): string => {
  switch (heatingSystem) {
    case HeatingSystemType.gas:
      return "Gas";
    case HeatingSystemType.oil:
      return "Öl";
    case HeatingSystemType.woodPellets:
      return "Holz (pellets)";
    case HeatingSystemType.electricity:
      return "Strom";
    case HeatingSystemType.districtHeating:
      return "Fernwärme";
    case HeatingSystemType.heatPump:
      return "Wärmepumpe";
    case HeatingSystemType.liquefiedPetroleumGas:
      return "Flüssiggas";
    case HeatingSystemType.other:
      return "Anderer Brennstoff";
    default:
      return "";
  }
};

export const allDoorTypes = () => {
  return Object.values(DoorType).filter((v) => isNaN(Number.parseInt(v)));
};

export const getDoorLabel = (doorType?: DoorType): string | undefined => {
  if (!doorType) return;
  switch (doorType) {
    case DoorType.steel:
      return "Stahl";
    case DoorType.plastic:
      return "Kunststoff";
    case DoorType.wood:
      return "Holz";
    default:
      return "";
  }
};

export const fromHash = (value: string): Steps => {
  switch (value) {
    case "#introduction":
      return Steps.introduction;
    case "":
      return Steps.start;
    case "#heating":
      return Steps.heating;
    case "#insulation":
      return Steps.insulation;
    case "#openings":
      return Steps.openings;
    case "#report":
      return Steps.report;
    case "#confirmation":
      return Steps.confirmation;
    default:
      throw new Error(`"${value}" is not a valid value for Steps.`);
  }
};

export const fromDoorLabel = (value: string): DoorType => {
  switch (value) {
    case "Stahl":
      return DoorType.steel;
    case "Kunststoff":
      return DoorType.plastic;
    case "Holz":
      return DoorType.wood;
    default:
      throw new Error(`"${value}" is not a valid value for DoorType.`);
  }
};

export const allWindowTypes = () => {
  return Object.values(WindowType).filter((v) => isNaN(Number.parseInt(v)));
};

export const getWindowLabel = (windowType?: WindowType): string => {
  switch (windowType) {
    case WindowType.simple:
      return "Einfach verglast";
    case WindowType.double:
      return "Doppelt verglast";
    case WindowType.triple:
      return "Dreifach verglast";
    default:
      return "";
  }
};

export const fromWindowLabel = (value: string): WindowType => {
  switch (value) {
    case "Einfach verglast":
      return WindowType.simple;
    case "Doppelt verglast":
      return WindowType.double;
    case "Dreifach verglast":
      return WindowType.triple;
    default:
      throw new Error(`"${value}" is not a valid value for WindowType.`);
  }
};
