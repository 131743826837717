import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { AuthError, AuthErrorType } from "../types/Auth";
import { useMemo, useState } from "react";
import { cognitoClientId, cognitoPoolId } from "../utils/params";

export default function useConfirmAccount() {
  const userPool = useMemo(() => new CognitoUserPool({ ClientId: cognitoClientId(), UserPoolId: cognitoPoolId() }), []);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AuthError | undefined>();
  const [confirmSuccess, setConfirmSuccess] = useState<{ username: string } | undefined>(undefined);

  const confirmAccount = async (email: string, code: string) => {
    setIsLoading(true);
    setConfirmSuccess(undefined);
    setError(undefined);
    const user = new CognitoUser({ Pool: userPool, Username: email });
    await new Promise((resolve: (val?: unknown) => void, reject) => {
      user.confirmRegistration(code, false, (err, success) => {
        if (err) {
          switch (err.name) {
            case "CodeMismatchException":
              setError({ type: AuthErrorType.FAILED_CONFIRMING_ACCOUNT_WRONG_CODE });
              break;
            case "NotAuthorizedException":
              setError({ type: AuthErrorType.FAILED_CONFIRMING_ACCOUNT_POSSIBLY_ALREADY_CONFIRMED });
              break;
            default:
              setError({ type: AuthErrorType.FAILED_CONFIRMING_ACCOUNT });
              return reject(err);
          }
          return resolve();
        }
        setConfirmSuccess({ username: email });
        return resolve(success);
      });
    }).finally(() => {
      setIsLoading(false);
    });
  };
  return { isLoading, confirmSuccess, error, confirmAccount };
}
