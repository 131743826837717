import { useCallback, useContext, useState } from "react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { AuthContext } from "../Contexts";
import { retrieveRum } from "../AwsRum";
import { isSessionValid } from "./useAuth";

export const useAuthAxios = <ReturnType>() => {
  const [responseStatus, setStatus] = useState<number>();
  const [running, setRunning] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const [data, setData] = useState<ReturnType>();
  const { session, refreshSession } = useContext(AuthContext);

  const authAxios = useCallback(
    async (req: AxiosRequestConfig) => {
      setError(undefined);
      setData(undefined);
      setRunning(true);

      if (!isSessionValid(session)) {
        await refreshSession();
      }
      try {
        const { data, status } = await axios.request<ReturnType>({
          ...req,
          headers: {
            Authorization: `Bearer ${session?.getIdToken().getJwtToken()}`,
            ...req.headers,
          },
        });
        setStatus(status);
        setData(data);
        return data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          setStatus(error.response?.status);
        }
        retrieveRum()?.recordError(error);
        setError(error);
        setData(undefined);
        return undefined;
      } finally {
        setRunning(false);
      }
    },
    [session, refreshSession],
  );

  const resetState = () => {
    setStatus(undefined);
    setRunning(false);
    setError(undefined);
    setData(undefined);
  };

  return { authAxios, data, responseStatus, error, running, resetState };
};
