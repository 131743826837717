import { FunctionComponent } from "react";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import heatingIcon from "../../imgs/report_heating.png";
import windowsIcon from "../../imgs/report_windows.png";
import roofIcon from "../../imgs/report_roof.png";
import envelopeIcon from "../../imgs/report_envelope.png";
import basementIcon from "../../imgs/report_basement.png";
import doorsIcon from "../../imgs/report_doors.png";
import { useIntl } from "react-intl";
import { ReportStatusEntity, StatusColor } from "../../types/Schnellcheck";

type ReportStatusItemProps = {
  item: ReportStatusEntity;
  color: StatusColor;
};

const convertStatusColorToBackgroundColor = (statusColor: StatusColor) => {
  switch (statusColor) {
    case StatusColor.green:
      return "#97f525";
    case StatusColor.yellow:
      return "#ffde59";
    case StatusColor.red:
      return "#ff5757";
  }
};

const icons = new Map<ReportStatusEntity, string>([
  [ReportStatusEntity.Heating, heatingIcon],
  [ReportStatusEntity.Windows, windowsIcon],
  [ReportStatusEntity.Roof, roofIcon],
  [ReportStatusEntity.Envelope, envelopeIcon],
  [ReportStatusEntity.Basement, basementIcon],
  [ReportStatusEntity.Doors, doorsIcon],
]);

export const ReportStatusItem: FunctionComponent<ReportStatusItemProps> = ({ item, color, ...props }) => {
  const intl = useIntl();
  return (
    <>
      <Stack spacing={1} alignItems='center' justifyContent={"space-evenly"}>
        <img
          src={icons.get(item)}
          style={{ borderRadius: "10px", backgroundColor: convertStatusColorToBackgroundColor(color) }}
          className='Status-icons'
          alt={intl.formatMessage({ id: "app.report." + item })}
          {...props}
        />
        <Typography variant='subtitle1' align='center' style={{ fontSize: 20, fontWeight: 400 }}>
          {intl.formatMessage({ id: "app.report." + item })}
        </Typography>
      </Stack>
    </>
  );
};
