import { useTheme } from "@mui/material/styles";
import useImage from "../../hooks/useImage";
import Box, { BoxProps } from "@mui/material/Box";

interface NovoLogoProps extends BoxProps {
  width?: number | string;
  height?: number | string;
  fileName?: string;
}

export default function NovoLogo({ fileName, width = "auto", height = "auto", ...props }: NovoLogoProps) {
  const theme = useTheme();
  const { image } = useImage(fileName ?? theme.logo);
  return (
    <Box {...props}>
      <img src={image} width={width} height={height} />
    </Box>
  );
}
