import NovoFileUpload, { FileRef } from "./NovoFileUpload";

interface ImageUploadProps extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "onChange" | "value"> {
  label?: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  direction?: "row" | "column";
  multiple?: boolean;
  maxImages?: number;
  maxSize?: number;
  withThumbnails?: boolean;
  onChange?: (value: FileRef[]) => void;
  value?: FileRef[];
}

/**
 *
 * Just an alias for `NovoFileUpload` with the most common configuration for handling image uploads
 */
export default function NovoImageUpload(props: ImageUploadProps) {
  return <NovoFileUpload withThumbnails label='Foto Hochladen' maxFiles={props.maxImages} accept='image/*' {...props} />;
}
