import { TextField, TextFieldProps, TextFieldVariants, useMediaQuery, useTheme } from "@mui/material";

export default function NovoTextField<Variant extends TextFieldVariants>(
  props: {
    /**
     * The variant to use.
     * @default 'outlined'
     */
    variant?: Variant;
  } & Omit<TextFieldProps, "variant">,
) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  return <TextField size={isMobile ? "small" : "medium"} {...props} />;
}
