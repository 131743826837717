import { Typography } from "@mui/material";
import { BubbleFlow } from "../../../../../../../types/cockpit/types";
import { SchnellcheckQuestionnaire } from "../../../../../../../types/Schnellcheck";
import OutlineFrame from "../OutlineFrame";

export default function ListedBuildingComponent({ schnellcheck, bubbleFlow }: { schnellcheck?: SchnellcheckQuestionnaire; bubbleFlow?: BubbleFlow }) {
  if (!schnellcheck && !bubbleFlow) {
    return null;
  }
  const isListedBuilding = schnellcheck?.answers?.houseDetails?.isMonument; // This data point is not present in the Bubble data

  return (
    <OutlineFrame label={"Denkmalgeschütztes"}>
      <Typography>{isListedBuilding ? "Ja" : "Nein"}</Typography>
    </OutlineFrame>
  );
}
