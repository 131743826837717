import { FunctionComponent } from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Check } from "@mui/icons-material";

type ContinueCardListItemProps = {
  text: string;
};

export const ContinueCardListItem: FunctionComponent<ContinueCardListItemProps> = ({ text }) => {
  return (
    <>
      <ListItem sx={{ py: 0 }}>
        <ListItemIcon sx={{ color: "#ed702d" }}>
          <Check />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ fontSize: "16px", fontWeight: 400, color: "#000000" }}>{text}</ListItemText>
      </ListItem>
    </>
  );
};
