import { Checkbox, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { HouseType, SchnellcheckQuestionnaire } from "../../../../../../../types/Schnellcheck";
import HomeIcon from "@mui/icons-material/Home";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import HolidayVillageOutlinedIcon from "@mui/icons-material/HolidayVillageOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import { BubbleFlow } from "../../../../../../../types/cockpit/types";
import OutlineFrame from "../OutlineFrame";

export default function HouseTypeComponent({ schnellcheck, bubbleFlow }: { schnellcheck?: SchnellcheckQuestionnaire; bubbleFlow?: BubbleFlow }) {
  if (!schnellcheck && !bubbleFlow) {
    return null;
  }
  let selectedType = schnellcheck?.answers?.houseDetails?.type;
  if (!selectedType) {
    switch (bubbleFlow?.statusQuoResponse?.houseType) {
      case "Einfamilienhaus":
        selectedType = HouseType.singleFamily;
        break;
      case "Zweifamilienhaus":
        selectedType = HouseType.doubleFamily;
        break;
      case "Mehrfamilienhaus":
        selectedType = HouseType.multiFamily;
        break;
    }
  }
  return (
    <OutlineFrame label='House type'>
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
      >
        <Grid size={{ xs: 6, md: 4 }}>
          <Stack
            direction={"row"}
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, backgroundColor: selectedType === HouseType.singleFamily ? "#00000015" : "inherit", borderRadius: 2 }}
          >
            <Stack alignItems={"start"} spacing={1}>
              {selectedType === HouseType.singleFamily && <HomeIcon sx={{ width: 40, height: 40, alignSelf: "center" }} />}
              {selectedType !== HouseType.singleFamily && <HomeOutlinedIcon sx={{ width: 40, height: 40, alignSelf: "center" }} />}
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Einfamilienhaus
              </Typography>
            </Stack>
            <Checkbox disabled sx={{ flexGrow: 1, "&.Mui-checked": { color: "text.primary" } }} checked={selectedType === HouseType.singleFamily} />
          </Stack>
        </Grid>
        <Grid size={{ xs: 6, md: 4 }}>
          <Stack
            direction={"row"}
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, backgroundColor: selectedType === HouseType.doubleFamily ? "#00000015" : "inherit", borderRadius: 2 }}
          >
            <Stack alignItems={"start"} spacing={1}>
              {selectedType === HouseType.doubleFamily && <HolidayVillageIcon sx={{ width: 40, height: 40, alignSelf: "center" }} />}
              {selectedType !== HouseType.doubleFamily && <HolidayVillageOutlinedIcon sx={{ width: 40, height: 40, alignSelf: "center" }} />}
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Zweifamilienhaus
              </Typography>
            </Stack>
            <Checkbox disabled sx={{ flexGrow: 1, "&.Mui-checked": { color: "text.primary" } }} checked={selectedType === HouseType.doubleFamily} />
          </Stack>
        </Grid>
        <Grid size={{ xs: 6, md: 4 }}>
          <Stack
            direction={"row"}
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, backgroundColor: selectedType === HouseType.multiFamily ? "#00000015" : "inherit", borderRadius: 2 }}
          >
            <Stack alignItems={"start"} spacing={1}>
              {selectedType === HouseType.multiFamily && <ApartmentIcon sx={{ width: 40, height: 40, alignSelf: "center" }} />}
              {selectedType !== HouseType.multiFamily && <ApartmentOutlinedIcon sx={{ width: 40, height: 40, alignSelf: "center" }} />}
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Mehrfamilienhaus
              </Typography>
            </Stack>
            <Checkbox disabled sx={{ flexGrow: 1, "&.Mui-checked": { color: "text.primary" } }} checked={selectedType === HouseType.multiFamily} />
          </Stack>
        </Grid>
      </Grid>
    </OutlineFrame>
  );
}
