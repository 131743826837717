import { TableRow, TableCell, Typography, Accordion, AccordionSummary, AccordionDetails, TableContainer, Table, TableBody } from "@mui/material";
import { FunctionComponent } from "react";
import { TotalMetric } from "./types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NoMetricsFound } from "./utils";

type TotalMetricsProps = {
  title: string;
  metrics: TotalMetric[];
  displayZeros?: boolean;
};

const TotalMetrics: FunctionComponent<TotalMetricsProps> = ({ title, metrics }) => {
  const rows = [];

  for (const metric of metrics) {
    rows.push(
      <TableRow>
        <TableCell style={{ borderBottom: "none" }} align='right'>
          <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
            {metric.label}
          </Typography>
        </TableCell>
        <TableCell style={{ borderBottom: "none" }} align='right'>
          <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
            {metric.value.toLocaleString("de-DE")}
          </Typography>
        </TableCell>
      </TableRow>,
    );
  }

  return (
    <Accordion slotProps={{ transition: { unmountOnExit: true } }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='subtitle1' sx={{ fontWeight: 500, flexGrow: 1 }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table>
            <TableBody>{rows.length > 0 ? rows : <NoMetricsFound />}</TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default TotalMetrics;
