import Grid from "@mui/material/Grid2";
import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";
import OutlineFrame from "../OutlineFrame";
import { Typography } from "@mui/material";
import { QuestionComponent } from "../QuestionComponent";

export default function BoilerComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  const options = [
    {
      label: "Ja",
      selected: renovations?.answers?.hotWaterAndVentilationInformation?.hasWaterBoiler === "yes",
    },
    {
      label: "Nein",
      selected: renovations?.answers?.hotWaterAndVentilationInformation?.hasWaterBoiler === "no",
    },
    {
      label: "Nicht sicher",
      selected: renovations?.answers?.hotWaterAndVentilationInformation?.hasWaterBoiler === "notSure",
    },
  ];
  return (
    <Grid container spacing={4}>
      <Grid size={6}>
        <QuestionComponent question='Gibt es einen Warmwasserspeicher?' options={options} />
      </Grid>
      {renovations?.answers?.hotWaterAndVentilationInformation?.hasWaterBoiler === "yes" && (
        <Grid size={6}>
          <OutlineFrame label={"Speichervolumen in Litern"}>
            <Typography>{renovations?.answers?.hotWaterAndVentilationInformation?.boilerCapacity}</Typography>
          </OutlineFrame>
        </Grid>
      )}
      {renovations?.answers?.hotWaterAndVentilationInformation?.hasWaterBoiler !== "yes" && <Grid size={6} />}
    </Grid>
  );
}
