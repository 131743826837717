import { Typography } from "@mui/material";
import { BubbleFlow } from "../../../../../../../types/cockpit/types";
import { SchnellcheckQuestionnaire } from "../../../../../../../types/Schnellcheck";
import { getWindowLabel } from "../../../../../../funnels/isfp/statusquo/utils";
import OutlineFrame from "../OutlineFrame";

export default function WindowTypeComponent({ schnellcheck, bubbleFlow }: { schnellcheck?: SchnellcheckQuestionnaire; bubbleFlow?: BubbleFlow }) {
  if (!schnellcheck && !bubbleFlow) {
    return null;
  }
  const windowType = getWindowLabel(schnellcheck?.answers?.houseOpenings?.windows) || bubbleFlow?.statusQuoResponse?.windowRenovation;

  return (
    <OutlineFrame label={"Art des Fensters"}>
      <Typography>{windowType}</Typography>
    </OutlineFrame>
  );
}
