import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";
import OutlineFrame from "../OutlineFrame";
import { Typography } from "@mui/material";

export default function FloorsComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  return (
    <OutlineFrame label={"Anzahl der Geschosse"}>
      <Typography>{renovations?.answers?.houseConfiguration?.floors}</Typography>
    </OutlineFrame>
  );
}
