import { useCallback, useState } from "react";
import { IsfpProcess } from "../types/cockpit/types";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";

export const API_ENDPOINT = `${apiEndpoint()}/isfps`;

export function useFinalizeIsfpProcess() {
  const { authAxios, error } = useAuthAxios();

  const [updatedIsfpProcess, setUpdatedIsfpProcess] = useState<IsfpProcess>();
  const [isLoading, setIsLoading] = useState(false);
  const [finalizeError, setFinalizeError] = useState<unknown>();

  const finalizeIsfpProcess = useCallback(
    async (file: File, isfpProcessId: string) => {
      setIsLoading(true);
      setFinalizeError(undefined);

      const [_, extension] = file.name.split(".");
      const requestUri = `${API_ENDPOINT}/presigned-file-link`;

      const response = await authAxios({
        method: "POST",
        baseURL: requestUri,
        data: { extension },
      });
      if (error) {
        setFinalizeError(error);
        setIsLoading(false);
        return;
      }

      const { presignedLink, filename } = response as { presignedLink: string; filename: string };

      await authAxios({
        method: "PUT",
        baseURL: presignedLink,
        data: file,
        headers: {
          "Content-Type": file.type,
          "Access-Control-Allow-Origin": "*",
          maxBodyLength: Infinity,
          maxContentLength: Infinity,
          responseType: "json",
        },
      });
      if (error) {
        setFinalizeError(error);
        setIsLoading(false);
        return;
      }

      const finalizeIsfpProcessResponse = await authAxios({
        method: "POST",
        baseURL: `${API_ENDPOINT}/${isfpProcessId}/finalize`,
        data: { filename },
      });
      if (error) {
        setFinalizeError(error);
        setIsLoading(false);
        return;
      }

      setUpdatedIsfpProcess(finalizeIsfpProcessResponse as IsfpProcess);
    },
    [authAxios, error],
  );

  const resetState = () => {
    setFinalizeError(undefined);
    setIsLoading(false);
  };

  return { finalizeIsfpProcess, isLoading, updatedIsfpProcess, finalizeError, resetState };
}
