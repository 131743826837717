import { Product } from "./types";

export type Currency = "eur" | "usd";
export type DiscountOrigin = "voucher" | "bubble";

export type DiscountedAmount = {
  origin: DiscountOrigin;
  voucherId?: string;
  bubbleLeadId?: string;
} & Amount;

export type Amount = {
  amount: number;
  currency: string;
};

export type OrderItem = {
  id: string;
  product: Product;
  name: string;
  // full price of the item
  price: Amount;
  // discounted price of the item, if a discount was applied
  discountedPrice?: DiscountedAmount;
};

export enum OrderPaymentStatus {
  Paid = "paid",
  Canceled = "canceled",
  Failed = "failed",
  NoPaymentRequired = "no_payment_required",
  OtherNotReady = "other_not_ready",
}

export type PurchaseOrder = {
  id: string;
  items: OrderItem[];
  // total value of the order, and its payment status
  paymentData?: {
    paymentStatus?: OrderPaymentStatus;
    totalAmount: Amount;
  };
  lastUpdated?: number;
};
