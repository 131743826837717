import { FunctionComponent } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { formatEuros, formatNum } from "../../utils/utils";

type InvestmentTableProps = {
  savings: number;
  totalCost: number;
  subsidy: number;
};

export const InvestmentTable: FunctionComponent<InvestmentTableProps> = ({ savings, totalCost, subsidy }) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 350 }} size='small' aria-label='Investment Summary'>
          <TableBody>
            <TableRow>
              <TableCell>Total cost</TableCell>
              <TableCell align='right' />
              <TableCell align='right'>{formatEuros(totalCost)} €</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Subsidy</TableCell>
              <TableCell align='right'>BAFA & iSFP</TableCell>
              <TableCell align='right'>{formatEuros(subsidy)} €</TableCell>
            </TableRow>
            <TableRow style={{ backgroundColor: "gray" }}>
              <TableCell>Investment</TableCell>
              <TableCell align='right'>after deducting the funding</TableCell>
              <TableCell align='right'>{formatEuros(totalCost - subsidy)} €</TableCell>
            </TableRow>
            <TableRow style={{ backgroundColor: "green" }}>
              <TableCell>Financing</TableCell>
              <TableCell align='right'>with DZ Bank</TableCell>
              <TableCell align='right'>90% of total value at 3% interest</TableCell>
            </TableRow>
            <TableRow style={{ backgroundColor: "yellow" }}>
              <TableCell>Savings</TableCell>
              <TableCell align='right'>energy costs per year</TableCell>
              <TableCell align='right'>{formatNum(savings)}%</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
