import { FunctionComponent } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { Property } from "../../types/types";
import { formatEuros } from "../../utils/utils";

type RenovationTableProps = {
  property?: Property;
};

export const KfwRenovationTable: FunctionComponent<RenovationTableProps> = ({ property }) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 350 }} size='small' aria-label='Energy Efficiency'>
          <TableBody>
            <TableRow>
              <TableCell colSpan={3} style={{ backgroundColor: "black", color: "white" }}>
                Cost estimate
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Building envelope</TableCell>
              <TableCell align='right'>{formatEuros(property!.analysis.kfw85ee.costs.buildingEnvelopeCosts)} €</TableCell>
            </TableRow>
            {!!property!.analysis.kfw85ee.costs.airWaterHeatPumpCosts && (
              <TableRow>
                <TableCell>Air-water heat pump</TableCell>
                <TableCell align='right'>{formatEuros(property!.analysis.kfw85ee.costs.airWaterHeatPumpCosts)} €</TableCell>
              </TableRow>
            )}
            {!!property!.analysis.kfw85ee.costs.heatingOptimizationCosts && (
              <TableRow>
                <TableCell>Heating optimization</TableCell>
                <TableCell align='right'>{formatEuros(property!.analysis.kfw85ee.costs.heatingOptimizationCosts)} €</TableCell>
              </TableRow>
            )}
            {!!property!.analysis.kfw85ee.costs.ventilationSystemCosts && (
              <TableRow>
                <TableCell>Ventilation system with heat exchanger</TableCell>
                <TableCell align='right'>{formatEuros(property!.analysis.kfw85ee.costs.ventilationSystemCosts)} €</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>PV system</TableCell>
              <TableCell align='right'>{formatEuros(property!.analysis.kfw85ee.costs.pVSystemCosts)} €</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Planning costs (20%)</TableCell>
              <TableCell align='right'>{formatEuros(property!.analysis.kfw85ee.costs.planningCosts)} €</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
