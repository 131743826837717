import Grid from "@mui/material/Grid2";
import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";
import OutlineFrame from "../OutlineFrame";
import { Typography } from "@mui/material";
import InsulationAnswerComponent from "../InsulationAnswerComponent";

export default function RoofComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  const roofTypology = renovations?.answers?.atticOrRoofInformation?.roofTypology;
  let roofTypologyLabel;
  switch (roofTypology) {
    case "pitched":
      roofTypologyLabel = "Schrägdach";
      break;
    case "coldPitched":
      roofTypologyLabel = "Schrägdach (unbeheizt)";
      break;

    case "gabled":
      roofTypologyLabel = "Satteldach";
      break;

    case "flat":
      roofTypologyLabel = "Flachdach";
      break;
  }
  return (
    <Grid container spacing={4}>
      <Grid size={6}>
        <OutlineFrame label={"Roof Typology"}>
          <Typography>{roofTypologyLabel}</Typography>
        </OutlineFrame>
      </Grid>
      <Grid size={6} />
      {roofTypology === "pitched" && (
        <Grid size={12}>
          <InsulationAnswerComponent question='Ist das Dach gedämmt?' insulation={renovations?.answers?.atticOrRoofInformation?.pitchedRoofInsulation} />
        </Grid>
      )}
      {roofTypology === "coldPitched" && (
        <>
          <Grid size={12}>
            <InsulationAnswerComponent
              question='Sind die Dachschrägen gedämmt?'
              insulation={renovations?.answers?.atticOrRoofInformation?.coldPitchedRoofInsulation}
            />
          </Grid>
          <Grid size={12}>
            <InsulationAnswerComponent
              question='Ist die Oberste Geschossdecke gedämmt?'
              insulation={renovations?.answers?.atticOrRoofInformation?.coldPitchedCeilingInsulation}
            />
          </Grid>
        </>
      )}
      {roofTypology === "gabled" && (
        <Grid size={12}>
          <InsulationAnswerComponent question='Ist das Dach gedämmt?' insulation={renovations?.answers?.atticOrRoofInformation?.gabledRoofInsulation} />
        </Grid>
      )}
      {roofTypology === "flat" && (
        <Grid size={12}>
          <InsulationAnswerComponent question='Ist das Dach gedämmt?' insulation={renovations?.answers?.atticOrRoofInformation?.flatRoofInsulation} />
        </Grid>
      )}
    </Grid>
  );
}
