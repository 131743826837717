import Grid from "@mui/material/Grid2";
import { SectionProps } from "./ConsumptionCertificateRequestPage";
import {
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  Paper,
  PaperProps,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, ReactNode } from "react";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { de } from "date-fns/locale/de";
import { addDays } from "date-fns";

export default function CoolingSection({ data, unableToProceed, updateHandler }: SectionProps) {
  const handleSelectedChange = (id: (typeof data.coolingData.coolingOptions)[number], selected: boolean) => {
    data.coolingData.coolingTypes[id].selected = selected;
    if (!selected) {
      data.coolingData.coolingTypes[id].surface = undefined;
      data.coolingData.coolingTypes[id].error = false;
    }
    updateHandler(data);
  };

  const handleSurfaceChange = (id: (typeof data.coolingData.coolingOptions)[number], surface?: string) => {
    data.coolingData.coolingTypes[id].surface = surface;
    updateHandler(data);
  };

  const numberHandler = (event: ChangeEvent<HTMLInputElement>) => {
    data.coolingData.number = event.target.value;
    updateHandler(data);
  };

  const nominalOutputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    data.coolingData.nominalOutput = event.target.value;
    updateHandler(data);
  };

  const nextInspectionHandler = (newDate: Date) => {
    data.coolingData.nextInspection = newDate;
    updateHandler(data);
  };

  const isActiveCoolingSelected = () => {
    return (
      data.coolingData.coolingTypes["Kühlung aus Strom"].selected ||
      data.coolingData.coolingTypes["Kühlung aus Wärme"].selected ||
      data.coolingData.coolingTypes["Gelieferte Kälte"].selected
    );
  };

  const handleBuildingAutomationChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    data.coolingData.hasBuildingAutomation = checked;
    updateHandler(data);
  };

  return (
    <Grid container spacing={2}>
      {data.coolingData.coolingOptions.map((option, index) => (
        <Grid size={{ xs: 12, md: 6 }} key={index}>
          <CoolingSystem
            title={option}
            icon={<AcUnitIcon />}
            selected={data.coolingData.coolingTypes[option].selected}
            surface={data.coolingData.coolingTypes[option].surface}
            requiresSurface={option !== "Passive Kühlung"}
            maxSurface={data.buildingData.surface}
            disabled={unableToProceed}
            error={data.coolingData.coolingTypes[option].error}
            handleSurfaceChange={handleSurfaceChange}
            handleSelectedChange={handleSelectedChange}
          />
        </Grid>
      ))}
      {isActiveCoolingSelected() && (
        <>
          <Grid size={12}>
            <FormControlLabel
              control={<Checkbox required={false} value={data.coolingData.hasBuildingAutomation} onChange={handleBuildingAutomationChange} />}
              label='Die Kühlanlage verfügt über Gebäudeautomation'
              disabled={unableToProceed}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <FormControl fullWidth>
              <TextField
                data-cy='cooling-number-input'
                variant='outlined'
                id='cooling-number-input'
                value={data.coolingData.number}
                error={data.coolingData.numberError}
                type='number'
                label='Anzahl-Klimaanlagen'
                onChange={numberHandler}
                disabled={unableToProceed}
                required={isActiveCoolingSelected()}
                slotProps={{
                  htmlInput: { min: 0 },
                }}
              />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, md: 3 }}>
            <FormControl fullWidth>
              <TextField
                data-cy='cooling-output-input'
                variant='outlined'
                id='cooling-output-input'
                value={data.coolingData.nominalOutput}
                error={data.coolingData.nominalOutputError}
                type='number'
                label='kW'
                onChange={nominalOutputHandler}
                disabled={unableToProceed}
                required={isActiveCoolingSelected()}
                slotProps={{
                  htmlInput: { min: 0 },
                }}
              />
            </FormControl>
            <FormHelperText id='next-inspection-due-date'>Nennleistung</FormHelperText>
          </Grid>
          <Grid size={{ xs: 12, md: 5 }}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                <DatePicker
                  label='Fälligkeitsdatum'
                  data-cy='cooling-next-inspection'
                  value={data.coolingData.nextInspection}
                  disabled={unableToProceed}
                  onChange={nextInspectionHandler}
                  minDate={addDays(new Date(), 1)}
                  slotProps={{
                    textField: {
                      required:
                        isActiveCoolingSelected() &&
                        (+data.coolingData.nominalOutput > 70 || (+data.coolingData.nominalOutput > 12 && !data.coolingData.hasBuildingAutomation)),
                      error: data.coolingData.nextInspectionError,
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
            <FormHelperText id='next-inspection-due-date'>Nächstes Fälligkeitsdatum der Inspektion</FormHelperText>
          </Grid>
        </>
      )}
    </Grid>
  );
}

interface CoolingSystemProps extends PaperProps {
  title: string;
  icon: ReactNode;
  selected: boolean;
  surface?: string;
  requiresSurface?: boolean;
  maxSurface?: string;
  disabled?: boolean;
  error?: boolean;
  handleSelectedChange: (id: string, selected: boolean) => void;
  handleSurfaceChange: (id: string, surface?: string) => void;
}
function CoolingSystem({
  title,
  disabled,
  icon,
  selected,
  surface = "",
  requiresSurface,
  maxSurface,
  error,
  handleSelectedChange,
  handleSurfaceChange,
}: CoolingSystemProps) {
  const toggleSelected = () => !disabled && handleSelectedChange(title, !selected);
  return (
    <Stack
      component={Paper}
      spacing={1}
      alignItems='flex-start'
      sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", backgroundColor: !selected ? "#f8f8f8" : "inherit" }}
      onClick={toggleSelected}
    >
      <Stack alignItems={"center"} spacing={2} direction='row' width='100%'>
        {icon}
        <Stack flexGrow={2} direction='row' spacing={1}>
          <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium" }}>
            {title}
          </Typography>
        </Stack>
        <Stack sx={{ flexGrow: 1, alignItems: "flex-end" }}>
          <Checkbox
            data-cy={`${title.replace(/\s/g, "-")}-input`}
            sx={{ "&.Mui-checked": { color: "text.primary" } }}
            onChange={toggleSelected}
            checked={selected}
            disabled={disabled}
          />
        </Stack>
      </Stack>
      {requiresSurface &&
        selected && ( // only show surface input if required and selected
          <Collapse orientation='horizontal' in={selected} sx={{ width: "100%", "& .MuiCollapse-wrapperInner": { width: "100%" } }}>
            <FormControl
              fullWidth
              onClick={(evt) => {
                evt.stopPropagation();
              }}
            >
              <TextField
                fullWidth
                variant='outlined'
                id={`${title.replace(/\s/g, "-")}-surface-input`}
                data-cy={`${title.replace(/\s/g, "-")}-surface-input`}
                value={surface}
                label='Fläche'
                onChange={(event) => handleSurfaceChange(title, event.target.value)}
                required={true}
                disabled={disabled}
                type='number'
                error={error}
                slotProps={{
                  input: { endAdornment: <InputAdornment position='end'>m²</InputAdornment> },
                  htmlInput: { min: 0, max: maxSurface },
                  inputLabel: { shrink: true },
                }}
              />
            </FormControl>
          </Collapse>
        )}
    </Stack>
  );
}
