import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FunctionComponent } from "react";
import novo from "../../imgs/novo_banner.jpeg";
import { Button } from "@mui/material";
import ConsentSnackbar from "./Consent";

export const SPLASH = "splash";

type SplashProps = {
  question: number;
  setNextQuestion: (questionNbr: number) => void;
};

export const StepsSplash: FunctionComponent<SplashProps> = ({ setNextQuestion }) => {
  return (
    <>
      <Stack spacing={0} alignItems='center' style={{ width: "100%" }}>
        <img src={novo} className='Splash-image' />
        <Stack justifyContent='center' paddingTop={"1vh"} paddingLeft={"5vw"} paddingRight={"5vw"}>
          <Typography style={{ fontSize: "calc(4px + 4vw)", fontWeight: 600 }} color='#e18931'>
            Energieberatung online - jetzt loslegen!
          </Typography>
          <Typography style={{ fontSize: "calc(4px + 3vw)", fontWeight: 400 }} color='#707172'>
            <ul>
              <li>Schritt 1: Basis-Informationen zu Ihrem Haus</li>
              <li>Schritt 2: Dämmung</li>
              <li>Schritt 3: Fenster & Türen</li>
              <li>Ergebnis: Ihr Energiestatus</li>
            </ul>
          </Typography>
          <Stack alignItems={"center"}>
            <Button variant='contained' style={{ width: "fit-content" }} onClick={() => setNextQuestion(1)} data-cy='start-process-btn'>
              <Typography style={{ fontSize: "calc(4px + 2vw)" }}>Starten Sie den kostenlosen Schnellcheck</Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <ConsentSnackbar />
    </>
  );
};
