import { useMemo, useState } from "react";
import { AuthError, AuthErrorType } from "../types/Auth";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { cognitoClientId } from "../utils/params";
import { cognitoPoolId } from "../utils/params";

export function useResetPasswordWithCode() {
  const userPool = useMemo(() => new CognitoUserPool({ ClientId: cognitoClientId(), UserPoolId: cognitoPoolId() }), []);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AuthError | undefined>();
  const [resetSuccessful, setResetSuccessful] = useState<{ username: string } | undefined>(undefined);

  const resetPassword = async (username: string, verificationCode: string, password: string) => {
    setIsLoading(true);
    setResetSuccessful(undefined);
    setError(undefined);
    const user = new CognitoUser({ Pool: userPool, Username: username });
    await new Promise((resolve, reject) => {
      user.confirmPassword(verificationCode, password, {
        onSuccess: (success) => {
          setResetSuccessful({ username });
          resolve(success);
        },
        onFailure: (err) => {
          switch (err.name) {
            case "ExpiredCodeException":
              setError({ type: AuthErrorType.FAILED_RESET_PASSWORD_EXPIRED_CODE });
              break;
            case "LimitExceededException":
              setError({ type: AuthErrorType.FAILED_RESET_PASSWORD_LIMIT_EXCEEDED });
              break;
            case "CodeMismatchException":
              setError({ type: AuthErrorType.FAILED_RESET_PASSWORD_INCORRECT_CODE });
              break;
            default:
              setError({ type: AuthErrorType.FAILED_RESETTING_PASSWORD });
          }
          return reject(err);
        },
      });
    }).finally(() => {
      setIsLoading(false);
    });
  };
  return { isLoading, resetSuccessful, error, resetPassword };
}
