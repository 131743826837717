import { UserResponse, UserRoles } from "../types/cockpit/types";
import { AuthErrorType } from "../types/Auth";

export const isAdmin = (user?: UserResponse) => {
  return user?.groups?.includes(UserRoles.admin);
};

export const isConsultant = (user?: UserResponse) => {
  return user?.groups?.includes(UserRoles.consultant);
};

export const isBankManager = (user?: UserResponse) => {
  return user?.groups?.includes(UserRoles.bankManager);
};

type AuthzPolicy = {
  [path: string]: UserRoles[];
};

export const isCockpitPathAuthorized = (path: string, user?: UserResponse) => {
  const relevantPath = path.replace("/cockpit/", "").split("/")[0];
  const policyPath = `/${relevantPath}`;
  const policy: AuthzPolicy = {
    "/leads": [UserRoles.consultant, UserRoles.bankManager, UserRoles.admin],
    "/invites": [UserRoles.consultant, UserRoles.bankManager, UserRoles.admin],
    "/academy": [UserRoles.consultant, UserRoles.bankManager, UserRoles.admin],
    "/consultants": [UserRoles.bankManager, UserRoles.admin],
    "/metrics": [UserRoles.admin],
    "/isfps": [UserRoles.admin],
    "/consumption-certificates": [UserRoles.admin],
    "/one-pager": [UserRoles.admin],
  };
  return user?.groups?.map((role) => policy[policyPath]?.includes(role)).reduce((acc, cur) => acc || cur);
};

export const authErrorTypeToErrorText = (errorType: AuthErrorType) => {
  switch (errorType) {
    case AuthErrorType.MISSING_MANDATORY_PASSWORD_CHANGE_DATA:
      return "Die Daten für die obligatorische Passwortänderung fehlen. Bitte versuche es erneut mit dem Login.";
    case AuthErrorType.FAILED_FETCHING_USER:
      return "Deine Benutzerdaten konnten nicht aus unserem System abgerufen werden.";
    case AuthErrorType.FAILED_AUTHENTICATING:
      return "Authentifizierungsfehler.";
    case AuthErrorType.FAILED_CHANGING_PASSWORD:
      return "Wegen eines technischen Fehlers konnte dein Passwort nicht geändert werden.";
    case AuthErrorType.FAILED_ALREADY_CHANGED_PASSWORD:
      return "Dein Passwort wurde bereits geändert.";
    case AuthErrorType.CURRENT_PASSWORD_IS_CORRECT:
      return "Dein aktuelles Passwort stimmt schon, keine Änderungen sind nötig";
    case AuthErrorType.WRONG_CREDENTIALS:
      return "Dein Benutzername oder dein Passwort stimmen nicht.";
    case AuthErrorType.ACCOUNT_CREATION_USERNAME_ALREADY_EXISTS:
      return "Dieser Benutzername existiert bereits. Bitte versuche dich anzumelden.";
    case AuthErrorType.FAILED_CREATING_NEW_USER:
      return "Das Erstellen deines neuen Accounts ist fehlgeschlagen. Bitte versuche es erneut oder kontaktiere uns.";
    case AuthErrorType.FAILED_CONFIRMING_ACCOUNT:
      return "Fehler bei der Bestäting deines Accounts.";
    case AuthErrorType.ACCOUNT_CREATION_UNRECOGNIZED_EMAIL:
      return "Stelle sicher, dass du dich mit deinem Bankberater-E-Mail-Account registrierst.";
    case AuthErrorType.ACCOUNT_CREATION_INVALID_PARAMETER:
      return "Einer der Eingabewerte ist nicht korrekt. Bitte versuche es erneut.";
    case AuthErrorType.FAILED_CONFIRMING_ACCOUNT_WRONG_CODE:
      return "Ungültiger Bestätigungscode eingegeben.";
    case AuthErrorType.FAILED_CONFIRMING_ACCOUNT_POSSIBLY_ALREADY_CONFIRMED:
      return "Fehler: Dein Account ist wahrscheinlich bereits aktiviert.";
    case AuthErrorType.FAILED_SENDING_FORGOT_PASSWORD_CODE:
      return "Es ist ein Fehler aufgetreten. Bitte versuche es erneut.";
    case AuthErrorType.FAILED_RESETTING_PASSWORD:
      return "Fehler beim Ändern deines Passworts. Bitte versuche es erneut.";
    case AuthErrorType.FAILED_RESET_PASSWORD_INCORRECT_CODE:
      return "Ungültiger Reset-Code eingegeben.";
    case AuthErrorType.FAILED_SENDING_FORGOT_PASSWORD_CODE_USER_INCORRECT:
      return "Ungültige E-Mail-Adresse. Hast du bereits ein Konto bei uns?";
    case AuthErrorType.FAILED_RESET_PASSWORD_LIMIT_EXCEEDED:
      return "Fehler: Dein Passwort wurde vor kurzem geändert. Wende dich bitte an uns unter hi@buildingnovo.com.";
    case AuthErrorType.FAILED_RESET_PASSWORD_EXPIRED_CODE:
      return "Fehler: Dieser Reset-Code wurde bereits verwendet.";
    default:
      return "Es ist leider ein Fehler aufgetreten. Bitte versuche es erneut.";
  }
};
