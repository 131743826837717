import { Avatar, Button, Skeleton, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import useGetReferrerInfo from "../../../../hooks/useGetReferrerInfo";

export default function IntroductionScreen({ next, referrerCode }: { next: () => void; referrerCode: string }) {
  const { getReferrerInfo, referrerInfoError, referrerInfo } = useGetReferrerInfo();
  const [berater, setBerater] = useState("Ein Energieberater");
  const [hasPicture, setHasPicture] = useState(false);

  useEffect(() => {
    getReferrerInfo(referrerCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (referrerInfo) {
      setBerater(`${referrerInfo?.name} von der ${referrerInfo?.tenant.displayName}`);
      setHasPicture(referrerInfo?.tenant.name === "bsh");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referrerInfo]);

  const intro = () => {
    return (
      <>
        <Grid container>
          <Grid size={hasPicture ? 2 : 0} alignSelf='center'>
            {hasPicture && (
              <Avatar
                sx={{ width: 150, height: 150 }}
                alt={referrerInfo!.name}
                src={`https://${referrerInfo!.cdnUrl}/bsh-consultants-pictures/${referrerInfo!.email}-profile.jpg`}
              />
            )}
          </Grid>
          <Grid size={hasPicture ? 10 : 12}>
            <Typography>Hallo,</Typography>
            <Typography mt={4}>
              {berater} lädt Sie zu einem kostenfreien Energie-Check für Ihr Zuhause ein: dem ersten Schritt zum individuellen Sanierungsfahrplan (iSFP) für Ihr
              Haus.
            </Typography>
            <Typography mt={4}>
              Erfahren Sie, wie Sie die Energieeffizienz Ihres Hauses maximieren können, mit der Hilfe der Energieeffizienzexperten von NOVO.
            </Typography>
          </Grid>
        </Grid>
        <Stack direction='row' spacing={2} sx={{ mt: 3, justifyContent: "center", flexGrow: 1 }}>
          <Button variant='contained' onClick={next}>
            Jetzt kostenlos starten »
          </Button>
        </Stack>
      </>
    );
  };

  return (
    <Grid
      container
      rowSpacing={{
        xs: 2,
        lg: 4,
      }}
      columnSpacing={{
        xs: 0,
        lg: 8,
      }}
      sx={{
        mx: { xs: 0, lg: 10 },
        mb: 4,
        maxWidth: "100%",
        alignItems: "center",
      }}
    >
      {(referrerInfoError || referrerInfo) && intro()}
      {!(referrerInfoError || referrerInfo) && <Skeleton>{intro()}</Skeleton>}
    </Grid>
  );
}
