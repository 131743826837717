import { useCallback } from "react";
import { useUnauthAxios } from "./useUnauthAxios";
import { apiEndpoint } from "../utils/params";
import { MailingSettings } from "./useGetMailingSettings";

export const usePutMailingSettings = () => {
  const { unauthAxios, error, running, data } = useUnauthAxios<MailingSettings>();

  const putMailingSettings = useCallback(
    async (payload: MailingSettings, leadId?: string) => {
      await unauthAxios({
        method: "PUT",
        url: `${apiEndpoint()}/mailing-preferences${leadId ? `?leadId=${leadId}` : ""}`,
        data: payload,
      });
    },
    [unauthAxios],
  );

  return { putMailingSettings, error, running, data };
};
