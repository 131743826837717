import axios from "axios";
import { useState } from "react";
import { v4 } from "uuid";
import { ConsumpCert, ConsumptionCertificateState } from "../types/ConsumptionCertificateState";
import { apiEndpoint } from "../utils/params";

export const API_ENDPOINT = `${apiEndpoint()}/consumption-certificate`;

export function useCreateConsumptionCertificate() {
  const [createdCertificate, setCreatedCertificate] = useState<ConsumpCert>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createConsumptionCertificate = async (answers: ConsumptionCertificateState, params: { lead?: string; leadId?: string; voucherId?: string }) => {
    setIsLoading(true);

    if (answers.photo) {
      const uuid = v4();
      const [name, extension] = answers.photo.name.split(".");
      const fileName = `${name}-${uuid.slice(0, 7)}.${extension}`;
      const requestUri = `${API_ENDPOINT}/photo-upload-link?photoId=${fileName}`;
      const response = await axios.get(requestUri, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const presignedLink = response.data.presignedLink;
      answers.photoId = fileName;
      await axios.put(presignedLink, answers.photo, {
        headers: {
          "Content-Type": answers.photo.type,
          "Access-Control-Allow-Origin": "*",
          maxBodyLength: Infinity,
          maxContentLength: Infinity,
          responseType: "json",
        },
      });
    }

    const body = {
      answers,
      bubbleLeadId: params.lead,
      leadId: params.leadId,
    };

    const createResponse = await axios.post(API_ENDPOINT, JSON.stringify(body), {
      headers: {
        "Content-Type": "application/json",
      },
    });

    setCreatedCertificate({ id: createResponse.data.id });
    setIsLoading(false);
  };

  return { createConsumptionCertificate, isLoading, createdCertificate };
}
