import { Typography } from "@mui/material";
import { RowDetailProps } from "./types";
import { formatDate } from "date-fns";

export default function IsfpInvited({ row }: RowDetailProps) {
  return (
    <Typography>
      Kunde wurde{" "}
      {row.eventTimestamps?.invitedOn ? (
        <>
          {" "}
          am <i>{formatDate(row.eventTimestamps?.invitedOn, "dd.MM.yyyy")} </i>
        </>
      ) : (
        ""
      )}{" "}
      zum Schnellcheck eingeladen
    </Typography>
  );
}
