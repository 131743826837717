import { Typography } from "@mui/material";
import { BubbleFlow } from "../../../../../../../types/cockpit/types";
import { SchnellcheckQuestionnaire } from "../../../../../../../types/Schnellcheck";
import OutlineFrame from "../OutlineFrame";

export default function DoorYearComponent({ schnellcheck, bubbleFlow }: { schnellcheck?: SchnellcheckQuestionnaire; bubbleFlow?: BubbleFlow }) {
  if (!schnellcheck && !bubbleFlow) {
    return null;
  }
  const heatingInstallationYear = schnellcheck?.answers?.houseOpenings?.doorsYear || bubbleFlow?.statusQuoResponse?.doorRenovation;

  return (
    <OutlineFrame label={"Jahr der Tür"}>
      <Typography>{heatingInstallationYear}</Typography>
    </OutlineFrame>
  );
}
