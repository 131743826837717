import { Component, ErrorInfo, ReactNode } from "react";
import { retrieveRum } from "./AwsRum";
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid2";
import { Stack } from "@mui/material";
import PropTypes from "prop-types";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public static propTypes = { children: PropTypes.any };

  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    retrieveRum()?.recordError(error);
    retrieveRum()?.dispatch();
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Grid container direction='column' alignItems='center' justifyContent='center' sx={{ minHeight: "100vh" }}>
          <Stack spacing={4} alignItems='center' maxWidth={520}>
            <ErrorIcon />
            <Typography alignSelf='start'>
              Beim Rendern der Seite ist ein Fehler aufgetreten.
              <Typography>
                Bitte deaktivieren Sie sämtliche Übersetzungs-Plugins, da diese auch die ordnungsgemäße Funktion dieser App beeinträchtigen können.
              </Typography>
            </Typography>
            <Typography alignSelf='start'>
              Wir arbeiten daran, das Problem so schnell wie möglich zu beheben.
              <Typography>
                Sie können uns unter <Link href='mailto: hi@buildingnovo.com'>hi@buildingnovo.com</Link> kontaktieren.
              </Typography>
            </Typography>
          </Stack>
        </Grid>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
