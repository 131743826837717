import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Alert, Button, FormControlLabel, MobileStepper, Radio, RadioGroup, Snackbar, TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { HOUSE_DETAILS } from "./storage";
import useUpsertQuestionnaire from "../../hooks/useUpsertQuestionnaire";

type HouseDetailsProps = {
  question: number;
  setNextQuestion: (questionNbr: number) => void;
};

type HouseDetailsState = {
  yearOfConstruction?: number;
  heatingSystemType?: string;
  area?: number;
};

export const HouseDetails: FunctionComponent<HouseDetailsProps> = ({ question, setNextQuestion }) => {
  const [upsertQuestionnaire] = useUpsertQuestionnaire();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const intl = useIntl();
  const [houseDetails, setHouseDetails] = useState<HouseDetailsState>();

  function shouldNextStepBeDisabled(newHouseDetails: HouseDetailsState) {
    return (
      newHouseDetails.heatingSystemType === undefined ||
      newHouseDetails.area === undefined ||
      newHouseDetails.yearOfConstruction === undefined ||
      newHouseDetails.yearOfConstruction > 2001
    );
  }

  function yearOfConstructionOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newHouseDetails = {
      ...houseDetails,
      yearOfConstruction: e.target.value ? parseInt(e.target.value) : undefined,
    };
    setDisabled(shouldNextStepBeDisabled(newHouseDetails));
    setHouseDetails(newHouseDetails);
    localStorage.setItem(HOUSE_DETAILS, JSON.stringify(newHouseDetails));
    if (newHouseDetails.yearOfConstruction && newHouseDetails.yearOfConstruction > 2001) {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
    }
  }

  function heatingSystemTypeOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newHouseDetails = {
      ...houseDetails,
      heatingSystemType: e.target.value,
    };
    setDisabled(shouldNextStepBeDisabled(newHouseDetails));
    setHouseDetails(newHouseDetails);
    localStorage.setItem(HOUSE_DETAILS, JSON.stringify(newHouseDetails));
  }

  function areaOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newHouseDetails = {
      ...houseDetails,
      area: e.target.value ? parseInt(e.target.value) : undefined,
    };
    setDisabled(shouldNextStepBeDisabled(newHouseDetails));
    setHouseDetails(newHouseDetails);
    localStorage.setItem(HOUSE_DETAILS, JSON.stringify(newHouseDetails));
  }

  function houseDetailsNextOnClick() {
    setNextQuestion(2);
    upsertQuestionnaire();
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setShowErrorMessage(false);
  };

  useEffect(() => {
    // Local Storage
    const answer = localStorage.getItem(HOUSE_DETAILS);
    if (answer) {
      const parsedAnswer = JSON.parse(answer);
      setHouseDetails(parsedAnswer);
      setDisabled(shouldNextStepBeDisabled(parsedAnswer));
    }
  }, []);

  return (
    <>
      <Stack spacing={2} alignItems='left' style={{ width: "100%" }} p={3}>
        <Typography component='h2' style={{ fontSize: 20, fontWeight: 600, padding: 10 }}>
          <FormattedMessage id='app.house-details.title' />
        </Typography>

        <TextField
          size='small'
          id='yearOfConstruction'
          label={intl.formatMessage({ id: "app.add-property.year" })}
          variant='outlined'
          value={houseDetails?.yearOfConstruction}
          onChange={yearOfConstructionOnChange}
          required={true}
          fullWidth
          data-cy='year-of-construction-input'
        />
        <TextField
          size='small'
          id='area'
          label={intl.formatMessage({ id: "app.add-property.area" })}
          variant='outlined'
          value={houseDetails?.area}
          onChange={areaOnChange}
          required={true}
          fullWidth
          data-cy='area-input'
        />

        <Typography variant='subtitle1' style={{ fontSize: 20 }}>
          <FormattedMessage id='app.house-details.heating' />
        </Typography>
        <RadioGroup name='heating-system-type' onChange={heatingSystemTypeOnChange} value={houseDetails?.heatingSystemType} row>
          <FormControlLabel
            value='gas'
            label={<Typography sx={{ fontSize: 18 }}>Gas</Typography>}
            control={<Radio size='small' data-cy='gas-option' checked={houseDetails?.heatingSystemType === "gas"} />}
          />
          <FormControlLabel
            value='oil'
            label={<Typography sx={{ fontSize: 18 }}>Öl</Typography>}
            control={<Radio size='small' data-cy='oil-option' checked={houseDetails?.heatingSystemType === "oil"} />}
          />
          <FormControlLabel
            value='woodPellets'
            label={<Typography sx={{ fontSize: 18 }}>Holz (pellets)</Typography>}
            control={<Radio size='small' data-cy='wood-pellets-option' checked={houseDetails?.heatingSystemType === "woodPellets"} />}
          />
          <FormControlLabel
            value='electricity'
            label={<Typography sx={{ fontSize: 18 }}>Strom</Typography>}
            control={<Radio size='small' data-cy='electricity-option' checked={houseDetails?.heatingSystemType === "electricity"} />}
          />
        </RadioGroup>
        <Snackbar open={showErrorMessage} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={handleClose}>
          <Alert onClose={handleClose} severity='error' sx={{ width: "100%" }}>
            Wir unterstützen Baujahr nur bis 2001.
          </Alert>
        </Snackbar>
        <MobileStepper
          variant='dots'
          steps={6}
          activeStep={question}
          position='top'
          sx={{ flexGrow: 1, position: "relative", alignItems: "center", width: "100%" }}
          nextButton={
            <Button variant='contained' size='small' data-cy='house-details-next-btn' onClick={() => houseDetailsNextOnClick()} disabled={disabled}>
              <FormattedMessage id='app.house-details.next' /> <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button variant='contained' size='small' onClick={() => setNextQuestion(0)}>
              <KeyboardArrowLeft /> <FormattedMessage id='app.house-details.back' />
            </Button>
          }
        />
      </Stack>
    </>
  );
};
