import { FunctionComponent } from "react";
import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { convertClassToColor, formatEuros, formatNum } from "../../utils/utils";
import { useIntl } from "react-intl";
import HelpIcon from "@mui/icons-material/Help";

type EnergyClass = {
  energyClass: string;
  cO2Emission: number;
  primaryEnergy: number;
  finalEnergy: number;
  energyRequirement: number;
  energyConsumption: number;
  costPerYear: number;
};

type EnergyClassTableProps = {
  before: EnergyClass;
  after: EnergyClass;
};

export const EnergyClassTable: FunctionComponent<EnergyClassTableProps> = (props: EnergyClassTableProps) => {
  const intl = useIntl();

  return (
    <>
      <TableContainer component={Paper} style={{ maxWidth: 500 }}>
        <Table sx={{ minWidth: 350 }} size='small' aria-label='Energy Efficiency'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align='right'>Before</TableCell>
              <TableCell align='right'>After</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{ backgroundColor: "black", color: "white" }}>Energy class</TableCell>
              <TableCell align='center' style={{ backgroundColor: `${convertClassToColor(props.before.energyClass)}`, color: "black" }}>
                {props.before.energyClass}
              </TableCell>
              <TableCell align='center' style={{ backgroundColor: `${convertClassToColor(props.after.energyClass)}`, color: "black" }}>
                {props.after.energyClass}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CO2 emission</TableCell>
              <TableCell align='right'>{formatNum(props.before.cO2Emission)} kg</TableCell>
              <TableCell align='right'>{formatNum(props.after.cO2Emission)} kg</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Primary energy</TableCell>
              <TableCell align='right'>{formatNum(props.before.primaryEnergy)} kWh/m2</TableCell>
              <TableCell align='right'>{formatNum(props.after.primaryEnergy)} kWh/m2</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Final energy</TableCell>
              <TableCell align='right'>{formatNum(props.before.finalEnergy)} kWh/m2</TableCell>
              <TableCell align='right'>{formatNum(props.after.finalEnergy)} kWh/m2</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Energy requirement</TableCell>
              <TableCell align='right'>{formatNum(props.before.energyRequirement)} kWh</TableCell>
              <TableCell align='right'>{formatNum(props.after.energyRequirement)} kWh</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Stack direction='row' justifyContent='right'>
                  Energy consumption &nbsp;
                  <Tooltip title={intl.formatMessage({ id: "app.energy.consumption.tooltip" })}>
                    <HelpIcon fontSize='small' />
                  </Tooltip>
                </Stack>
              </TableCell>
              <TableCell align='right'>{formatNum(props.before.energyConsumption)} kWh</TableCell>
              <TableCell align='right'>{formatNum(props.after.energyConsumption)} kWh</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cost per year</TableCell>
              <TableCell align='right'>{formatEuros(props.before.costPerYear)} €</TableCell>
              <TableCell align='right'>{formatEuros(props.after.costPerYear)} €</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
