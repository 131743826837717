import { Typography } from "@mui/material";
import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";
import OutlineFrame from "../OutlineFrame";

export function WindowsYearComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  return (
    <OutlineFrame label={"Baujahr Fenster"}>
      <Typography>{renovations?.answers?.windowsAndDoorsInformation?.windowInstallationYear}</Typography>
    </OutlineFrame>
  );
}
