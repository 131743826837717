import { FunctionComponent } from "react";
import { Paper } from "@mui/material";
import { convertClassToColor } from "../../utils/utils";

type ReportEnergyClassCardProps = {
  energyClass: string;
};

export const ReportEnergyClassCard: FunctionComponent<ReportEnergyClassCardProps> = ({ energyClass, ...props }) => {
  return (
    <>
      <Paper
        sx={{
          backgroundColor: convertClassToColor(energyClass),
          borderRadius: "10px",
          display: "baseline",
          textAlign: "center",
          fontSize: {
            xs: 20,
            sm: 30,
          },
          fontWeight: 700,
          paddingY: 0.5,
          paddingX: 2,
        }}
        {...props}
      >
        {energyClass}
      </Paper>
    </>
  );
};
