import { useCallback } from "react";
import { IsfpProcess } from "../types/cockpit/types";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";

export const API_ENDPOINT = `${apiEndpoint()}/isfps`;

export default function useFetchIsfpProcesses() {
  const { authAxios, error, running, data } = useAuthAxios<IsfpProcess[]>();

  const fetchIsfpProcesses = useCallback(
    async () =>
      await authAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}`,
      }),
    [authAxios],
  );

  return { fetchIsfpProcesses, error, isLoading: running, isfpProcesses: data };
}
