import { Typography } from "@mui/material";
import { RowDetailProps } from "./types";
import { formatDate } from "date-fns";

export default function IsfpOfferAccepted({ row }: RowDetailProps) {
  return (
    <Typography>
      Angebot wurde vom Kunden{" "}
      {row.eventTimestamps?.offerAcceptOrRejectOn ? (
        <>
          {" "}
          am <i>{formatDate(row.eventTimestamps?.offerAcceptOrRejectOn, "dd.MM.yyyy")} </i>
        </>
      ) : (
        ""
      )}
      akzeptiert. {row.schnellCheckId && "Eine E-Mail mit Link zum iSFP-Fragebogen wurde geschickt."}
    </Typography>
  );
}
