import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Button, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Status } from "../../../components/one-pager/Status";
import { PropertyInfoTable } from "../../../components/one-pager/PropertyInfoTable";
import useFetchSingleProperty from "../../../hooks/useFetchSingleProperty";
import { EnergyStatusTable } from "../../../components/property/EnergyStatusTable";
import { OwnerStatusTable } from "../../../components/property/OwnerStatusTable";
import { FormattedMessage } from "react-intl";

export default function Property() {
  const { property, isLoading } = useFetchSingleProperty();
  const navigate = useNavigate();

  if (property === undefined) {
    return <></>;
  }

  return (
    <>
      <Grid container justifyContent='center' padding={5}>
        {isLoading && <CircularProgress />}
        {!isLoading && (
          <Stack spacing={4} alignItems='center'>
            <Typography variant='h4' align='center' style={{ fontWeight: 600 }}>
              {property!.property.address}
            </Typography>
            <Stack direction='row' spacing={8} justifyContent='center' alignItems='center'>
              <Status title={"Status quo"} {...property!.analysis.status} />

              <Stack spacing={4} alignItems='left'>
                <Button variant='contained' onClick={() => navigate("kfw")} sx={{ paddingY: 1.5 }}>
                  <FormattedMessage id='app.property.analysis' />
                </Button>
                <Button variant='contained' onClick={() => navigate("sanierungspflicht")} sx={{ paddingY: 1.5 }}>
                  <FormattedMessage id='app.property.obligation-analysis' />
                </Button>
              </Stack>
            </Stack>
            <OwnerStatusTable {...property!} />
            <Stack direction='row' spacing={4} justifyContent='center' alignItems='center'>
              <EnergyStatusTable {...property!} />
              <PropertyInfoTable {...property!.property} />
            </Stack>
          </Stack>
        )}
      </Grid>
    </>
  );
}
