import { useCallback } from "react";
import { GuidedToursState } from "../types/types";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";

const API_ENDPOINT = `${apiEndpoint()}/user/guided-tours`;
const headers = { "Content-Type": "application/json" };

export function useUpsertGuidedTourState() {
  const { authAxios, error, running, data: response } = useAuthAxios<GuidedToursState>();

  const upsertGuidedToursState = useCallback(
    async (request: Partial<GuidedToursState>) => {
      await authAxios({
        method: "PUT",
        url: API_ENDPOINT,
        data: request,
        headers,
      });
    },
    [authAxios],
  );

  return { upsertGuidedToursState, error, running, response };
}
