import LaunchIcon from "@mui/icons-material/Launch";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { SetStateAction, Dispatch, useEffect, useState } from "react";
import { useGetConsumptionCertPdfLink } from "../../../../../hooks/useGetConsumptionCertPdfLink";
import { CCState, Product } from "../../../../../types/cockpit/types";
import { DisplayConsumptionCertificateDialog } from "../DisplayConsumptionCertificateDialog";
import { RowProps } from "../LeadList";
import Box from "@mui/material/Box";
import { EXAMPLE_CC_ID_PREFIX, SAMPLE_CC_DOCUMENT_URL } from "../PlaceholderLeadData";

export default function CCWorkflow({ row, openInviteLeadModal }: { row: RowProps; openInviteLeadModal: (product: Product) => void }) {
  const [actionTriggered, setActionTriggered] = useState(false);

  const cursorStyle = {
    cursor: row.consultantDataReviewConsent !== false && [CCState.SUBMITTED, CCState.FINAL].includes(row.cc.state) ? "pointer" : undefined,
  };

  const renderStates = () => {
    return (
      <Stack direction={"row"} alignItems={"center"} alignSelf={"flex-end"} onClick={() => setActionTriggered(true)} sx={cursorStyle}>
        <Stack>
          <Typography sx={{ fontWeight: "fontWeightBold", fontSize: "0.6rem", color: "text.secondary", ml: 1 }}>Aktueller Status</Typography>
          <Chip label={CCState.toString(row.cc.state)} />
        </Stack>
        {row.cc.state !== CCState.FINAL && <NavigateNextIcon sx={{ alignSelf: "flex-end", mb: "0.25rem" }} />}
        {row.cc.state !== CCState.FINAL && (
          <Stack>
            <Typography sx={{ fontWeight: "fontWeightBold", fontSize: "0.6rem", color: "text.secondary", ml: 1 }}>Nächster Schritt</Typography>
            <Chip label={CCState.toString(row.cc.state + 1)} />
          </Stack>
        )}
      </Stack>
    );
  };
  if (row.cc.state === CCState.START) {
    return (
      <Stack direction={"row"} spacing={5} alignItems={"flex-end"}>
        <Typography
          sx={{
            fontSize: "0.9rem",
            fontWeight: "fontWeightBold",
            width: "12rem",
            alignSelf: "center",
          }}
        >
          Energieausweis
        </Typography>
        <Tooltip
          title={
            "Ein Energieausweis ist gesetzlich vorgeschrieben für Immobilien, die verkauft oder vermietet werden sollen sowie für Neubauten. Zur Erfüllung von ESG-Anforderungen klassifizieren Banken ihren Bestand ebenfalls mittels Energieausweisen."
          }
        >
          <Button
            variant='contained'
            sx={{
              fontSize: "0.7rem",
            }}
            onClick={() => openInviteLeadModal(Product.consumptionCertificate)}
          >
            {CCState.toString(row.cc.state)}
          </Button>
        </Tooltip>
      </Stack>
    );
  }
  return (
    <Stack direction={"row"} spacing={5} alignItems={"center"}>
      <Box pt={2}>
        <Typography
          sx={{
            fontSize: "0.9rem",
            fontWeight: "fontWeightBold",
            width: "12rem",
          }}
        >
          Energieausweis
        </Typography>
      </Box>
      {renderStates()}
      <Box pt={1}>
        <CcAction row={row} actionTriggered={actionTriggered} setActionTriggered={setActionTriggered} />
      </Box>
    </Stack>
  );
}

interface CcActionProps {
  row: RowProps;
  actionTriggered: boolean;
  setActionTriggered: Dispatch<SetStateAction<boolean>>;
}

function CcAction({ row, actionTriggered, setActionTriggered }: CcActionProps) {
  const [isCcAnswersOpen, setCcAnswersOpen] = useState(false);
  const [downloadDocument, setDownloadDocument] = useState(false);
  const { getConsumptionCertLink, documentUrl } = useGetConsumptionCertPdfLink<{ url: string } | undefined>();

  useEffect(() => {
    if (row.cc.id?.startsWith(EXAMPLE_CC_ID_PREFIX)) {
      return; // ignore doc fetch when loading example data.
    }
    if (row.cc.id) {
      getConsumptionCertLink(row.cc.id);
    }
  }, [getConsumptionCertLink, row.cc.id]);

  useEffect(() => {
    if (downloadDocument && row.cc.id?.startsWith(EXAMPLE_CC_ID_PREFIX)) {
      window.open(SAMPLE_CC_DOCUMENT_URL, "_blank", "noopener,noreferrer");
      setActionTriggered(false);
      setDownloadDocument(false);
    }
    if (downloadDocument && documentUrl) {
      window.open(documentUrl.url, "_blank", "noopener,noreferrer");
      setActionTriggered(false);
      setDownloadDocument(false);
    }
  }, [downloadDocument, documentUrl, setActionTriggered, row.cc.id]);

  useEffect(() => {
    if (actionTriggered && row.consultantDataReviewConsent !== false) {
      if (row.cc.state === CCState.SUBMITTED) {
        setCcAnswersOpen(true);
      }
      if (row.cc.state === CCState.FINAL) {
        setDownloadDocument(true);
      }
    }
  }, [actionTriggered, row.cc.state, row.consultantDataReviewConsent]);

  const closeCcAnswersModal = () => {
    setCcAnswersOpen(false);
    setActionTriggered(false);
  };

  if (row.consultantDataReviewConsent === false) {
    return <></>;
  }

  if (row.cc.state === CCState.SUBMITTED) {
    return (
      <>
        <Link
          component='button'
          underline={"always"}
          sx={{
            color: "text.primary",
            fontSize: "0.9rem",
            textDecorationColor: "inherit",
          }}
          onClick={() => setCcAnswersOpen(true)}
        >
          <LaunchIcon
            sx={{
              fontSize: "1.2rem",
              verticalAlign: "middle",
            }}
          />
          {"Antworten ansehen"}
        </Link>
        <DisplayConsumptionCertificateDialog consumptionCertificate={row.cc.ccData} open={isCcAnswersOpen} handleClose={closeCcAnswersModal} />
      </>
    );
  } else if (row.cc.state === CCState.FINAL) {
    return (
      <Link
        component='button'
        onClick={() => setDownloadDocument(true)}
        underline={"always"}
        sx={{
          color: "text.primary",
          fontSize: "0.9rem",
          textDecorationColor: "inherit",
        }}
      >
        <PictureAsPdfIcon
          sx={{
            fontSize: "1.2rem",
            verticalAlign: "middle",
          }}
        />
        Energieausweis einsehen
      </Link>
    );
  } else {
    return <></>;
  }
}
