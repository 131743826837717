import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { KPIDial, KPINumbers } from "../../../components/dashboard/KPIDial";
import useFetchConsultantMetrics from "../../../hooks/useFetchConsultantMetrics";
import { ConsultantMetricsEventCounts, getInviteAcceptedCounts, getInviteSentCounts } from "../../../types/ConsultantMetrics";

type LeadKpis = {
  total: KPINumbers;
  active: KPINumbers;
  sent: KPINumbers;
};

export function KPIs() {
  const [kpis, setKPIs] = useState<LeadKpis>();

  const { metrics, isLoading, getConsultantMetrics } = useFetchConsultantMetrics();

  useEffect(() => {
    getConsultantMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoading || !metrics) {
      return;
    }

    const absoluteInvitesSent = getInviteSentCounts(metrics.absoluteSum.currMonth);
    const absoluteActiveCustomers = getInviteAcceptedCounts(metrics.absoluteSum.currMonth);
    const absoluteLeadsCount = metrics.absoluteSum.currMonth?.newLeadCreated;

    const prevMonth: ConsultantMetricsEventCounts | undefined = metrics.timeRangeSum.prevMonth;
    const currMonth: ConsultantMetricsEventCounts | undefined = metrics.timeRangeSum.currMonth;
    const prevActiveCount: number = getInviteAcceptedCounts(prevMonth);
    const currActiveCount: number = getInviteAcceptedCounts(currMonth);
    const activeCountPercChange: number | undefined = prevActiveCount ? Math.round((currActiveCount * 100) / prevActiveCount) - 100 : undefined;
    const prevSentCount: number = getInviteSentCounts(prevMonth);
    const currSentCount: number = getInviteSentCounts(currMonth);
    const sentCountPercChange: number | undefined = prevSentCount ? Math.round((currSentCount * 100) / prevSentCount) - 100 : undefined;
    const totalLeadsMonthPercChange = prevMonth?.newLeadCreated
      ? Math.round((currMonth?.newLeadCreated ? currMonth?.newLeadCreated * 100 : 0) / prevMonth.newLeadCreated) - 100
      : undefined;

    const leadKpis: LeadKpis = {
      active: { count: absoluteActiveCustomers, percentageChange: activeCountPercChange },
      sent: { count: absoluteInvitesSent, percentageChange: sentCountPercChange },
      total: { count: absoluteLeadsCount || 0, percentageChange: totalLeadsMonthPercChange },
    };
    setKPIs(leadKpis);
  }, [isLoading, metrics]);

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "background.default", p: 2 }}>
      <Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={2}>
        <KPIDial
          isLoading={isLoading}
          title={"Gesamte Kunden"}
          tooltip={"Anzahl deiner aktiven sowie eingeladenen Kunden"}
          iconFilename='up-metrics.png'
          {...kpis?.total}
        />
        <KPIDial
          isLoading={isLoading}
          title={"Aktive Kunden"}
          tooltip={"Anzahl der Kunden, die deine Einladung angenommen haben"}
          iconFilename='sofa-work.png'
          {...kpis?.active}
        />
        <KPIDial
          isLoading={isLoading}
          title={"Versendete Einladungen"}
          tooltip={"Anzahl der Einladungen, die du diesen Monat versendet hast"}
          iconFilename='reporting.png'
          {...kpis?.sent}
        />
      </Stack>
    </Box>
  );
}
