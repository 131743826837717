import { createTheme } from "@mui/material/styles";
import { Themes } from "../types/types";
import novo from "./novo";

const vb = createTheme(novo, {
  name: Themes.VB,
  logo: "VB_by_NOVO.png",
  logoClass: "App-logo",
  palette: {
    mode: "light",
    primary: {
      main: "#f08300",
      light: "#ff9c24",
      dark: "#bd6800",
    },
    secondary: {
      main: "#006DF0",
    },
  },
});

export default vb;
