import { useParams } from "react-router";
import { apiEndpoint } from "../utils/params";
import { useEffect, useCallback } from "react";
import { Property } from "../types/types";
import { useAuthAxios } from "./useAuthAxios";

const API_ENDPOINT = `${apiEndpoint()}/properties/`;

export default function useFetchSingleProperty() {
  const { id } = useParams();
  const { authAxios, data: property, running: isLoading } = useAuthAxios<Property>();

  const fetchSingleProperty = useCallback(async () => {
    await authAxios({
      method: "GET",
      baseURL: API_ENDPOINT + id,
    });
  }, [authAxios, id]);

  useEffect(() => {
    fetchSingleProperty();
  }, [fetchSingleProperty, id]);

  return { property, isLoading };
}
