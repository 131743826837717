import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useImage from "../../../hooks/useImage";
import { Box, Stack, StackProps, Typography } from "@mui/material";

interface ProgressBarProps extends StackProps {
  label: string;
  value: number;
}
export default function ProgressBar({ label, value, spacing = 1, ...props }: ProgressBarProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const progressCheck = useImage("progress-check.png");
  return (
    <Stack spacing={spacing} {...props}>
      <Typography
        sx={{
          color: "text.secondary",
          fontSize: isMobile ? 11 : 13,
          width: `calc(${value}% + 5rem)`,
          maxWidth: "100%",
          textAlign: "center",
          pl: `calc(${value}% - 5rem)`,
        }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          color: "success.dark",
          "&:before": {
            content: "''",
            display: "inline-block",
            position: "relative",
            width: `${value}%`,
            borderTop: "4px solid #007838",
            paddingBottom: "4px",
          },
          "&:after": {
            content: "''",
            display: "inline-block",
            position: "relative",
            width: `${100 - value}%`,
            borderTop: "4px solid rgba(0, 172, 79, 0.2)",
            paddingBottom: "4px",
          },
        }}
      >
        <img
          src={progressCheck.image}
          height={20}
          style={{
            position: "absolute",
            zIndex: 1,
            marginLeft: value < 10 ? `-${value}px` : value > 90 ? `-${value - 80}px` : "-10px", //keeping the symbol centered but bound to 0%-100% width, without overflowing
          }}
        />
      </Box>
    </Stack>
  );
}
