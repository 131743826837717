import LaunchIcon from "@mui/icons-material/Launch";
import { Button, Card, CardContent, CardMedia, Link as MuiLink, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Contexts";
import useImage from "../../../hooks/useImage";
import { isBankManager, isConsultant } from "../../../utils/auth";
import { RUN_COCKPIT_TOUR_SEARCH_ARG } from "../CockpitTour";
import { faqNotionEndpoint } from "../../../utils/params";

type WorkshopData = {
  copy: string;
  link: string;
  linkCopy: string;
};

export function WorkshopLink() {
  const { user } = useContext(AuthContext);
  const sophia = useImage("sophia-avatar.png");
  const [cockpitWorkshopData, setCockpitWorkshopData] = useState<WorkshopData>();

  useEffect(() => {
    if (!user) {
      return;
    }
    if (isBankManager(user)) {
      setCockpitWorkshopData({
        copy: "Möchtest du erfahren, wie du mit Hilfe von NOVO dein Team ins Rollen bringst? Hol’ dir Best Practices im Workshop für Bezirksdirektoren!",
        link: "https://calendly.com/sophia-buildingnovo/erfolgstraining-fur-bezirksdirektoren",
        linkCopy: "Hier Bezirksdirektoren-Workshop buchen",
      });
    } else if (isConsultant(user)) {
      setCockpitWorkshopData({
        copy: "Möchtest du erfahren, wie du mit Hilfe von NOVO erfolgreich Abschlüsse für Sanierungsfinanzierungen machst? Dann buche dich zu unserem Erfolgstraining mit unserem Team ein.",
        link: "https://calendly.com/sophia-buildingnovo/erfolgstraining-fuer-bezirksleiter",
        linkCopy: "Hier Bezirksleiter-Training buchen",
      });
    }
  }, [user]);

  if (!cockpitWorkshopData) {
    return <></>;
  }

  return (
    <Grid container>
      <Grid size={9}>
        <Typography paddingBottom={4}>{cockpitWorkshopData.copy}</Typography>
        <Box paddingBottom={2}>
          <MuiLink
            underline={"always"}
            sx={{
              color: "text.primary",
              textDecorationColor: "inherit",
            }}
            aria-label={cockpitWorkshopData.linkCopy}
            component={Link}
            target='_blank'
            to={cockpitWorkshopData.link}
          >
            {cockpitWorkshopData.linkCopy}{" "}
            <LaunchIcon
              sx={{
                fontSize: "1.2rem",
                verticalAlign: "middle",
              }}
            />
          </MuiLink>
        </Box>
      </Grid>
      <Grid flexGrow={1} />
      <Grid width={64}>
        <Box
          sx={{
            backgroundImage: `url('${sophia.image}')`,
            backgroundSize: "cover",
            borderRadius: "50%",
            height: 64,
            width: 64,
          }}
        />
      </Grid>
    </Grid>
  );
}

export function CockpitTourRedo() {
  const navigate = useNavigate();

  return (
    <Stack spacing={2}>
      <Typography paddingBottom={2}>Wiederhole die Cockpit-Tour, um mehr über die wichtigsten Funktionen zu erfahren.</Typography>
      <Box>
        <Button
          variant='outlined'
          color='secondary'
          onClick={() => {
            navigate(`/cockpit/leads?${RUN_COCKPIT_TOUR_SEARCH_ARG}=true`);
          }}
        >
          Cockpit-tour wiederholen
        </Button>
      </Box>
    </Stack>
  );
}

export function FAQ() {
  const { user } = useContext(AuthContext);
  const faqEndpoint = faqNotionEndpoint(user?.tenant?.name);

  return (
    <Grid container>
      <MuiLink
        underline={"always"}
        sx={{
          color: "text.primary",
          textDecorationColor: "inherit",
        }}
        aria-label={faqEndpoint}
        component={Link}
        target='_blank'
        to={faqEndpoint!}
      >
        Zur Seite mit den häufig gestellten Fragen{" "}
        <LaunchIcon
          sx={{
            fontSize: "1.2rem",
            verticalAlign: "middle",
          }}
        />
      </MuiLink>
    </Grid>
  );
}

type VideoProps = {
  url: string;
  title?: string;
  description?: string;
};

export type VideoCarrouselProps = {
  videos: VideoProps[];
};

export function VideoCarrousel({ videos }: VideoCarrouselProps) {
  return (
    <Grid container spacing={8}>
      {videos.map((video, index) => (
        <Grid key={index} size={4}>
          <Card sx={{ minWidth: 280 }}>
            <CardMedia component='video' image={video.url} controls />
            <CardContent>
              <Typography variant='body2'>
                <Box fontWeight={700}>{video.title}</Box>
                {video.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
