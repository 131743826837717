import Chip from "@mui/material/Chip";
import { EnergyConsumption } from "../types/types";
import { Product } from "../types/cockpit/types";

export const createTags = (input: string[]) => {
  return input.map((e) => <Chip label={e} key={e} />);
};

export const isVolksbank = (tenantName?: string) => {
  return tenantName?.includes("volksbank") || tenantName?.includes("vr_bank");
};

export const calculateEnergyCostSavings = (consumptionBefore: EnergyConsumption, consumptionAfter: EnergyConsumption): number => {
  const costPerYearBefore = consumptionBefore.costPerYear;
  const costPerYearAfter = consumptionAfter.costPerYear;
  return ((costPerYearBefore - costPerYearAfter) / costPerYearBefore) * 100;
};

export const formatNum = (num?: number): string => {
  return num ? new Intl.NumberFormat("de-de").format(num) : "-";
};

export const formatEuros = (num?: number): string => {
  return num ? new Intl.NumberFormat("de-de", { minimumFractionDigits: 2 }).format(num) : "-";
};

export const convertClassToColor = (energyClass: string) => {
  switch (energyClass) {
    case "A+":
      return "#22fe16";
    case "A":
      return "#97f525";
    case "B":
      return "#bbff00";
    case "C":
      return "#e0fe00";
    case "D":
      return "#fefe02";
    case "E":
      return "#fef200";
    case "F":
      return "#fcda00";
    case "G":
      return "#fe7b00";
    case "H":
      return "#fc3700";
    default:
      return "#ffffff";
  }
};

export const getProductCost = (product: Product) => {
  switch (product) {
    case Product.consumptionCertificate:
      return "59€";
    case Product.onePager:
    case Product.requirementCertificate:
      return "249€";
    case Product.isfp:
    default:
      return "";
  }
};
