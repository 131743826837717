import { useCallback } from "react";
import { User } from "../types/types";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";

export const API_ENDPOINT = `${apiEndpoint()}/user`;

/**
 * Gets details for logged-in user
 * @returns
 */
export function useFetchUser() {
  const { authAxios, error, running, data } = useAuthAxios<User>();

  const getUser = useCallback(
    async () =>
      await authAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}`,
      }),
    [authAxios],
  );

  return { getUser, error, isLoading: running, user: data };
}
