import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LockIcon from "@mui/icons-material/Lock";
import { Box, Tooltip, Typography } from "@mui/material";

export type ConsentNotGivenProps = {
  fontSize?: string | number;
  py?: number;
  p?: number;
  px?: number;
};

export function ConsentNotGiven({ fontSize, px, py, p }: ConsentNotGivenProps) {
  return (
    <Box py={py} px={px} p={p} display={"flex"} flexDirection={"row"} pr={1} alignItems={"center"}>
      <Box display={"flex"} flexDirection={"column"}>
        <LockIcon sx={{ fontSize: "1.1rem" }} />
        <Tooltip title='Der Kunde hat der Datenschutz-Einwilligung nicht zugestimmt. Deshalb könnte es sein, dass möglicherweise nicht alle seine Daten angezeigt werden.'>
          <HelpOutlineOutlinedIcon sx={{ fontSize: "1.1rem", mt: 1 }} />
        </Tooltip>
      </Box>
      <Typography pl={2} sx={{ fontSize, color: "text.secondary" }}>
        Datenschutz-Einwilligung wurde nicht zugestimmt
      </Typography>
    </Box>
  );
}
