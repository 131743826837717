import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";
import { QuestionComponent } from "../QuestionComponent";

export function HasPlansComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  const options = [
    {
      label: "Anbau geplant",
      selected: !!renovations?.answers?.houseInformation?.plannedExpansion,
    },
    {
      label: "kein Anbau geplant",
      selected: !renovations?.answers?.houseInformation?.plannedExpansion,
    },
  ];
  return <QuestionComponent question='Ist im Zuge der Sanierung ein Anbau/Ausbau geplant?' options={options} />;
}
