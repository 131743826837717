import { Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";

type AnswerOption = {
  label: string;
  selected: boolean;
};

export type QuestionComponentProps = {
  question: string;
  options: AnswerOption[];
};

export function QuestionComponent({ question, options }: QuestionComponentProps) {
  const selectedValues = options.filter((option) => option.selected).map((option) => option.label);
  const exclusive = selectedValues.length < 2;
  return (
    <Stack direction='row' alignItems='baseline' spacing={2}>
      <Typography fontSize={14}>{question}</Typography>
      <ToggleButtonGroup size='small' exclusive={exclusive} value={exclusive ? selectedValues.pop() : selectedValues}>
        {options.map((option, index) => (
          <ToggleButton key={index} value={option.label} sx={{ fontSize: 10 }}>
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
}
