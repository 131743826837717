import { Typography } from "@mui/material";
import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";
import OutlineFrame from "../OutlineFrame";
import { WaterHeating } from "../../../../../../funnels/isfp/renovations/utils";

export function HeatingComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  return (
    <OutlineFrame label={"Wassererwärmung"}>
      <Typography>{WaterHeating[renovations?.answers?.hotWaterAndVentilationInformation?.waterHeating as keyof typeof WaterHeating] || "--"}</Typography>
    </OutlineFrame>
  );
}
