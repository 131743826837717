import { Typography } from "@mui/material";
import { ConsultantMetricsEventCounts, EventType } from "../../../types/ConsultantMetrics";
import { ClassifiedMetric } from "./types";
import { FunctionComponent } from "react";

export const NoMetricsFound: FunctionComponent = () => {
  return (
    <Typography variant='subtitle2' style={{ fontWeight: 400 }}>
      No metrics found
    </Typography>
  );
};

export const metricLabel = (event: EventType): string => {
  switch (event) {
    case EventType.freeIsfpInviteSent:
      return "Free ISFP invites sent";
    case EventType.paidIsfpInviteSent:
      return "Paid ISFP invites sent";
    case EventType.freeIsfpInviteAccept:
      return "Free ISFP invites accepted";
    case EventType.paidIsfpInviteAccept:
      return "Paid ISFP invites accepted";
    case EventType.freeConsCertInviteSent:
      return "Free consumption certificate invites sent";
    case EventType.paidConsCertInviteSent:
      return "Paid consumption certificate invites sent";
    case EventType.freeConsCertInviteAccept:
      return "Free consumption certificate invites accepted";
    case EventType.paidConsCertInviteAccept:
      return "Paid consumption certificate invites accepted";
    case EventType.freeOnePagerInviteSent:
      return "Free one-pager invites sent";
    case EventType.paidOnePagerInviteSent:
      return "Paid one-pager invites sent";
    case EventType.freeConsCertLinkOpen:
      return "Free consumption certificate links opened";
    case EventType.paidConsCertLinkOpen:
      return "Paid consumption certificate links opened";
    case EventType.consCertDataPersisted:
      return "Consumption certificate data persisted";
    case EventType.consCertDelivered:
      return "Consumption certificate delivered";
    case EventType.newLeadCreated:
      return "New leads created";
    case EventType.isfpStatusQuoAnswrd:
      return "ISFP schnellcheck questionnaire answered";
    case EventType.isfpRenAnswrd:
      return "ISFP renovation questionnaire answered";
    case EventType.isfpStatusQuoRevenueEstimate:
      return "ISFP status-quo revenue estimated";
    case EventType.onePagerRevenueEstimate:
      return "One-pager revenue estimated";
    default:
      return event;
  }
};

export const classifyMetrics = (totals: ConsultantMetricsEventCounts): ClassifiedMetric => {
  return {
    isfpMetrics: [
      {
        label: metricLabel(EventType.freeIsfpInviteAccept),
        value: totals.freeIsfpInviteAccept,
      },
      {
        label: metricLabel(EventType.freeIsfpInviteSent),
        value: totals.freeIsfpInviteSent,
      },
      {
        label: metricLabel(EventType.isfpRenAnswrd),
        value: totals.isfpRenAnswrd,
      },
      {
        label: metricLabel(EventType.isfpStatusQuoAnswrd),
        value: totals.isfpStatusQuoAnswrd,
      },
      {
        label: metricLabel(EventType.isfpStatusQuoRevenueEstimate),
        value: totals.isfpStatusQuoRevenueEstimate,
      },
      {
        label: metricLabel(EventType.paidIsfpInviteAccept),
        value: totals.paidIsfpInviteAccept,
      },
      {
        label: metricLabel(EventType.paidIsfpInviteSent),
        value: totals.paidIsfpInviteSent,
      },
    ],
    consCertMetrics: [
      {
        label: metricLabel(EventType.consCertDataPersisted),
        value: totals.consCertDataPersisted,
      },
      {
        label: metricLabel(EventType.consCertDelivered),
        value: totals.consCertDelivered,
      },
      {
        label: metricLabel(EventType.freeConsCertInviteAccept),
        value: totals.freeConsCertInviteAccept,
      },
      {
        label: metricLabel(EventType.freeConsCertInviteSent),
        value: totals.freeConsCertInviteSent,
      },
      {
        label: metricLabel(EventType.freeConsCertLinkOpen),
        value: totals.freeConsCertLinkOpen,
      },
      {
        label: metricLabel(EventType.paidConsCertInviteAccept),
        value: totals.paidConsCertInviteAccept,
      },
      {
        label: metricLabel(EventType.paidConsCertInviteSent),
        value: totals.paidConsCertInviteSent,
      },
      {
        label: metricLabel(EventType.paidConsCertLinkOpen),
        value: totals.paidConsCertLinkOpen,
      },
    ],
    onePagerMetrics: [
      {
        label: metricLabel(EventType.freeOnePagerInviteSent),
        value: totals.freeOnePagerInviteSent,
      },
      {
        label: metricLabel(EventType.paidOnePagerInviteSent),
        value: totals.paidOnePagerInviteSent,
      },
      {
        label: metricLabel(EventType.onePagerRevenueEstimate),
        value: totals.onePagerRevenueEstimate,
      },
    ],
    newLeadCreated: [
      {
        label: metricLabel(EventType.newLeadCreated),
        value: totals.newLeadCreated,
      },
    ],
  };
};
