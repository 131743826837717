import { ChallengeName, CognitoUserSession } from "amazon-cognito-identity-js";
import { UserResponse } from "../types/cockpit/types";

export enum AuthErrorType {
  FAILED_FETCHING_USER,
  ACCOUNT_CREATION_USERNAME_ALREADY_EXISTS,
  FAILED_AUTHENTICATING,
  WRONG_CREDENTIALS,
  FAILED_ALREADY_CHANGED_PASSWORD,
  FAILED_CHANGING_PASSWORD,
  CURRENT_PASSWORD_IS_CORRECT,
  MISSING_MANDATORY_PASSWORD_CHANGE_DATA,
  FAILED_CREATING_NEW_USER,
  FAILED_CONFIRMING_ACCOUNT,
  FAILED_CONFIRMING_ACCOUNT_WRONG_CODE,
  FAILED_CONFIRMING_ACCOUNT_POSSIBLY_ALREADY_CONFIRMED,
  ACCOUNT_CREATION_UNRECOGNIZED_EMAIL,
  ACCOUNT_CREATION_INVALID_PARAMETER,
  FAILED_SENDING_FORGOT_PASSWORD_CODE,
  FAILED_RESETTING_PASSWORD,
  FAILED_RESET_PASSWORD_INCORRECT_CODE,
  FAILED_SENDING_FORGOT_PASSWORD_CODE_USER_INCORRECT,
  FAILED_RESET_PASSWORD_LIMIT_EXCEEDED,
  FAILED_RESET_PASSWORD_EXPIRED_CODE,
}

export type AuthError = {
  type: AuthErrorType;
  msg?: string;
};

export enum ActionType {
  PASSWORD_CHANGE_REQUIRED,
  CONFIRM_ACCOUNT_REQUIRED,
  MFA_REQUIRED,
}

export type LoginAction<ActionType> = {
  actionType: ActionType;
};

export type NewPasswordRequired = LoginAction<ActionType.PASSWORD_CHANGE_REQUIRED> & {
  oldPassword: string;
  username: string;
  userAttributes: { [key: string]: string };
  requiredAttributes: string[];
};

export type MfaRequired = LoginAction<ActionType.MFA_REQUIRED> & { challengeName: ChallengeName; challengeParameters: unknown };

export type AccountConfirmationRequired = LoginAction<ActionType.CONFIRM_ACCOUNT_REQUIRED> & { username: string };

export type ActionNeeded = NewPasswordRequired | MfaRequired | AccountConfirmationRequired;

export type AuthSession = {
  isLoading: boolean;
  session?: CognitoUserSession;
  user?: UserResponse;
  error?: AuthError;
  actionNeeded?: ActionNeeded;
  refreshSession: () => void;
  login: (username: string, password: string) => void;
  logout: () => void;
  clearActionNeeded: () => void;
  updatePasswordOnRequireChange: (newPassword: string, requiredAttributes: { [key: string]: string }) => void;
};
