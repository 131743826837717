import Box from "@mui/material/Box";
import { useContext } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import { CockpitTourRedo, FAQ, VideoCarrousel, WorkshopLink } from "./AcademyComponents";
import { isBankManager } from "../../../utils/auth";
import { AuthContext } from "../../../Contexts";
import { isVolksbank } from "../../../utils/utils";
import { faqNotionEndpoint } from "../../../utils/params";

export function Academy() {
  const { user } = useContext(AuthContext);
  const faqEndpoint = faqNotionEndpoint(user?.tenant?.name);

  const generalVideos = [
    {
      url: "https://timgs.novo.eco/videos/tutorials/unbranded/NOVOerreichen.mp4",
      title: "Wie erreiche ich NOVO?",
      description: "Hier erfährst du, wie du NOVO kontaktieren und uns Feedback geben kannst",
    },
    {
      url: "https://timgs.novo.eco/videos/tutorials/unbranded/TrainingsUndVideos.mp4",
      title: "Weiterbildung",
      description: "Finde heraus, wo du Informationen darüber findest, wie das Cockpit funktioniert",
    },
  ];

  let bshVideos;
  if (user?.tenant?.name === "bsh") {
    bshVideos = [
      {
        url: "https://timgs.novo.eco/videos/tutorials/bsh/0_BSH_Cockpit_Ueberblick.mp4",
        title: "Dein Einstieg ins Cockpit",
        description: "Eine Einführung in die wichtigsten Funktionen deines Cockpits",
      },
      {
        url: "https://timgs.novo.eco/videos/tutorials/bsh/1_BSH_Kunden_einladen_Schnellcheck_Erstgespraech.mp4",
        title: "Wie werden Kunden eingeladen?",
        description: "In diesem Video zeigen wir dir, wie du Kunden einladen kannst und wie sie die Einladung annehmen",
      },
      {
        url: "https://timgs.novo.eco/videos/tutorials/bsh/2_BSH_Kundenantworten_und_Ergebnisse_einsehen.mp4",
        title: "Dokumente und Kundenantworten",
        description:
          "In diesem kurzen Video wird gezeigt, wie bereits verfügbare Dokumente heruntergeladen werden können. Auch die vom Kunden beantworteten Fragebögen können angezeigt werden, sodass du stets einen transparenten Überblick über deine Kunden hast",
      },
    ];
    if (isBankManager(user)) {
      bshVideos.push({
        url: "https://timgs.novo.eco/videos/tutorials/bsh/6_BSH_nur_fuer_BD_Teammitglieder_einladen.mp4",
        title: "Teammitglied einladen",
        description: "Erfahre, wie du ein Teammitglied einladen kannst",
      });
    }
  }

  let vbVideos;
  if (isVolksbank(user?.tenant?.name)) {
    vbVideos = [
      {
        url: "https://timgs.novo.eco/videos/tutorials/volksbank/VR_Banken_Neues_Cockpit_long_version.mp4",
        title: "Wie funktioniert das NOVO-Cockpit?",
        description:
          "Eine erste Einführung in dein NOVO-Cockpit. Erfahre, wie du mit deinem Cockpit Kunden einladen, deren Fortschritt verfolgen und Dokumente sowie von Kunden beantwortete Fragebögen einsehen kannst",
      },
    ];
  }
  return (
    <Box
      sx={{
        flexGrow: 1,
        ml: "56px",
        pl: 2,
        pr: 2,
        width: "calc(100% - 56px)",
      }}
    >
      <Stack
        spacing={4}
        sx={{
          mt: 10,
          maxWidth: "lg",
          mx: { xs: 0, xl: "auto" },
        }}
      >
        <Typography variant='h5' style={{ fontWeight: 600 }}>
          Willkommen in der NOVO-Akademie, {user?.name}
        </Typography>
        <Box data-cy={"academy-sections"}>
          {faqEndpoint && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='faq' id='faq-header'>
                <Stack direction='row' spacing={2}>
                  <QuestionAnswerIcon />
                  <Typography fontWeight={700}>Häufig gestellte Fragen</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Box px={10} py={4}>
                  <FAQ />
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
          <Accordion data-joyride='videos-and-learning' defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='videos' id='videos-header'>
              <Stack direction='row' spacing={2}>
                <OndemandVideoOutlinedIcon />
                <Typography fontWeight={700}>Videothek & Lernen</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2}>
                <Typography variant='h6' sx={{ color: "text.secondary" }}>
                  Allgemein
                </Typography>
                <VideoCarrousel videos={generalVideos} />
              </Stack>
              {bshVideos && (
                <Stack mt={8} spacing={2}>
                  <Typography variant='h6' sx={{ color: "text.secondary" }}>
                    BSH
                  </Typography>
                  <VideoCarrousel videos={bshVideos} />
                </Stack>
              )}
              {vbVideos && (
                <Stack mt={8} spacing={2}>
                  <Typography variant='h6' sx={{ color: "text.secondary" }}>
                    Volksbank
                  </Typography>
                  <VideoCarrousel videos={vbVideos} />
                </Stack>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='tour' id='tour-header'>
              <Stack direction='row' spacing={2}>
                <MapOutlinedIcon />
                <Typography fontWeight={700}>Wiederhole die Cockpit-Tour</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Box px={10} py={4}>
                <CockpitTourRedo />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='workshop' id='workshop-header'>
              <Stack direction='row' spacing={2}>
                <EventOutlinedIcon />
                <Typography fontWeight={700}>Workshop buchen</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Box px={10} py={4}>
                <WorkshopLink />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Stack>
    </Box>
  );
}
