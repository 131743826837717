import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../Contexts";
import Loading from "../pages/cockpit/Loading";
import { isAdmin, isBankManager, isConsultant, isCockpitPathAuthorized } from "../utils/auth";

export const ProtectedRoute = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { isLoading, user } = useContext(AuthContext);
  const location = useLocation();

  if (isLoading) {
    return <Loading />;
  }

  if (!isCockpitPathAuthorized(location.pathname, user)) {
    if (isAdmin(user)) {
      return <Navigate to='/cockpit/metrics' replace />;
    } else if (isConsultant(user) || isBankManager(user)) {
      return <Navigate to='/cockpit/leads' replace />;
    }
    return <Navigate to='/signin' replace />;
  } else if (user) {
    return <>{children}</>;
  }

  return <Navigate to='/signin' />;
};
