import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import InfoIcon from "@mui/icons-material/Info";
import { Skeleton, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import React from "react";
import useImage from "../../hooks/useImage";

export type KPINumbers = { count?: number; percentageChange?: number };

export type KPIDialProps = {
  isLoading?: boolean;
  title: string;
  tooltip: string;
  iconFilename: string;
} & KPINumbers;

export const KPIDial: React.FC<KPIDialProps> = ({ count, percentageChange, title, tooltip, iconFilename, isLoading }) => {
  const { image } = useImage(iconFilename);
  if (isLoading) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid size={4} sx={{ direction: "rtl" }}>
            <Skeleton variant='circular' animation='wave' sx={{ height: 60, width: 60 }} />
          </Grid>
          <Grid size={8}>
            <Box sx={{ width: "100%" }}>
              <Skeleton variant='text' animation='wave' />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Skeleton variant='text' animation='wave' sx={{ height: 40 }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} sx={{ minWidth: "235px", minHeight: "106px" }}>
        <Grid size={4} sx={{ direction: "rtl" }} alignSelf={"center"}>
          <Tooltip title={tooltip}>
            <img src={image} height={80} />
          </Tooltip>
        </Grid>
        <Grid size={8} alignSelf={"flex-start"}>
          <Box sx={{ width: "100%" }}>
            <Tooltip title={tooltip}>
              <Typography variant='caption' sx={{ color: "#acacac" }}>
                {title} <InfoIcon fontSize='inherit' sx={{ verticalAlign: "middle" }} />
              </Typography>
            </Tooltip>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography variant='h4'>{count ?? 0}</Typography>
          </Box>
          {!!percentageChange && (
            <Box sx={{ width: "100%" }}>
              {percentageChange >= 0 && <ArrowUpwardIcon sx={{ fontSize: 24, fontWeight: 800, color: "success.light" }} />}
              {percentageChange < 0 && <ArrowDownwardIcon sx={{ fontSize: 24, fontWeight: 800, color: "error.light" }} />}
              <Typography variant='caption' sx={{ fontWeight: 800, color: percentageChange >= 0 ? "success.light" : "error.light", verticalAlign: "super" }}>
                {percentageChange >= 0 ? "+" : ""}
                {percentageChange}%
              </Typography>
              <Typography variant='caption' sx={{ verticalAlign: "super" }}>
                {" diesen Monat"}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
