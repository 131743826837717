import { useCallback } from "react";
import { TenantImages } from "../types/cockpit/types";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";

const API_ENDPOINT = `${apiEndpoint()}/tenant`;

export function useFetchTenantImages() {
  const { authAxios, error, running, data } = useAuthAxios<TenantImages>();

  const fetchTenantImages = useCallback(
    async (id: string) => {
      return await authAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}/${id}/images`,
      });
    },
    [authAxios],
  );

  return { fetchTenantImages, error, isLoading: running, imageUrls: data };
}
