import heatingIcon from "../../../../imgs/report_heating.png";
import windowsIcon from "../../../../imgs/report_windows.png";
import roofIcon from "../../../../imgs/report_roof.png";
import envelopeIcon from "../../../../imgs/report_envelope.png";
import basementIcon from "../../../../imgs/report_basement.png";
import doorsIcon from "../../../../imgs/report_doors.png";
import Grid from "@mui/material/Grid";
import { StatusQuoResponse } from "../../../../types/cockpit/types";
import { StatusItem } from "../../../../components/one-pager/StatusItem";
import { Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { ceilingStatus, doorStatus, estimateIsfpBenefits, floorStatus, heatingSystemStatus, outerWallStatus, windowStatus } from "./StatusQuoCalculations";

type StatusProps = {
  statusQuoResponse?: StatusQuoResponse;
};

export const Status: FunctionComponent<StatusProps> = (props) => {
  const { buildYear, cellingRenovation, doorRenovation, floorInsulation, outerWallInsulation, heatingSystemAge, windowRenovation } =
    props.statusQuoResponse || {};
  const benefits = estimateIsfpBenefits(props.statusQuoResponse);

  return (
    <Grid container spacing={2} marginBottom={2}>
      <Grid item xs={4}>
        <StatusItem logo={doorsIcon} label={"Türen"} color={doorStatus(doorRenovation, buildYear).color} />
      </Grid>
      <Grid item xs={4}>
        <StatusItem logo={basementIcon} label={"Boden & Kellerdecke"} color={floorStatus(floorInsulation, buildYear).color} />
      </Grid>
      <Grid item xs={4}>
        <StatusItem logo={heatingIcon} label={"Heizsystem"} color={heatingSystemStatus(heatingSystemAge, buildYear).color} />
      </Grid>
      <Grid item xs={4}>
        <StatusItem logo={envelopeIcon} label={"Außenwände"} color={outerWallStatus(outerWallInsulation, buildYear).color} />
      </Grid>
      <Grid item xs={4}>
        <StatusItem logo={roofIcon} label={"Dach"} color={ceilingStatus(cellingRenovation, buildYear).color} />
      </Grid>
      <Grid item xs={4}>
        <StatusItem logo={windowsIcon} label={"Fenster"} color={windowStatus(windowRenovation, buildYear).color} />
      </Grid>
      <Grid item xs={12} ml={10}>
        <Typography variant='subtitle1'>
          Dein Einspar-Potenzial:{" "}
          <Typography variant='subtitle1' fontWeight={800} display='inline'>{`${benefits.minSavings}€ - ${benefits.maxSavings}€`}</Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} ml={10}>
        <Typography variant='subtitle1'>
          Deine mögliche Förderhöhe:{" "}
          <Typography variant='subtitle1' fontWeight={800} display='inline'>
            bis zu {benefits.subsidy}%
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};
