import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const classes = ["A+", "A", "B", "C", "D", "E", "F", "G", "H"];
const pantones: { [key: string]: string[] } = {
  "A+": ["3,135,60", "218,241,228"], // [colour, bgColour] in rgb values to be ready to use with alpha gradients
  A: ["88,161,38", "227,245,214"],
  B: ["139,172,23", "241,249,210"],
  C: ["182,165,9", "251,252,207"],
  D: ["229,179,0", "252,248,207"],
  E: ["239,145,0", "249,241,210"],
  F: ["218,105,23", "247,231,212"],
  G: ["214,80,27", "250,228,219"],
  H: ["204,47,35", "249,222,220"],
};

export default function EnergyClasses({ future, current }: { future?: string; current?: string }) {
  return (
    <Stack direction={"row"} spacing={"2px"}>
      {classes.map((clazz) => (
        <SingleClass key={clazz} clazz={clazz} future={future} current={current} />
      ))}
    </Stack>
  );
}

function SingleClass({ clazz, future, current }: { clazz: string; future?: string; current?: string }) {
  return (
    <Stack flexGrow={1}>
      <Box
        sx={{
          borderTop: `2px solid rgb(${pantones[clazz][0]})`,
          borderBottom: `2px solid rgb(${pantones[clazz][0]})`,
          backgroundColor: `rgb(${pantones[clazz][1]})`,
          height: "60px",
        }}
      >
        <Typography
          color={`rgb(${pantones[clazz][0]})`}
          fontWeight={"fontWeightBold"}
          textAlign={"center"}
          sx={{ lineHeight: "60px" }}
          data-cy={future === clazz ? "future-class-text" : current === clazz ? "current-class-text" : ""}
        >
          {clazz}
        </Typography>
      </Box>
      {(future === clazz || current === clazz) && (
        <Box
          sx={{
            background: `linear-gradient(180deg, rgba(${pantones[clazz][1]},1) 0%, rgba(${pantones[clazz][1]},0) 100%)`,
            height: "60px",
          }}
        >
          <Box
            sx={{
              width: 0,
              height: 0,
              mx: "auto",
              my: "4px",
              borderLeft: "12px solid transparent",
              borderRight: "12px solid transparent",
              borderTop: `12px solid rgb(${pantones[clazz][0]})`,
            }}
          />
          <Typography fontWeight={"fontWeightBold"} fontSize={12} textAlign={"center"}>
            {future === clazz ? "Saniert" : "Aktuell"}
          </Typography>
        </Box>
      )}
    </Stack>
  );
}
