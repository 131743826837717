import axios from "axios";
import { apiEndpoint } from "../utils/params";
import { AnalysisState } from "../types/Schnellcheck";
import { getLocalAnswers, storeLocalReport } from "../components/questionnaire/storage";
import { retrieveRum } from "../AwsRum";

const API_ENDPOINT = `${apiEndpoint()}/questionnaires/`;
const headers = { "Content-Type": "application/json" };

export default function useUpsertQuestionnaire() {
  function upsertQuestionnaire() {
    const request = getLocalAnswers();
    const sendData = async () => (await axios.post<AnalysisState>(API_ENDPOINT, request, { headers })).data;
    sendData()
      .then((response) => storeLocalReport(response))
      .catch((e) => {
        console.error(e);
        retrieveRum()?.recordError(e);
      });
  }

  return [upsertQuestionnaire];
}
