import { ChangeEvent, FunctionComponent, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import useCreateProperty from "../../hooks/useCreateProperty";
import { useNavigate } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import HouseIcon from "@mui/icons-material/House";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { ToggleButton } from "@mui/material";

export type Property = {
  type?: string;
  address?: string;
  area?: number;
  yearOfConstruction?: number;
  isMonument?: boolean;
  heatingSystemType?: string;
  heatingYearOfInstalation?: number;
};

export const Property: FunctionComponent = () => {
  const [property, setProperty] = useState<Property>({});
  const intl = useIntl();
  const [createProperty] = useCreateProperty();
  const navigate = useNavigate();

  function createPropertyOnClick() {
    createProperty(property);
    navigate(-1);
  }

  function toggleChange(type: string) {
    const newProperty = {
      ...property,
      type,
    };
    setProperty(newProperty);
  }

  function isSelected(value: string) {
    return property && property.type === value;
  }

  function addressOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newProperty = {
      ...property,
      address: e.target.value,
    };
    setProperty(newProperty);
  }

  function areaOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newProperty = {
      ...property,
      area: parseInt(e.target.value),
    };
    setProperty(newProperty);
  }

  function yearOfConstructionOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newProperty = {
      ...property,
      yearOfConstruction: parseInt(e.target.value),
    };
    setProperty(newProperty);
  }

  function heatingSystemTypeOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newProperty = {
      ...property,
      heatingSystemType: e.target.value,
    };
    setProperty(newProperty);
  }

  function heatingYearOfInstalationOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newProperty = {
      ...property,
      heatingYearOfInstalation: parseInt(e.target.value),
    };
    setProperty(newProperty);
  }

  return (
    <>
      <Stack spacing={2} style={{ padding: 10 }} width={700}>
        <Typography variant='h4' align='center' style={{ fontWeight: 600 }}>
          <FormattedMessage id='app.add-property.add-new-property' />
        </Typography>
        <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
          <ToggleButton value='detachedHouse' onChange={() => toggleChange("detachedHouse")} selected={isSelected("detachedHouse")} size='large'>
            <HouseIcon style={{ color: "green" }} />
          </ToggleButton>
          <ToggleButton value='semidetachedHouse' onChange={() => toggleChange("semidetachedHouse")} selected={isSelected("semidetachedHouse")} size='large'>
            <HouseIcon style={{ color: "black" }} />
            <HouseIcon style={{ color: "green", marginLeft: -10 }} />
          </ToggleButton>
          <ToggleButton value='attachedHouse' onChange={() => toggleChange("attachedHouse")} selected={isSelected("attachedHouse")} size='large'>
            <HouseIcon style={{ color: "black" }} />
            <HouseIcon style={{ color: "green", marginLeft: -10, zIndex: 1 }} />
            <HouseIcon style={{ color: "black", marginLeft: -10 }} />
          </ToggleButton>
          <ToggleButton value='apartmentBuilding' onChange={() => toggleChange("apartmentBuilding")} selected={isSelected("apartmentBuilding")} size='large'>
            <ApartmentIcon style={{ color: "green" }} />
          </ToggleButton>
        </Stack>
        <TextField
          id='address'
          label={intl.formatMessage({ id: "app.add-property.address" })}
          variant='outlined'
          value={property?.address}
          onChange={addressOnChange}
          required={true}
        />
        <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
          <TextField
            id='area'
            label={intl.formatMessage({ id: "app.add-property.area" })}
            variant='outlined'
            value={property?.area}
            onChange={areaOnChange}
            required={true}
            fullWidth
          />
          <TextField
            id='yearOfConstruction'
            label={intl.formatMessage({ id: "app.add-property.year" })}
            variant='outlined'
            value={property?.yearOfConstruction}
            onChange={yearOfConstructionOnChange}
            required={true}
            fullWidth
          />
        </Stack>
        <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
          <TextField
            id='heating-system-type'
            value={property?.heatingSystemType}
            label={intl.formatMessage({ id: "app.add-property.heating-system" })}
            onChange={heatingSystemTypeOnChange}
            required={true}
            fullWidth
            select
          >
            <MenuItem value={"gas"}>
              <FormattedMessage id='app.add-property.gas' />
            </MenuItem>
            <MenuItem value={"oil"}>
              <FormattedMessage id='app.add-property.oil' />
            </MenuItem>
            <MenuItem value={"woodPellets"}>
              <FormattedMessage id='app.add-property.woodPellets' />
            </MenuItem>
            <MenuItem value={"electricity"}>
              <FormattedMessage id='app.add-property.electricity' />
            </MenuItem>
            {/* <MenuItem value={"districtHeating"}><FormattedMessage id="app.add-property.districtHeating" /></MenuItem>
          <MenuItem value={"biomass"}><FormattedMessage id="app.add-property.biomass" /></MenuItem>
          <MenuItem value={"otherFuel"}><FormattedMessage id="app.add-property.otherFuel" /></MenuItem> */}
          </TextField>
          <TextField
            id='yearOfInstalationHeating'
            label={intl.formatMessage({ id: "app.add-property.heating-instalation" })}
            variant='outlined'
            value={property?.heatingYearOfInstalation}
            onChange={heatingYearOfInstalationOnChange}
            fullWidth
          />
        </Stack>

        <Button variant='contained' onClick={() => createPropertyOnClick()} sx={{ height: 40 }}>
          <FormattedMessage id='app.add-property.add-property' />
        </Button>
      </Stack>
    </>
  );
};
