import { Button, Grid, Box, Stack, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { FunctionComponent, useEffect, useState } from "react";
import { ReportDataField } from "./ReportDataField";
import { ReportEnergyClassCard } from "./ReportEnergyClassCard";
import { ContinueCard } from "./ContinueCard";
import { env } from "../../utils/params";
import { CONSENT } from "./Consent";
import { IconsTable } from "./IconsTable";
import { AnalysisState, StatusColor } from "../../types/Schnellcheck";
import { ANALYSIS } from "./storage";
import usePutMetric from "../../hooks/usePutMetric";
import { formatEuros } from "../../utils/utils";

type ReportProps = {
  question: number;
  setNextQuestion: (questionNbr: number) => void;
};

export const Report: FunctionComponent<ReportProps> = ({ setNextQuestion }) => {
  const [putMetric] = usePutMetric();
  const [analysisState, setAnalysisState] = useState<AnalysisState>({
    id: "",
    report: {
      energyCostSavings: 0,
      subsidy: 0,
      trees: 0,
      currentEnergyClass: "E",
      afterRenovationEnergyClass: "A+",
      status: {
        heating: StatusColor.red,
        windows: StatusColor.red,
        roof: StatusColor.red,
        envelope: StatusColor.red,
        basement: StatusColor.red,
        doors: StatusColor.red,
      },
    },
  });

  function gtag_report_conversion(url?: Location) {
    const callback = function () {
      if (typeof url != "undefined") {
        window.location = url;
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).gtag("event", "conversion", { send_to: "AW-16485162317/vhgDCOP045sZEM263rQ9", event_callback: callback });
    return false;
  }

  useEffect(() => {
    // Local Storage
    const answer = localStorage.getItem(ANALYSIS);
    if (answer) {
      const parsedAnswer = JSON.parse(answer);
      setAnalysisState(parsedAnswer);
    }
    // Tracking
    const consentString = localStorage.getItem(CONSENT);
    if (consentString && JSON.parse(consentString) && env() === "prod") {
      gtag_report_conversion();
    }
    putMetric("ZuGuEmbedded", "RenderReportPage");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid
        container
        columnSpacing={8}
        p={6}
        sx={{
          height: "100%",
          paddingY: {
            xs: 3,
            sm: 6,
          },
          paddingX: {
            xs: 3,
            sm: 8,
          },
        }}
      >
        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            <Typography component='h2' style={{ fontSize: 20, fontWeight: 700 }}>
              Ihr Haus im Überblick
            </Typography>
            <IconsTable />
            <ReportDataField label='weniger Energiekosten' data={`${analysisState.report.energyCostSavings}%`} data-cy='energy-cost-text' />
            <ReportDataField label='Zuschüsse' data={`mehr als ${formatEuros(analysisState.report.subsidy)} €`} data-cy='subsidy-text' />
            <ReportDataField label='CO2 Ersparnis' data={`${analysisState.report.trees} Bäume`} data-cy='trees-text' />

            <Stack direction='row' alignItems={"flex-start"} justifyContent={"space-between"}>
              <Typography align='left' style={{ fontSize: 16, fontWeight: 400 }}>
                Energieklasse
              </Typography>
              <Stack direction='row' alignItems={"center"} width={"60%"} justifyContent={"space-evenly"}>
                <ReportEnergyClassCard energyClass={analysisState.report.currentEnergyClass} data-cy='current-class-text' />
                <ArrowForward />
                <ReportEnergyClassCard energyClass={analysisState.report.afterRenovationEnergyClass} />
              </Stack>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ContinueCard setNextQuestion={() => setNextQuestion(5)} />
        </Grid>
        <Grid item>
          <Box>
            <Button
              variant='text'
              size='small'
              onClick={() => setNextQuestion(3)}
              sx={{ color: "#c7c7c7", textDecorationLine: "underline", ":hover": { backgroundColor: "transparent" } }}
            >
              Zurück
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
