import { Cancel, CheckCircle, Construction, Delete, Error, HelpOutline, PictureAsPdf, Task } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, SxProps, TableSortLabel, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { formatDate } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import Spinner from "../../../components/Spinner";
import useDeleteConsumptionCertificate from "../../../hooks/useDeleteConsumptionCertificate";
import { useFetchTenantImages } from "../../../hooks/useFetchTenantImages";
import { useGetConsumptionCertPdfLink } from "../../../hooks/useGetConsumptionCertPdfLink";
import { ConsumptionCertOrderPaymentStatus } from "../../../types/ConsumptionCertificateState";
import ConsumptionCertificateDialog from "./ConsumptionCertificateDialog";

export type Order = "asc" | "desc";
export type OrderBy = keyof ConsumptionCertTableEntry;

export const CONSUMPTION_CERT_COLUMN_COUNT = 9;

export type FinalizeCertItem = {
  id: string;
  address: string;
};

type CertificateActions = {
  id: string;
  isPdfAvailable?: boolean;
  state?: string;
  finalizeCertData: FinalizeCertItem;
};

type PurchaseOrder = {
  id?: string;
  status?: ConsumptionCertOrderPaymentStatus;
};

export type ConsumptionCertTableEntry = {
  id: string;
  createdAt: number;
  state?: string;
  address?: string;
  leadName?: string;
  leadEmail?: string;
  leadTenantId?: string;
  referrer?: string;
  purchaseOrderId?: string;
  purchaseOrder?: PurchaseOrder;
  actions: CertificateActions;
};

type CertificateChange = (entry: ConsumptionCertTableEntry) => void;

export interface ConsumptionCertificateTableProps {
  isLoading: boolean;
  error?: string;
  data: ConsumptionCertTableEntry[];
  onOrderByChange: (ordering: { order: Order; orderBy: OrderBy }) => void;
  onDelete: CertificateChange;
}

export function ConsumptionCertificateTable({ isLoading, onDelete, data, error, onOrderByChange }: ConsumptionCertificateTableProps) {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<OrderBy>("createdAt");

  const handleOrderChange = (newOrder: OrderBy) => {
    if (newOrder === orderBy) {
      setOrder(order === "desc" ? "asc" : "desc");
    } else {
      setOrderBy(newOrder);
    }
  };

  useEffect(() => {
    onOrderByChange({ order, orderBy });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy]);

  if (isLoading && data.length == 0) {
    return <LoadingData />;
  }
  if (error) {
    return <LoadingError error={error} />;
  }
  if (!isLoading && data.length == 0) {
    return <EmptyTable />;
  }
  return (
    <>
      <Table aria-label='collapsible table' data-cy='isfp-processes-list' sx={{ "& .MuiTableCell-root": { border: "none" } }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === "leadName"}
                direction={order}
                onClick={() => {
                  handleOrderChange("leadName");
                }}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "leadEmail"}
                direction={order}
                onClick={() => {
                  handleOrderChange("leadEmail");
                }}
              >
                E-Mail
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "address"}
                direction={order}
                onClick={() => {
                  handleOrderChange("address");
                }}
              >
                Adresse
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "referrer"}
                direction={order}
                onClick={() => {
                  handleOrderChange("referrer");
                }}
              >
                Berater E-Mail
              </TableSortLabel>
            </TableCell>
            <TableCell>Partner</TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "state"}
                direction={order}
                onClick={() => {
                  handleOrderChange("state");
                }}
              >
                Status
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "createdAt"}
                direction={order}
                onClick={() => {
                  handleOrderChange("createdAt");
                }}
              >
                Erstellt am
              </TableSortLabel>
            </TableCell>
            <TableCell>Zahlung</TableCell>
            <TableCell>Funktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{data?.map((row) => row && <Row key={row.id} row={row} onDelete={onDelete} />)}</TableBody>
      </Table>
    </>
  );
}

function LoadingData() {
  return (
    <Box sx={{ flexGrow: CONSUMPTION_CERT_COLUMN_COUNT, minHeight: 1200, width: "100%", pt: 4 }}>
      <Stack spacing={2}>
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
      </Stack>
    </Box>
  );
}

function LoadingError({ error }: { error: string | string[] }) {
  return (
    <Box sx={{ flexGrow: CONSUMPTION_CERT_COLUMN_COUNT, minHeight: 800, width: "100%", pt: 4 }}>
      <Typography>{error}</Typography>
    </Box>
  );
}

function EmptyTable() {
  return (
    <Box sx={{ flexGrow: CONSUMPTION_CERT_COLUMN_COUNT, minHeight: 800, width: "100%", pt: 4 }}>
      <Typography>Keine Ergebnisse gefunden</Typography>
    </Box>
  );
}

function Row({ row, onDelete }: { row: ConsumptionCertTableEntry; onDelete: CertificateChange }) {
  const intl = useIntl();

  const dontBreakOut: SxProps<Theme> = {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    wordBreak: "break-word",
    msWordBreak: "break-word",
    msHyphens: "auto",
    hyphens: "auto",
    MozHyphens: "auto",
  };

  return (
    <Fragment>
      <TableRow
        sx={{
          height: 75,
          "& .MuiTableCell-root": {
            position: "relative",
            bottom: 0,
            bgcolor: "background.default",
          },
        }}
      >
        <TableCell scope='row' sx={{ ...dontBreakOut }}>
          {row.leadName}
        </TableCell>
        <TableCell sx={{ ...dontBreakOut }} data-cy={`lead-row-${row.leadEmail}`}>
          {row.leadEmail}
        </TableCell>
        <TableCell sx={{ ...dontBreakOut }}>{row.address}</TableCell>
        <TableCell sx={{ ...dontBreakOut }}>{row.referrer}</TableCell>
        <TableCell>
          <TenantLogo tenantId={row.leadTenantId} />
        </TableCell>
        <TableCell sx={{ ...dontBreakOut }}>
          {intl.formatMessage({
            id: `app.consumption-certificates-list.state.${row.state}`,
          })}
        </TableCell>
        <TableCell>{row.createdAt ? formatDate(row.createdAt, "dd.MM.yyyy") : "-"}</TableCell>
        <TableCell>
          <PaymentStatus status={row.purchaseOrder?.status} />
        </TableCell>
        <TableCell>
          <RowActions row={row} onDelete={onDelete} />
        </TableCell>
      </TableRow>
      <TableRow sx={{ height: "1rem" }} />
    </Fragment>
  );
}

type RowActionProps = {
  row: ConsumptionCertTableEntry;
  onDelete: CertificateChange;
};

const RowActions = ({ row, onDelete }: RowActionProps) => {
  const intl = useIntl();
  const [finalizeCertOpenDialog, setfinalizeCertOpenDialog] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [finalizeCertItem, setFinalizeCertItem] = useState<FinalizeCertItem>();
  const { getConsumptionCertLink, documentUrl, getDocumentRunning } = useGetConsumptionCertPdfLink<{ url: string } | undefined>();

  useEffect(() => {
    if (documentUrl) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.href = documentUrl.url;
      link.click();
    }
  }, [documentUrl]);

  const finalizeCertificateClick = (finalizeData: FinalizeCertItem) => {
    return () => {
      setFinalizeCertItem(finalizeData);
      setfinalizeCertOpenDialog(true);
    };
  };

  const dialogCloseHandler = () => {
    setfinalizeCertOpenDialog(false);
  };

  const deleteDialogCloseHandler = () => {
    setConfirmDeleteOpen(!confirmDeleteOpen);
  };

  const deleteCertificateClick = () => {
    setConfirmDeleteOpen(true);
  };

  const openPdfDoc = () => {
    getConsumptionCertLink(row.id);
  };

  return (
    <>
      <Box minWidth={160}>
        <IconButton
          color='primary'
          title={intl.formatMessage({ id: "app.consumption-certificates-list.actions.show-pdf" })}
          disabled={!row.actions.isPdfAvailable}
          onClick={openPdfDoc}
        >
          {getDocumentRunning ? <Spinner size={20} enabled /> : <PictureAsPdf />}
        </IconButton>
        <IconButton
          color='primary'
          component={Link}
          disabled={row.state === "final"}
          title={intl.formatMessage({ id: "app.consumption-certificates-list.actions.modernisation-recommendations" })}
          to={`${row.id}/modernisation-recommendations`}
        >
          <Construction />
        </IconButton>
        <IconButton
          color='primary'
          disabled={row.state === "final"}
          title={"Verbrauchsausweis generieren und hochladen"}
          onClick={finalizeCertificateClick({ address: row.address ?? "", id: row.id })}
        >
          <Task />
        </IconButton>
        <IconButton color='primary' disabled={row.state === "final"} title={"Verbrauchsausweis löschen"} onClick={deleteCertificateClick}>
          <Delete />
        </IconButton>
      </Box>
      <ConsumptionCertificateDialog isOpen={finalizeCertOpenDialog} closeHandler={dialogCloseHandler} certificate={finalizeCertItem} />
      <ConfirmDeletionDialog row={row} onClose={deleteDialogCloseHandler} onDelete={onDelete} open={confirmDeleteOpen} />
    </>
  );
};

type ConfirmDeletionDialogProps = {
  open: boolean;
  row: ConsumptionCertTableEntry;
  onDelete: (entry: ConsumptionCertTableEntry) => void;
  onClose: () => void;
};

const ConfirmDeletionDialog = ({ open, row, onDelete, onClose }: ConfirmDeletionDialogProps) => {
  const { deleteConsumptionCertificate, running: runningDelete, deletedCert } = useDeleteConsumptionCertificate();

  useEffect(() => {
    if (deletedCert) {
      onDelete(row);
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedCert]);

  const deleteCertHandler = () => {
    deleteConsumptionCertificate(row.id);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ px: 4, py: 2 }}>
        Eintrag löschen
        <Button
          sx={{ float: "inline-end", color: "rgba(0, 0, 0, 0.54)", ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
          onClick={onClose}
          endIcon={<CloseIcon />}
        >
          Schließen
        </Button>
      </DialogTitle>
      <DialogContent sx={{ px: 4, py: 2 }} dividers>
        <Typography minWidth={500}>
          Verbrauchsausweis von <b>{row.address}</b> Löschen?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ pr: 4, py: 2 }}>
        <Button variant='outlined' color='secondary' onClick={onClose}>
          Abbrechen
        </Button>
        <ButtonWithSpinner variant={"contained"} loading={runningDelete} label='Löschen' onClick={deleteCertHandler} />
      </DialogActions>
    </Dialog>
  );
};

type TenantImageProps = {
  tenantId?: string;
  width?: number;
};

type PaymentStatusProps = {
  status?: ConsumptionCertOrderPaymentStatus;
};

const PaymentStatus = ({ status }: PaymentStatusProps) => {
  switch (status) {
    case ConsumptionCertOrderPaymentStatus.Canceled:
      return (
        <Icon title='Zahlgang abgebrochen'>
          <Cancel />
        </Icon>
      );
    case ConsumptionCertOrderPaymentStatus.Failed:
      return (
        <Icon color='warning' title='Zahlung fehlgeschlagen'>
          <Error />
        </Icon>
      );
    case ConsumptionCertOrderPaymentStatus.Paid:
      return (
        <Icon color='success' title='Kunde hat bezahlt'>
          <CheckCircle />
        </Icon>
      );
    case ConsumptionCertOrderPaymentStatus.NoPaymentRequired:
      return (
        <Icon color='success' title='Bezahlt via Voucher'>
          <CheckCircle />
        </Icon>
      );
    default:
      return (
        <Icon title='Noch keine Info'>
          <HelpOutline />
        </Icon>
      );
  }
};

const TenantLogo = ({ tenantId, width }: TenantImageProps) => {
  const { imageUrls, fetchTenantImages, isLoading } = useFetchTenantImages();

  useEffect(() => {
    if (!tenantId) {
      return;
    }
    fetchTenantImages(tenantId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!tenantId) {
    return <></>;
  }
  if (isLoading) {
    return <Skeleton width={width ?? 100} height={width ? width * 0.4 : 40} animation='wave' />;
  }

  return (
    <>
      {imageUrls?.logoUrl && <img width={width ?? 100} src={imageUrls.logoUrl} />}
      {!imageUrls?.logoUrl && <>{imageUrls?.displayName}</>}
    </>
  );
};
