import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import NOVOlogo from "../../imgs/logo.png";
import DZBANKlogo from "../../imgs/DZBANK_Logo.png";
import FoerderWeltlogo from "../../imgs/FoerderWelt.png";

export default function Topbar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position='static'
        style={{ height: "min(92px, 10vh)", overflow: "hidden", background: "transparent", boxShadow: "none", borderBottom: "1px solid #cbcbcb" }}
      >
        <Toolbar disableGutters>
          <img src={NOVOlogo} className='App-logo-sidebar' alt='logo' />
          <Box sx={{ flexGrow: 1 }} />
          <img src={FoerderWeltlogo} className='App-logo-foerder-welt' alt='logo' />
          <img src={DZBANKlogo} className='App-logo-dz' alt='logo' />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
