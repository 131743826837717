import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import ContactFormDialog from "./ContactFormDialog";

export function ContactRequestRequired({
  description = "In Ihrem Fall ist ein Bedarfsausweis verpflichtend und ein Verbrauchsausweis leider nicht ausreichend. Wir kümmern uns gern darum! Kontaktieren Sie uns per E-Mail und wir unterstützen Sie bei den nächsten Schritten.",
  message = "",
  title,
}: {
  description?: string;
  title: string;
  message?: string;
}) {
  const [searchParams] = useSearchParams();
  const [lead] = useState(searchParams.get("lead") ?? undefined); // Lead ID coming from Bubble
  const [leadId] = useState(searchParams.get("leadId") ?? undefined);
  const [contactFormOpen, setContactFormOpen] = useState(false);

  const HighlightPanel = styled(Stack)(({ theme }) => ({
    "@keyframes fadeOut": {
      from: {
        backgroundColor: `rgb(from ${theme.palette.primary.light} r g b / 1.0)`,
        margin: "-2rem",
        padding: "2rem",
      },
      to: {
        backgroundColor: `rgb(from ${theme.palette.primary.light} r g b / 0)`,
        margin: 0,
        padding: 0,
      },
    },
    animation: "fadeOut 1.5s ease-out",
  }));

  const toggleContactFormOpen = () => {
    setContactFormOpen(!contactFormOpen);
  };

  return (
    <>
      <HighlightPanel spacing={2}>
        <Typography variant='body1'>{description}</Typography>
        <Button onClick={toggleContactFormOpen} data-cy='request-contact-button' variant='contained'>
          Kontaktieren Sie uns
        </Button>
      </HighlightPanel>
      <ContactFormDialog
        bubbleLeadId={lead}
        leadId={leadId}
        title={title}
        initialMessage={message}
        handleClose={toggleContactFormOpen}
        open={contactFormOpen}
      />
    </>
  );
}
