import { TextField, TextFieldProps } from "@mui/material";
import { ChangeEvent } from "react";

export type PhoneInputProps = TextFieldProps;

// Assumes German numbers only for now!
export const GermanPhoneInput: React.FC<PhoneInputProps> = (props: PhoneInputProps) => {
  const telephoneHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (/^\+49$|^\+49[1-9][0-9]{0,10}$/g.test(event.target.value)) {
      props.onChange?.(event);
    }
  };

  return <TextField fullWidth {...props} onChange={telephoneHandler} />;
};
