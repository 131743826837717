import { Stack } from "@mui/material";
import EnergyClasses from "../../../../../../funnels/isfp/statusquo/EnergyClasses";
import HouseStatus from "../../../../../../funnels/isfp/statusquo/HouseStatus";
import SavingsEstimates from "../../../../../../funnels/isfp/statusquo/SavingsEstimates";
import { SchnellcheckQuestionnaire, StatusColor } from "../../../../../../../types/Schnellcheck";
import { BubbleFlow, StatusCollor } from "../../../../../../../types/cockpit/types";
import {
  ceilingStatus,
  doorStatus,
  estimateIsfpBenefits,
  floorStatus,
  heatingSystemStatus,
  outerWallStatus,
  windowStatus,
} from "../../../../leadlist/StatusQuoCalculations";

export default function Report({ schnellcheck, bubbleFlow }: { schnellcheck?: SchnellcheckQuestionnaire; bubbleFlow?: BubbleFlow }) {
  if (!schnellcheck && !bubbleFlow) {
    return null;
  }
  const currentClass = schnellcheck?.report.currentEnergyClass;
  const futureClass = schnellcheck?.report.afterRenovationEnergyClass;
  let houseStatus = schnellcheck?.report.status;
  if (!houseStatus) {
    let heating;
    switch (heatingSystemStatus(bubbleFlow?.statusQuoResponse?.heatingSystemAge, bubbleFlow?.statusQuoResponse?.buildYear).color) {
      case StatusCollor.red:
        heating = StatusColor.red;
        break;
      case StatusCollor.yellow:
        heating = StatusColor.yellow;
        break;
      case StatusCollor.green:
        heating = StatusColor.green;
        break;
      default:
        heating = StatusColor.red;
    }

    let windows;
    switch (windowStatus(bubbleFlow?.statusQuoResponse?.windowRenovation, bubbleFlow?.statusQuoResponse?.buildYear).color) {
      case StatusCollor.red:
        windows = StatusColor.red;
        break;
      case StatusCollor.yellow:
        windows = StatusColor.yellow;
        break;
      case StatusCollor.green:
        windows = StatusColor.green;
        break;
      default:
        windows = StatusColor.red;
    }

    let roof;
    switch (ceilingStatus(bubbleFlow?.statusQuoResponse?.cellingRenovation, bubbleFlow?.statusQuoResponse?.buildYear).color) {
      case StatusCollor.red:
        roof = StatusColor.red;
        break;
      case StatusCollor.yellow:
        roof = StatusColor.yellow;
        break;
      case StatusCollor.green:
        roof = StatusColor.green;
        break;
      default:
        roof = StatusColor.red;
    }

    let envelope;
    switch (outerWallStatus(bubbleFlow?.statusQuoResponse?.cellingRenovation, bubbleFlow?.statusQuoResponse?.buildYear).color) {
      case StatusCollor.red:
        envelope = StatusColor.red;
        break;
      case StatusCollor.yellow:
        envelope = StatusColor.yellow;
        break;
      case StatusCollor.green:
        envelope = StatusColor.green;
        break;
      default:
        envelope = StatusColor.red;
    }

    let basement;
    switch (floorStatus(bubbleFlow?.statusQuoResponse?.floorInsulation, bubbleFlow?.statusQuoResponse?.buildYear).color) {
      case StatusCollor.red:
        basement = StatusColor.red;
        break;
      case StatusCollor.yellow:
        basement = StatusColor.yellow;
        break;
      case StatusCollor.green:
        basement = StatusColor.green;
        break;
      default:
        basement = StatusColor.red;
    }

    let doors;
    switch (doorStatus(bubbleFlow?.statusQuoResponse?.doorRenovation, bubbleFlow?.statusQuoResponse?.buildYear).color) {
      case StatusCollor.red:
        doors = StatusColor.red;
        break;
      case StatusCollor.yellow:
        doors = StatusColor.yellow;
        break;
      case StatusCollor.green:
        doors = StatusColor.green;
        break;
      default:
        doors = StatusColor.red;
    }

    houseStatus = {
      heating,
      windows,
      roof,
      envelope,
      basement,
      doors,
    };
  }
  let energyCostSavings, subsidy, trees;
  if (schnellcheck) {
    energyCostSavings = schnellcheck?.report.energyCostSavings;
    subsidy = schnellcheck?.report.subsidy;
    trees = schnellcheck?.report.trees;
  } else {
    const bubbleReport = estimateIsfpBenefits(bubbleFlow?.statusQuoResponse);
    energyCostSavings = bubbleReport.maxSavings;
    subsidy = bubbleReport.subsidy;
  }
  return (
    <Stack>
      {!!futureClass && !!currentClass && <EnergyClasses future={futureClass} current={currentClass} />}
      <HouseStatus status={houseStatus} />
      <SavingsEstimates legacy={!schnellcheck && !!bubbleFlow} energyCostSavings={energyCostSavings} subsidy={subsidy} trees={trees} />
    </Stack>
  );
}
