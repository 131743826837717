import { Typography } from "@mui/material";
import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";
import OutlineFrame from "../OutlineFrame";

export function HouseUnitsComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  return (
    <OutlineFrame label={"Anzahl der Wohneinheiten"}>
      <Typography>{renovations?.answers?.houseInformation?.units}</Typography>
    </OutlineFrame>
  );
}
