import { FormHelperText, Stack, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from "@mui/material";
import { RenovationsScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { ReverseVentilation, ReverseWaterHeating, Ventilation, WaterHeating } from "./utils";
import { useState } from "react";
import NovoSelect from "../../../../components/common/NovoSelect";
import FormScreen from "../FormScreen";
import { HotWaterAndVentilationInformation } from "../../../../types/RenovationQuestionnaire";
import NovoTextField from "../../../../components/common/NovoTextField";

export default function HotWaterAndVentilationScreen({ renovations, setRenovations, isUpdateRunning, prev, next }: RenovationsScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [waterHeatingError, setWaterHeatingError] = useState<string>();
  const [hasWaterBoilerError, setHasWaterBoilerError] = useState<string>();
  const [boilerCapacityError, setBoilerCapacityError] = useState<string>();
  const [ventilationError, setVentilationError] = useState<string>();
  const [otherVentilationError, setOtherVentilationError] = useState<string>();
  const [hotWaterAndVentilationInformation, setHotWaterAndVentilationInformation] = useState<HotWaterAndVentilationInformation>(
    renovations.answers?.hotWaterAndVentilationInformation || {},
  );

  const minCapacity = 50;
  const maxCapacity = 1500;

  const validate = () => {
    if (!hotWaterAndVentilationInformation.waterHeating) {
      setWaterHeatingError("Bitte auswählen");
      return false;
    } else {
      setWaterHeatingError(undefined);
    }
    if (!hotWaterAndVentilationInformation.hasWaterBoiler) {
      setHasWaterBoilerError("Bitte auswählen");
      return false;
    } else {
      setHasWaterBoilerError(undefined);
    }
    if (hotWaterAndVentilationInformation.hasWaterBoiler === "yes") {
      if (!hotWaterAndVentilationInformation.boilerCapacity) {
        setBoilerCapacityError("Pflichtfeld");
        return false;
      } else if (hotWaterAndVentilationInformation.boilerCapacity < minCapacity || hotWaterAndVentilationInformation.boilerCapacity > maxCapacity) {
        setBoilerCapacityError(`Muss zwischen ${minCapacity} und ${maxCapacity} liegen`);
        return false;
      } else {
        setBoilerCapacityError(undefined);
      }
    }
    if (!hotWaterAndVentilationInformation.ventilation) {
      setVentilationError("Bitte auswählen");
      return false;
    } else {
      setVentilationError(undefined);
    }
    if (hotWaterAndVentilationInformation.ventilation === "other" && !hotWaterAndVentilationInformation.otherVentilation) {
      setOtherVentilationError("Pflichtfeld");
      return false;
    } else {
      setOtherVentilationError(undefined);
    }
    return true;
  };
  const validateAndSave = () => {
    if (validate()) {
      const newState = { ...renovations, answers: { ...renovations.answers, hotWaterAndVentilationInformation } };
      setRenovations(newState);
      next(newState);
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            <strong>Schritt 7: </strong>Informationen zum Warmwasser und zur Lüftung
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <NovoSelect
            label={"Wassererwärmung"}
            options={[...Object.values(WaterHeating)]}
            value={
              hotWaterAndVentilationInformation.waterHeating ? WaterHeating[hotWaterAndVentilationInformation.waterHeating as keyof typeof WaterHeating] : ""
            }
            onUpdate={(value) => {
              const waterHeating = ReverseWaterHeating[value as keyof typeof ReverseWaterHeating];
              setHotWaterAndVentilationInformation({ ...hotWaterAndVentilationInformation, waterHeating });
              setWaterHeatingError(undefined);
            }}
            error={!!waterHeatingError}
            helperText={waterHeatingError}
            required
          />
        </Grid>
        <Grid size={12}>
          <Stack direction={isMobile ? "column" : "row"} alignItems='baseline' spacing={2}>
            <Typography fontSize={{ xs: 14, lg: 16 }}>Gibt es einen Warmwasserspeicher?</Typography>
            <ToggleButtonGroup
              size='small'
              exclusive
              value={hotWaterAndVentilationInformation.hasWaterBoiler}
              onChange={(event, value) => {
                setHotWaterAndVentilationInformation({ ...hotWaterAndVentilationInformation, hasWaterBoiler: value });
                setHasWaterBoilerError(undefined);
              }}
            >
              <ToggleButton value='yes' sx={{ fontSize: { xs: 10, lg: 13 } }}>
                Ja
              </ToggleButton>
              <ToggleButton value='no' sx={{ fontSize: { xs: 10, lg: 13 } }}>
                Nein
              </ToggleButton>
              <ToggleButton value='notSure' sx={{ fontSize: { xs: 10, lg: 13 } }}>
                Nicht sicher
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        <Grid size={12} sx={{ mt: { xs: -1, lg: -2 }, borderTop: (theme) => (hasWaterBoilerError ? `1px solid ${theme.palette.error.main}` : "") }}>
          {!!hasWaterBoilerError && <FormHelperText error>{hasWaterBoilerError}</FormHelperText>}
        </Grid>
        {hotWaterAndVentilationInformation.hasWaterBoiler === "yes" && (
          <Grid size={{ xs: 12, lg: 6 }}>
            <NovoTextField
              fullWidth
              variant='outlined'
              id='water-boiler-capacity-input'
              label='Speichervolumen in Litern'
              required={true}
              type='number'
              value={hotWaterAndVentilationInformation.boilerCapacity}
              onChange={(event) => {
                const boilerCapacity = parseInt(event.target.value);
                setHotWaterAndVentilationInformation({ ...hotWaterAndVentilationInformation, boilerCapacity });
                setBoilerCapacityError(undefined);
              }}
              slotProps={{
                htmlInput: { min: 50, max: 5000 },
              }}
              error={!!boilerCapacityError}
              helperText={boilerCapacityError}
            />
          </Grid>
        )}
        <Grid size={{ xs: 0, lg: 6 }}>
          <></>
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <NovoSelect
            label={"Lüftung"}
            required
            options={[...Object.values(Ventilation)]}
            value={hotWaterAndVentilationInformation.ventilation ? Ventilation[hotWaterAndVentilationInformation.ventilation as keyof typeof Ventilation] : ""}
            onUpdate={(value) => {
              const ventilation = ReverseVentilation[value as keyof typeof ReverseVentilation];
              setHotWaterAndVentilationInformation({ ...hotWaterAndVentilationInformation, ventilation });
              setVentilationError(undefined);
            }}
            error={!!ventilationError}
            helperText={ventilationError}
          />
        </Grid>
        {hotWaterAndVentilationInformation.ventilation === "other" && (
          <Grid size={{ xs: 12, lg: 6 }}>
            <NovoTextField
              fullWidth
              variant='outlined'
              id='other-ventilation-input'
              label='Andere Lüftung'
              required={true}
              value={hotWaterAndVentilationInformation.otherVentilation}
              onChange={(event) => {
                setHotWaterAndVentilationInformation({ ...hotWaterAndVentilationInformation, otherVentilation: event.target.value });
                setOtherVentilationError(undefined);
              }}
              error={!!otherVentilationError}
              helperText={otherVentilationError}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <FormScreen
      prev={prev}
      next={validateAndSave}
      isUpdateRunning={isUpdateRunning}
      progressLabel='Warmwasser & Lüftung'
      progressValue={58}
      content={content}
      helpContent={helpContent}
    />
  );
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Für eine optimale Planung von
      <br />
      zukünftigen Maßnahmen müssen wir
      <br />
      die bestehende Anlagentechnik
      <br />
      genau aufnehmen.
      <br />
      <br />
      Für maximale Energieeffizienz
      <br />
      in Ihrem Haus sollten
      <br />
      Warmwassererzeugung und
      <br />
      Heizung gut aufeinander abgestimmt
      <br />
      sein. Wir prüfen das für Sie.
    </Typography>
  );
}
