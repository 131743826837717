export enum Steps {
  start = "",
  general = "#general",
  floors = "#floors",
  outerWalls = "#outer-walls",
  attic = "#attic",
  basement = "#basement",
  doorsWindows = "#doors-windows",
  hotWaterVentilation = "#hot-water-ventilation",
  photovoltaics = "#photovoltaics",
  customerPreferences = "#customer-preferences",
  photoUpload = "#photo-upload",
  plansAndDocumentsUpload = "#plans-and-documents-upload",
  final = "#final",
}

export const fromHash = (value: string): Steps => {
  switch (value) {
    case "":
      return Steps.start;
    case "#general":
      return Steps.general;
    case "#floors":
      return Steps.floors;
    case "#outer-walls":
      return Steps.outerWalls;
    case "#attic":
      return Steps.attic;
    case "#basement":
      return Steps.basement;
    case "#doors-windows":
      return Steps.doorsWindows;
    case "#hot-water-ventilation":
      return Steps.hotWaterVentilation;
    case "#photovoltaics":
      return Steps.photovoltaics;
    case "#customer-preferences":
      return Steps.customerPreferences;
    case "#photo-upload":
      return Steps.photoUpload;
    case "#plans-and-documents-upload":
      return Steps.plansAndDocumentsUpload;
    case "#final":
      return Steps.final;
    default:
      throw new Error(`"${value}" is not a valid value for Steps.`);
  }
};

export enum WallMaterials {
  brick = "Ziegelmauerwerk",
  concrete = "Beton",
  wood = "Massivholzwand",
  doubleLeafWall = "zweischaliges Mauerwerk",
  hollowBrick = "Hochlochziegel",
  halfWoodBrick = "Fachwerk (Ziegel)",
  halfWoodClay = "Fachwerk (Lehm)",
}

export enum ReverseWallMaterials {
  "Ziegelmauerwerk" = "brick",
  "Beton" = "concrete",
  "Massivholzwand" = "wood",
  "zweischaliges Mauerwerk" = "doubleLeafWall",
  "Hochlochziegel" = "hollowBrick",
  "Fachwerk (Ziegel)" = "halfWoodBrick",
  "Fachwerk (Lehm)" = "halfWoodClay",
}

export enum InsulationMaterials {
  mineralWool = "Mineralwolle",
  rockWool = "Steinwolle",
  styrofoam = "Polystyrol/Styropor",
  woodFiber = "Holzfaser",
}

export enum ReverseInsulationMaterials {
  "Mineralwolle" = "mineralWool",
  "Steinwolle" = "rockWool",
  "Polystyrol/Styropor" = "styrofoam",
  "Holzfaser" = "woodFiber",
}

export enum FrameMaterial {
  wood = "Holz",
  metal = "Metall",
  plastic = "Kunststoff",
}

export enum ReverseFrameMaterial {
  "Holz" = "wood",
  "Metall" = "metal",
  "Kunststoff" = "plastic",
}

export enum WaterHeating {
  sameAsHeating = "über Heizung (zentral)",
  waterHeater = "Durchlauferhitzer (dezentral)",
  gasBoiler = "Gas-Boiler (dezentral)",
  miscellaneous = "Sonstiges",
}

export enum ReverseWaterHeating {
  "über Heizung (zentral)" = "sameAsHeating",
  "Durchlauferhitzer (dezentral)" = "waterHeater",
  "Gas-Boiler (dezentral)" = "gasBoiler",
  "Sonstiges" = "miscellaneous",
}

export enum Ventilation {
  windows = "Fensterlüftung",
  fan = "Lüftungsanlage",
  airConditioning = "Klimaanlage",
  other = "andere",
}

export enum ReverseVentilation {
  "Fensterlüftung" = "windows",
  "Lüftungsanlage" = "fan",
  "Klimaanlage" = "airConditioning",
  "andere" = "other",
}

export enum PreferredHeating {
  heatPump = "Wärmepumpe",
  biomass = "Biomasse (Holz/Pellets)",
  solar = "Solarthermie",
  disrictHeating = "Fernwärme",
  dontKnow = "ich habe dazu Beratungsbedarf",
}

export enum ReversePreferredHeating {
  "Wärmepumpe" = "heatPump",
  "Biomasse (Holz/Pellets)" = "biomass",
  "Solarthermie" = "solar",
  "Fernwärme" = "disrictHeating",
  "ich habe dazu Beratungsbedarf" = "dontKnow",
}

export enum RenovationPlans {
  exteriorWalls = "Dämmung Außenwände",
  roof = "Dämmung Dach / obere Geschossdecke",
  basement = "Dämmung Keller / Bodenplatte",
  heating = "Heizungstausch / Optimierung",
  windows = "Neue Fenster / Haustür",
  photovoltaic = "Photovoltaik",
  solar = "Solarthermie",
  fullRenovation = "Sanierung in einem Zug zum KFW Effizienzhaus",
  dontKnow = "ich habe dazu Beratungsbedarf",
}
export enum ReverseRenovationPlans {
  "Dämmung Außenwände" = "exteriorWalls",
  "Dämmung Dach / obere Geschossdecke" = "roof",
  "Dämmung Keller / Bodenplatte" = "basement",
  "Heizungstausch / Optimierung" = "heating",
  "Neue Fenster / Haustür" = "windows",
  "Photovoltaik" = "photovoltaic",
  "Solarthermie" = "solar",
  "Sanierung in einem Zug zum KFW Effizienzhaus" = "fullRenovation",
  "ich habe dazu Beratungsbedarf" = "dontKnow",
}
