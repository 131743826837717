import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useFetchIsfpDocumentUrl } from "../../../../../../hooks/useFetchIsfpDocument";
import { EXAMPLE_ISFP_ID_PREFIX, SAMPLE_ISFP_DOCUMENT_URL } from "../../PlaceholderLeadData";

export default function OpenIsfp({ id, disabled }: { id: string | undefined; disabled: boolean; dataReviewConsent?: boolean }) {
  const { fetchIsfpDocumentUrl, isfpDocumentLink, fetchDocumentRunning } = useFetchIsfpDocumentUrl();

  const openPdfDoc = () => {
    if (fetchDocumentRunning || !id) {
      return;
    }
    if (id?.startsWith(EXAMPLE_ISFP_ID_PREFIX)) {
      const newWindow = window.open(SAMPLE_ISFP_DOCUMENT_URL, "_blank", "noopener,noreferrer");
      if (newWindow) {
        newWindow.opener = null;
      }
    }
    fetchIsfpDocumentUrl(id);
  };

  useEffect(() => {
    if (isfpDocumentLink) {
      const newWindow = window.open(isfpDocumentLink.url, "_blank", "noopener,noreferrer");
      if (newWindow) {
        newWindow.opener = null;
      }
    }
  }, [isfpDocumentLink]);

  return (
    <Button color='secondary' startIcon={<FileDownloadOutlinedIcon />} onClick={openPdfDoc} disabled={disabled || fetchDocumentRunning}>
      iSFP einsehen
    </Button>
  );
}
