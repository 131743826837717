import Grid from "@mui/material/Grid2";
import { RenovationQuestionnaire } from "../../../../../../../types/RenovationQuestionnaire";
import BasementComponent from "./BasementComponent";
import BoilerComponent from "./BoilerComponent";
import { DoorMaterialComponent } from "./DoorMaterialComponent";
import ExteriorWallsComponent from "./ExteriorWallsComponent";
import FloorsComponent from "./FloorsComponent";
import FloorsHeightComponent from "./FloorsHeightComponent";
import { HasPlansComponent } from "./HasPlansComponent";
import { HeatingComponent } from "./HeatingComponent";
import HouseTypologyComponent from "./HouseTypologyComponent";
import { HouseUnitsComponent } from "./HouseUnitsComponent";
import PhotovoltaicsComponent from "./PhotovoltaicsComponent";
import { PreferredHeatingComponent } from "./PreferredHeatingComponent";
import RenovationsWishlistComponent from "./RenovationsWishlistComponent";
import RoofComponent from "./RoofComponent";
import SolarComponent from "./SolarComponent";
import { VentilationComponent } from "./VentilationComponent";
import { WindowsMaterialComponent } from "./WindowsMaterialComponent";
import { WindowsOtherTypesComponent } from "./WindowsOtherTypesComponent";
import { WindowsYearComponent } from "./WindowsYearComponent";

export default function RenovationsAnswers({ renovations }: { renovations?: RenovationQuestionnaire }) {
  if (!renovations) {
    return null;
  }

  return (
    <Grid
      container
      spacing={4}
      sx={{
        maxWidth: "800px",
      }}
    >
      <Grid size={12}>
        <HouseTypologyComponent renovations={renovations} />
      </Grid>
      <Grid size={3}>
        <HouseUnitsComponent renovations={renovations} />
      </Grid>
      <Grid size={6}>
        <HasPlansComponent renovations={renovations} />
      </Grid>
      <Grid size={3}>
        <FloorsComponent renovations={renovations} />
      </Grid>
      <Grid size={12}>
        <FloorsHeightComponent renovations={renovations} />
      </Grid>
      <Grid size={12}>
        <ExteriorWallsComponent renovations={renovations} />
      </Grid>
      <Grid size={12}>
        <RoofComponent renovations={renovations} />
      </Grid>
      <Grid size={12}>
        <BasementComponent renovations={renovations} />
      </Grid>
      <Grid size={6}>
        <WindowsYearComponent renovations={renovations} />
      </Grid>
      <Grid size={6}>
        <WindowsMaterialComponent renovations={renovations} />
      </Grid>
      <Grid size={6}>
        <WindowsOtherTypesComponent renovations={renovations} />
      </Grid>
      <Grid size={6}>
        <DoorMaterialComponent renovations={renovations} />
      </Grid>
      <Grid size={6}>
        <HeatingComponent renovations={renovations} />
      </Grid>
      <Grid size={6}>
        <VentilationComponent renovations={renovations} />
      </Grid>
      <Grid size={12}>
        <BoilerComponent renovations={renovations} />
      </Grid>
      <Grid size={12}>
        <PhotovoltaicsComponent renovations={renovations} />
      </Grid>
      <Grid size={12}>
        <SolarComponent renovations={renovations} />
      </Grid>
      <Grid size={6}>
        <PreferredHeatingComponent renovations={renovations} />
      </Grid>
      <Grid size={6} />
      <Grid size={12}>
        <RenovationsWishlistComponent renovations={renovations} />
      </Grid>
    </Grid>
  );
}
