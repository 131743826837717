import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import { Skeleton, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useFetchConsumptionCertificates } from "../../../hooks/useFetchConsumptionCertificates";
import { useReachedScrollBottom } from "../../../hooks/useReachedScrollBottom";
import { ConsumptionCertificate, ConsumptionCertificateGenState } from "../../../types/ConsumptionCertificateState";
import { CONSUMPTION_CERT_COLUMN_COUNT, ConsumptionCertificateTable, ConsumptionCertTableEntry, Order, OrderBy } from "./ConsumptionCertificateTable";

export function ConsumptionCertificateList() {
  const { isLoading, fetchData, consumptionCerts, error } = useFetchConsumptionCertificates();
  const [consumptionCertsPages, setConsumptionCertsPages] = useState<ConsumptionCertTableEntry[][]>([]);
  const reachedScreenBottom = useReachedScrollBottom(20);
  const [lastFetchEmpty, setLastFetchEmpty] = useState(false);

  const [fetchParams, setFetchParams] = useState<{
    page: number;
    pageSize: number;
    search?: string;
    state?: string;
    orderBy?: string;
    order?: "asc" | "desc";
  }>({
    page: 0,
    pageSize: 20,
  });

  useEffect(() => {
    if (consumptionCerts?.length) {
      const updatedCertPages = [...consumptionCertsPages];
      updatedCertPages[fetchParams.page] = toTableRows(consumptionCerts);
      setConsumptionCertsPages(updatedCertPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumptionCerts]);

  useEffect(() => {
    if (!isLoading) {
      setLastFetchEmpty(consumptionCerts?.length != undefined && consumptionCerts.length < fetchParams.pageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (!lastFetchEmpty && fetchParams.order && fetchParams.orderBy && fetchParams.pageSize) {
      fetchData(fetchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchParams]);

  const resetView = () => {
    setConsumptionCertsPages([]);
    setLastFetchEmpty(false);
  };

  useEffect(() => {
    if (reachedScreenBottom) {
      setFetchParams({ ...fetchParams, page: fetchParams.page + 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reachedScreenBottom]);

  const searchChangeHandler = (_: React.SyntheticEvent, value: string) => {
    resetView();
    setFetchParams({ ...fetchParams, search: value, page: 0 });
  };

  const orderByChangeHandler = (ordering: { order: Order; orderBy: OrderBy }) => {
    resetView();
    setFetchParams({ ...fetchParams, ...ordering, page: 0 });
  };

  const refreshHandler = () => {
    resetView();
    // if page argument is greater than 0, it suffices to reset it back to 0 to trigger a page refresh
    if (fetchParams.page > 0) {
      setFetchParams({ ...fetchParams, page: 0 });
      // otherwise, just call fetch data with current search and paging parameters
    } else {
      fetchData(fetchParams);
    }
  };

  const onDeleteHandler = (changed: ConsumptionCertTableEntry) => {
    setConsumptionCertsPages([...consumptionCertsPages].map((page) => page.filter((cert) => cert.id != changed.id)));
  };

  return (
    <Box sx={{ flexGrow: CONSUMPTION_CERT_COLUMN_COUNT, minHeight: 800, width: "100%", pt: 2, px: 2 }}>
      <Stack>
        <Typography pb={2} variant='h5' style={{ fontWeight: 600 }} data-cy='consumption-certs-table'>
          Verbrauchsausweise
        </Typography>
        <TableTopActions onSearchChange={searchChangeHandler} onRefresh={refreshHandler} />
        <ConsumptionCertificateTable
          isLoading={isLoading}
          error={error ? "Es ist ein Fehler aufgetreten. Bitte versuche es erneut." : undefined}
          data={consumptionCertsPages.reduce((a, b) => a.concat(b), [])}
          onDelete={onDeleteHandler}
          onOrderByChange={orderByChangeHandler}
        />
        {!lastFetchEmpty && <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />}
        {lastFetchEmpty && !isLoading && consumptionCertsPages?.length != 0 && (
          <Typography align='center' pt={2} pb={5}>
            Keine weitere Einträge
          </Typography>
        )}
      </Stack>
    </Box>
  );
}

type TableTopActionsProps = {
  onSearchChange: (event: React.SyntheticEvent, value: string) => void;
  onRefresh: () => void;
};
function TableTopActions({ onSearchChange, onRefresh }: TableTopActionsProps) {
  return (
    <Box>
      <Stack direction={"row"} spacing={2}>
        <Autocomplete
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label='Suchen'
              data-cy={"search-isfp-process-input"}
              placeholder='Name, Email, Berater-Email...'
              slotProps={{
                input: {
                  ...params.InputProps,
                  type: "search",
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          )}
          options={[]}
          onChange={onSearchChange}
          sx={{ flexGrow: 1, bgcolor: "background.default" }}
        />
        <Button data-cy='refresh-isfp-list-btn' variant='outlined' color='secondary' sx={{ fontSize: 12 }} onClick={onRefresh}>
          <RefreshIcon />
        </Button>
      </Stack>
    </Box>
  );
}

const toTableRows = (props: ConsumptionCertificate[] = []): ConsumptionCertTableEntry[] => {
  if (typeof props === "object" && !Array.isArray(props)) {
    return [];
  }
  return props.map(toTableRow);
};

export const toTableRow = (consumptionCert: ConsumptionCertificate): ConsumptionCertTableEntry => {
  return {
    id: consumptionCert.id,
    createdAt: consumptionCert.createdAt,
    state: consumptionCert.state,
    address: consumptionCert.answers.address,
    leadName: consumptionCert.lead?.name,
    leadEmail: consumptionCert.lead?.email,
    referrer: consumptionCert.lead?.referrer,
    purchaseOrderId: consumptionCert.purchaseOrder?.id,
    purchaseOrder: {
      id: consumptionCert.purchaseOrder?.id,
      status: consumptionCert.purchaseOrder?.payment.status,
    },
    actions: {
      id: consumptionCert.id,
      isPdfAvailable: consumptionCert.document?.state === ConsumptionCertificateGenState.Ready,
      state: consumptionCert.state,
      finalizeCertData: {
        id: consumptionCert.id,
        address: `${consumptionCert.answers.address ? `${consumptionCert.answers.address}, ` : ""}${consumptionCert.answers.postalCode ? `${consumptionCert.answers.postalCode} - ` : ""}${consumptionCert.answers.location || ""}`,
      },
    },
  };
};
