import { Box, FormHelperText, Grow, InputAdornment, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { RenovationsScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import FormScreen from "../FormScreen";
import { RenewablesInformation } from "../../../../types/RenovationQuestionnaire";
import NovoTextField from "../../../../components/common/NovoTextField";

export default function PhotovoltaicsScreen({ renovations, setRenovations, isUpdateRunning, prev, next }: RenovationsScreenProps) {
  const [photovoltaicsInstalledError, setPhotovoltaicsInstalledError] = useState<string>();
  const [photovoltaicsCapacityError, setPhotovoltaicsCapacityError] = useState<string>();
  const [photovoltaicsInstallationYearError, setPhotovoltaicsInstallationYearError] = useState<string>();
  const [isFullfeedError, setIsFullfeedError] = useState<string>();
  const [isBatteryInstalledError, setIsBatteryInstalledError] = useState<string>();
  const [batteryCapacityError, setBatteryCapacityError] = useState<string>();
  const [batteryInstallationYearError, setBatteryInstallationYearError] = useState<string>();
  const [solarInstalledError, setSolarInstalledError] = useState<string>();
  const [solarCapacityError, setSolarCapacityError] = useState<string>();
  const [renewablesInformation, setRenewablesInformation] = useState<RenewablesInformation>(renovations.answers?.renewablesInformation || {});

  const minPVCapacity = 0.6;
  const maxPVCapacity = 30;
  const minBatteryCapacity = 1;
  const maxBatteryCapacity = 25;
  const minYear = renovations.constructionYear ? Math.max(renovations.constructionYear, 2000) : 2000;
  const maxYear = new Date().getFullYear();

  const validate = () => {
    if (renewablesInformation.photovoltaicsInstalled === undefined) {
      setPhotovoltaicsInstalledError("Bitte auswählen");
      return false;
    } else {
      setPhotovoltaicsInstalledError(undefined);
    }
    if (renewablesInformation.photovoltaicsInstalled) {
      if (!renewablesInformation.photovoltaicsCapacity) {
        setPhotovoltaicsCapacityError("Pflichtfeld");
        return false;
      } else if (renewablesInformation.photovoltaicsCapacity < minPVCapacity || renewablesInformation.photovoltaicsCapacity > maxPVCapacity) {
        setPhotovoltaicsCapacityError(`Muss zwischen ${minPVCapacity} und ${maxPVCapacity} liegen`);
        return false;
      } else {
        setPhotovoltaicsCapacityError(undefined);
      }
      if (!renewablesInformation.photovoltaicsInstallationYear) {
        setPhotovoltaicsInstallationYearError("Pflichtfeld");
        return false;
      } else if (renewablesInformation.photovoltaicsInstallationYear < minYear || renewablesInformation.photovoltaicsInstallationYear > maxYear) {
        setPhotovoltaicsInstallationYearError(`Muss zwischen ${minYear} und ${maxYear} liegen`);
        return false;
      } else {
        setPhotovoltaicsInstallationYearError(undefined);
      }
      if (renewablesInformation.isFullfeed === undefined) {
        setIsFullfeedError("Bitte auswählen");
        return false;
      } else if (!renewablesInformation.isFullfeed) {
        if (renewablesInformation.isBatteryInstalled === undefined) {
          setIsBatteryInstalledError("Bitte auswählen");
          return false;
        } else if (renewablesInformation.isBatteryInstalled) {
          if (!renewablesInformation.batteryCapacity) {
            setBatteryCapacityError("Pflichtfeld");
            return false;
          } else if (renewablesInformation.batteryCapacity < minBatteryCapacity || renewablesInformation.batteryCapacity > maxBatteryCapacity) {
            setBatteryCapacityError(`Muss zwischen ${minBatteryCapacity} und ${maxBatteryCapacity} liegen`);
            return false;
          } else {
            setBatteryCapacityError(undefined);
          }
          if (!renewablesInformation.batteryInstallationYear) {
            setBatteryInstallationYearError("Pflichtfeld");
            return false;
          } else if (renewablesInformation.batteryInstallationYear < minYear || renewablesInformation.batteryInstallationYear > maxYear) {
            setBatteryInstallationYearError(`Muss zwischen ${minYear} und ${maxYear} liegen`);
            return false;
          } else {
            setBatteryInstallationYearError(undefined);
          }
        } else {
          setIsBatteryInstalledError(undefined);
        }
      } else {
        setIsFullfeedError(undefined);
      }
    }
    if (renewablesInformation.solarInstalled === undefined) {
      setSolarInstalledError("Bitte auswählen");
      return false;
    } else if (renewablesInformation.solarInstalled) {
      if (!renewablesInformation.solarCapacity) {
        setSolarCapacityError("Pflichtfeld");
        return false;
      } else {
        setSolarCapacityError(undefined);
      }
    } else {
      setSolarInstalledError(undefined);
    }
    return true;
  };
  const validateAndSave = () => {
    if (validate()) {
      const newState = { ...renovations, answers: { ...renovations.answers, renewablesInformation } };
      setRenovations(newState);
      next(newState);
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            <strong>Schritt 8: </strong>Informationen zu Erneuerbaren Energien
          </Typography>
        </Grid>
        <Grid size={12}>
          <Stack direction='row' alignItems='baseline' spacing={2}>
            <Typography flexGrow={1} maxWidth='50%' fontSize={{ xs: 14, lg: 16 }}>
              Ist aktuell Photovoltaik vorhanden?
            </Typography>
            <ToggleButtonGroup
              size='small'
              exclusive
              value={renewablesInformation.photovoltaicsInstalled === undefined ? "" : renewablesInformation.photovoltaicsInstalled ? "yes" : "no"}
              onChange={(event, value) => {
                if (value === "yes") {
                  setRenewablesInformation({ ...renewablesInformation, photovoltaicsInstalled: true });
                } else if (value === "no") {
                  setRenewablesInformation({ ...renewablesInformation, photovoltaicsInstalled: false });
                } else {
                  setRenewablesInformation({ ...renewablesInformation, photovoltaicsInstalled: undefined });
                }
                setPhotovoltaicsInstalledError(undefined);
              }}
            >
              <ToggleButton value='yes' sx={{ fontSize: { xs: 10, lg: 13 } }}>
                Ja
              </ToggleButton>
              <ToggleButton value='no' sx={{ fontSize: { xs: 10, lg: 13 } }}>
                Nein
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        <Grid size={12} sx={{ mt: { xs: -1, lg: -2 }, borderTop: (theme) => (photovoltaicsInstalledError ? `1px solid ${theme.palette.error.main}` : "") }}>
          {!!photovoltaicsInstalledError && <FormHelperText error>{photovoltaicsInstalledError}</FormHelperText>}
        </Grid>
        {renewablesInformation.photovoltaicsInstalled && (
          <>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Grow in timeout={800}>
                <Box>
                  <NovoTextField
                    fullWidth
                    variant='outlined'
                    id='pv-panels-capacity-input'
                    label='Photovoltaik Kapazität'
                    required={true}
                    type='number'
                    value={renewablesInformation.photovoltaicsCapacity ?? ""}
                    onChange={(event) => {
                      const photovoltaicsCapacity = parseInt(event.target.value);
                      setRenewablesInformation({ ...renewablesInformation, photovoltaicsCapacity });
                      setPhotovoltaicsCapacityError(undefined);
                    }}
                    slotProps={{
                      htmlInput: {
                        min: minPVCapacity,
                        max: maxPVCapacity,
                      },
                      input: {
                        endAdornment: <InputAdornment position='end'>kWp</InputAdornment>,
                      },
                    }}
                    error={!!photovoltaicsCapacityError}
                    helperText={photovoltaicsCapacityError}
                  />
                </Box>
              </Grow>
            </Grid>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Grow in timeout={800}>
                <Box>
                  <NovoTextField
                    fullWidth
                    variant='outlined'
                    id='pv-panels-year-input'
                    label='Photovoltaik Installationsjahr'
                    required={true}
                    type='number'
                    value={renewablesInformation.photovoltaicsInstallationYear ?? ""}
                    onChange={(event) => {
                      const photovoltaicsInstallationYear = parseInt(event.target.value);
                      setRenewablesInformation({ ...renewablesInformation, photovoltaicsInstallationYear });
                      setPhotovoltaicsInstallationYearError(undefined);
                    }}
                    slotProps={{
                      htmlInput: {
                        min: minYear,
                        max: maxYear,
                      },
                    }}
                    error={!!photovoltaicsInstallationYearError}
                    helperText={photovoltaicsInstallationYearError}
                  />
                </Box>
              </Grow>
            </Grid>
            <Grid size={12}>
              <Grow in timeout={800}>
                <Stack direction='row' alignItems='baseline' spacing={2}>
                  <Typography flexGrow={1} maxWidth='50%' fontSize={{ xs: 14, lg: 16 }}>
                    Betreiben Sie PV-Volleinspeisung in das Stromnetz?
                  </Typography>
                  <ToggleButtonGroup
                    size='small'
                    exclusive
                    value={renewablesInformation.isFullfeed === undefined ? "" : renewablesInformation.isFullfeed ? "yes" : "no"}
                    onChange={(event, value) => {
                      if (value === "yes") {
                        setRenewablesInformation({ ...renewablesInformation, isFullfeed: true });
                      } else if (value === "no") {
                        setRenewablesInformation({ ...renewablesInformation, isFullfeed: false });
                      } else {
                        setRenewablesInformation({ ...renewablesInformation, isFullfeed: undefined });
                      }
                      setIsFullfeedError(undefined);
                    }}
                    sx={{ flexGrow: 1 }}
                  >
                    <ToggleButton value='yes' sx={{ fontSize: { xs: 10, lg: 13 } }}>
                      Ja
                    </ToggleButton>
                    <ToggleButton value='no' sx={{ fontSize: { xs: 10, lg: 13 } }}>
                      Nein
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </Grow>
            </Grid>
            <Grid size={12} sx={{ mt: { xs: -1, lg: -2 }, borderTop: (theme) => (isFullfeedError ? `1px solid ${theme.palette.error.main}` : "") }}>
              {!!isFullfeedError && <FormHelperText error>{isFullfeedError}</FormHelperText>}
            </Grid>
          </>
        )}
        {renewablesInformation.photovoltaicsInstalled && !renewablesInformation.isFullfeed && (
          <>
            <Grid size={12}>
              <Grow in timeout={800}>
                <Stack direction='row' alignItems='baseline' spacing={2}>
                  <Typography flexGrow={1} maxWidth='50%' fontSize={{ xs: 14, lg: 16 }}>
                    Ist ein Batteriespeicher in Verbindung mit der PV-Anlage vorhanden?
                  </Typography>
                  <ToggleButtonGroup
                    size='small'
                    exclusive
                    value={renewablesInformation.isBatteryInstalled === undefined ? "" : renewablesInformation.isBatteryInstalled ? "yes" : "no"}
                    onChange={(event, value) => {
                      if (value === "yes") {
                        setRenewablesInformation({ ...renewablesInformation, isBatteryInstalled: true });
                      } else if (value === "no") {
                        setRenewablesInformation({ ...renewablesInformation, isBatteryInstalled: false });
                      } else {
                        setRenewablesInformation({ ...renewablesInformation, isBatteryInstalled: undefined });
                      }
                      setIsBatteryInstalledError(undefined);
                    }}
                    sx={{ flexGrow: 1 }}
                  >
                    <ToggleButton value='yes' sx={{ fontSize: { xs: 10, lg: 13 } }}>
                      Ja
                    </ToggleButton>
                    <ToggleButton value='no' sx={{ fontSize: { xs: 10, lg: 13 } }}>
                      Nein
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </Grow>
            </Grid>
            <Grid size={12} sx={{ mt: { xs: -1, lg: -2 }, borderTop: (theme) => (isBatteryInstalledError ? `1px solid ${theme.palette.error.main}` : "") }}>
              {!!isBatteryInstalledError && <FormHelperText error>{isBatteryInstalledError}</FormHelperText>}
            </Grid>
          </>
        )}
        {renewablesInformation.photovoltaicsInstalled && !renewablesInformation.isFullfeed && renewablesInformation.isBatteryInstalled && (
          <>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Grow in timeout={800}>
                <Box>
                  <NovoTextField
                    fullWidth
                    variant='outlined'
                    id='pv-battery-capacity-input'
                    label='Batterie Kapazität'
                    required={true}
                    type='number'
                    value={renewablesInformation.batteryCapacity ?? ""}
                    onChange={(event) => {
                      const batteryCapacity = parseInt(event.target.value);
                      setRenewablesInformation({ ...renewablesInformation, batteryCapacity });
                      setBatteryCapacityError(undefined);
                    }}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position='end'>kWh</InputAdornment>,
                      },
                      htmlInput: { min: minBatteryCapacity, max: maxBatteryCapacity },
                    }}
                    error={!!batteryCapacityError}
                    helperText={batteryCapacityError}
                  />
                </Box>
              </Grow>
            </Grid>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Grow in timeout={800}>
                <Box>
                  <NovoTextField
                    fullWidth
                    variant='outlined'
                    id='pv-battery-year-input'
                    label='Installationsjahr Batteriespeicher'
                    required={true}
                    type='number'
                    value={renewablesInformation.batteryInstallationYear ?? ""}
                    onChange={(event) => {
                      const batteryInstallationYear = parseInt(event.target.value);
                      setRenewablesInformation({ ...renewablesInformation, batteryInstallationYear });
                      setBatteryInstallationYearError(undefined);
                    }}
                    slotProps={{
                      htmlInput: { min: minYear, max: maxYear },
                    }}
                    error={!!batteryInstallationYearError}
                    helperText={batteryInstallationYearError}
                  />
                </Box>
              </Grow>
            </Grid>
          </>
        )}
        <Grid size={12}>
          <Stack direction='row' alignItems='baseline' spacing={2}>
            <Typography flexGrow={1} maxWidth='50%' fontSize={{ xs: 14, lg: 16 }}>
              Ist Solarthermie vorhanden?
            </Typography>
            <ToggleButtonGroup
              size='small'
              exclusive
              sx={{ flexGrow: 1 }}
              value={renewablesInformation.solarInstalled === undefined ? "" : renewablesInformation.solarInstalled ? "yes" : "no"}
              onChange={(event, value) => {
                if (value === "yes") {
                  setRenewablesInformation({ ...renewablesInformation, solarInstalled: true });
                } else if (value === "no") {
                  setRenewablesInformation({ ...renewablesInformation, solarInstalled: false });
                } else {
                  setRenewablesInformation({ ...renewablesInformation, solarInstalled: undefined });
                }
                setSolarInstalledError(undefined);
              }}
            >
              <ToggleButton value='yes' sx={{ fontSize: { xs: 10, lg: 13 } }}>
                Ja
              </ToggleButton>
              <ToggleButton value='no' sx={{ fontSize: { xs: 10, lg: 13 } }}>
                Nein
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        <Grid size={12} sx={{ mt: { xs: -1, lg: -2 }, borderTop: (theme) => (solarInstalledError ? `1px solid ${theme.palette.error.main}` : "") }}>
          {!!solarInstalledError && <FormHelperText error>{solarInstalledError}</FormHelperText>}
        </Grid>
        {renewablesInformation.solarInstalled && (
          <Grid size={{ xs: 12, lg: 6 }}>
            <Grow in timeout={800}>
              <Box>
                <NovoTextField
                  fullWidth
                  variant='outlined'
                  id='solar-capacity-input'
                  label='Größe'
                  required={true}
                  type='number'
                  value={renewablesInformation.solarCapacity ?? ""}
                  onChange={(event) => {
                    const solarCapacity = parseInt(event.target.value);
                    setRenewablesInformation({ ...renewablesInformation, solarCapacity });
                    setSolarCapacityError(undefined);
                  }}
                  slotProps={{
                    htmlInput: {
                      min: 1,
                      max: 50,
                    },
                    input: {
                      endAdornment: <InputAdornment position='end'>m²</InputAdornment>,
                    },
                  }}
                  error={!!solarCapacityError}
                  helperText={solarCapacityError}
                />
              </Box>
            </Grow>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <FormScreen
      prev={prev}
      next={validateAndSave}
      isUpdateRunning={isUpdateRunning}
      progressLabel='Erneuerbare Energien'
      progressValue={66}
      content={content}
      helpContent={helpContent}
    />
  );
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Sollte Ihr Haus bereits über
      <br />
      Erneuerbare Energien verfügen,
      <br />
      beziehen wir diese Kapazität in
      <br />
      unsere Sanierungsempfehlungen mit ein.
      <br />
      Falls Sie keine Erneuerbaren Energien
      <br />
      haben, prüfen wir, ob Ihr Gebäude
      <br />
      dafür geeignet ist und weisen auf
      <br />
      Förderprogramme auf Bundesebene hin.
    </Typography>
  );
}
