import { createTheme } from "@mui/material/styles";
import { Themes } from "../types/types";
import novo from "./novo";

const coba = createTheme(novo, {
  name: Themes.COBA,
  logo: "CoBa_by_NOVO.png",
});

export default coba;
