import { useCallback } from "react";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";
import { ConsultantInvitationResponse } from "../types/cockpit/types";

export const API_ENDPOINT = `${apiEndpoint()}/consultant/invitation`;

export default function useInviteConsultantToCockpit() {
  const { authAxios, error, running, data } = useAuthAxios<ConsultantInvitationResponse>();

  const inviteConsultantToCockpit = useCallback(
    async (email: string) =>
      await authAxios({
        method: "POST",
        baseURL: API_ENDPOINT,
        data: { email },
      }),
    [authAxios],
  );

  return { inviteConsultantToCockpit, error, sendingInvite: running, inviteResponse: data };
}
