import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FunctionComponent } from "react";
import novo from "../../imgs/novo_banner.jpeg";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import ConsentSnackbar from "./Consent";

export const SPLASH = "splash";

type SplashProps = {
  question: number;
  setNextQuestion: (questionNbr: number) => void;
};

export const Splash: FunctionComponent<SplashProps> = ({ setNextQuestion }) => {
  return (
    <>
      <Stack spacing={0} alignItems='center' style={{ width: "100%" }}>
        <img src={novo} className='Splash-image' />
        <Grid container justifyContent='center' paddingTop={"1vh"} paddingLeft={"5vw"} paddingRight={"5vw"}>
          <Stack spacing={"1vh"}>
            <Typography style={{ fontSize: "calc(4px + 4vw)", fontWeight: 600 }} color='#e18931'>
              Energieberatung online - jetzt loslegen!
            </Typography>
            <Typography style={{ fontSize: "calc(4px + 2vw)", fontWeight: 400 }} color='#707172'>
              Unser Partner NOVO bietet Ihnen eine digitale Energieberatung. Verlieren Sie keine Zeit und starten Sie jetzt mit einem kostenlosen Schnell-Check.
              Hinweis: Mit Klick auf den Link verlassen Sie die FörderWelt.
            </Typography>
            <Stack p={3} alignItems={"center"}>
              <Button variant='contained' style={{ width: "fit-content" }} onClick={() => setNextQuestion(1)} data-cy='start-process-btn'>
                <Typography style={{ fontSize: "calc(4px + 2vw)" }}>Starten Sie den kostenlosen Schnellcheck</Typography>
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Stack>
      <ConsentSnackbar />
    </>
  );
};
