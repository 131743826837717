import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import InviteList from "./InviteList";

export default function Leads() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        ml: "56px",
        width: "calc(100% - 56px)",
      }}
    >
      <Grid
        data-joyride='lead-invites-list-container'
        container
        spacing={2}
        columnSpacing={{
          xs: 2,
          xl: 4,
        }}
        sx={{
          mt: 2,
          maxWidth: "xl",
          mx: { xs: 0, xl: "auto" },
        }}
      >
        <InviteList />
      </Grid>
    </Box>
  );
}
