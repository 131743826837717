import { apiEndpoint } from "../utils/params";
import { useCallback } from "react";
import { useAuthAxios } from "./useAuthAxios";

export default function useDeleteProperty() {
  const { authAxios } = useAuthAxios();

  const deleteProperty = useCallback(
    async (id: string) =>
      await authAxios({
        method: "DELETE",
        baseURL: `${apiEndpoint()}/properties/${id}`,
      }),
    [authAxios],
  );

  return deleteProperty;
}
