import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export interface ThemeSelectorProps {
  themes: Array<string>;
  currentTheme: string;
  setTheme: (theme: string) => void;
}

export default function ThemeSelector({ themes, currentTheme, setTheme }: ThemeSelectorProps) {
  const selectHandler = (event: SelectChangeEvent) => {
    setTheme(event.target.value as string);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id='theme-selector-label'>Theme</InputLabel>
        <Select labelId='theme-selector-label' id='theme-selector' value={currentTheme} label='Theme' onChange={selectHandler}>
          {themes.map((theme) => ThemeItem(theme))};
        </Select>
      </FormControl>
    </Box>
  );
}

function ThemeItem(theme: string) {
  return (
    <MenuItem key={theme} value={theme}>
      {theme}
    </MenuItem>
  );
}
