import { useCallback } from "react";
import { Md5 } from "ts-md5";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";
import { ConsumptionCertificate } from "../types/ConsumptionCertificateState";

export const API_ENDPOINT = `${apiEndpoint()}/consumption-certificate`;

export type FinalizeCertRequest = {
  certificateId: string;
  dibtUser: string;
  // unhashed value, will be hashed to md5 when sent to server (that's how it is used by DIBt servers, out of our control :|)
  dibtPassword: string;
};

export const useFinalizeConsumptionCert = () => {
  const {
    authAxios,
    error: finalizeCertError,
    resetState: resetStateFinalizeCert,
    running: finalizeCertIsRunning,
    responseStatus: finalizeCertResponseStatus,
  } = useAuthAxios<ConsumptionCertificate>();

  const postFinalizeCert = useCallback(
    async (finalizeCertRequest: FinalizeCertRequest) =>
      authAxios({
        method: "POST",
        baseURL: `${API_ENDPOINT}/${finalizeCertRequest.certificateId}/finalize`,
        data: JSON.stringify({ ...finalizeCertRequest, dibtPassword: Md5.hashStr(finalizeCertRequest.dibtPassword) }),
        headers: {
          "content-type": "application/json",
        },
      }),
    [authAxios],
  );

  return { postFinalizeCert, finalizeCertError, resetStateFinalizeCert, finalizeCertResponseStatus, finalizeCertIsRunning };
};
