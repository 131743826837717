import Dialog from "@mui/material/Dialog";
import { BubbleFlow } from "../../../../types/cockpit/types";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

interface DisplayBubbleFlowRenovationDialogProps {
  bubbleFlow?: BubbleFlow;
  open: boolean;
  handleClose: () => void;
}

export function DisplayBubbleFlowRenovationDialog({ bubbleFlow, open, handleClose }: DisplayBubbleFlowRenovationDialogProps) {
  if (!bubbleFlow) return null;
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Antworten zum iSFP-Fragebogen
        <Button
          sx={{ float: "inline-end", color: "rgba(0, 0, 0, 0.54)", ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
          onClick={handleClose}
          endIcon={<CloseIcon />}
        >
          Schließen
        </Button>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Welche Verglasung haben deine Fenster?
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              {bubbleFlow.renovationResponse?.windowGlazing || "--"}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Woraus bestehen die Fensterrahmen?
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              {bubbleFlow.renovationResponse?.windowFrame || "--"}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Wie sind die Außenwände deines Hauses beschaffen?
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              {bubbleFlow.renovationResponse?.exteriorWalls?.join(",") || "--"}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Schauen wir uns die Geschossdecken in deinem Haus an: Woraus bestehen sie?
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              {bubbleFlow.renovationResponse?.floor || "--"}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Hat dein Haus zurzeit Beschädigungen, die zu Zugluft oder Feuchtigkeit führen? Wenn ja, gib&apos; uns bitte eine kurze Beschreibung, wo sie sich
              befinden.
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              {bubbleFlow.renovationResponse?.damages || "--"}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Welche Art von Wärmeerzeuger hat deine Heizung?
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              {bubbleFlow.renovationResponse?.heatGenerator || "--"}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Hast du einen Pufferspeicher an der Heizung?
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              {bubbleFlow.renovationResponse?.heaterBufferTank || "--"}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Wie groß ist dein Pufferspeicher?
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              {bubbleFlow.renovationResponse?.bufferTankSize || "--"}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Gibt es eine Zirkulationspumpe für Heißwasser?
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              {bubbleFlow.renovationResponse?.hotWaterCirculationPump || "--"}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Gibt es einen Heißwassertank?
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              {bubbleFlow.renovationResponse?.hotWaterTank || "--"}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Wird das Warmwasser zentral oder dezentral (mit Boilern oder Durchlauferhitzern) erzeugt?
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              {bubbleFlow.renovationResponse?.hotWaterSystem || "--"}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Wurde für deine Heizung schon mal ein hydraulischer Abgleich durchgeführt?
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              {bubbleFlow.renovationResponse?.heaterHydraulicallyBalanced || "--"}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Ist dein Dachboden beheizt? (teilweise oder vollständig)
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              {bubbleFlow.renovationResponse?.atticHeated || "--"}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Ist dein Keller beheizt? (teilweise oder vollständig)
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              {bubbleFlow.renovationResponse?.basementHeated || "--"}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Sind deine Heizungsrohre gedämmt?
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              {bubbleFlow.renovationResponse?.heatingPipesInsulation || "--"}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant='overline' lineHeight={0} fontSize={11}>
              Welche Luftöffnungen hat dein Haus außer den Fenstern?
            </Typography>
            <Typography variant='body2' fontWeight={600}>
              {bubbleFlow.renovationResponse?.airVents || "--"}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='secondary' onClick={handleClose}>
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
