import { Checkbox, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { HeatingSystemType, SchnellcheckQuestionnaire } from "../../../../../../../types/Schnellcheck";
import { BubbleFlow } from "../../../../../../../types/cockpit/types";
import { allHeatingTypes, getHeatingLabel } from "../../../../../../funnels/isfp/statusquo/utils";
import OutlineFrame from "../OutlineFrame";

export default function HeatingTypeComponent({ schnellcheck, bubbleFlow }: { schnellcheck?: SchnellcheckQuestionnaire; bubbleFlow?: BubbleFlow }) {
  if (!schnellcheck && !bubbleFlow) {
    return null;
  }
  let heatingType = schnellcheck?.answers?.houseHeating?.type;
  if (!heatingType) {
    switch (bubbleFlow?.statusQuoResponse?.heatingType) {
      case "Gas":
        heatingType = HeatingSystemType.gas;
        break;

      case "Öl":
        heatingType = HeatingSystemType.oil;
        break;

      case "Holz (pellets)":
        heatingType = HeatingSystemType.woodPellets;
        break;

      case "Holz(pellets)":
        heatingType = HeatingSystemType.woodPellets;
        break;

      case "Strom":
        heatingType = HeatingSystemType.electricity;
        break;

      case "Fernwärme":
        heatingType = HeatingSystemType.districtHeating;
        break;

      case "Wärmepumpe":
        heatingType = HeatingSystemType.heatPump;
        break;

      case "Flüssiggas":
        heatingType = HeatingSystemType.liquefiedPetroleumGas;
        break;

      default:
        heatingType = HeatingSystemType.other;
        break;
    }
  }

  return (
    <OutlineFrame label={"Heizungsystem"}>
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
      >
        {allHeatingTypes().map((system) => (
          <Grid key={system} size={{ xs: 6, md: 4 }}>
            <FormControlLabel
              control={
                <Checkbox
                  name={`checkbox-${system}`}
                  sx={{ "&.Mui-checked": { color: "text.primary" }, cursor: "default" }}
                  id={`${system}-heating-system-option`}
                  checked={heatingType === system}
                />
              }
              label={getHeatingLabel(system)}
              sx={{ "& > span": { fontSize: { xs: 14, lg: 16 } }, cursor: "default" }}
            />
          </Grid>
        ))}
      </Grid>
    </OutlineFrame>
  );
}
