import Grid from "@mui/material/Grid2";
import { Insulation } from "../../../../../../types/RenovationQuestionnaire";
import { QuestionComponent } from "./QuestionComponent";
import OutlineFrame from "./OutlineFrame";
import { Stack, Typography } from "@mui/material";
import { InsulationMaterials } from "../../../../../funnels/isfp/renovations/utils";

export default function InsulationAnswerComponent({ question, insulation }: { question: string; insulation?: Insulation }) {
  const options = [
    { label: "Ja", selected: !!insulation?.installed },
    { label: "Nein", selected: !insulation?.installed },
  ];
  return (
    <Grid container spacing={4}>
      <Grid size={12}>
        <QuestionComponent question={question} options={options} />
      </Grid>
      <Grid size={4}>
        <OutlineFrame label={"Jahr der Dämmung"}>
          <Typography>{insulation?.year || "--"}</Typography>
        </OutlineFrame>
      </Grid>
      <Grid size={4}>
        <OutlineFrame label={"Dämmdicke"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography>{insulation?.thickness || "--"}</Typography>
            <Typography>cm</Typography>
          </Stack>
        </OutlineFrame>
      </Grid>
      <Grid size={4}>
        <OutlineFrame label={"Dämmmaterial"}>
          <Typography>{InsulationMaterials[insulation?.material as keyof typeof InsulationMaterials] || "--"}</Typography>
        </OutlineFrame>
      </Grid>
    </Grid>
  );
}
