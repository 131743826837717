export enum EventType {
  freeIsfpInviteSent = "freeIsfpInviteSent",
  paidIsfpInviteSent = "paidIsfpInviteSent",
  freeIsfpInviteAccept = "freeIsfpInviteAccept",
  paidIsfpInviteAccept = "paidIsfpInviteAccept",
  freeConsCertInviteSent = "freeConsCertInviteSent",
  paidConsCertInviteSent = "paidConsCertInviteSent",
  freeConsCertInviteAccept = "freeConsCertInviteAccept",
  paidConsCertInviteAccept = "paidConsCertInviteAccept",
  freeOnePagerInviteSent = "freeOnePagerInviteSent",
  paidOnePagerInviteSent = "paidOnePagerInviteSent",
  // link for a free consumption certificate was opened by a lead
  freeConsCertLinkOpen = "freeConsCertLinkOpen",
  paidConsCertLinkOpen = "paidConsCertLinkOpen",
  // consumption certificate data updated or inserted (could be changed by our enrgy advisors as well)
  consCertDataPersisted = "consCertDataPersisted",
  // finished consumption certificate was delivered to lead (via email or other means)
  consCertDelivered = "consCertDelivered",
  // new lead record was inserted into Dynamo
  newLeadCreated = "newLeadCreated",
  // status quo questionnaire for isfp answered
  isfpStatusQuoAnswrd = "isfpStatusQuoAnswrd",
  // isfp renovation questions answered
  isfpRenAnswrd = "isfpRenAnswrd",
  // once a lead is invited to schnellcheck, there will be metrics for estimated revenue being
  // generated; if the questionnaire hasn't been answered, a fix amount will be recorded; after the
  // answers have been submited, the estimation is adjusted and will depend on the answers provided.
  // therefore, at least two different revenue estimatives will be generated for a lead throughout time,
  // and should be considered (or deduplicated, taking only the last recorded value) when
  // presenting the sum of revenue for a specific time range.
  isfpStatusQuoRevenueEstimate = "isfpStatusQuoRevenueEstimate",
  onePagerRevenueEstimate = "onePagerRevenueEstimate",
}

export type ConsultantMetricsEventCounts = {
  [eventType in EventType]: number;
};

export type ConsultantMetrics = {
  // calculates counts for events from beginning of time until the "to" value of the
  // time range parameter
  absoluteSum: RangeMetrics;
  // for each range of time (from, to), compute the metrics for the consultant.
  // this allows us to compute metrics by intervals such as previous and current month
  // and then further calculate the differences between both periods in the UI if desired
  timeRangeSum: RangeMetrics;
};

export type RangeMetrics = {
  [rangeName: string]: ConsultantMetricsEventCounts;
};

export const getInviteSentCounts = (metrics?: ConsultantMetricsEventCounts) => {
  if (!metrics) {
    return 0;
  }
  return (
    metrics.freeIsfpInviteSent +
    metrics.paidIsfpInviteSent +
    metrics.freeConsCertInviteSent +
    metrics.paidConsCertInviteSent +
    metrics.freeOnePagerInviteSent +
    metrics.paidOnePagerInviteSent
  );
};

export const getInviteAcceptedCounts = (metrics?: ConsultantMetricsEventCounts) => {
  if (!metrics) {
    return 0;
  }
  return metrics.freeIsfpInviteAccept + metrics.paidIsfpInviteAccept + metrics.freeConsCertInviteAccept + metrics.paidConsCertInviteAccept;
};

export const getRevenueEstimate = (metrics?: ConsultantMetricsEventCounts) => {
  if (!metrics) {
    return 0;
  }
  return (metrics.isfpStatusQuoRevenueEstimate + metrics.onePagerRevenueEstimate) / 100; // value is returned as cents from the backend
};

export interface GetTenantsMetricsResponse {
  tenantMetrics: TenantMetrics[];
}

export interface TenantMetrics {
  tenantId: string;
  tenantDisplayName: string;
  metrics: RangeMetrics;
}
