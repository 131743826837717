import { createTheme } from "@mui/material/styles";
import { Themes } from "../types/types";
import novo from "./novo";

const bsh = createTheme(novo, {
  name: Themes.BSH,
  logo: "BSH_by_NOVO.png",
  logoClass: "App-logo",
  palette: {
    mode: "light",
    primary: {
      main: "#ffce00",
      light: "#ffd633",
      dark: "#cca300",
    },
    secondary: {
      main: "#94001d",
    },
  },
});

export default bsh;
