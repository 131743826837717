import { Checkbox, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Grid2";
import OutlineFrame from "../OutlineFrame";
import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";
import { RenovationPlans } from "../../../../../../funnels/isfp/renovations/utils";

export default function RenovationsWishlistComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  const selectedOptions = renovations?.answers?.customerPreferences?.renovationPlans?.map((renovation) => RenovationPlans[renovation]) || [];
  return (
    <OutlineFrame label={"gewünschte Maßnahmen"}>
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
      >
        {[...Object.values(RenovationPlans)].map((renovation) => (
          <Grid key={renovation} size={{ xs: 6, md: 4 }}>
            <FormControlLabel
              control={
                <Checkbox
                  name={`checkbox-${renovation}`}
                  sx={{ "&.Mui-checked": { color: "text.primary" }, cursor: "default" }}
                  id={`${renovation}-renovation-preference-option`}
                  checked={selectedOptions?.includes(renovation)}
                />
              }
              label={renovation}
              sx={{ "& > span": { fontSize: { xs: 14, lg: 16 } }, cursor: "default" }}
            />
          </Grid>
        ))}
      </Grid>
    </OutlineFrame>
  );
}
