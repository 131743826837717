import { useMemo, useState } from "react";
import { AuthError, AuthErrorType } from "../types/Auth";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { cognitoClientId } from "../utils/params";
import { cognitoPoolId } from "../utils/params";

export const useForgotPasswordCode = () => {
  const userPool = useMemo(() => new CognitoUserPool({ ClientId: cognitoClientId(), UserPoolId: cognitoPoolId() }), []);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AuthError | undefined>();
  const [sendCodeSuccess, setSendCodeSuccess] = useState<{ username: string } | undefined>(undefined);

  const sendForgotPasswordCode = async (username: string) => {
    setIsLoading(true);
    setSendCodeSuccess(undefined);
    setError(undefined);
    const user = new CognitoUser({ Pool: userPool, Username: username });
    await new Promise((resolve: (val?: unknown) => void, reject) => {
      user.forgotPassword({
        onSuccess: (success) => {
          setSendCodeSuccess({ username });
          resolve(success);
        },
        onFailure: (err) => {
          switch (err.name) {
            case "UserNotFoundException":
              setError({ type: AuthErrorType.FAILED_SENDING_FORGOT_PASSWORD_CODE_USER_INCORRECT });
              break;
            default:
              setError({ type: AuthErrorType.FAILED_SENDING_FORGOT_PASSWORD_CODE });
              return reject(err);
          }
          return resolve();
        },
      });
    }).finally(() => {
      setIsLoading(false);
    });
  };
  return { isLoading, sendCodeSuccess, error, sendForgotPasswordCode };
};
