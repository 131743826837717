import Grid from "@mui/material/Grid2";
import { UpsertQuestionnaireRequest } from "../../../../../../../types/RenovationQuestionnaire";
import { QuestionComponent } from "../QuestionComponent";
import OutlineFrame from "../OutlineFrame";
import { Typography } from "@mui/material";

export default function SolarComponent({ renovations }: { renovations?: UpsertQuestionnaireRequest }) {
  const options = [
    {
      label: "Ja",
      selected: !!renovations?.answers?.renewablesInformation?.solarInstalled,
    },
    {
      label: "Nein",
      selected: !renovations?.answers?.renewablesInformation?.solarInstalled,
    },
  ];
  return (
    <Grid container spacing={4}>
      <Grid size={6}>
        <QuestionComponent question='Ist Solarthermie vorhanden?' options={options} />
      </Grid>
      {!!renovations?.answers?.renewablesInformation?.solarInstalled && (
        <Grid size={6}>
          <OutlineFrame label={"Größe"}>
            <Typography>{renovations?.answers?.renewablesInformation?.solarCapacity}</Typography>
          </OutlineFrame>
        </Grid>
      )}
      {!renovations?.answers?.renewablesInformation?.solarInstalled && <Grid size={6} />}
    </Grid>
  );
}
