import { useMediaQuery, useTheme } from "@mui/material";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Fragment, useState } from "react";

interface NovoMultiSelectProps extends Omit<AutocompleteProps<string, undefined, undefined, undefined>, "renderInput" | "value"> {
  label: string;
  value: Array<string>;
  error?: boolean;
  required?: boolean;
  helperText?: string;
  onUpdate?: (value: Array<string>) => void;
}

export default function NovoMultiSelect({ label, value, onUpdate, error, helperText, ...props }: NovoMultiSelectProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [_value, setValue] = useState(value || []);
  const name = label.toLowerCase().replace(" ", "-");

  const handler = (event: React.SyntheticEvent, value: Array<string>) => {
    setValue(value);
    if (onUpdate) {
      onUpdate(value);
    }
  };

  return (
    <Autocomplete
      multiple
      id={`${name}-tags-outlined`}
      value={_value}
      filterSelectedOptions
      onChange={handler}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            <>
              {label} {props.required && <sup>*</sup>}
            </>
          }
          error={error}
          helperText={helperText}
          slotProps={{
            inputLabel: { shrink: !!_value.length },
          }}
        />
      )}
      size={isMobile ? "small" : "medium"}
      {...props}
    />
  );
}
