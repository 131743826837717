import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FunctionComponent } from "react";

export const Bye: FunctionComponent = () => {
  return (
    <>
      <Stack spacing={0} alignItems='center' direction='column' justifyContent='center' height={"100%"}>
        <TaskAltIcon htmlColor='#458946' fontSize='large' />
        <Typography style={{ fontSize: "calc(8px + 2vw)", fontWeight: 400 }} data-cy='thank-you-text'>
          Danke für Ihre Angaben!
        </Typography>
        <Typography style={{ fontSize: "calc(8px + 1vw)", fontWeight: 400 }} color='#707172'>
          Wir werden uns kurzfristig mit Ihnen in Verbindung setzen.
        </Typography>
        <Typography style={{ fontSize: "calc(8px + 1vw)", fontWeight: 400 }} color='#707172'>
          Sie können dieses Fenster jetzt schließen.
        </Typography>
      </Stack>
    </>
  );
};
