import { useCallback } from "react";
import { PurchaseOrder } from "../types/PurchaseOrder";
import { Product } from "../types/types";
import { apiEndpoint } from "../utils/params";
import { useUnauthAxios } from "./useUnauthAxios";

const API_ENDPOINT = `${apiEndpoint()}`;

type OrderItemRequest = {
  id: string;
  product: Product;
};

type PurchaseOrderRequest = {
  // this is bubble's lead id; if set, we can check against bubble's API if
  // there are discounts / free vouchers for the items being requested
  bubbleLeadId?: string;
  leadId?: string;
  voucherId?: string;
  items?: OrderItemRequest[];
};

export const useCreatePurchaseOrder = () => {
  const { unauthAxios, error: createOrderError, running: createOrderRunning, data: createdPurchaseOrder } = useUnauthAxios<PurchaseOrder>();

  const createPurchaseOrder = useCallback(
    async (order: PurchaseOrderRequest) =>
      await unauthAxios({
        method: "POST",
        baseURL: `${API_ENDPOINT}/orders`,
        data: JSON.stringify(order),
      }),
    [unauthAxios],
  );

  return { createPurchaseOrder, createOrderError, createOrderRunning, createdPurchaseOrder };
};
