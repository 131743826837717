import HelpIcon from "@mui/icons-material/Help";
import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from "@mui/material";
import { FunctionComponent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Property } from "../../types/types";
import { convertClassToColor, formatEuros, formatNum } from "../../utils/utils";

export const EnergyStatusTable: FunctionComponent<Property> = (props) => {
  const intl = useIntl();

  return (
    <>
      <TableContainer component={Paper} style={{ maxWidth: 500 }}>
        <Table size='small' aria-label='Energy Efficiency'>
          <TableBody>
            <TableRow>
              <TableCell style={{ backgroundColor: "black", color: "white" }}>
                <FormattedMessage id='app.energy.class' />
              </TableCell>
              <TableCell align='center' style={{ backgroundColor: `${convertClassToColor(props.analysis.consumption.energyClass)}`, color: "black" }}>
                {props.analysis.consumption.energyClass}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id='app.energy.co2' />
              </TableCell>
              <TableCell align='right'>{formatNum(props.analysis.consumption.cO2Emission)} kg</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id='app.energy.primary' />
              </TableCell>
              <TableCell align='right'>{formatNum(props.analysis.consumption.primaryEnergy)} kWh/m2</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id='app.energy.final' />
              </TableCell>
              <TableCell align='right'>{formatNum(props.analysis.consumption.finalEnergy)} kWh/m2</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id='app.energy.requirement' />
              </TableCell>
              <TableCell align='right'>{formatNum(props.analysis.consumption.energyRequirement)} kWh</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id='app.energy.consumption' />
              </TableCell>
              <TableCell align='right'>
                <Stack direction='row' justifyContent='right'>
                  {formatNum(props.analysis.consumption.energyConsumption)} kWh &nbsp;
                  <Tooltip title={intl.formatMessage({ id: "app.energy.consumption.tooltip" })}>
                    <HelpIcon fontSize='small' />
                  </Tooltip>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id='app.energy.cost' />
              </TableCell>
              <TableCell align='right'>{formatEuros(props.analysis.consumption.costPerYear)} €</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
