import { createContext } from "react";
import { AuthSession } from "./types/Auth";

export const AuthContext = createContext<AuthSession>({
  isLoading: true,
  refreshSession: () => {},
  login: (): void => {},
  logout: () => {},
  updatePasswordOnRequireChange: () => {},
  clearActionNeeded: () => {},
});
