import { Alert, Backdrop, Checkbox, CircularProgress, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ActionContainer from "../../../components/ActionContainer";
import { MailingSettings, useGetMailingSettings, usePutMailingSettings } from "../../../hooks";

export const MailingPreferences = () => {
  const [settings, setMailingSettings] = useState<MailingSettings>();
  const [searchParams] = useSearchParams();
  const leadId = searchParams.get("leadId");
  const email = searchParams.get("email");

  const missingParamsError = !leadId && !email;

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();
  const { getMailingSettings, data: currSetting, error: getSettingsErr, running: getIsRunning } = useGetMailingSettings();
  const { putMailingSettings, data: updatedSetting, error: postSettingsErr, running: postIsRunning } = usePutMailingSettings();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (updatedSetting) {
      setMailingSettings(updatedSetting);
    } else if (currSetting) {
      setMailingSettings(currSetting);
    }
  }, [currSetting, isLoading, updatedSetting]);

  useEffect(() => {
    if (getSettingsErr) {
      return setError("Fehler bei der Abfrage Ihrer Einstellungen");
    }
    if (postSettingsErr) {
      return setError("Fehler beim Speichern Ihrer Einstellungen");
    }
  }, [getSettingsErr, postSettingsErr]);

  useEffect(() => {
    if (leadId) {
      getMailingSettings({ leadId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId]);
  useEffect(() => {
    if (email) {
      getMailingSettings({ email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    setIsLoading(getIsRunning || postIsRunning);
  }, [getIsRunning, postIsRunning]);

  const callUpdateMailingSettings = (settings: MailingSettings) => {
    if (settings?.email) {
      setError(undefined);
      putMailingSettings(settings, leadId ? leadId : undefined);
    }
  };

  const onAcceptOfferChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    if (settings) {
      callUpdateMailingSettings({ ...settings, acceptOffers: checked });
    }
  };

  if (missingParamsError) {
    return (
      <ActionContainer minWidth={600}>
        <Typography variant='h4'>Fehlende URL Parameter</Typography>
        <Alert sx={{ marginTop: 8 }} severity='error'>
          Entweder email oder leadId fehlen
        </Alert>
      </ActionContainer>
    );
  }

  return (
    <ActionContainer>
      <Typography variant='h4'>Ändern Sie Ihre E-Mail-Einstellungen</Typography>
      <FormGroup sx={{ mt: 2 }}>
        <FormControlLabel
          control={<Checkbox checked={settings?.acceptOffers === undefined ? true : settings.acceptOffers} onChange={onAcceptOfferChange} />}
          label='Neue Angebote bekommen'
        />
      </FormGroup>
      {error && (
        <Alert sx={{ mt: 2 }} title='Fehler' severity='error'>
          {error}
        </Alert>
      )}
      {updatedSetting && (
        <Alert sx={{ mt: 2 }} title='Erfolg'>
          Einstellungen gespeichert
        </Alert>
      )}
      {isLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
    </ActionContainer>
  );
};
