import { useEffect, useState } from "react";

export default function useImage(fileName: string) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [image, setImage] = useState();

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const imageData = await import(`../imgs/${fileName}`);
        setImage(imageData.default);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchImage();
  }, [fileName]);
  return { loading, error, image };
}
