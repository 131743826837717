import { Box, Skeleton } from "@mui/material";
import useImage from "../../hooks/useImage";

type SlowLoadingImageProps = {
  assetPath: string;
  // dimensions are important because the loading box will need to be the same size
  width?: number | string;
  height?: number | string;
};

export const SlowLoadingImage = ({ assetPath, width, height }: SlowLoadingImageProps) => {
  const { image, loading } = useImage(assetPath);

  height = height ?? "100%";
  width = width ?? "100%";

  const style: React.CSSProperties = {
    objectFit: "contain",
  };
  if (width || height) {
    delete style.objectFit;
  }

  if (loading) {
    return (
      <Box display='flex' justifyContent='center'>
        <Skeleton animation='wave' variant='rectangular' height={height} width={width} />
      </Box>
    );
  }
  return <img src={image} width={width} height={height} style={style} />;
};
