import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import { ConsumptionCertificateList } from "./ConsumptionCertificateList";

export default function ConsumptionCertificate() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        ml: "56px",
        width: "calc(100% - 56px)",
      }}
    >
      <Grid
        container
        spacing={2}
        columnSpacing={{
          xs: 2,
          xl: 4,
        }}
        sx={{
          mt: 2,
          maxWidth: "xl",
          mx: { xs: 0, xl: "auto" },
        }}
      >
        <ConsumptionCertificateList />
      </Grid>
    </Box>
  );
}
