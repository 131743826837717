declare global {
  interface Window {
    google: unknown;
  }
}

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { HTMLAttributes, SyntheticEvent, useEffect, useMemo, useRef, useState } from "react";
import { GermanState, getGermanState } from "../../pages/funnels/consumption-certificate/ConsumptionCertificateRequestEnums";

const GOOGLE_MAPS_API_KEY = "AIzaSyCYHmN88fLm5mIfH4eJNNX-Hy69BSnTLqI";

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService: { current: google.maps.places.AutocompleteService | undefined } = { current: undefined };
const placesService: { current: google.maps.places.PlacesService | undefined } = { current: undefined };

interface NovoAddressAutocompleteProps
  extends Omit<AutocompleteProps<google.maps.places.AutocompletePrediction, undefined, undefined, undefined>, "renderInput" | "options"> {
  id: string;
  data_cy: string;
  address: google.maps.places.AutocompletePrediction | null;
  addressError?: string;
  updateAddress: (newAddress: google.maps.places.AutocompletePrediction | null) => void;
}

// Heavily based on https://github.com/mui/material-ui/blob/v6.0.2/docs/data/material/components/autocomplete/GoogleMaps.tsx
export default function NovoAddressAutocomplete({ id, data_cy, address, updateAddress, addressError, disabled, ...props }: NovoAddressAutocompleteProps) {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<readonly google.maps.places.AutocompletePrediction[]>([]);
  const [missingHouseNumber, setMissingHouseNumber] = useState(false);
  const loaded = useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`, document.querySelector("head"), "google-maps");
    }
    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      debounce((request: { input: string }, callback: (results: google.maps.places.AutocompletePrediction[] | null) => void) => {
        autocompleteService.current?.getPlacePredictions({ ...request, language: "de", region: "de", componentRestrictions: { country: "de" } }, callback);
      }, 400),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!placesService.current && window.google) {
      if (!window.google.maps.places.PlacesService) {
        window.google.maps.importLibrary("places");
      } else {
        placesService.current = new window.google.maps.places.PlacesService(document.getElementById("map") as HTMLDivElement);
      }
    }
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(address ? [address] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results: google.maps.places.AutocompletePrediction[] | null) => {
      if (active) {
        let newOptions: readonly google.maps.places.AutocompletePrediction[] = [];

        if (address) {
          newOptions = [address];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [address, inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === "string" ? option : option?.description ?? "")}
      filterOptions={(x) => x}
      id={id}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={address}
      noOptionsText='Keine Adresse'
      data-cy={data_cy}
      disabled={disabled}
      onChange={(_event: SyntheticEvent, newValue: google.maps.places.AutocompletePrediction | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        updateAddress(newValue);
        setMissingHouseNumber(!includesHouseNumber(newValue));
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={true}
          label='Straße und Hausnummer'
          error={!!addressError || missingHouseNumber}
          helperText={addressError ? addressError : missingHouseNumber ? "Geben Sie die Hausnummer an" : undefined}
          disabled={disabled}
          fullWidth
          slotProps={{
            inputLabel: { shrink: !!address || !!inputValue },
          }}
        />
      )}
      renderOption={(props: HTMLAttributes<HTMLLIElement> & { key: string }, option, state) => {
        const { key, ...optionProps } = props;
        const matches = option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );
        return (
          <li key={key} {...optionProps} data-cy={`location-index-${state.index}`}>
            <Grid container sx={{ alignItems: "center" }}>
              <Grid size={12} sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid size={12} sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                {parts.map((part, index) => (
                  <Box key={index} component='span' sx={{ fontWeight: part.highlight ? "bold" : "regular" }}>
                    {part.text}
                  </Box>
                ))}
                <Typography variant='body2' sx={{ color: "text.secondary" }}>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      sx={{ flexGrow: 1 }}
      {...props}
    />
  );
}

export const includesHouseNumber = (address: google.maps.places.AutocompletePrediction | null): boolean => {
  // https://developers.google.com/maps/documentation/javascript/geocoding
  return ["premise", "subpremise", "street_address", "establishment"].findIndex((expectedAddressType) => !!address?.types.includes(expectedAddressType)) >= 0;
};

export interface AddressDetails {
  address?: string;
  postalCode?: string;
  town?: string;
  state?: GermanState;
}

export const getAddressDetails = (autocompleteAddress: google.maps.places.AutocompletePrediction, callback: (address: AddressDetails) => void) => {
  if (placesService.current && autocompleteAddress.place_id) {
    placesService.current.getDetails({ placeId: autocompleteAddress.place_id, language: "de" }, (details) => {
      let route, number, postalCode, town, state;
      details?.address_components?.forEach((component) => {
        if (component.types.includes("postal_code")) {
          postalCode = component.long_name;
        }
        if (component.types.includes("locality")) {
          town = component.long_name;
        }
        if (component.types.includes("route")) {
          route = component.long_name;
        }
        if (component.types.includes("street_number")) {
          number = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          state = getGermanState(component.long_name);
        }
      });
      callback({ address: `${route} ${number}`, postalCode, town, state });
    });
  }
};
